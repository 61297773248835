import React from "react";

// Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import {PaginationMeta} from "../../store/types";
import TableFilters, {Filter} from "./TableFilters";
import Pagination from "../Pagination/Pagination";

export type Callback = (row: any) => string | JSX.Element;

export interface RecordListTableColumnProps {
    headerTitle: string | JSX.Element | JSX.Element[] | Callback;
    accessor: string | Callback;
    headerClassName?: string | Callback;
    colClassName?: string;
}

export interface RecordListTableProps<T extends object = {}> {
    columns: RecordListTableColumnProps[];
    data: T[] | undefined;
    paginationMeta?: PaginationMeta;
    loadMoreResults?: (pageNumber: number, pageSize: number, filter?: string) => void;
    rowClassColumn?: string
    rowIdColumn?: string
    noRecordsLabel?: React.ReactNode
    hideHeader?: boolean
    filters?: Filter[]
    activeFilter?: string
    onRowClick?: (row: T) => void;
}

export function RecordListTable<T extends object>({
                                                      columns,
                                                      data,
                                                      activeFilter,
                                                      paginationMeta,
                                                      loadMoreResults,
                                                      rowClassColumn,
                                                      rowIdColumn,
                                                      noRecordsLabel,
                                                      hideHeader,
                                                      filters,
                                                      onRowClick
                                                  }: RecordListTableProps<T>) {

    const renderNote = () => {
        if (!noRecordsLabel) {
            return <h3>No records found.</h3>
        }
        return noRecordsLabel
    }

    const renderBody = () => {
        if (data && data.length > 0) {
            return data.map((row: T, index) => {
                // @ts-ignore
                let rowId = rowIdColumn != null ? row[rowIdColumn] : index;
                // @ts-ignore
                let rowClass = rowClassColumn != null ? row[rowClassColumn] : ``;
                rowClass = onRowClick ? `${rowClass} clickable` : rowClass;

                return <TableRow className={rowClass} key={rowId} onClick={() => onRowClick && onRowClick(row) }>
                    {columns.map((column,colIndex) => {
                        const rowColClassName = column && column.colClassName ? column.colClassName : '';
                        const accessor = column.accessor;
                        const columnId = `${rowId}-${colIndex}`;
                        if (typeof accessor === "string") {
                            // @ts-ignore
                            return <TableCell key={columnId} className={rowColClassName}>{row[accessor]}</TableCell>;
                        }

                        // @ts-ignore
                        return <TableCell key={columnId} className={rowColClassName}>{accessor.call({}, row)}</TableCell>
                    })}
                </TableRow>
            })
        }

        return <TableRow>
            <TableCell colSpan={columns.length}>{renderNote()}</TableCell>
        </TableRow>
    }

    return <>
        {filters && filters.length > 0 && <TableFilters activeFilter={activeFilter} filters={filters} onClick={(filterValue) => {
            if (loadMoreResults) {
                loadMoreResults(paginationMeta?.current_page || 1, paginationMeta?.per_page || 20, filterValue)
            }
        }}/>}
        <TableContainer>
            <Table className={`ps-table`} aria-label="simple table">
                {!hideHeader ? <TableHead>
                    <TableRow>
                        {columns.map(column => {
                            let headerClassName = ``
                            if (column && column.headerClassName) {
                                if (typeof column.headerClassName === "string") {
                                    headerClassName = column.headerClassName
                                } else {
                                    // @ts-ignore
                                    headerClassName = column.headerClassName.call({})
                                }
                            }

                            if (typeof column.headerTitle === 'string') {
                                return <TableCell className={headerClassName}>{column.headerTitle}</TableCell>
                            }

                            // @ts-ignore
                            return <TableCell className={headerClassName}>{column.headerTitle.call({})}</TableCell>
                        })}
                    </TableRow>
                </TableHead> : null}
                <TableBody>{renderBody()}</TableBody>
                <TableFooter>
                    <TableCell colSpan={columns.length}>
                        <Pagination paginationMeta={paginationMeta}
                                    onChangePage={(event, page) => {
                                        loadMoreResults && loadMoreResults(page, paginationMeta?.per_page || 20);
                                    }} />
                    </TableCell>
                </TableFooter>
            </Table>
        </TableContainer>
    </>
}

export default RecordListTable;
