import React from "react";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import Utils from "../../../services/Utils";

type RequestDepositDialogProps = {
    showDialog: boolean
    onConfirm: () => void
    onCancel: () => void
    amount?: number
}

/**
 * @param showDialog
 * @param onConfirm
 * @param onCancel
 * @param amount
 *
 * @constructor
 */
const RequestDepositDialog: React.FC<RequestDepositDialogProps> = ({
                                                                       showDialog,
                                                                       onConfirm,
                                                                       onCancel,
                                                                       amount
                                                                   }) => {
    const forAmount = !!amount ? <>of <b>{Utils.formatAmount(amount)}</b> </> : ``
    return <ConfirmDialog
        showDialog={showDialog}
        confirmButtonText={`Request Secure funds`}
        onCancel={onCancel} onConfirm={onConfirm}
        title={`Request to secure funds`}>
        <p>Are you sure you want to secure funds {forAmount}for this milestone?</p>
    </ConfirmDialog>
}

export default RequestDepositDialog;
