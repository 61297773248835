import {Reducer} from "redux"
import {ProfileActionTypes, ProfileState} from "./types";
import {ToastActionTypes} from "../types";

export const initialState: ProfileState = {
    data: undefined,
    loading: false,
    notifications: {
        errors: [],
        alerts: [],
        infos: [],
        warnings: [],
        success: []
    },
    toasts: [],
}

const reducer: Reducer<ProfileState> = (state = initialState, action) => {
    switch (action.type) {
        case ProfileActionTypes.UPDATE_REQUEST:
        case ProfileActionTypes.FETCH_REQUEST:
            return {
                ...state, loading: true, toasts: [], notifications: {
                    errors: [],
                    alerts: [],
                    infos: [],
                    warnings: [],
                    success: []
                }
            }

        case ProfileActionTypes.FETCH_SUCCESS:
            return {...state, data: action.payload, loading: false}

        case ProfileActionTypes.FETCH_ERROR:
            return {
                ...state, loading: false, notifications: {
                    ...state.notifications,
                    errors: action.payload
                }
            }

        case ToastActionTypes.RESET_TOASTS:
            return {...state, toasts: []}

        case ToastActionTypes.ADD_TOAST:
            const existingToasts = state.toasts ?? [];
            return {...state, toasts: [...existingToasts, action.payload]}

        default:
            return state;
    }
}
export {reducer as ProfileReducer}
