import ResourceManagerFactory, {make} from "../pyntax/lib/data/ResourceManagerFactory";
import ResourceUrls from "../pyntax/lib/data/ResourceUrls";
import * as RequestManager from "../pyntax/lib/data/RequestManager";

class DataApi {
    private readonly _verification;
    private readonly _payableAgreement;
    private readonly _viewPayableAgreement;
    private readonly _incomingPayableAgreement;
    private readonly _incomingPayableAgreementMilestone;
    private readonly _milestones;
    private readonly _user;
    private readonly _user_registration;
    private readonly _bank_accounts;
    private readonly _recipient: ResourceManagerFactory
    private readonly _profile: ResourceManagerFactory;
    private readonly _dashboard: ResourceManagerFactory;
    private readonly _businessEntity: ResourceManagerFactory;
    private readonly _attachment: ResourceManagerFactory;
    private readonly _transaction: ResourceManagerFactory;
    private readonly _twoFactorAuth: ResourceManagerFactory;
    private readonly _thirdPartyConnections: ResourceManagerFactory;
    private readonly _socialLogin: ResourceManagerFactory;

    constructor() {
        const authAxios = RequestManager.withAuth(RequestManager.factory());

        this._verification = make(new ResourceUrls("verify"), authAxios);
        this._payableAgreement = make(new ResourceUrls("payable_agreement"), authAxios);
        this._viewPayableAgreement = make(new ResourceUrls("view_payable_agreement"), authAxios);
        this._incomingPayableAgreement = make(new ResourceUrls("incoming_payable_agreement"), authAxios);
        this._milestones = make(new ResourceUrls("milestones"), authAxios);
        this._user = make(new ResourceUrls("user"), authAxios);
        this._user_registration = make(new ResourceUrls("register"), authAxios);
        this._incomingPayableAgreementMilestone = make(new ResourceUrls("incoming_payable_agreement_milestone"), authAxios);
        this._bank_accounts = make(new ResourceUrls('bank_accounts'), authAxios);
        this._recipient = make(new ResourceUrls("recipients"), authAxios);
        this._profile = make(new ResourceUrls("profile"), authAxios);
        this._dashboard = make(new ResourceUrls("dashboard"), authAxios);
        this._businessEntity = make(new ResourceUrls("business_entities"), authAxios);
        this._attachment = make(new ResourceUrls("attachments"), authAxios);
        this._twoFactorAuth = make(new ResourceUrls("tfa"), authAxios);
        this._thirdPartyConnections = make(new ResourceUrls("third_party_connections"), authAxios);
        this._socialLogin = make(new ResourceUrls("login_with_social"), authAxios)
        this._transaction = make(new ResourceUrls("transactions"), authAxios)
    }

    get socialLogin(): ResourceManagerFactory {
        return this._socialLogin;
    }

    get transaction(): any {
        return this._transaction;
    }

    get thirdPartyConnections(): any {
        return this._thirdPartyConnections;
    }

    get dashboard() {
        return this._dashboard;
    }

    get profile() {
        return this._profile;
    }

    get recipient() {
        return this._recipient;
    }

    get user_registration() {
        return this._user_registration;
    }

    get viewPayableAgreement() {
        return this._viewPayableAgreement;
    }

    get incomingPayableAgreement() {
        return this._incomingPayableAgreement;
    }

    get incomingPayableAgreementMilestone() {
        return this._incomingPayableAgreementMilestone;
    }

    get verification() {
        return this._verification;
    }

    get payableAgreement() {
        return this._payableAgreement;
    }

    get milestones() {
        return this._milestones;
    }

    get user() {
        return this._user;
    }

    get bank_accounts() {
        return this._bank_accounts;
    }

    get business_entity(): any {
        return this._businessEntity;
    }

    get attachment(): any {
        return this._attachment;
    }

    get twoFactorAuth(): any {
        return this._twoFactorAuth;
    }
}

export default DataApi;
