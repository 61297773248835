import React from 'react';
import classNames from "classnames";
import {renderPayableAgreementBadge} from "../../classes";
import {renderPayableAgreementStatusLabel} from "../../labels";
import {UserType} from "../../enums";

interface Props {
    status?: string
    userType?: UserType
}

/**
 *
 * @param status
 * @constructor
 */
export const StatusPill: React.FunctionComponent<Props> = ({status, userType}) => {
    if (!status) {
        return null;
    }

    return <span data-status={status}
                 className={classNames('badge', renderPayableAgreementBadge(status))}>
                    {renderPayableAgreementStatusLabel(status, userType)}</span>
}

export default StatusPill;
