import React, {useState} from "react";
import {DangerButton} from "../../Buttons";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import {cancelAgreementRequest} from "../../../store/payableAgreement/action";
import {useDispatch} from "react-redux";

interface Props {
    payableAgreementId: number
}

/**
 * @param props
 * @constructor
 */
const CancelAgreementButton = (props: Props) => {
    const {payableAgreementId} = props,
        [showModal, setShowModal] = useState<boolean>(false),
        dispatch = useDispatch(),
        handleOnConfirm = () => {
            setShowModal(false);
            dispatch(cancelAgreementRequest(payableAgreementId));
        }

    return <>
        <DangerButton className="float-right" onClick={() => setShowModal(true)}>Cancel agreement</DangerButton>
        <ConfirmDialog showDialog={showModal} title={`Cancel agreement`}
                       onCancel={() => setShowModal(false)} onConfirm={handleOnConfirm}>
            <div className="row">
                <div className="col">
                    <p>Are you sure you want to cancel this agreement. All the milestones will be cancelled. Any money
                        debited would be refunded back.</p>
                    <p className="small mt-4">* Any money debited would be refunded minus the fees</p>
                </div>
            </div>
        </ConfirmDialog>
    </>
}

export default CancelAgreementButton
