import React from "react";
import {BankAccount} from "../../models/BankAccount";
import {TextInput} from "../../components/Forms/Fields/Input";
import {ConnectForm} from "../../components/Forms/ConnectedForm";
import FormGroup from "../../components/Forms/FormGroup";
import StatusPill from "../../components/BankAccounts/StatusPill";

type Props = {
    isUpdating: boolean
}

const BankAccountFields = (props: Props) => {
    return <ConnectForm<BankAccount>>
        {({register, formState: {errors}, getValues}) => {
            return <div className="row push">
                <div className="col">
                    <TextInput errors={errors} {...register('nickname', {
                        required: true
                    })} label={`Account name`} placeholder="Eg. ANZ Personal"/>
                    {!props.isUpdating && <>
                        <TextInput errors={errors} {...register('bsbBranchCode', {
                            required: true,
                            maxLength: 6,
                        })} label={`BSB`} placeholder="Enter BSB"
                                   readOnly={props.isUpdating}/>
                        <TextInput errors={errors} {...register('accountNumber', {
                            required: true,
                            maxLength: 9
                        })} label={`Bank account number`} placeholder="Enter bank account number"
                                   readOnly={props.isUpdating}/>
                    </>}
                    {props.isUpdating && <>
                        <TextInput errors={errors} {...register('identifier_label', {
                            required: true
                        })} label={`Identified label`} placeholder="Identified label" readOnly/>
                        <TextInput readOnly label={'Connection type'} value={getValues()?.connection_type === 'Zepto_IAV' ? 'Online banking login' : 'Manual BSB & Bank account'} />
                    </>}
                </div>
                {props.isUpdating  && <div className="col">

                    <FormGroup className={'border-0 p-0'} label={'Status'} fieldName={'status'}>
                        <div className="d-block border-0">
                            <StatusPill status={(getValues())?.status}/>
                        </div>
                    </FormGroup>
                    <TextInput errors={errors} {...register('ddr_connection_request_on_display', {
                        required: true
                    })} label={`Connected on`} placeholder="Connected on" readOnly/>
                </div>}
            </div>
        }}
    </ConnectForm>
}

export default BankAccountFields
