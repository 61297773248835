import {Store, createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import {ApplicationState, createRootReducer, rootSaga} from "./store";
import {createBrowserHistory} from 'history'
import {routerMiddleware} from "connected-react-router";

export const history = createBrowserHistory();

export default function configureStore(initialSate: ApplicationState): Store<ApplicationState> {
    const sagaMiddleware = createSagaMiddleware(), enhancers = [],
        windowIfDefined = typeof window === 'undefined' ? null : (window as any);

    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const store = createStore(
        createRootReducer(history),
        initialSate,
        compose(
            applyMiddleware(
                thunk,
                sagaMiddleware,
                routerMiddleware(history)
            ),
            ...enhancers
        )
    )
    sagaMiddleware.run(rootSaga);

    return store;
}
