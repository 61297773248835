import {all, call, put, takeEvery} from 'redux-saga/effects';
import {RecipientActionTypes} from "./types";
import {fetchError, fetchSuccess, fetchListSuccess} from "./action";
import ServiceManager from "../../services/ServiceManager";
import {Recipient} from "../../models/Recipient";
import {AxiosResponse} from "axios";
import {PaginationMeta} from "../types";
import {handleError} from "../saga";
import {Events, makeFactory} from "../events";
import {EntityTypes} from "../../models/types";
import {addToast} from "../action";
import {push} from "connected-react-router";
import {ApiResponse} from "../../models/Api/response";

/**
 * @param action
 */
function* handleFetch(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.recipient, ServiceManager.dataApi.recipient.getById
        ], action.payload);
        const recipient: Recipient = response.data.data as Recipient;
        yield put(fetchSuccess(recipient));

    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleListRequest(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.recipient,
                ServiceManager.dataApi.recipient.findAll
            ],
            action.payload.pageSize || 20,
            action.payload.pageNumber || 1,
            {}
        );

        const listOfPayableAgreements: Recipient[] = response.data.data as Recipient[];
        const paginationMeta: PaginationMeta = response.data.meta as PaginationMeta;
        yield put(fetchListSuccess(listOfPayableAgreements, paginationMeta))
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

/**
 * @param action
 */
function* handleUpdate(action: any) {
    try {
        const response: AxiosResponse<ApiResponse<Recipient>> = yield call(
            [
                ServiceManager.dataApi.recipient,
                ServiceManager.dataApi.recipient.update
            ],
            action.payload.id,
            action.payload.data
        )

        const recipient: Recipient = response.data.data
        yield put(fetchSuccess(recipient));
        yield put(addToast("Contact updated successfully.", "success"));

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.RECIPIENT.toString(), Events.UPDATED, recipient.id));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

/**
 *
 * @param action
 */
function* handleCreate(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.recipient,
                ServiceManager.dataApi.recipient.create
            ],
            action.payload.data
        )
        const recipient: Recipient = response.data.data as Recipient;
        yield put(fetchSuccess(recipient));
        yield put(addToast("Contact saved successfully.", "success"));

        if (action.payload.redirect) {
            yield put(push(`/recipients/${recipient.id}/edit`));
        }

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.RECIPIENT.toString(), Events.CREATED, recipient.id));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

/**
 * @param action
 */
function* handleDelete(action: any) {
    try {
        yield call(
            [
                ServiceManager.dataApi.recipient,
                ServiceManager.dataApi.recipient.delete
            ],
            action.payload.id,
            {}
        )
        yield put(addToast("Contact deleted successfully.", "success"));
        yield put(push(`/recipients`));

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.RECIPIENT.toString(), Events.DELETED, action.payload.id));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

function* watchFetchRequest() {
    yield takeEvery(RecipientActionTypes.FETCH_LIST_REQUEST, handleListRequest)
    yield takeEvery(RecipientActionTypes.FETCH_REQUEST, handleFetch)
    yield takeEvery(RecipientActionTypes.UPDATE_REQUEST, handleUpdate)
    yield takeEvery(RecipientActionTypes.CREATE_REQUEST, handleCreate)
    yield takeEvery(RecipientActionTypes.DELETE_REQUEST, handleDelete)
}

function* payableAgreementSaga() {
    yield all([
        watchFetchRequest()
    ]);
}

export default payableAgreementSaga;
