import ServiceManager from "../../../services/ServiceManager";
import ResourceUrls from "./ResourceUrls";
import {AxiosError, AxiosInstance} from "axios";
import {useHistory} from "react-router-dom";

/**
 * @type {{BASE_RESOURCE_URI: string, RESOURCE_BY_ID: string}}
 */
export const CRUD = {
    BASE_RESOURCE_URI: "BASE_RESOURCE_URI",
    RESOURCE_BY_ID: "RESOURCE_BY_ID",
}


/**
 * @param errorCallback
 */
export function useHandleRequestError(errorCallback: (args: any) => void) {
    const history = useHistory();

    return function (errorResponse: AxiosError) {
        if (errorResponse && errorResponse.response && errorResponse.response.status) {
            errorCallback = errorCallback || function () {
            }
            switch (errorResponse.response.status) {
                case 401:
                    ServiceManager.storageService.clearAll().then(function () {
                        ServiceManager.storageService.setRedirectUrlAfterLoggingIn(window.location.pathname + '?' + window.location.search);

                        history.push('/login');
                    });
                    break;
            }
        }

        if (errorCallback) {
            errorCallback(errorResponse);
        }
    }
}

/**
 *
 * @param resourceUrls
 * @param withAuthAxios
 */
export function make(resourceUrls: ResourceUrls, withAuthAxios: AxiosInstance) {
    return new ResourceManagerFactory(resourceUrls, withAuthAxios)

}

class ResourceManagerFactory {
    private resourceUrls: ResourceUrls;
    private requestManager: AxiosInstance;

    /**
     * @param resourceUrls
     * @param requestManager
     */
    constructor(resourceUrls: ResourceUrls, requestManager: AxiosInstance) {
        this.resourceUrls = resourceUrls;
        this.requestManager = requestManager;
    }

    /**
     *
     * @param id
     * @returns {*}
     */
    public getResourceByIdUrl(id: string) {
        return this.resourceUrls.getResourceByIdUrl(id);
    }

    /**
     *
     * @returns {*}
     */
    public getCollectionUrl() {
        return this.resourceUrls.getCollectionUrl();
    }


    /**
     * @param id
     * @returns {*}
     */
    public getById(id: string) {
        const URL = this.getResourceByIdUrl(id);
        return this.get(URL);
    }

    /**
     *
     * @param url
     * @returns {*}
     */
    public get(url: string) {
        return this.requestManager.get(url);
    }

    /**
     *
     * @param url
     * @param data
     * @returns {Promise<AxiosResponse<any>> | IDBRequest<IDBValidKey> | Promise<void>}
     */
    public put(url: string, data: any) {
        return this.requestManager.put(url, data, {timeout: 10000});
    }


    /**
     *
     * @param url
     * @param data
     * @returns {Promise<AxiosResponse<any>> | IDBRequest<IDBValidKey> | Promise<void>}
     */
    public deleteReq(url: string, data: any) {
        return this.requestManager.delete(url, data);
    }

    /**
     *
     * @param url
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    public post(url: string, data: any) {
        return this.requestManager.post(url, data);
    }

    public downloadFile(id: string, fileName: string, data: any = {}) {
        return this.requestManager.put(this.getResourceByIdUrl(id), data, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
    }

    /**
     * @param data
     */
    public uploadFile(data: any) {
        const formData = new FormData();
        Object.keys(data).forEach((key: string) => {
            formData.append(key, data[key]);
        });

        return this.requestManager.post(this.resourceUrls.getCollectionUrl(), formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    /**
     *
     * @param data
     */
    public create(data: any) {
        return this.post(this.resourceUrls.getCollectionUrl(), data);
    }

    /**
     * @param id
     * @param data
     * @returns {Promise<T>}
     */
    public update(id: string, data: any) {
        const URL = this.getResourceByIdUrl(id);
        return this.put(URL, data);
    }

    /**
     *
     * @param id
     * @param data
     * @returns {*}
     */
    public delete(id: string, data: any) {
        const URL = this.getResourceByIdUrl(id);
        return this.deleteReq(URL, data);
    }


    public prependGetVariables(additionalParameters: any) {
        let _urlWithGetStrings = "";
        Object.keys(additionalParameters).forEach((key) => {
            _urlWithGetStrings += (key + "=" + additionalParameters[key] + "&");
        });

        return "&" + _urlWithGetStrings;
    }

    /**
     * @param pageSize
     * @param pageNumber
     * @param additionalParameters
     *
     * @returns {*}
     */
    public findAll(pageSize: number, pageNumber: number, additionalParameters?: any) {
        additionalParameters = additionalParameters || {};
        pageSize = pageSize || 20;
        pageNumber = pageNumber || 1;

        const URL = this.resourceUrls.getCollectionUrl() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber;
        return this.get(URL + this.prependGetVariables(additionalParameters));
    }
}

export default ResourceManagerFactory;
