import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";


import {PaginationMeta} from "../../store/types";
import {ApplicationState} from "../../store";
import {fetchListRequest} from "../../store/businessEntity/action";
import {BusinessEntity} from '../../models/BusinessEntity'

import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import Block from "../../components/Blocks/Block";
import RecordListTable from "../../components/Table/RecordListTable";
import {ToastProp} from "../../components/Notifications/Toasts";

import {LANG} from "../../labels"
import ListPageAddLink from "../../components/Buttons/ListPageAddLink";
import NotificationsContainer from "../../components/Notifications/Containers";
import {NotificationState} from "../../store/state";

interface PropsFromState {
    loading: boolean;
    notifications: NotificationState;
    listItems?: BusinessEntity[];
    paginationMeta?: PaginationMeta;
    toasts?: ToastProp[];
}

interface PropsFromDispatch {
    fetchListOfBusinesses: (pageNumber?: number, pageSize?: number) => any;
}


type AllProps = PropsFromState & PropsFromDispatch;

export const BusinessListPage: React.FunctionComponent<AllProps> = ({
                                                                        fetchListOfBusinesses,
                                                                        listItems,
                                                                        loading,
                                                                        paginationMeta,
                                                                        notifications,
                                                                        toasts,
                                                                    }) => {
    const columns = [
        {
            headerTitle: "Name",
            accessor: "name"
        },
        {
            headerTitle: "Business Number",
            accessor: "number"
        },
        {
            headerTitle: "Address",
            accessor: "full_address"
        }, {
            headerTitle: 'View / Edit',
            accessor: (row: BusinessEntity) => {
                return <>
                    <Link className={`btn btn-primary btn-sm`} to={`/businesses/${row.id}/edit`}> <i
                        className="fa fa-pencil-alt fa-sm"/> View / Edit</Link>
                </>
            }
        },
    ];

    useEffect(() => {
        fetchListOfBusinesses(1);
    }, [])

    return <MainLayout pageTitle={`My Businesses`} isLoading={loading} toasts={toasts}>
        {/* Hero */}
        <PageTitle title={LANG.BusinessPageList.DISPLAY_NAME} toolTip={`My business page`}
                   subTitle={<>These are all your registered businesses.</>}>
            <ListPageAddLink desktopBtnLabel={`Add business`} to={`/businesses/new`}/>
        </PageTitle>

        <NotificationsContainer notifications={notifications}/>

        <div className="content">
            <Block noPadding={true}>
                <RecordListTable<BusinessEntity> loadMoreResults={(pageNumber: number) => {
                    fetchListOfBusinesses(pageNumber)
                }} paginationMeta={paginationMeta} columns={columns} data={listItems}/>
            </Block>
        </div>
    </MainLayout>
}

const mapStateToProps = ({businessEntity}: ApplicationState) => ({
    loading: businessEntity.loading,
    notifications: businessEntity.notifications,
    listItems: businessEntity.listItems,
    paginationMeta: businessEntity.paginationMeta,
    toasts: businessEntity.toasts,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchListOfBusinesses: (pageNumber?: number, pageSize?: number) => {
            dispatch(fetchListRequest({pageNumber: pageNumber ?? 1, pageSize}));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessListPage);
