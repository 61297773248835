import React from "react";
import classNames from "classnames";
import {renderMilestoneBadge} from "../../classes";
import renderMilestoneStatusLabel, {renderStatusIcon} from "../../labels";
import {UserType} from "../../enums";

export type Props = {
    status?: string
    userType?: UserType
}

/**
 * @param milestone
 * @constructor
 */
export const StatusPill: React.FunctionComponent<Props> = ({status, userType}) => {
    return status && status !== 'pending' && userType ? <span data-status={status}
                 className={classNames("badge p-2", renderMilestoneBadge(status))}>
            {renderStatusIcon(status, userType)}{` `}{renderMilestoneStatusLabel(status, userType)}
    </span>: <span></span>;
}
