import {all, call, put, takeEvery} from 'redux-saga/effects';
import {LoginActionsTypes} from "./types";
import {
    loginError,
    loginSuccess,
    resetPasswordError,
    resetPasswordSuccess,
    forgotPasswordSuccess,
    processRedirectUrlAfterLoggingIn,
    logoutSuccess, setToken, setUser
} from "./action";
import ServiceManager from "../../services/ServiceManager";
import {AxiosError, AxiosResponse} from "axios";
import User, {LoginSuccessResponse} from "../../models/User";
import DataApi from "../../services/DataApi";
import {addToast} from "../action";

function* handleSocialLoginOrRegisterRequest(action: any) {
    try {
        const response: AxiosResponse<LoginSuccessResponse> = yield call([
                ServiceManager.authManager,
                ServiceManager.authManager.socialLoginOrRegister
            ],
            "google", action.payload
        );

        const user: User = response.data.user
        yield put(setToken(response.data.token))
        yield put(setUser(user))

        yield put(loginSuccess(user));
        yield put(processRedirectUrlAfterLoggingIn());
    } catch (err) {
        yield handleAuthErrors(err)
    }
}

function* handleProcessRedirectUrlAfterLogin(action: any) {
    const redirectUrlAfterLogin: string | null = window.localStorage.getItem('RedirectUrlAfterLoggingIn');

    if (redirectUrlAfterLogin !== null && redirectUrlAfterLogin !== undefined && redirectUrlAfterLogin !== 'null') {
        ServiceManager.storageService.setRedirectUrlAfterLoggingIn(null);
        window.location.href = redirectUrlAfterLogin;
    } else {
        window.location.href = '/agreements?filter=sent';
    }
}

function* handleForgotPassword(action: any) {
    try {
        const response: AxiosResponse = yield call([
                ServiceManager.authManager,
                ServiceManager.authManager.forgotPassword
            ],
            action.payload
        );

        yield put(forgotPasswordSuccess());
    } catch (err) {
        const errResp: AxiosError = err;
        yield put(resetPasswordError(errResp.response?.data?.message || "Oops! Something went wrong, please try again."))
    }
}

function* handleResetPassword(action: any) {
    try {
        const response: AxiosResponse = yield call([
                ServiceManager.authManager,
                ServiceManager.authManager.resetPassword
            ],
            action.payload.email,
            action.payload.password,
            action.payload.rePassword,
            action.payload.token,
        );

        yield put(resetPasswordSuccess());
    } catch (err) {
        const errResp: AxiosError = err;
        if (errResp?.response?.status === 422 && errResp.response?.data?.errors != null
            && errResp.response?.data?.errors?.password && errResp.response?.data?.errors?.password[0] != null) {
            yield put(resetPasswordError(errResp.response?.data?.errors?.password[0]));
        } else {
            yield put(resetPasswordError(errResp.response?.data?.message || "Oops! Something went wrong, please try again."));
        }
    }
}

function* handleLogout(action: any) {
    yield ServiceManager.authManager.logout();
    yield put(logoutSuccess());
}

function* handleLogoutSuccess(action: any) {
    window.location.href = '/';
}

function* handleFetchUser() {
    try {
        const response: AxiosResponse<User> = yield call([
            ServiceManager.authManager,
            ServiceManager.authManager.getUser
        ])

        const user: User = response.data
        yield put(setUser(user));
        yield put(processRedirectUrlAfterLoggingIn());

    } catch (err) {
        yield handleAuthErrors(err)
    }
}

function* handleLogin(action: any) {
    try {
        const response: AxiosResponse<LoginSuccessResponse> = yield call([
                ServiceManager.authManager,
                ServiceManager.authManager.login
            ],
            action.payload.username,
            action.payload.password,
            () => {
            }
        );

        yield put(setToken(response.data.token))
        yield put(setUser(response.data.user))

        yield put(loginSuccess(response.data.user));
        yield put(processRedirectUrlAfterLoggingIn());
    } catch (err) {
        yield handleAuthErrors(err)
    }
}

function* handleFetchSuccess(action: any) {
    yield setUser(action.payload)
}

function* handleAuthErrors(err: any) {
    if (err.response.status === 400) {
        yield put(loginError(`Invalid username or password. Please try again or try Forgot Password to reset your password.`))
        yield put(addToast("Invalid username or password. Please try again or try Forgot Password to reset your password.", "error"));
    } else {
        yield put(loginError(err.message))
        // ToDo: Is this causing the authenticated error.
        // yield put(addToast(err?.response?.data?.message, "error"));
    }
}

function* handleSetUser(action: any) {
    const user: User = action.payload.user
    ServiceManager.storageService.setUser(user);

    // Updated the authenticated User
    ServiceManager.analytics.identifyUser(user.id, user);
}

function* handleSetToken(action: any) {
    ServiceManager.storageService.setOAuthToken(action.payload.token);

    // We create a new instance of the DataApi with the new logged-in user data.
    ServiceManager.dataApi = new DataApi();
}

function* watchLoginRequest() {
    yield takeEvery(LoginActionsTypes.LOGOUT_REQUEST, handleLogout);
    yield takeEvery(LoginActionsTypes.LOGOUT_SUCCESS, handleLogoutSuccess);
    yield takeEvery(LoginActionsTypes.FETCH_REQUEST, handleLogin);
    yield takeEvery(LoginActionsTypes.SET_USER, handleSetUser)
    yield takeEvery(LoginActionsTypes.SET_TOKEN, handleSetToken)
    yield takeEvery(LoginActionsTypes.INIT_SOCIAL_REGISTER_OR_LOGIN_REQUEST, handleSocialLoginOrRegisterRequest);
    yield takeEvery(LoginActionsTypes.FORGOT_PASSWORD, handleForgotPassword);
    yield takeEvery(LoginActionsTypes.RESET_PASSWORD, handleResetPassword);
    yield takeEvery(LoginActionsTypes.PROCESS_REDIRECT_URL_AFTER_LOGIN, handleProcessRedirectUrlAfterLogin);
    yield takeEvery(LoginActionsTypes.FETCH_SUCCESS, handleFetchSuccess);
    yield takeEvery(LoginActionsTypes.FETCH_USER_REQUEST, handleFetchUser);
}

function* loginSaga() {
    yield all([
        watchLoginRequest()
    ]);
}

export default loginSaga
