import React from 'react'
import {DraftMilestone, Milestone} from '../../../models/Milestone'
import RequestDepositButton from '../Buttons/RequestDepositButton'
import CancelMilestoneButton from '../Buttons/CancelMilestoneButton'
import {useDispatch} from 'react-redux'
import {
    cancelMilestoneRequest,
    deleteMilestoneRequest,
    requestDepositRequest, updateMilestoneRequest,
} from '../../../store/payableAgreement/action'
import {canCancel, canRequestDeposit} from './utils'
import DeleteMilestoneButton from '../Buttons/DeleteMilestoneButton'
import EditMilestoneButton from "../Buttons/EditMilestoneButton";

interface Props {
    milestone: DraftMilestone
    payableAgreementStatus: string
}

export const DraftMilestoneActions = ({
                                          milestone,
                                          payableAgreementStatus,
                                      }: Props) => {
    const dispatch = useDispatch()
    if (!milestone.payable_agreement_id) {
        return null
    }

    const payableAgreementId = milestone.payable_agreement_id
    const canEditOrDeleteMilestone = milestone.status === 'pending' && (payableAgreementStatus === 'draft' || payableAgreementStatus === 'sent')
    const canRequestDepositForMilestone = (payableAgreementStatus === 'accepted'
        || payableAgreementStatus === 'requesting_money' || payableAgreementStatus === 'in_progress') && canRequestDeposit(milestone)
    const canCancelMilestone = (payableAgreementStatus === 'accepted'
        || payableAgreementStatus === 'requesting_money' || payableAgreementStatus === 'in_progress') && canCancel(milestone);

    return <>
        {canRequestDepositForMilestone ? <RequestDepositButton amount={milestone.amount}
                                                               handleRequestDepositRequest={() => {
                                                                   dispatch(requestDepositRequest(milestone.id))
                                                               }}/> : null}

        {canEditOrDeleteMilestone ?
            <>
                <EditMilestoneButton milestone={milestone} />
                <DeleteMilestoneButton handleDeleteMilestone={() => {
                    dispatch(deleteMilestoneRequest(milestone.id, payableAgreementId))
                }}/>
            </> : null}

        {canCancelMilestone ? <CancelMilestoneButton handleCancelMilestone={(reason: string) => {
            dispatch(cancelMilestoneRequest(milestone.id, payableAgreementId, reason))
        }}/> : null}
    </>
}

export default DraftMilestoneActions
