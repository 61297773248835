import {action} from "typesafe-actions";
import {BankAccountActionTypes} from "./types";
import {
    FetchListRequestPayload,
    FetchRequest,
    FetchSuccess,
    ListOfWithPagination, RedirectToPayableAgreementAfterSuccessRequest,
    UpdateRequest
} from "../types";
import {BankAccount, BankAccountConfirmation} from "../../models/BankAccount";

export const fetchListRequest = (payload: FetchListRequestPayload) => action(BankAccountActionTypes.FETCH_LIST_REQUEST, payload);
export const fetchListSuccess = (payload: ListOfWithPagination<BankAccount>) => action(BankAccountActionTypes.FETCH_LIST_SUCCESS, {
    listOfBankAccounts: payload.list,
    paginationMeta: payload.paginationMeta
});
export const updateRequest = (payload: UpdateRequest<BankAccount>) => action(BankAccountActionTypes.UPDATE_REQUEST, payload);
export const fetchRequest = (payload: FetchRequest) => action(BankAccountActionTypes.FETCH_REQUEST, payload.id);
export const fetchSuccess = (data: FetchSuccess<BankAccount>) => action(BankAccountActionTypes.FETCH_SUCCESS, data);

export const fetchError = (message: string) => action(BankAccountActionTypes.FETCH_ERROR, [message]);

export const connectABankAccount = (payload?: RedirectToPayableAgreementAfterSuccessRequest) => action(BankAccountActionTypes.CONNECT_REQUEST, payload)
export const connectABankAccountSuccess = (connectionUrl: string) => action(BankAccountActionTypes.CONNECT_SUCCESS, {connectionUrl})
export const verifyManualBankAccountRequest = (payload: BankAccountConfirmation) => action(BankAccountActionTypes.VERIFY_BANK_ACCOUNT_REQUEST, payload)

export const deleteRequest = (payload: FetchRequest) => action(BankAccountActionTypes.DELETE_REQUEST, {id: payload.id})
