import React, {useEffect} from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";

import {PaginationMeta} from "../../store/types";
import {ApplicationState} from "../../store";
import {fetchListRequest} from "../../store/transaction/action";

import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import Block from "../../components/Blocks/Block";
import RecordListTable from "../../components/Table/RecordListTable";
import {ToastProp} from "../../components/Notifications/Toasts";

import {LANG} from "../../labels"
import {Transaction} from "../../models/Transaction";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";

interface PropsFromState {
    loading: boolean;
    notifications: NotificationState;
    listItems?: Transaction[];
    paginationMeta?: PaginationMeta;
    toasts?: ToastProp[];
}

interface PropsFromDispatch {
    fetchListOfTransactions: (pageNumber?: number, pageSize?: number) => any;
}


type AllProps = PropsFromState & PropsFromDispatch;

export const TransactionListPage: React.FunctionComponent<AllProps> = ({
                                                                           fetchListOfTransactions,
                                                                           listItems,
                                                                           loading,
                                                                           paginationMeta,
                                                                           notifications,
                                                                           toasts,
                                                                       }) => {
    const columns = [
        {
            headerTitle: '',
            accessor: () => <div className="d-flex justify-content-center">
                <i className="svg-icon coin highlighted "></i>
            </div>
        },
        {
            headerTitle: "Description",
            accessor: "description"
        },
        {
            headerTitle: "Contact",
            accessor: (row: Transaction) => {
                if (row && row.recipient) {
                    return row?.recipient?.first_name + ` ` + row?.recipient?.last_name + ` (` + row?.recipient?.email + `)`
                }

                return ``;
            }
        },
        {
            headerTitle: "Amount",
            accessor: "transaction_amount_display"
        },
        {
            headerTitle: "Status",
            accessor: 'Completed'
        }
    ];

    useEffect(() => {
        fetchListOfTransactions(1);
    }, [])

    return <MainLayout pageTitle={`My transactions`} isLoading={loading} toasts={toasts}>
        {/* Hero */}
        <PageTitle title={LANG.TransactionPageList.DISPLAY_NAME}/>
        <NotificationsContainer notifications={notifications}/>

        <div className="content">
            <Block noPadding={true}>
                <RecordListTable<Transaction> loadMoreResults={(pageNumber: number) => {
                    fetchListOfTransactions(pageNumber)
                }} paginationMeta={paginationMeta} columns={columns} data={listItems}/>
            </Block>
        </div>
    </MainLayout>
}

const mapStateToProps = ({transaction}: ApplicationState) => ({
    loading: transaction.loading,
    notifications: transaction.notifications,
    listItems: transaction.listItems,
    paginationMeta: transaction.paginationMeta,
    toasts: transaction.toasts,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchListOfTransactions: (pageNumber?: number, pageSize?: number) => {
            dispatch(fetchListRequest({pageNumber, pageSize}));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionListPage);
