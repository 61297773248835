import React from "react";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Styles from "../../styles";

export interface LoadingModalProps {
    isLoading: boolean
    title?: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    titleContainer: Styles.Dialog.titleContainer,
    title: Styles.Dialog.title,
}))

export const LoadingModal: React.FC<LoadingModalProps> = ({isLoading, title}) => {
    const classes = useStyles();
    return <Dialog open={isLoading} fullWidth={true} maxWidth={`sm`}>
        <MuiDialogContent className="dialog-content-container">
            <Typography className={classes.title} variant="h6">{title ?? `Loading...`}</Typography>
            <div className="progress push">
                <div className="progress-bar progress-bar-striped bg-info" role="progressbar"
                     style={{width: "90%"}} aria-valuenow={90} aria-valuemin={0} aria-valuemax={100}/>
            </div>
        </MuiDialogContent>
    </Dialog>
}

export default LoadingModal;
