import React, {useState} from "react";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import FormGroup, {defaultInputClasses} from "../../Forms/FormGroup";
import {ErrorNotification} from "../../Notifications";
import classnames from "classnames";
import {hasErrors} from "../../Forms/common";

type RejectPaymentRequestDialogProps = {
    showDialog: boolean
    onConfirm: (reason: string) => void
    onCancel: () => void
}

const RejectPaymentRequestDialog: React.FC<RejectPaymentRequestDialogProps> = ({
                                                                                   showDialog,
                                                                                   onConfirm,
                                                                                   onCancel
                                                                               }) => {

    const [reason, setReason] = useState<string | undefined>();
    const [error, showError] = useState<boolean>(false);

    return <ConfirmDialog showDialog={showDialog}
                          confirmButtonText={`Reject Payment Request`}
                          onCancel={onCancel}
                          onConfirm={() => {
                              if (reason) {
                                  onConfirm(reason)
                              } else {
                                  showError(true)
                              }
                          }} title={`Reject Payment Request`}>
        <p>Are you sure you want to <span className="text-danger">reject release of payment</span> for this milestone?</p>
        {error && <ErrorNotification title={`Error`}>Please enter reason for rejection</ErrorNotification>}

        <FormGroup label={`Reason for rejection`} fieldName={`reason`}>
            <textarea name={`reason`}
                      className={classnames(defaultInputClasses)}
                      onChange={(e) => setReason(e.target.value)}>{reason}</textarea>
        </FormGroup>
    </ConfirmDialog>
}

export default RejectPaymentRequestDialog;
