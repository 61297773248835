import {action} from "typesafe-actions";
import {LoginActionsTypes} from "./types";
import User from "../../models/User";
import BearerToken from "../../models/Auth/token";

export const logoutRequest = () => action<LoginActionsTypes>(LoginActionsTypes.LOGOUT_REQUEST)
export const logoutSuccess = () => action<LoginActionsTypes>(LoginActionsTypes.LOGOUT_SUCCESS)

export const loginRequest = (username: string, password: string) => action(LoginActionsTypes.FETCH_REQUEST, {
    username, password
})
export const loginSuccess = (user: User) => action(LoginActionsTypes.FETCH_SUCCESS, {user});

export const setToken = (token: BearerToken) => action(LoginActionsTypes.SET_TOKEN, {token})
export const fetchUser = () => action(LoginActionsTypes.FETCH_USER_REQUEST)
export const setUser = (user: User) => action(LoginActionsTypes.SET_USER, {user})

export const processRedirectUrlAfterLoggingIn = () => action(LoginActionsTypes.PROCESS_REDIRECT_URL_AFTER_LOGIN);
export const loginError = (message: string) => action(LoginActionsTypes.FETCH_ERROR, [message]);

export const forgotPasswordRequest = (email: any) => action(LoginActionsTypes.FORGOT_PASSWORD, email);
export const forgotPasswordSuccess = () => action(LoginActionsTypes.FORGOT_PASSWORD_SUCCESS);
export const resetPasswordError = (message: string) => action(LoginActionsTypes.FETCH_ERROR, [message]);

export const resetPasswordRequest = (email: string, password: string, rePassword: string, token: string) => action(LoginActionsTypes.RESET_PASSWORD, {
    email, password, rePassword, token
});
export const resetPasswordSuccess = () => action(LoginActionsTypes.RESET_PASSWORD_SUCCESS);
export const socialLoginOrRegisterRequest = (socialResponsePayload: any) => action(LoginActionsTypes.INIT_SOCIAL_REGISTER_OR_LOGIN_REQUEST, socialResponsePayload);
