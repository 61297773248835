import {PayableAgreement} from "../../models/PayableAgreement";
import {PaginationMeta} from "../types";
import BaseState from "../state";

export enum IncomingPayableAgreementActionTypes {
    FETCH_REQUEST = "@incomingPayableAgreement/FETCH_REQUEST",
    FETCH_SUCCESS = "@incomingPayableAgreement/FETCH_SUCCESS",
    FETCH_ERROR = "@incomingPayableAgreement/FETCH_ERROR",

    APPROVE_PAYMENT_REQUEST = "@incomingPayableAgreement/APPROVE_PAYMENT_REQUEST",
    REJECT_PAYMENT_REQUEST = "@incomingPayableAgreement/REJECT_PAYMENT_REQUEST",

    APPROVE_DEPOSIT_REQUEST = "@incomingPayableAgreement/APPROVE_DEPOSIT_REQUEST",
    REJECT_DEPOSIT_REQUEST = "@incomingPayableAgreement/REJECT_DEPOSIT_REQUEST",

    CANCEL_MILESTONE_FETCH_REQUEST = "@incomingPayableAgreement/CANCEL_MILESTONE_FETCH_REQUEST",
    CANCEL_MILESTONE_FETCH_SUCCESS = "@incomingPayableAgreement/CANCEL_MILESTONE_FETCH_SUCCESS",
    CANCEL_MILESTONE_FETCH_ERROR = "@incomingPayableAgreement/CANCEL_MILESTONE_FETCH_ERROR",

    SEND_TFA_CODE_REQUEST = "@payableAgreement/SEND_TFA_CODE_REQUEST",
    SEND_TFA_CODE_SUCCESS = "@payableAgreement/SEND_TFA_CODE_SUCCESS",
    SEND_TFA_CODE_ERROR = "@payableAgreement/SEND_TFA_CODE_ERROR",

    APPROVE_CANCEL_MILESTONE_FETCH_REQUEST = "@incomingPayableAgreement/APPROVE_CANCEL_MILESTONE_FETCH_REQUEST",
    REJECT_CANCEL_MILESTONE_FETCH_REQUEST = "@incomingPayableAgreement/REJECT_CANCEL_MILESTONE_FETCH_REQUEST",

    ACCEPT_PAYABLE_AGREEMENT = "@incomingPayableAgreement/ACCEPT_PAYABLE_AGREEMENT",
    REJECT_PAYABLE_AGREEMENT = "@incomingPayableAgreement/REJECT_PAYABLE_AGREEMENT",

    UPDATE_REQUEST = "@incomingPayableAgreement/UPDATE_REQUEST",
    SAVE_REQUEST = "@incomingPayableAgreement/SAVE_REQUEST",

    PAY_UNSECURE_AGREEMENT_REQUEST = "@payableAgreement/PAY_UNSECURE_AGREEMENT_REQUEST",

    FETCH_LIST_REQUEST = "@incomingPayableAgreement/FETCH_LIST_REQUEST",
    FETCH_LIST_SUCCESS = "@incomingPayableAgreement/FETCH_LIST_SUCCESS",
}

export interface IncomingPayableAgreementState extends BaseState {
    readonly data?: PayableAgreement;
    readonly listItems?: PayableAgreement[];
    readonly paginationMeta?: PaginationMeta;
}
