import React, {useEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {isMobile, isTablet} from 'react-device-detect';
import Styles from '../../styles'

type InfoDialogProps = {
    showDialog: boolean;
    children: React.ReactNode;
    onOk: () => void;
    okButtonText?: string;
    title?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    titleContainer: Styles.Dialog.titleContainer,
    title: Styles.Dialog.title,
    successButton: Styles.Button.successButton,
    dialogActions: {
        display: "flex"
    }
}))


const InfoDialog: React.FC<InfoDialogProps> = ({
                                                         children,
                                                         showDialog,
                                                         onOk,
                                                         title,
                                                         okButtonText,
                                                     }) => {

    const [fullScreen, setFullScreen] = useState<boolean>(false);

    useEffect(() => {
        setFullScreen(isTablet || isMobile);
    }, [isMobile, isTablet])

    const classes = useStyles();
    return <Dialog open={showDialog} fullWidth={true} maxWidth={`sm`} fullScreen={fullScreen} className={`form-dialog`}>
        <MuiDialogContent className={`dialog-content-container`}>
            <Typography className={classes.title} variant="h6">{title}</Typography>
            {children}
        </MuiDialogContent>
        <MuiDialogActions className={classes.dialogActions}>
            <Button className={classes.successButton} variant={`contained`} color={`primary`}
                    onClick={onOk}>{okButtonText || "Ok"}</Button>
        </MuiDialogActions>
    </Dialog>
}

export default InfoDialog;
