import {BusinessEntity} from "../../models/BusinessEntity";
import {PaginationMeta} from "../types";
import BaseState from "../state";

export enum BusinessEntityActionTypes {
    CREATE_REQUEST = "@businessEntity/CREATE_REQUEST",
    CREATE_SUCCESS = "@businessEntity/CREATE_SUCCESS",
    CREATE_ERROR = "@businessEntity/CREATE_ERROR",

    FETCH_REQUEST = "@businessEntity/FETCH_REQUEST",
    FETCH_SUCCESS = "@businessEntity/FETCH_SUCCESS",
    FETCH_ERROR = "@businessEntity/FETCH_ERROR",

    UPDATE_REQUEST = "@businessEntity/UPDATE_REQUEST",
    SAVE_REQUEST = "@businessEntity/SAVE_REQUEST",

    FETCH_LIST_REQUEST = "@businessEntity/FETCH_LIST_REQUEST",
    FETCH_LIST_SUCCESS = "@businessEntity/FETCH_LIST_SUCCESS",
}

export interface BusinessEntityState extends BaseState {
    readonly data?: BusinessEntity;
    readonly listItems?: BusinessEntity[];
    readonly paginationMeta?: PaginationMeta;
}