import {BankAccountStatus, BankConnectionType} from "../models/BankAccount";
import Severity_classes from "../classes/severity_classes";

export function renderConnectionLabel(connectionType: BankConnectionType): string {
    if (connectionType === 'Zepto_Manual_Connection') {
        return 'Manual connection'
    }

    return 'Online banking login'
}

export function renderConnectionStatus(connectionStatus: BankAccountStatus): string {
    switch (connectionStatus) {
        case "Auto_VerificationFailed":
        case "Manual_VerificationFailed":
            return 'Failed';
        case "Auto_VerificationPending":
        case "Manual_VerificationPending":
            return 'Pending verification'
        case "connected":
            return 'Active';

        default:
            return 'Unknown';
    }
}


/**
 *
 * @param status
 */
export function renderBankConnectionStatusBadge(status: BankAccountStatus) {

    const Danger = "badge-danger " + Severity_classes.DANGER,
        Warning = "badge-warning " + Severity_classes.WARNING,
        Success = "badge-success " + Severity_classes.SUCCESS

    return {
        [Danger]: (
            status === "Auto_VerificationFailed" || status === "Manual_VerificationFailed"
        ),
        [Warning]: (status === "Auto_VerificationPending" || status === "Manual_VerificationPending"),
        [Success]: (
            status === "connected"
        ),
    }
}
