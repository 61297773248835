import React from "react";
import {
    CancellationRequestedByContractorMilestone,
    CancellationRequestedByCustomerMilestone
} from "../../../models/Milestone";
import {PayableAgreementTypes} from "../../../labels";
import {useDispatch} from "react-redux";
import {
    approveCancellationOfMilestoneRequest,
    rejectCancellationOfMilestoneRequest
} from "../../../store/payableAgreement/action";
import {
    approveCancellationOfMilestoneRequest as approveCancellationOfMilestoneBySellerRequest,
    rejectCancellationOfMilestoneRequest as rejectCancellationOfMilestoneBySellerRequest,
} from "../../../store/incomingPayableAgreement/action";
import ApproveMilestoneCancellationButtons, {CancellationAction} from "../Buttons/ApproveMilestoneCancellationButtons";

interface Props {
    milestone: CancellationRequestedByCustomerMilestone | CancellationRequestedByContractorMilestone
    payableAgreementType: PayableAgreementTypes
}

export const CancellationRequestedMilestoneActions: React.FC<Props> = ({
                                                                           milestone,
                                                                           payableAgreementType
                                                                       }: Props) => {
    const dispatch = useDispatch();
    const onConfirm = (cancellation: CancellationAction) => {
        if (milestone.cancellation_requested_by === 'buyer') {
            if (cancellation === 'approve') {
                dispatch(approveCancellationOfMilestoneRequest(milestone.id))
            } else {
                dispatch(rejectCancellationOfMilestoneRequest(milestone.id))
            }
        } else if (milestone.cancellation_requested_by === 'seller') {
            if (cancellation === 'approve') {
                dispatch(approveCancellationOfMilestoneBySellerRequest(milestone.id))
            } else {
                dispatch(rejectCancellationOfMilestoneBySellerRequest(milestone.id))
            }
        }
    }

    return <>
        {(!milestone.cancel_milestone_approved_on || !milestone.cancel_milestone_rejected_on) ?
            <ApproveMilestoneCancellationButtons milestone={milestone} onCancel={() => {
            }} onConfirm={onConfirm}/>
            : null}
    </>
}

export default CancellationRequestedMilestoneActions
