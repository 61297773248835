import React, {useRef} from "react";
import {useForm} from "react-hook-form";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import {ReduxFormErrorPanel} from "../../Forms/ErrorPanel";
import {ApplicationState} from "../../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {createRequest} from "../../../store/attachment/action";
import {connect} from "react-redux";
import FormGroup from "../../Forms/FormGroup";

interface PropsFromDispatch {
    uploadFile: (file: File, entity: string, id: number) => any;
}

type AllProps = {
    showAlert: boolean
    onConfirm: () => void,
    onCancel: () => void,
    entityType: string,
    entityTypeId?: number
    shouldSave?: boolean;
    queueDataBeforeSave?: (attachmentFile: any, entityType: string) => void;
} & PropsFromDispatch;

type Inputs = {
    attachment_file: any;
}

const FIELD_NAME = 'attachment_file';

const SaveAttachmentDialogComponent: React.FC<AllProps> = ({
                                                               showAlert,
                                                               onConfirm,
                                                               uploadFile,
                                                               onCancel,
                                                               entityType,
                                                               entityTypeId,
                                                               shouldSave = true,
                                                               queueDataBeforeSave
                                                           }: AllProps) => {
    const {register, formState: {errors}, handleSubmit, watch, setError, reset} = useForm<Inputs>()
    const hiddenSaveBtn = useRef<HTMLButtonElement>(null)
    const watchAttachmentFile = watch(FIELD_NAME)

    const handleOnConfirm = () => {
        if (watchAttachmentFile === undefined) {
            setError('attachment_file', {message: 'Please select a file to upload.'})
        } else if (hiddenSaveBtn && hiddenSaveBtn.current) {
            hiddenSaveBtn.current.click();
            onConfirm();
        } else {
            setError('attachment_file', {message: 'Please select a file to upload.'});
        }
    }
    const handleOnSubmit = (data: Inputs) => {
        if (shouldSave) {
            if (entityTypeId && data.attachment_file && data.attachment_file.length > 0 && data.attachment_file[0]) {
                uploadFile(data.attachment_file[0], entityType, entityTypeId);
            } else {
                console.error('Failed to upload the attachments.')
            }
        } else if (queueDataBeforeSave) {
            // We want to save this attachment alter not now.
            queueDataBeforeSave(data.attachment_file[0], entityType);
        }
    }

    return <ConfirmDialog showDialog={showAlert} onConfirm={handleOnConfirm} confirmButtonText={`Upload attachment`}
                          title={`Add Attachment`} onCancel={() => {
        onCancel();
        reset();
    }}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <ReduxFormErrorPanel errors={errors}/>
            <button type={`submit`} ref={hiddenSaveBtn} className={`hidden-save`}/>
            <FormGroup label={`File`} fieldName={FIELD_NAME}>
                <input type={`file`} {...register(FIELD_NAME, {
                    required: {
                        value: true,
                        message: 'Please select a file to upload.'
                    }
                })}/>
            </FormGroup>
        </form>
    </ConfirmDialog>
}

const mapStateToProps = ({attachment}: ApplicationState) => ({
    // ToDo: Change this to have notifications only!
    errors: attachment.notifications.errors,
    data: attachment.data,
    listOfBankAccounts: attachment.listItems
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        uploadFile: (file: File, entity: string, entityId: number) => {
            dispatch(createRequest({file, parent_entity_type: entity, parent_entity_type_id: entityId}))
        }
    }
}
export const SaveAttachmentDialog = connect(mapStateToProps, mapDispatchToProps)(SaveAttachmentDialogComponent);
