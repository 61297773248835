import React from "react";
import {Control, Controller} from "react-hook-form";
import AsyncAutocomplete from "../../Autocomplete/AsyncAutocomplete";
import ServiceManager from "../../../services/ServiceManager";

interface ListOfBusinessesProps {
    onChange: (event: React.ChangeEvent<{}>, value: any) => void
    control: Control
}

const ListOfBusinesses: React.FunctionComponent<ListOfBusinessesProps> = ({onChange, control}) => {
    return <div className="row item-push">
        <div className="col-lg-4">
            <label htmlFor="sltTypesOfAgreement">Business </label>
            <p className="font-size-sm text-muted">
                The business is the name of the business or organization that the customer is purchasing goods or
                services for.
            </p>
        </div>
        <div className="col-lg-8">
            <p>
                <Controller
                    name="sender_business_entity_id" control={control} defaultValue={false}
                    render={(field) => {
                        return <AsyncAutocomplete
                            entityType={`business_entity`}
                            onChange={onChange}
                            resourceManagerFactory={ServiceManager.dataApi.business_entity}
                            inputLabel={`Choose Business`}/>
                    }}
                />
            </p>
        </div>
    </div>
}

export default ListOfBusinesses;
