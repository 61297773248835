import React from "react";
import User from "../../models/User";

export interface HeaderUserMenuProps {
    user?: User
}

const HeaderUserMenu = ({user}: HeaderUserMenuProps) => {
    const getUserName = () => {
        if (user) {
            if (user.name) {
                return `${user.name} (${user.email})`
            }

            return user.email
        }

        return 'Loading...'
    }

    return <div className="username-container">
        <span className="username">{getUserName()}</span>
        <a href="/profile"><i className="svg-icon user" /></a>
        <a href="/agreements/new"><i className="svg-icon add-icon"></i></a>
    </div>
}

export default HeaderUserMenu;
