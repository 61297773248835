import React, {useState} from "react";

import AysncRecipientsDropDown from "../Recipients/AsyncRecipientsDropDown";
import Block from "../Blocks/Block";
import Description from "../Forms/Fields/Description";
import EditRecipientDialog from "../Recipients/Dialogs/EditRecipientDialog";
import FormGroup from "../Forms/FormGroup";
import Recipient from "../../models/Recipient";
import TotalAmount from "../Forms/Fields/TotalAmount";
import Url from "../Forms/Fields/Url";
import {PayableAgreement} from "../../models/PayableAgreement";
import {RecipientCard} from "../Cards";
import {SecondaryButton, SuccessButton, TertiaryButton} from "../Buttons";
import {useFormContext} from "react-hook-form";
import AgreementTitle from "../Forms/Fields/AgreementTitle";
import {useDispatch} from "react-redux";
import {sendAgreementRequest} from "../../store/payableAgreement/action";
import Utils from "../../services/Utils";
import FeeCalculator from "../../services/FeeCalculator";
import {StatusPill} from "../Milestones/StatusPill";
import {MilestoneContractorActions} from "../Milestones/MilestoneContractorActions";
import Statues from "../../enums";

/**
 * @class Props
 */
interface Props {
    onSubmit: (data: PayableAgreement) => void
}

/**
 * @param props
 * @constructor
 */
const ProductAgreementForm = (props: Props) => {
    const dispatch = useDispatch();
    const methods = useFormContext<PayableAgreement>();
    const recipient = methods.watch('recipient');
    const [showCreateContact, setShowCreateContact] = useState<boolean>(false);
    const payableAgreement = methods.getValues();
    const shouldShowDetails = payableAgreement.status !== Statues.PayableAgreement.PENDING

    return <>
        <form onSubmit={methods.handleSubmit(props.onSubmit)}>
            <div className="content content-full">
                <div className="row push">
                    <div className="col-md-7 col-xs-12"/>
                    <div className="col-md-5 col-xs-12">
                        <div className="contract-actions">
                            <SuccessButton type={`submit`}>Save product agreement</SuccessButton>

                            {payableAgreement && payableAgreement?.id && payableAgreement?.status === 'draft' &&
                                <SecondaryButton data-testid={'send-agreement'} type={`button`} onClick={() => {
                                    if (payableAgreement && payableAgreement?.id) {
                                        dispatch(sendAgreementRequest(payableAgreement?.id.toString()))
                                    }
                                }}>Send agreement</SecondaryButton>}

                            {payableAgreement && payableAgreement?.id &&
                                (payableAgreement?.status !== 'draft') &&
                                <>
                                    <span
                                        className={'timestamp'}>Last sent at {payableAgreement?.sent_on_display}</span>
                                    <TertiaryButton type="button" onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        if (payableAgreement && payableAgreement?.id) {
                                            dispatch(sendAgreementRequest(payableAgreement?.id.toString()))
                                        }
                                    }}>Re-send agreement</TertiaryButton>
                                </>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 rhc-container">
                        <Block>
                            <FormGroup label={`Choose a contact`} isRequired={true}
                                       fieldName={`recipient_user_email`}
                                       errors={methods.formState.errors}>
                                <>
                                    {recipient ? <>
                                        <RecipientCard recipient={recipient} displayType={'compact'}/>
                                        <a className="link remove-recipient"
                                           href="#" onClick={(e) => {
                                            e.preventDefault();
                                            methods.setValue('recipient', undefined)
                                            methods.setValue('recipient_id', undefined);
                                            methods.setValue('recipient_user_email', undefined);
                                        }}> <i className="svg-icon trash highlighted"/> Update recipient</a>
                                    </> : <>
                                        <AysncRecipientsDropDown onSelected={(recipient: Recipient) => {
                                            methods.setValue('recipient', recipient)
                                        }} errors={methods.formState.errors} control={methods.control}
                                                                 setValue={methods.setValue}
                                                                 reset={methods.reset}
                                                                 register={methods.register}/>
                                        <div className={`or-divider`}>OR</div>
                                        <a href="#" className="link add-new-contact"
                                           onClick={() => setShowCreateContact(true)}><i
                                            className="svg-icon add-icon highlighted"/>{` `}Add new contact</a>
                                    </>}
                                </>
                            </FormGroup>
                        </Block>

                        {shouldShowDetails && <div className="dates">
                            <Block>
                                <table className="table total-table ps-table">
                                    <tr>
                                        <td>Accepted on</td>
                                        <td>{payableAgreement.accepted_on_display}</td>
                                    </tr>
                                    {payableAgreement && payableAgreement.milestones && payableAgreement.milestones[0] &&
                                        <tr>
                                            <td>Money secured on</td>
                                            <td>{payableAgreement.milestones && payableAgreement.milestones[0].money_secured_on_display}</td>
                                        </tr>}
                                    {payableAgreement && payableAgreement.milestones && payableAgreement.milestones[0] &&
                                        <tr>
                                            <td>Transaction ID:</td>
                                            <td>#ABCD</td>
                                        </tr>}
                                    {payableAgreement && payableAgreement.milestones && payableAgreement.milestones[0] &&
                                        <tr>
                                            <td>Money released on</td>
                                            <td>{payableAgreement.milestones && payableAgreement.milestones[0].money_secured_on_display}</td>
                                        </tr>}
                                </table>
                            </Block>
                        </div>}

                        <div className="agreement-amounts">
                            <Block>
                                <div className="form-group agreement-status">
                                    <label className="label">Agreement Status</label>
                                    <div className="agreement-status">
                                        <StatusPill status={payableAgreement.status} userType={'contractor'}/>
                                    </div>
                                </div>
                                <table className={`table total-table ps-table`}>
                                    <tr>
                                        <td className={`label`}>Total amount</td>
                                        <td className={`amount`}><em>{payableAgreement?.total_amount_display}</em></td>
                                    </tr>
                                    <tr>
                                        <td className={`label`}>Fees</td>
                                        {/*Till the fees are not applied let's calculate it manually, this should be re-instated once we want to start charging fees*/}
                                        {/*<td className={`amount`}>{payableAgreement?.milestone_fee_display}</td>*/}
                                        <td className={`amount discounted`}>{payableAgreement?.total_amount && Utils.formatAmount(FeeCalculator.calculateFee(payableAgreement?.total_amount))}</td>
                                    </tr>
                                    <tr>
                                        <td className={`label`}>Payout amount</td>
                                        <td className={`amount`}>{payableAgreement?.payout_amount_display}</td>
                                    </tr>
                                </table>
                            </Block>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <Block withHeaderBorder={true} className={`title-and-descr-block`}>
                            <AgreementTitle label={'Agreement title'}
                                            placeholder={'E.g. Selling a golf club, car or watch.'}/>

                            <div className="row push">
                                <div className="col-md-12 col-lg-12">
                                    <Description placeholder={'Description'} label={'Description'}/>
                                </div>
                            </div>

                            <div className="row push">
                                <div className="col-md-12 col-lg-6">
                                    <TotalAmount label={'Price'}/>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <Url
                                        placeholder={'Copy and paster URL from Facebook Market place or Gumtree etc.'}
                                        label={'Url'}/>
                                </div>
                            </div>
                        </Block>

                        {shouldShowDetails && payableAgreement && payableAgreement.milestones && payableAgreement.milestones[0] &&
                            <div className="row">
                                <div className="col">
                                    <Block>
                                        <MilestoneContractorActions agreement={payableAgreement}
                                                                    showAddAttachments={true}
                                                                    milestone={payableAgreement.milestones[0]}/>
                                    </Block>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </form>

        <EditRecipientDialog recipient={{id: -1}}
                             showDialog={showCreateContact}
                             onCancel={() => setShowCreateContact(false)}
                             onSaved={(recipient: Recipient) => {
                                 methods.setValue('recipient_id', recipient.id);
                                 methods.setValue('recipient_user_email', recipient.email);
                                 methods.setValue('recipient', recipient);

                                 setShowCreateContact(false);
                             }}/>
    </>
}

export default ProductAgreementForm;
