import Utils from "./Utils";
import FormUtils from "./Forms";
import StorageService from "../pyntax/lib/services/StorageService";
import AuthManager from "../pyntax/lib/data/AuthManager";
import DataApi from "./DataApi";
import {AnalyticsInterface} from "./analytics";

class ServiceManager {
    private static _StorageService: any;

    private static _utils: Utils;

    private static _formUtils: FormUtils;

    private static _storageService: StorageService;

    private static _authManager: AuthManager;

    private static _dataApi: DataApi;

    private static _analytics: AnalyticsInterface;

    static get analytics(): AnalyticsInterface {
        return this._analytics;
    }

    static set analytics(value: AnalyticsInterface) {
        this._analytics = value;
    }

    static get dataApi(): DataApi {
        return this._dataApi;
    }

    static set dataApi(value: DataApi) {
        this._dataApi = value;
    }

    static get authManager(): AuthManager {
        return this._authManager;
    }

    static set authManager(value: AuthManager) {
        this._authManager = value;
    }

    static get storageService(): StorageService {
        return this._storageService;
    }

    static set storageService(value: StorageService) {
        this._storageService = value;
    }

    static get formUtils(): FormUtils {
        return this._formUtils;
    }

    static set formUtils(value: FormUtils) {
        this._formUtils = value;
    }

    static get utils(): Utils {
        return this._utils;
    }

    static set utils(value: Utils) {
        this._utils = value;
    }

    static get StorageService(): any {
        return this._StorageService;
    }

    static set StorageService(value: any) {
        this._StorageService = value;
    }
}

export default ServiceManager
