import React, {useEffect, useMemo, useState} from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {ApplicationState} from "../../store";
import PageTitle from "../../components/PageTitle";
import NotificationsContainer from "../../components/Notifications/Containers";
import {useForm, FormProvider} from "react-hook-form";
import {DangerButton, PrimaryButton, SuccessButton} from "../../components/Buttons";
import {fetchRequest, updateRequest} from "../../store/bankAccount/action";
import {BankAccount} from "../../models/BankAccount";
import BankAccountFields from "./BankAccountFields";
import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import {InfoNotification} from "../../components/Notifications";
import Block from "../../components/Blocks/Block";
import VerifyBankAccountModal from "../../components/BankAccounts/Dialogs/VerifyBankAccountModal";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import DeleteBankAccountButton from "./Buttons/DeleteBankAccountButton";
import useQuery from "../../components/utils/userQuery";

interface OwnProps extends RouteComponentProps<{ id: string }> {
}

const mapStateToProps = ({bankAccount}: ApplicationState) => ({
    loading: bankAccount.loading,
    notifications: bankAccount.notifications,
    toasts: bankAccount.toasts,
    data: bankAccount.data
})
const connector = connect(mapStateToProps, {})
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = OwnProps & PropsFromRedux

const EditBankAccountPage = (props: Props) => {
    const dispatch = useDispatch();
    const {loading, notifications, data, toasts, match: {params}} = props
    const methods = useForm<BankAccount>({
        defaultValues: data ?? {
            connection_type: 'Zepto_Manual_Connection',
            status: 'Manual_VerificationPending'
        }
    })
    const {handleSubmit} = methods;
    const onSubmit = (data: BankAccount) => {
        dispatch(updateRequest({
            id: data.id.toString(),
            data
        }))
    }
    const [showBankAccountVerification, setShowBankAccountVerification] = useState<boolean>(false);
    const history = useHistory(),
        query = useQuery(),
        returnPayableAgreementId = query.get('returnPayableAgreementId'),
        agreementType = query.get('type');

    useEffect(() => {
        if (returnPayableAgreementId && parseInt(returnPayableAgreementId) > 0) {
            if (agreementType && agreementType === 'incoming') {
                history.push(`/incoming_agreements/${returnPayableAgreementId}/view`)
            } else {
                history.push(`/agreements/${returnPayableAgreementId}/edit`)
            }
        }

        dispatch(fetchRequest({id: params.id}))
    }, [dispatch, params])

    useEffect(() => {
        methods.reset({
            ...data
        })
    }, [data, methods])

    const isNotVerified = useMemo(() => {
        return data?.connection_type === 'Zepto_Manual_Connection' && data?.status === 'Manual_VerificationPending'
    }, [data])

    return <MainLayout pageTitle={`Edit Bank Account`} isLoading={loading} toasts={toasts}>
        <PageTitle title={`Edit bank account`}>
            <>
                {isNotVerified && <PrimaryButton onClick={() => setShowBankAccountVerification(true)}>Verify bank account</PrimaryButton>}
            </>
        </PageTitle>

        <div className="content pt-0">
            <NotificationsContainer notifications={notifications}/>

            {isNotVerified &&
                <InfoNotification>This bank account is pending verification. Please check your bank account for a
                    deposit from paysecure, it should show up in your account in the next 5 - 15 minutes. Once you see
                    the transaction click on Verify now to verify the account.</InfoNotification>}

            <Block withHeaderBorder>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <BankAccountFields isUpdating={true}/>

                        <div className="row push">
                            <div className="col">
                                <div className="form-group">
                                    <ButtonGroup>
                                        <SuccessButton type={`submit`}>Update bank account</SuccessButton>
                                        {data?.id && <DeleteBankAccountButton canDelete={true} id={data?.id}/>}
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </Block>
        </div>

        {showBankAccountVerification && data?.id &&
            <VerifyBankAccountModal id={data.id} showDialog={showBankAccountVerification} closeModal={() => {
                setShowBankAccountVerification(false)
            }}/>}
    </MainLayout>
}

export default connector(withRouter(EditBankAccountPage))
