import React from "react";
import styled from "styled-components";

const Root = styled.div`
    gap: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    
    @media (max-width: 768px) {
        flex-direction: column-reverse; 
    }
`

type Props = {
    children: React.ReactNode | null | undefined;
}

export const ButtonGroup = ({children}: Props) => {
    return <Root className="actions">{children}</Root>
}

export default ButtonGroup
