import React, {useEffect} from "react";
import {useSnackbar} from "notistack";

export type ToastProp = {
    severity: "error" | "warning" | "info" | "success"
    message: string
}

// /**
//  * @param severity
//  * @param message
//  *
//  * @constructor
//  */
// export const Toast: React.FC<ToastProp> = ({severity, message}: ToastProp) => {
//     const [open, setOpen] = useState<boolean>(true);
//     const handleClose = () => setOpen(false);
//
//     return <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
//                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
//         <MuiAlert onClose={handleClose} severity={severity}>{message}</MuiAlert>
//     </Snackbar>
// }

export type ToastsProps = {
    toasts?: ToastProp[]
}

/**
 * @param toasts
 * @constructor
 */
export const Toasts: React.FC<ToastsProps> = ({toasts}: ToastsProps) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(() => {
        if (toasts) {
            toasts.map((toast) => {
                return enqueueSnackbar(toast.message, {
                    persist: false,
                    autoHideDuration: 5000,
                    preventDuplicate: true,
                    variant: toast.severity,
                    action: (key: any) => <a className="link" onClick={() => closeSnackbar(key)}><i
                        className="svg-icon cross highlighted"/></a>
                });
            })
        }
    }, [toasts])


    return null;
}

export default Toasts;
