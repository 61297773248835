import React from 'react'
import { MoneySecuredMilestone } from '../../../models/Milestone'
import { useDispatch } from 'react-redux'
import { canCancel, canRequestPayment } from './utils'
import RequestPaymentButton from '../Buttons/RequestPaymentButton'
import { cancelMilestoneRequest, requestPaymentRequest } from '../../../store/payableAgreement/action'
import CancelMilestoneButton from '../Buttons/CancelMilestoneButton'

interface Props {
    milestone: MoneySecuredMilestone
    canUserRequestPayment: boolean
}

export const MoneySecuredMilestoneActions: React.FC<Props> = ({
    milestone,
    canUserRequestPayment,
}: Props) => {
    const dispatch = useDispatch()
    const payableAgreementId = milestone.payable_agreement_id

    if (!payableAgreementId) {
        return null
    }

    return canRequestPayment(milestone) && milestone.total_amount ? <>
        <RequestPaymentButton milestoneId={milestone.id} amount={milestone.total_amount}
                              handleRequestPayment={() => {
                                  dispatch(requestPaymentRequest(milestone.id))
                              }} canRequestPayment={canRequestPayment(milestone) && canUserRequestPayment}/>

        {canCancel(milestone) && Number(payableAgreementId) > 0 && payableAgreementId &&
            <CancelMilestoneButton handleCancelMilestone={(reason: string) => {
                dispatch(cancelMilestoneRequest(milestone.id, Number(payableAgreementId), reason))
            }}/>}
    </> : null
}

export default MoneySecuredMilestoneActions
