import React from "react";
import {useDispatch} from "react-redux";
import RequestDepositButton from "../Buttons/RequestDepositButton";
import {RejectDepositMilestone} from "../../../models/Milestone";
import {cancelMilestoneRequest, requestDepositRequest} from "../../../store/payableAgreement/action";
import {canCancel} from "./utils";
import CancelMilestoneButton from "../Buttons/CancelMilestoneButton";

interface Props {
    milestone: RejectDepositMilestone
}

/**
 * @param milestone
 * @constructor
 */
export const RejectDepositMilestoneActions: React.FC<Props> = ({milestone}: Props) => {
    const dispatch = useDispatch()
    const payableAgreementId = milestone.payable_agreement_id

    if (!payableAgreementId) {
        return null
    }

    return <>
        <RequestDepositButton amount={milestone.amount}
                              handleRequestDepositRequest={() => {
                                  dispatch(requestDepositRequest(milestone.id))
                              }}/>
        {canCancel(milestone) && <CancelMilestoneButton handleCancelMilestone={(reason: string) => {
            dispatch(cancelMilestoneRequest(milestone.id, payableAgreementId, reason))
        }}/>}
    </>
}

export default RejectDepositMilestoneActions
