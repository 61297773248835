import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import ProtectedRoute from "./components/Routes/ProtectedRoute";
import {AppRoutes, AppRoute} from './components/Routes';
import {history} from "./configureStore";
import {ConnectedRouter} from "connected-react-router";
import ServiceManager from "./services/ServiceManager";
import App404Page from "./pages/App404Page";

history.listen((location) => {
    ServiceManager.analytics.trackPage(location.pathname)
})

/**
 * @constructor
 */
const AppRouter: React.FunctionComponent = () => {
    return <ConnectedRouter history={history}>
        <Switch>
            {AppRoutes.map((route: AppRoute) => {
                return <Route path={route.path} exact={true}>
                    {route.isProtected ? <ProtectedRoute>{route.component}</ProtectedRoute> : route.component}
                </Route>
            })}
            <Route path={`/`} exact={true}><Redirect to={`/dashboard`}/></Route>
            <Route path='*' exact={true} component={App404Page} />
        </Switch>
    </ConnectedRouter>
}

export default AppRouter;
