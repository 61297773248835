import ServiceManager from "../services/ServiceManager";

export const handleError = (err: any) => {
    if (err && err.response && err.response.status === 401) {
        ServiceManager.authManager.logout().then(function () {
            ServiceManager.storageService.setRedirectUrlAfterLoggingIn(window.location.pathname + '?' + window.location.search);
            window.location.href = '/login';
        });
    }
}
