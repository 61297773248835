import {Transaction} from "../../models/Transaction";
import {PaginationMeta} from "../types";
import BaseState from "../state";

export enum TransactionActionTypes {
    FETCH_REQUEST = "@transaction/FETCH_REQUEST",
    FETCH_SUCCESS = "@transaction/FETCH_SUCCESS",
    FETCH_ERROR = "@transaction/FETCH_ERROR",

    SEND_AGREEMENT_REQUEST = "@transaction/SEND_AGREEMENT_REQUEST",
    UPDATE_REQUEST = "@transaction/UPDATE_REQUEST",
    CREATE_REQUEST = "@transaction/CREATE_REQUEST",

    FETCH_LIST_REQUEST = "@transaction/FETCH_LIST_REQUEST",
    FETCH_LIST_SUCCESS = "@transaction/FETCH_LIST_SUCCESS",
}

export interface TransactionState extends BaseState {
    readonly data?: Transaction;
    readonly listItems?: Transaction[];
    readonly paginationMeta?: PaginationMeta;
}
