import React from 'react';
import classNames from "classnames";
import {BankAccountStatus} from "../../models/BankAccount";
import {renderBankConnectionStatusBadge, renderConnectionStatus} from "../../labels/bankAccount";

interface Props {
    status?: BankAccountStatus
}

/**
 *
 * @param status
 * @constructor
 */
export const StatusPill: React.FunctionComponent<Props> = ({status}) => {
    if (!status) {
        return null;
    }

    return <span data-status={status}
                 className={classNames('badge', renderBankConnectionStatusBadge(status))}>
                    {renderConnectionStatus(status)}</span>
}

export default StatusPill;
