import React from "react";
import {Attachment} from "../../models/Attachment";
import {useDispatch} from "react-redux";
import {downloadRequest} from "../../store/attachment/action";
import DeleteAttachmentButton from "./Buttons/DeleteAttachmentButton";
import {UserType} from "../../enums";

export type Props = {
    attachments: Attachment[]
    userType: UserType
    canDelete?: boolean
}

export const ListOfAttachments: React.FC<Props> = ({
                                                       attachments,
                                                       canDelete,
                                                       userType
                                                   }: Props) => {
    const dispatch = useDispatch();

    if (attachments.length === 0) {
        return <></>
    }

    return <div className="pills-container">
        {attachments.map((att: Attachment, key) => {
            const fileName = att.file_name || att.file?.name
            return <div className="rounded-pill bg-light pill" key={`${key}-ListOfAttachments`}>
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    if (fileName) {
                        dispatch(downloadRequest(att.id.toString(), fileName, userType))
                    }
                }}>{fileName}</a>
                {canDelete && att && <DeleteAttachmentButton attachment={att}/>}
            </div>
        })}
    </div>
}

ListOfAttachments.defaultProps = {
    canDelete: true,
    userType: 'contractor'
}

export default ListOfAttachments;
