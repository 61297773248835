import React, {useCallback, useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {ApplicationState} from "../../store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";
import Recipient from "../../models/Recipient";
import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import {fetchRequest, updateRequest} from "../../store/recipient/action";
import NotificationsContainer from "../../components/Notifications/Containers";
import {SuccessButton} from "../../components/Buttons";
import RecipientFields from "./RecipientFields";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import Block from "../../components/Blocks/Block";
import DeleteRecipientButton from "./Buttons/DeleteRecipientButton";

interface OwnProps extends RouteComponentProps<{ id: string }> {
}

const mapStateToProps = ({recipient}: ApplicationState) => ({
    loading: recipient.loading,
    notifications: recipient.notifications,
    toasts: recipient.toasts,
    data: recipient.data,
})
const connector = connect(mapStateToProps, {})
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = OwnProps & PropsFromRedux

const EditRecipientPage = (props: Props) => {
    const dispatch = useDispatch();

    const {loading, notifications, data, toasts, match: {params}} = props

    const methods = useForm<Recipient>({
        defaultValues: data ?? {}
    })

    const {handleSubmit} = methods;
    const onSubmit = (data: Recipient) => {
        dispatch(updateRequest(data.id, data))
    }

    useEffect(() => {
        if (params.id) {
            dispatch(fetchRequest(params.id))
        }
    }, [dispatch, params])

    useEffect(() => {
        methods.reset({...data})
    }, [data, methods]);

    return <MainLayout pageTitle={`Edit contact`} isLoading={loading} toasts={toasts}>
        <PageTitle title={`Edit contact`}/>
        <div className="content">
            <NotificationsContainer notifications={notifications}/>

            <Block withHeaderBorder>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <RecipientFields isUpdating={true}/>
                        <div className="row push">
                            <div className="col">
                                <div className="form-group">
                                    <ButtonGroup>
                                        <SuccessButton type={`submit`}>Save contact</SuccessButton>
                                        {data?.id && <DeleteRecipientButton canDelete={data?.can_delete === true}
                                                                            id={data?.id}/>}
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </Block>


        </div>
    </MainLayout>
}

export default connector(withRouter(EditRecipientPage))
