import React from 'react';

export type Filter = {
    label: string
    value: string
}

export interface TableFilterProps {
    filters: Filter[]
    onClick: (filter: string) => void
    activeFilter?: string
}

export const TableFilters: React.FunctionComponent<TableFilterProps> = ({filters, onClick, activeFilter}) => {
    return <div className="table-filters">
        <ul>
            {filters.map((filter: Filter, key) =>
                <li key={key}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        onClick(filter.value)
                    }} className={filter.value === activeFilter ? 'active' : ''}>{filter.label}</a>
                </li>)}
        </ul>
    </div>
}

export default TableFilters;
