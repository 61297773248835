import React from "react";
import Config from "../../../pyntax/lib/config";
import ServiceManager from "../../../services/ServiceManager";

export const FacebookButton = () => {
    return <button type="button" className="btn btn-primary btn-fb" onClick={() => {
        window.location.href = Config.BASE_URL + "/" + ServiceManager.dataApi.socialLogin.getResourceByIdUrl('facebook');
    }}><span className="fb-icon"/> <span>Continue with Facebook</span>
    </button>
}

export default FacebookButton
