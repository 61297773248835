import {action} from "typesafe-actions";
import {TransactionActionTypes} from "./types";
import {Transaction} from "../../models/Transaction";
import {PaginationMeta} from "../types";

export const fetchListRequest = (payload: any) => action(TransactionActionTypes.FETCH_LIST_REQUEST, payload);
export const fetchListSuccess = (listOfTransactions: Transaction[], paginationMeta: PaginationMeta) => action(TransactionActionTypes.FETCH_LIST_SUCCESS, {
    listOfTransactions,
    paginationMeta
});

// export const createRequest = (payload: Transaction) => action(TransactionActionTypes.CREATE_REQUEST, {data: payload});
export const fetchError = (message: string) => action(TransactionActionTypes.FETCH_ERROR, [message]);
export const fetchRequest = (id: string) => action(TransactionActionTypes.FETCH_REQUEST, id);
export const fetchSuccess = (data: Transaction) => action(TransactionActionTypes.FETCH_SUCCESS, data);
// export const updateRequest = (id: number, payload: Transaction) => action(TransactionActionTypes.UPDATE_REQUEST, {
//     id,
//     data: payload
// });
