import React, {useState} from "react";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import Checkbox from '@material-ui/core/Checkbox';
import {ErrorNotification} from "../../Notifications";
import {Controller, useForm} from "react-hook-form";
import Utils from "../../../services/Utils";
import {PayableAgreementTypes} from "../../../labels";

type TermsAndConditionsAcceptingPayableAgreementAlertProps = {
    action: string
    showAlert: boolean
    onConfirm: (receiverBusinessEntityId: number) => void
    onCancel: () => void
    type?: string
    amount?: number
    firstMilestoneAmount?: number
    payableAgreementType?: PayableAgreementTypes
}

const AcceptingAlert: React.FC<TermsAndConditionsAcceptingPayableAgreementAlertProps> = (
    props
) => {
    const {action, showAlert, onConfirm, onCancel, amount, payableAgreementType, firstMilestoneAmount} = props
    const [termsAgreed, setTermsAgreed] = useState<boolean>(false),
        [receiverBusinessEntityId, setReceiverBusinessEntityId] = useState(-1),
        [receiverBusinessEntityName, setReceiverBusinessEntityName] = useState(""),
        [showPleaseAgreeToTermsAndAgreements, setShowPleaseAgreeToTermsAndAgreements] = useState<boolean>(false),
        handleOnConfirm = () => {
            if (termsAgreed) {
                setShowPleaseAgreeToTermsAndAgreements(false);
                onConfirm(receiverBusinessEntityId)
            } else {
                setShowPleaseAgreeToTermsAndAgreements(true);
            }
        }
    const {register, setValue, formState: {errors}, handleSubmit, control, reset} = useForm<any>()
    let amountClause = null
    if (amount) {
        amountClause = <b>{Utils.formatAmount(amount)}</b>
    }

    return <ConfirmDialog confirmButtonText={`I ${action}`} title={`Accept Payable Agreement`}
                          showDialog={showAlert} onConfirm={handleOnConfirm} onCancel={onCancel}>
        <>
            <div className="form-group">
                <p>
                    By clicking on <b>I Accept & Sign Agreement</b> you authorise paysecure to debit your
                    nominated bank account to a total of {amountClause}.
                </p>
            </div>
            {payableAgreementType !== 'unsecured_agreement' && <>
                {firstMilestoneAmount && <div className="form-group">
                    <p>
                        Your nominated bank account will be debited <b>{Utils.formatAmount(firstMilestoneAmount)}</b> now.
                    </p>
                </div>}
                <div className="form-group">
                    <p>
                        You are always in control of your funds. And you agree to release the payment once the agreement has
                        been fulfilled as per the T&Cs.
                    </p>
                </div>
            </>}
            {/*<div className="form-group">*/}
            {/*    <label htmlFor="sender_business_entity_id">You can choose an Business entity to accept the agreement.</label>*/}
            {/*    <Controller*/}
            {/*        name="sender_business_entity_id" control={control} defaultValue={false}*/}
            {/*        render={(field) => {*/}
            {/*            return <AsyncAutocomplete*/}
            {/*                entityType={`business_entity`}*/}
            {/*                onChange={((event, value) => {*/}
            {/*                    if (value === null) {*/}
            {/*                        setReceiverBusinessEntityId(-1);*/}
            {/*                        setReceiverBusinessEntityName("");*/}
            {/*                        return;*/}
            {/*                    }*/}
            {/*                    setReceiverBusinessEntityId(value.id);*/}
            {/*                    setReceiverBusinessEntityName(value.display_label);*/}
            {/*                })}*/}
            {/*                resourceManagerFactory={ServiceManager.dataApi.business_entity}*/}
            {/*                inputLabel={`Choose Business to accept this Agreement`}/>*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<p>*/}
            {/*    {receiverBusinessEntityId === -1 ?*/}
            {/*        <div> <p className="text-center">OR</p> <p>Just click on <b>I Accept & Sign Agreement</b> to accept the agreement as an individual.</p> </div> :*/}
            {/*        <label>You're accepting this agreement as {receiverBusinessEntityName}</label>}*/}
            {/*</p>*/}
            <p>
                <Checkbox id={`accept-toc-for-agreements`} checked={termsAgreed} onChange={(event) => {
                    setTermsAgreed(event.target.checked);

                    if (event.target.checked) {
                        setShowPleaseAgreeToTermsAndAgreements(false)
                    }
                }} color="primary"/> I agree with the <a target={`_blank`}
                                                         href="http://assets.paysecure.com.au/terms/index.html">terms
                and conditions here</a>
            </p>
            {showPleaseAgreeToTermsAndAgreements ?
                <ErrorNotification>Please accept the terms and conditions.</ErrorNotification> : null}
        </>
    </ConfirmDialog>
}

export default AcceptingAlert;
