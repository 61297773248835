import React, {useMemo} from "react";
import {Alert, AlertTitle} from '@material-ui/lab';

export * from './Containers'

export interface AlertProps {
    children: JSX.Element[] | JSX.Element | string | null | undefined,
    title?: string
}

export interface SeverityProps {
    severity: 'error' | 'info' | 'success' | 'warning'
}

export const NotificationContainer = ({children}: Exclude<AlertProps, 'title'>) => useMemo(() => {
    return <div className="notification-container">{children}</div>
}, [children])


export const Notification = (props: AlertProps & SeverityProps) => {
    const {severity, children, title} = props;
    return <NotificationContainer>
        <Alert severity={severity}>
            <AlertTitle>{title}</AlertTitle>
            {children}
        </Alert>
    </NotificationContainer>
}

export function ErrorNotification(props: AlertProps) {
    return <Notification {...props} title={props.title ?? "Error"} severity={'error'}>{props.children}</Notification>
}

export function InfoNotification(props: AlertProps) {
    return <Notification {...props} title={props.title ?? "Info"} severity={'info'}>{props.children}</Notification>
}

export function WarningNotification(props: AlertProps) {
    return <Notification {...props} title={props.title ?? "Warning"}
                         severity={'warning'}>{props.children}</Notification>
}

export function SuccessNotification(props: AlertProps) {
    return <Notification {...props} title={props.title ?? "Success"}
                         severity={'success'}>{props.children}</Notification>
}
