import React, {useState} from "react";
import DeleteMilestoneDialog from "../Dialogs/DeleteMilestoneDialog";
import {DangerButton} from "../../Buttons";
import {useShowDialog} from "../../../hooks";

interface Props {
    handleDeleteMilestone: () => void
}

export const DeleteMilestoneButton: React.FunctionComponent<Props> = ({
                                                                          handleDeleteMilestone
                                                                      }) => {
    const {showDialog, show, hide} = useShowDialog(false);
    return <>
        <DeleteMilestoneDialog showDialog={showDialog}
                               onConfirm={handleDeleteMilestone}
                               onCancel={hide}/>
        <DangerButton onClick={show} className={`btn btn-danger`}>Delete milestone</DangerButton>
    </>
}

export default DeleteMilestoneButton;
