import React, {useCallback, useEffect, useState} from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import Block from "../../components/Blocks/Block";
import RecordListTable from "../../components/Table/RecordListTable";
import PageTitle from "../../components/PageTitle";
import Recipient from "../../models/Recipient";
import {PaginationMeta} from "../../store/types";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {fetchListRequest} from "../../store/recipient/action";
import {connect} from "react-redux";
import {ToastProp} from "../../components/Notifications/Toasts";
import {LANG} from "../../labels"
import {RecipientCard} from "../../components/Cards";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";
import {Link} from "react-router-dom";
import ListPageAddButton from "../../components/Buttons/ListPageAddButton";
import ListPageAddLink from "../../components/Buttons/ListPageAddLink";

interface PropsFromState {
    loading: boolean;
    listItems?: Recipient[];
    paginationMeta?: PaginationMeta;
    notifications: NotificationState;
    toasts?: ToastProp[];
}

interface PropsFromDispatch {
    fetchListOfRecipients: (pageNumber?: number, pageSize?: number) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;


export type RecipientListPageParams = {
    statusFilter: string;
}

export const RecipientListPage: React.FunctionComponent<AllProps> = ({
                                                                         loading,
                                                                         listItems,
                                                                         paginationMeta,
                                                                         notifications,
                                                                         fetchListOfRecipients,
                                                                         toasts,
                                                                     }) => {
    const pageSize = 20;

    useEffect(() => {
        fetchListOfRecipients(1, pageSize);
    }, [])

    const [selectedRecipient, setSelectedRecipient] = useState<Recipient | null>(null);

    const onRowClick = (row: Recipient) => {
        setSelectedRecipient(row);
    }

    const onRecipientSaved = (row: Recipient) => {
        //  TODO / QUESTION see the issue mentioned in EditRecipientForm and adjust this if required
        setSelectedRecipient(row);
        //fetchListOfRecipients(1, 20)
    }

    const columns = [
        {
            headerTitle: '',
            accessor: () => <div className="d-flex justify-content-center">
                <i className="svg-icon contact grey-3"></i>
            </div>
        },
        {
            headerTitle: "Name",
            accessor: (row: Recipient) => {
                return `${row.first_name} ${row.last_name}`
            }
        },
        {
            headerTitle: "Mobile",
            accessor: "mobile"
        },
        {
            headerTitle: "Email",
            accessor: "email"
        },
        {
            headerTitle: "View / Edit",
            accessor: (row: Recipient) => <Link className={`btn btn-primary btn-sm`} to={`/recipients/${row.id}/edit`}>View
                / Edit</Link>
        }
    ];

    return <MainLayout pageTitle={`Recipients`} isLoading={loading} toasts={toasts}>
        {/* Hero */}
        <PageTitle title={LANG.RecipientListPage.DISPLAY_NAME}
                   toolTip={LANG.RecipientListPage.DISPLAY_NAME_TOOLTIP}>
            <ListPageAddLink desktopBtnLabel={`Add new contact`} to={`/recipients/new`}/>
        </PageTitle>
        {/* END Hero */}

        <NotificationsContainer notifications={notifications}/>

        <div className="content">
            <div className="row">
                <div className="col-md-7">
                    <Block noPadding={true}>
                        <RecordListTable<Recipient> loadMoreResults={(pageNumber: number) => {
                            fetchListOfRecipients(pageNumber, pageSize)
                        }} paginationMeta={paginationMeta} columns={columns} data={listItems} rowIdColumn="id"
                                                    onRowClick={onRowClick}/>
                    </Block>
                </div>
                <div className="col-md-5">
                    {selectedRecipient && <RecipientCard recipient={selectedRecipient} onSaved={onRecipientSaved}/>}
                </div>
            </div>
        </div>
    </MainLayout>
}

const mapStateToProps = ({recipient}: ApplicationState) => ({
    loading: recipient.loading,
    notifications: recipient.notifications,
    listItems: recipient.listItems,
    paginationMeta: recipient.paginationMeta,
    toasts: recipient.toasts,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchListOfRecipients: (pageNumber?: number, pageSize?: number) => {
            dispatch(fetchListRequest({pageNumber, pageSize}));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientListPage);
