import React from "react";
import classnames from "classnames";

interface BlockProps {
    title?: string;
    showBlockOptions?: boolean;
    children: React.ReactNode | null | undefined;
    noPadding?: boolean | undefined;
    footer?: JSX.Element | JSX.Element[] | null | undefined;
    withHeaderBorder?: boolean;
    className?: string
}

const Block: React.FunctionComponent<BlockProps> = ({
                                                        title,
                                                        children,
                                                        noPadding,
                                                        withHeaderBorder,
                                                        footer,
                                                        className
                                                    }) => {
    return <div
        className={classnames('block block-rounded', {'p-0': noPadding}, {'with-border': withHeaderBorder}, className)}>
        {title && <div className="block-header block-header-default">
            <h3 className="block-title">{title}</h3>
        </div>}
        <div className={classnames('block-content', {'p-0': noPadding})}>{children}</div>
        {footer}
    </div>
}

export default Block;
