import React, {ChangeEvent, useEffect, useState} from 'react'
import {ThunkDispatch} from 'redux-thunk'
import {AnyAction} from 'redux'
import {connect} from 'react-redux'
import {useForm} from 'react-hook-form'
import {PrimaryButton, SecondaryButton, TertiaryButton} from '../../components/Buttons'
import Block from '../../components/Blocks/Block'
import ListOfMilestones from '../../components/Milestones/ListOfMilestones'
import Milestone, {BaseMilestone} from '../../models/Milestone'
import MainLayout from '../../components/Layouts/MainLayout'
import {PayableAgreement} from '../../models/PayableAgreement'
import Statues from '../../enums'
import {ApplicationState} from '../../store'
import {
    createMilestone,
    downloadMilestoneReceiptRequest, fetchRequest, sendAgreementRequest, updateRequest,
} from '../../store/payableAgreement/action'
import {downloadRequest} from '../../store/attachment/action'
import {useParams} from 'react-router-dom'
import MilestoneContractorActions from '../../components/Milestones/MilestoneContractorActions'
import {RecipientCard} from '../../components/Cards'
import StatusPill from '../../components/PayableAgreement/StatusPill'
import {ToastProp} from '../../components/Notifications/Toasts'
import {InfoNotification, WarningNotification} from '../../components/Notifications'
import PayabelAgreementTimeline from '../../components/Timeline/PayabelAgreementTimeline'
import NotificationsContainer from "../../components/Notifications/Containers";
import {NotificationState} from "../../store/state";
import CancelAgreementButton from "../../components/PayableAgreement/Buttons/CancelAgreementButton";
import FeeCalculator from "../../services/FeeCalculator";
import Utils from "../../services/Utils";
import {TextInput} from "../../components/Forms/Fields/Input";

// Defining Props
interface PropsFromState {
    loading: boolean;
    notifications: NotificationState;
    data?: PayableAgreement;
    toasts?: ToastProp[];
}

// Defining PROPS.
interface PropsFromDispatch {
    sendAgreement: (id: string) => any;
    downloadAttachment: (id: string, fileName: string) => any;
    fetchPayableAgreement: (id: string) => any;
    updatePayableAgreement: (id: string, data: any) => any;
    saveNewMilestone: (newMilestone: BaseMilestone) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

type EditAgreementRouterParams = {
    id: string;
}

const EditAgreementPage: React.FC<AllProps> = ({
                                                   loading,
                                                   data,
                                                   updatePayableAgreement,
                                                   fetchPayableAgreement,
                                                   sendAgreement,
                                                   notifications,
                                                   toasts,
                                                   saveNewMilestone
                                               }) => {
    const {id} = useParams<EditAgreementRouterParams>()
    const {
        setValue,
        formState,
        register,
    } = useForm<any>({
        defaultValues: {
            ...data,
            due_by: data?.due_by_display,
            start_date: data?.start_date_display,
        },
    })
    const [payableAgreement, setPayableAgreement] = useState<PayableAgreement>({
        id: -1,
        status: 'draft',
        title: '',
        type: 'standard',
        action: 'selling',
        agreement_details: '',
        product_service_provided: 'service',
        milestones: [],
        sendNow: false,
    })
    const [isEditable, setIsEditable] = useState<boolean>(false)

    useEffect(() => {
        if (id) {
            fetchPayableAgreement(id)
        }
    }, [fetchPayableAgreement, id])

    useEffect(() => {
        if (data) {
            setPayableAgreement(data)
            setIsEditable(data && (data?.status === Statues.PayableAgreement.PENDING || data?.status === Statues.PayableAgreement.SENT))
            setIsSecured(data && data?.type !== 'unsecured_agreement')
        }
    }, [data])
    const onHandleSubmit = (e: MouseEvent) => {
        e.preventDefault()

        // Let's create a new copy of the Payable Agreement.
        const updatedPayableAgreement = Object.assign({}, payableAgreement)
        if (!isSecured) {
            updatedPayableAgreement.type = 'unsecured_agreement'
        }

        // We validate the payable agreement now.
        updatePayableAgreement(id, updatedPayableAgreement)
    }
    const [isSecured, setIsSecured] = useState<boolean>(true)
    // eslint-disable-next-line eqeqeq
    if (loading === false && data && data.id && data.product_service_provided == 'product') {
        return <MainLayout pageTitle={'Redirect to Product page'} isLoading={loading} errors={[]} toasts={[]}>
            <div className="content content-full">
                <InfoNotification>
                    <div>
                        Please click on the following link to view this Agreement. <a
                        href={`/product/agreements/${data.id}/edit`} className="link">Click here to view</a>
                    </div>
                </InfoNotification>
            </div>
        </MainLayout>
    }

    return <MainLayout pageTitle={`Create Agreement`} isLoading={loading} errors={[]} toasts={toasts}>
        <div className="content content-full">
            <NotificationsContainer notifications={notifications}/>
            {data && !data?.can_request_payment && !isSecured ? <div className="content">
                <InfoNotification>
                    <div>
                        As this a unsecured agreement, please make sure you have your bank account setup in order to get
                        paid{` `}<a href={`/bank_accounts/connect?return_payable_agreement_id=${data?.id}`}>click here
                        to
                        connect a bank
                        account.</a>
                    </div>
                </InfoNotification>
            </div> : null}

            <PayabelAgreementTimeline payableAgreement={payableAgreement}/>
            {!isSecured && <div className="row pb-4">
                <div className="col">
                    <WarningNotification>
                        <>This agreement is <b>unsecured</b>. This is an unsecured agreement. Funds will be immediately
                            transferred to you the seller. Please ensure your ‘customer’ is aware they will not be
                            entitled
                            to the protection under a secured agreement.
                        </>
                    </WarningNotification>
                </div>
            </div>}
            <div className="row">
                <div className="col-md-3 rhc-container">
                    {payableAgreement?.recipient && <>
                        <RecipientCard recipient={payableAgreement.recipient}/>
                    </>}
                    <Block>
                        <div className="form-group agreement-status">
                            <label className="label">Agreement Status</label>
                            <div className="agreement-status">
                                <StatusPill status={payableAgreement.status} userType={'contractor'}/>
                            </div>
                        </div>

                        {payableAgreement?.accepted_on_display && <div className="form-group agreement-status">
                            <label className="label">Accepted on</label>
                            <div className="agreement-status">
                                {payableAgreement?.accepted_on_display}
                            </div>
                        </div>}

                        {!payableAgreement?.accepted_on_display && payableAgreement?.sent_on_display &&
                            <div className="form-group agreement-status">
                                <label className="label">Last sent on</label>
                                <div className="agreement-status">
                                    {payableAgreement?.sent_on_display}
                                </div>
                            </div>}

                        {/*{isEditable && payableAgreement?.type ?*/}
                        {/*    <>*/}
                        {/*        <FormGroup label={`Secure this agreement`} fieldName={`secured_agreement`}*/}
                        {/*                   className={`secure_agreement_switch`} isRequired={true}>*/}
                        {/*            <Switch checked={isSecured} color="primary" name="secured_agreement"*/}
                        {/*                    defaultChecked={true}*/}
                        {/*                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                        {/*                        setIsSecured(event.target.checked)*/}
                        {/*                    }}/>*/}
                        {/*        </FormGroup>*/}
                        {/*        /!*{isSecured && <FormGroup label={`Agreement type`} fieldName={`agreement_type`}>*!/*/}
                        {/*        /!*    <select name="type"*!/*/}
                        {/*        /!*            onChange={(e: ChangeEvent<HTMLSelectElement>) => setValue('agreement_type', e.target.value)}*!/*/}
                        {/*        /!*            className={`form-control form-control-alt`}*!/*/}
                        {/*        /!*            value={payableAgreement?.type}>*!/*/}
                        {/*        /!*        <option value={`standard`}>{renderAgreementTypeLabel(`standard`)}</option>*!/*/}
                        {/*        /!*        <option*!/*/}
                        {/*        /!*            value={`only_payment_request`}>{renderAgreementTypeLabel(`only_payment_request`)}</option>*!/*/}
                        {/*        /!*    </select>*!/*/}
                        {/*        /!*</FormGroup>}*!/*/}
                        {/*    </> : <div className="form-group agreement-type">*/}
                        {/*        <label className="label">Agreement Type</label>*/}
                        {/*        <div className="type">{renderAgreementTypeLabel(payableAgreement?.type)}</div>*/}
                        {/*    </div>}*/}
                        {/*{isEditable ? <FormGroup label={`Start date`} fieldName={`start_date`}>*/}
                        {/*    <DayPickerInput*/}
                        {/*        component={(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>) => {*/}
                        {/*            return <input {...props} value={props.value}*/}
                        {/*                          className={`form-control form-control-alt`}/>*/}
                        {/*        }}*/}
                        {/*        onDayChange={(args) => setValue('start_date', MomentLocaleUtils.formatDate(args, 'DD/MM/YYYY'))}*/}
                        {/*        format={`DD/MM/YYYY`}*/}
                        {/*        formatDate={MomentLocaleUtils.formatDate}*/}
                        {/*        parseDate={MomentLocaleUtils.parseDate}*/}
                        {/*        placeholder={`Start date`}/>*/}
                        {/*</FormGroup> : <div className="form-group start-date">*/}
                        {/*    <label className="label">Start Date:</label>*/}
                        {/*    <div className="type">{payableAgreement?.start_date_display ?? '-'}</div>*/}
                        {/*</div>}*/}

                        {/*{isEditable ? <FormGroup label={`Due date`} fieldName={`due_by`}>*/}
                        {/*    <DayPickerInput*/}
                        {/*        component={(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>) =>*/}
                        {/*            <input {...props} className={`form-control form-control-alt`}/>}*/}
                        {/*        onDayChange={(args) => setValue('due_by', MomentLocaleUtils.formatDate(args, 'DD/MM/YYYY'))}*/}
                        {/*        format={`DD/MM/YYYY`}*/}
                        {/*        formatDate={MomentLocaleUtils.formatDate}*/}
                        {/*        parseDate={MomentLocaleUtils.parseDate}*/}
                        {/*        placeholder={`Due Date`}/>*/}
                        {/*</FormGroup> : <div className="form-group start-date">*/}
                        {/*    <label className="label">Due Date:</label>*/}
                        {/*    <div className="type">{payableAgreement?.due_by_display ?? '-'}</div>*/}
                        {/*</div>}*/}
                    </Block>

                    <div className="agreement-amounts">
                        <Block>
                            <table className={`table total-table`}>
                                {/*<tr>*/}
                                {/*    <td className={`label`}>Subtotal</td>*/}
                                {/*    <td className={`amount`}>$0.00</td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <td className={`label`}>Total amount</td>
                                    <td className={`amount`}><em>{payableAgreement?.total_amount_display}</em></td>
                                </tr>
                                <tr>
                                    <td className={`label`}>Fees</td>
                                    {/*Till the fees are not applied let's calculate it manually, this should be re-instated once we want to start charging fees*/}
                                    {/*<td className={`amount`}>{payableAgreement?.milestone_fee_display}</td>*/}
                                    <td className={`amount discounted`}>{payableAgreement?.total_amount && Utils.formatAmount(FeeCalculator.calculateFee(payableAgreement?.total_amount))}</td>
                                </tr>
                                <tr>
                                    <td className={`label`}>Payout amount</td>
                                    <td className={`amount`}>{payableAgreement?.payout_amount_display}</td>
                                </tr>
                            </table>
                        </Block>
                    </div>
                </div>
                <div className="col-md-9">
                    <Block>
                        {isEditable ? <div className="contract-title form-group">
                            <TextInput label={'Agreement title'} isRequired={true}
                                       {...register('title', {
                                           required: true,
                                           minLength: 3,
                                           maxLength: 50
                                       })} value={payableAgreement?.title} onChange={(e => {
                                setPayableAgreement({...payableAgreement, title: e.target.value})
                            })}/>
                        </div> : <h2 className="agreement-title pb-3">{payableAgreement?.title}</h2>}
                        {/*{isEditable ? <div className="form-group">*/}
                        {/*    <TextField {...register('agreement_details', {required: true})} multiline={true}*/}
                        {/*               onChange={(e: ChangeEvent<HTMLInputElement>) => {*/}
                        {/*                   setPayableAgreement({*/}
                        {/*                       ...payableAgreement,*/}
                        {/*                       agreement_details: e.currentTarget.value,*/}
                        {/*                   })*/}
                        {/*               }} value={payableAgreement?.agreement_details}*/}
                        {/*               id={`agreement_details`} label="Job description goes here"/>*/}
                        {/*</div> : <div className="agreement_details">{payableAgreement?.agreement_details}</div>}*/}
                    </Block>


                    <div className="milestone-row heading">
                        <div className="step-number">#</div>
                        <div className="title">Title</div>
                        <div className="description">Description</div>
                        <div className="due text-right">Due by</div>
                        <div className="fees text-right">Fees</div>
                        <div className="amount text-right">Amount</div>
                    </div>

                    {payableAgreement.milestones && <ListOfMilestones
                        payableAgreement={payableAgreement}
                        milestones={payableAgreement.milestones}
                        payableAgreementStatus={payableAgreement?.status ?? 'pending'}
                        onAddingNewMilestone={(m: Milestone) => {
                            // // Because we are adding a new milestone and this is going directly to the API let's set it to -1
                            // m.id = -1
                            // const updatedPayableAgreement: PayableAgreement = {
                            //     ...payableAgreement,
                            //     milestones: [{...m}, ...payableAgreement.milestones || []],
                            // }
                            // setPayableAgreement(updatedPayableAgreement)
                            // updatePayableAgreement(payableAgreement?.id.toString(), updatedPayableAgreement);
                            saveNewMilestone({
                                ...m,
                                payable_agreement_id: payableAgreement.id
                            } as BaseMilestone)
                        }} canAddMoreMilestones={isEditable}>
                        <MilestoneContractorActions agreement={payableAgreement} showAddAttachments={true}/>
                    </ListOfMilestones>}

                    <div className="row">
                        <div className="col">
                            <div className="contract-actions">
                                {isEditable && payableAgreement?.status !== 'CLOSED' &&
                                    <PrimaryButton type={`button`} onClick={onHandleSubmit}>Save
                                        agreement</PrimaryButton>}

                                {payableAgreement && payableAgreement?.id && payableAgreement?.status === 'draft' &&
                                    <SecondaryButton data-testid={'send-agreement'} type={`button`} onClick={() => {
                                        if (payableAgreement && payableAgreement?.id) {
                                            sendAgreement(payableAgreement?.id.toString())
                                        }
                                    }}>Send agreement</SecondaryButton>}

                                {payableAgreement && payableAgreement?.id &&
                                    (payableAgreement?.status !== 'draft') &&
                                    <TertiaryButton type="button" onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        if (payableAgreement && payableAgreement?.id) {
                                            sendAgreement(payableAgreement?.id.toString())
                                        }
                                    }}>Re-send agreement</TertiaryButton>}

                                {data && data.id && data.can_cancel_agreement &&
                                    <CancelAgreementButton payableAgreementId={data?.id}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
}

const mapStateToProps = ({
                             payableAgreement,
                             attachment,
                             recipient,
                         }: ApplicationState) => ({
    loading: payableAgreement.loading || attachment.loading || recipient.loading,
    notifications: payableAgreement.notifications,
    data: payableAgreement.data,
    attachmentsToBeSaved: payableAgreement.attachmentsToBeSaved ?? [],
    toasts: payableAgreement.toasts,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        downloadAttachment: (attachmentId: string, fileName: string) => {
            dispatch(downloadRequest(attachmentId, fileName, 'contractor'))
        },
        sendAgreement: (id: string) => {
            dispatch(sendAgreementRequest(id))
        },
        fetchPayableAgreement: (id: string) => {
            dispatch(fetchRequest({id}))
        },
        updatePayableAgreement: (id: string, data: PayableAgreement) => {
            dispatch(updateRequest({
                id,
                data,
            }))
        },
        downloadMilestoneReceipt: (milestoneId: string) => {
            dispatch(downloadMilestoneReceiptRequest(milestoneId))
        },
        saveNewMilestone: (data: BaseMilestone) => {
            dispatch(createMilestone({data}))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAgreementPage)

