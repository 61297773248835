import React from "react";
import classnames from "classnames";
import {FieldErrors} from "react-hook-form";
import {renderErrorIfAny} from "./common";

export const defaultInputClasses = 'form-control';

/**
 * @interface
 */
interface FormGroupProps {
    label: string
    fieldName: string
    children: React.ReactChild
    errors?: FieldErrors
    isRequired?: boolean
    className?: string
}

/**
 * @param fieldName
 * @param errors
 * @param isRequired
 * @param className
 * @param label
 * @param children
 *
 * @constructor
 */
const FormGroup: React.FC<FormGroupProps> = ({
                                                 fieldName,
                                                 errors,
                                                 isRequired,
                                                 className,
                                                 label,
                                                 children
                                             }) => {



    return <div className={classnames("form-group", className)}>
        <label htmlFor={fieldName}>{label} {isRequired && <span className="text-danger">*</span>}</label>
        {React.isValidElement(children) ? children : null}
        {renderErrorIfAny({errors, fieldName, label})}
    </div>
}

export default FormGroup;
