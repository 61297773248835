import React, {MouseEventHandler} from "react";
import {ButtonProps, SuccessButton} from "./index";
import ServiceManager from "../../services/ServiceManager";

interface SaveButtonProps {
    buttonLabel?: string
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * @param buttonLabel
 * @param onClick
 *
 * @constructor
 */
const SaveButton: React.FunctionComponent<SaveButtonProps> = ({buttonLabel, onClick}) => {
    buttonLabel = ServiceManager.utils.isEmpty(buttonLabel) ? "Save" : buttonLabel;

    return <SuccessButton onClick={onClick}>{buttonLabel}</SuccessButton>
}

export default SaveButton;
