import ReactGA, {EventArgs} from "react-ga";
import Config from '../pyntax/lib/config';
import {CustomEvent, Events} from "../store/events";
import User from "../models/User";

/**
 * @class AnalyticsInterface
 */
export interface AnalyticsInterface {
    /**
     * @param eventName
     * @param eventData
     */
    trackEvent: (event: CustomEvent) => void;

    /**
     * @param userId
     * @param userDetails
     */
    identifyUser: (userId: number, userDetails: User) => void;

    /**
     * @param pageName
     * @param pageData
     */
    trackPage: (pageUrl: string) => void;
}

/**
 * @class Analytics
 */
export class Analytics implements AnalyticsInterface {
    /**
     *
     * @param pageUrl
     */
    trackPage(pageUrl: string): void {
        // // ReactGA.pageview(pageUrl);
        // // @ts-ignore
        // if (window.analytics) {
        //     // @ts-ignore
        //     window.analytics.page({
        //         url: pageUrl
        //     })
        // }
    }

    shouldTrack(): boolean {
        return Config.APP_ENV === 'prod'
    }

    /**
     * @param event
     */
    trackEvent(event: CustomEvent): void {
        if (!this.shouldTrack()) {
            return;
        }

        const eventArgs: EventArgs = {
            category: event.entity,
            action: event.action,
        };

        // @ts-ignore
        if (window.analytics) {
            // @ts-ignore
            window.analytics.track({
                event: `${event.entity.toString()} ${event.type.toString()}`,
                properties: event
            });
        }

        // If we have an value number we can pass it.
        // if (event.value) {
        //     eventArgs.value = event.value;
        // }

        // ReactGA.event(eventArgs);
    }

    /**
     * @param userId
     * @param userDetails
     */
    identifyUser(userId: number, userDetails: User): void {
        if (!this.shouldTrack()) {
            return;
        }

        // @ts-ignore
        if (window.analytics) {
            // @ts-ignore
            window.analytics.identify(userId, {
                name: userDetails.name,
            });
        }
    }
}

export default Analytics
