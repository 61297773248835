import React from "react";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import Utils from "../../../services/Utils";


type AuthoriseDepositRequestDialogProps = {
    showDialog: boolean
    onConfirm: () => void
    onCancel: () => void
    amount?: number
    title?: string
    isAgreementSecured: boolean
}

const AuthoriseDepositRequestDialog: React.FC<AuthoriseDepositRequestDialogProps> = ({
                                                                                         showDialog,
                                                                                         onConfirm,
                                                                                         onCancel,
                                                                                         amount,
                                                                                         isAgreementSecured,
                                                                                         title = 'Authorise release of funds'
                                                                                     }) => {

    const forAmount = !!amount ? <>of <b>{Utils.formatAmount(amount)}</b> </> : ``
    return <ConfirmDialog
        showDialog={showDialog}
        confirmButtonText={title}
        onCancel={onCancel} onConfirm={onConfirm}
        title={title}>
        <div className="form-group">
            <p>By clicking <em>`{title}`</em>, I authorise paysecure to debit my connected bank
                account
                {forAmount}for the milestone payment.</p>
        </div>
        {isAgreementSecured && <div className="form-group">
            <p>The money will sit securely with paysecure until the job is complete and will only be released once
                you're happy and the Job is completed.</p>
        </div>}
        {!isAgreementSecured && <>
            <div className="form-group">
                <p>This is a <b>unsecured agreement</b>, the money will <b>NOT</b> sit with paysecure and will be paid out to the seller
                    as soon as it clears.</p>
            </div>
            <div className="form-group">
                <em>**Please make sure you have verified the work completed.**</em>
            </div>
        </>}
    </ConfirmDialog>
}

export default AuthoriseDepositRequestDialog;
