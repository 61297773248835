import React, {ChangeEvent, useState} from "react";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {useForm} from "react-hook-form";

import MainLayout from "../../components/Layouts/MainLayout";
import Milestone from "../../models/Milestone";
import {PayableAgreement} from "../../models/PayableAgreement";
import {createRequest} from "../../store/payableAgreement/action";
import {ApplicationState} from "../../store";
import Block from "../../components/Blocks/Block";
import ListOfMilestones from "../../components/Milestones/ListOfMilestones";
import {SuccessButton} from "../../components/Buttons";
import FormGroup from "../../components/Forms/FormGroup";
import AysncRecipientsDropDown from "../../components/Recipients/AsyncRecipientsDropDown";

import classnames from "classnames";
import {RecipientCard} from "../../components/Cards";
import EditRecipientDialog from "../../components/Recipients/Dialogs/EditRecipientDialog";
import Recipient from "../../models/Recipient";
import {WarningNotification} from "../../components/Notifications";
import NewAgreementMilestoneActions from "../../components/Milestones/NewAgreementMilestoneActions";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";
import {TextInput} from "../../components/Forms/Fields/Input";

// Defining Props
interface PropsFromState {
    loading: boolean;
    data?: PayableAgreement;
    notifications: NotificationState
}

// Defining PROPS.
interface PropsFromDispatch {
    createAgreement: (payableAgreement: PayableAgreement, sendNow: boolean) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

/**
 * @constructor
 */
const CreateAgreementPage: React.FunctionComponent<AllProps> = ({loading, createAgreement, notifications}) => {
    const {
        register,
        setValue,
        formState: {errors},
        getValues,
        setError,
        control,
        reset
    } = useForm<any>({});
    const [payableAgreement, setPayableAgreement] = useState<PayableAgreement>({
        id: -1,
        status: 'pending',
        title: '',
        type: "standard",
        action: "selling",
        agreement_details: "",
        product_service_provided: "service",
        milestones: [],
        sendNow: false,
        agreement_type: 'standard'
    });
    const [isSecured, setIsSecured] = useState<boolean>(true);

    const onHandleSubmit = (e: MouseEvent) => {
        e.preventDefault();
        let hasErrors = false;

        // Let's check if we have a Recipient
        const hasRecipient = payableAgreement?.recipient ?? undefined;
        const recipient_id = getValues("recipient_id");
        const recipient_user_email = getValues('recipient_user_email');

        if (!hasRecipient) {
            // Let's validate the payable agreement;
            if (!recipient_user_email && !recipient_id) {
                setError('recipient_user_email', {message: 'Please choose an existing recipient or add a new one.'});
                hasErrors = true;
            }

            if (payableAgreement.title.trim().length === 0) {
                setError('title', {message: 'Please enter agreement title'});
                hasErrors = true;
            }
        }

        // ToDo: Make agreement_details not required anymore.
        // if (payableAgreement.agreement_details.trim().length === 0) {
        //     setError('agreement_details', {message: 'Please enter agreement details or a short description'});
        //     hasErrors = true;
        // }

        if (hasErrors) {
            return;
        } else {
            // Let's create a new copy of the Payable Agreement.
            const newPayableAgreement = Object.assign({}, payableAgreement);

            if (!hasRecipient) {
                if (recipient_id) {
                    newPayableAgreement.recipient_id = recipient_id;
                }
                if (recipient_user_email) {
                    newPayableAgreement.recipient_user_email = recipient_user_email;
                }
            } else {
                newPayableAgreement.recipient = payableAgreement?.recipient;
            }

            if (!isSecured) {
                newPayableAgreement.type = 'unsecured_agreement';
            }

            // This line should only be used if we are hiding the agreement description.
            newPayableAgreement.agreement_details = newPayableAgreement.title

            // We validate the payable agreement now.
            createAgreement(newPayableAgreement, false);
        }
    }

    const [showCreateContact, setShowCreateContact] = useState<boolean>(false);

    // @ts-ignore
    return <MainLayout pageTitle={`Create Agreement`} isLoading={loading} errors={[]}>

        <div className="content content-full">
            <NotificationsContainer notifications={notifications}/>

            <div className="row">
                <div className="col">
                    {!isSecured && <div className="pb-2">
                        <WarningNotification>
                            <>This agreement is <b>unsecured</b>. Please note funds will be transferred immediately to
                                you. The buyer will not have the protection to release funds prior to reviewing
                                goods/services.
                            </>
                        </WarningNotification>
                    </div>}
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 rhc-container">
                    <Block>
                        {/*<FormGroup label={`Secure this agreement`} fieldName={`secured_agreement`}*/}
                        {/*           className={`secure_agreement_switch`} isRequired={true}>*/}
                        {/*    <Switch checked={isSecured} color="primary" name="secured_agreement" defaultChecked={true}*/}
                        {/*            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                        {/*                setIsSecured(event.target.checked)*/}
                        {/*            }}/>*/}
                        {/*</FormGroup>*/}
                        <FormGroup label={`Choose a contact`} isRequired={true} fieldName={`recipient_user_email`}
                                   errors={errors}>
                            <>
                                {payableAgreement?.recipient ?
                                    <>
                                        <RecipientCard recipient={payableAgreement?.recipient} displayType={'compact'}/>
                                        <a className="link remove-recipient"
                                           href="#" onClick={() => {
                                            setPayableAgreement({
                                                ...payableAgreement,
                                                recipient: undefined
                                            })
                                            setValue('recipient_id', undefined);
                                            setValue('recipient_user_email', undefined);
                                        }}> <i className="svg-icon trash highlighted"/> Remove recipient</a>
                                    </> : <>
                                        <AysncRecipientsDropDown onSelected={(recipient: Recipient) => {
                                            setPayableAgreement({
                                                ...payableAgreement,
                                                recipient: recipient
                                            })
                                        }} errors={errors} control={control} setValue={setValue} reset={reset}
                                                                 register={register}/>
                                        <div className={`or-divider`}>OR</div>
                                        <a href="#" className="link add-new-contact"
                                           onClick={() => setShowCreateContact(true)}><i
                                            className="svg-icon add-icon highlighted"/>{` `}Add new contact</a>
                                    </>}
                                <EditRecipientDialog recipient={{id: -1}}
                                                     showDialog={showCreateContact}
                                                     onCancel={() => setShowCreateContact(false)}
                                                     onSaved={(recipient: Recipient) => {
                                                         setValue('recipient_id', recipient.id);
                                                         setValue('recipient_user_email', recipient.email);
                                                         setShowCreateContact(false);
                                                         setPayableAgreement({
                                                             ...payableAgreement,
                                                             recipient: recipient
                                                         });
                                                     }}/>
                            </>
                        </FormGroup>

                        {/*{isSecured && <FormGroup label={`Agreement type`} isRequired={true} fieldName={`agreement_type`}*/}
                        {/*                         errors={errors}>*/}
                        {/*    <select {...register('agreement_type', {required: true})}*/}
                        {/*            className={`form-control`}>*/}
                        {/*        <option value={`standard`}>{renderAgreementTypeLabel(`standard`)}</option>*/}
                        {/*        <option*/}
                        {/*            value={`only_payment_request`}>{renderAgreementTypeLabel(`only_payment_request`)}</option>*/}
                        {/*    </select>*/}
                        {/*</FormGroup>}*/}
                        {/*<FormGroup label={`Start date`} fieldName={`start_date`} errors={errors}>*/}
                        {/*    <DayPickerInput*/}
                        {/*        component={(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>) =>*/}
                        {/*            <input {...props} className={`form-control `}/>}*/}
                        {/*        onDayChange={(date: Date) => {*/}
                        {/*            if (date) {*/}
                        {/*                setPayableAgreement({*/}
                        {/*                    ...payableAgreement,*/}
                        {/*                    start_date: MomentLocaleUtils.formatDate(date, 'DD/MM/YYYY')*/}
                        {/*                });*/}
                        {/*                setValue('start_date', MomentLocaleUtils.formatDate(date, 'DD/MM/YYYY'))*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/*        format={`DD/MM/YYYY`}*/}
                        {/*        formatDate={MomentLocaleUtils.formatDate}*/}
                        {/*        parseDate={MomentLocaleUtils.parseDate}*/}
                        {/*        placeholder={`Start date`}/>*/}
                        {/*</FormGroup>*/}
                        {/*<FormGroup label={`Due date`} fieldName={`due_by`} errors={errors}>*/}
                        {/*    <DayPickerInput*/}
                        {/*        component={(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>) =>*/}
                        {/*            <input {...props} className={`form-control `}/>}*/}
                        {/*        onDayChange={(date: Date) => {*/}
                        {/*            if (date) {*/}
                        {/*                setPayableAgreement({*/}
                        {/*                    ...payableAgreement,*/}
                        {/*                    due_by: MomentLocaleUtils.formatDate(date, 'DD/MM/YYYY')*/}
                        {/*                })*/}
                        {/*                setValue('due_by', MomentLocaleUtils.formatDate(date, 'DD/MM/YYYY'))*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/*        format={`DD/MM/YYYY`}*/}
                        {/*        formatDate={MomentLocaleUtils.formatDate}*/}
                        {/*        parseDate={MomentLocaleUtils.parseDate}*/}
                        {/*        placeholder={`Due Date`}/>*/}
                        {/*</FormGroup>*/}
                    </Block>

                </div>
                <div className="col-lg-9">
                    <Block withHeaderBorder={true} className={`title-and-descr-block`}>
                        {payableAgreement && payableAgreement.id && payableAgreement.id > 0 &&
                            <p>ID: {payableAgreement?.id}</p>}
                        <div data-testid={'agreement_title'}
                             className={classnames("contract-title form-group", {"is-invalid": errors && errors.title})}>
                            <TextInput label={'Agreement title'} isRequired={true}
                                       {...register('title', {
                                           required: true,
                                           minLength: 3,
                                           maxLength: 50
                                       })}
                                       onChange={(e => {
                                           setPayableAgreement({...payableAgreement, title: e.target.value})
                                       })}/>
                            {errors && errors.title && <div className="text-danger">{errors.title.message}</div>}
                        </div>
                        {/*<div*/}
                        {/*    className={classnames("contract-agreement-details form-group", {"is-invalid": errors && errors.agreement_details})}>*/}
                        {/*    <TextField variant={`filled`} {...register('agreement_details', {*/}
                        {/*        required: true,*/}
                        {/*        minLength: 3*/}
                        {/*    })} multiline={true} value={payableAgreement?.agreement_details}*/}
                        {/*               onChange={(e: ChangeEvent<HTMLInputElement>) => {*/}
                        {/*                   setPayableAgreement({*/}
                        {/*                       ...payableAgreement,*/}
                        {/*                       agreement_details: e.currentTarget.value*/}
                        {/*                   })*/}
                        {/*               }} error={errors && errors['agreement_details']}*/}
                        {/*               id={`agreement_details`} label="Description goes here"/>*/}
                        {/*    {errors && errors.agreement_details &&*/}
                        {/*        <div className="text-danger">{errors.agreement_details.message}</div>}*/}
                        {/*</div>*/}
                    </Block>

                    <div className="milestone-row heading">
                        <div className="step-number">#</div>
                        <div className="title">Title</div>
                        <div className="description">Description</div>
                        <div className="due text-right">Due by</div>
                        <div className="fees text-right">Fees</div>
                        <div className="amount text-right">Amount</div>
                    </div>

                    <ListOfMilestones showAddNewFormOnLoad={true}
                                      payableAgreement={payableAgreement}
                                      milestones={payableAgreement.milestones}
                                      payableAgreementStatus={payableAgreement?.status}
                                      onAddingNewMilestone={(m: Milestone) => {
                                          if (payableAgreement && payableAgreement.milestones) {
                                              m.step_number = (payableAgreement?.milestones?.length + 1).toString();
                                          } else {
                                              m.step_number = "1";
                                          }
                                          setPayableAgreement({
                                              ...payableAgreement,
                                              milestones: [...payableAgreement.milestones || [], m]
                                          })
                                      }} canAddMoreMilestones={true}>
                        <NewAgreementMilestoneActions onDelete={(milestoneId => {
                            setPayableAgreement({
                                ...payableAgreement,
                                milestones: payableAgreement.milestones?.filter((milestone: Milestone) => milestone.id !== milestoneId)
                            })
                        })} onMilestoneEdit={(milestone: Milestone) => {
                            let milestones: Milestone[] = [];
                            if (payableAgreement.milestones) {
                                milestones = payableAgreement.milestones?.filter((m: Milestone) => m.id !== milestone.id)
                            }

                            setPayableAgreement({
                                ...payableAgreement,
                                milestones: [
                                    ...milestones,
                                    milestone
                                ]
                            })
                        }}/>
                    </ListOfMilestones>

                    <div className="contract-actions">
                        <SuccessButton type={`button`} onClick={onHandleSubmit}>Save agreement</SuccessButton>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
}

/**
 * @param payableAgreement
 */
const mapStateToProps = ({payableAgreement, recipient}: ApplicationState) => ({
    loading: payableAgreement.loading || recipient.loading,
    notifications: payableAgreement.notifications,
    data: payableAgreement.data
})

/**
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        createAgreement: (payableAgreement: PayableAgreement, sendAgreement: boolean = false) => {
            if (!payableAgreement?.recipient) {
                payableAgreement.recipient = {
                    id: -1,
                    first_name: payableAgreement?.recipient_first_name,
                    last_name: payableAgreement?.recipient_last_name,
                    mobile: payableAgreement.recipient_user_mobile,
                    email: payableAgreement.recipient_user_email
                };
            }

            // Check if we want to send at the same time.
            payableAgreement.sendNow = sendAgreement;

            dispatch(createRequest({data: payableAgreement}));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAgreementPage);
