import React, {useEffect, useState} from "react";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import {BankAccount} from "../../../models/BankAccount";
import FormGroup, {defaultInputClasses} from "../../Forms/FormGroup";

interface Props {
    showDialog: boolean
    onCancel: () => void
    onConfirm: (bankAccountId: number) => void
    listOfBankAccounts?: BankAccount[]
}

const PayUnsecureAgreementDialog: React.FC<Props> = ({
                                                         onCancel, onConfirm, showDialog, listOfBankAccounts
                                                     }) => {
    const [activeBankAccounts, setActiveBankAccounts] = useState<BankAccount[]>([]);

    useEffect(() => {
        if (listOfBankAccounts) {
            setActiveBankAccounts(listOfBankAccounts.filter((bankAccount: BankAccount) => bankAccount?.status === 'connected'))
        }
    }, [listOfBankAccounts])

    const [bankAccountId, setBankAccountId] = useState<number>(0);
    return <ConfirmDialog title={`Pay now`} confirmButtonText={`Pay now`}
                          showDialog={showDialog} onCancel={onCancel} onConfirm={() => {
        onConfirm(bankAccountId)
    }}>
        <>
            <div className="row">
                <div className="col d-flex align-items-center flex-column">
                    <i className="svg-icon bank highlighted svg-2x"/>
                    <h3 className="h3">Choose a Bank account</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FormGroup label={`Bank account`} fieldName={`bank_account_id`}>
                        <select className={defaultInputClasses} id="bank_account_id"
                                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                                    setBankAccountId(parseInt(e.currentTarget.value));
                                }}>
                            <option>Choose bank account</option>
                            {activeBankAccounts?.map((listOfBankAccount: BankAccount) => {
                                return <option value={listOfBankAccount.id}>{listOfBankAccount.nickname}</option>
                            })}
                        </select>
                    </FormGroup>
                </div>
            </div>
        </>
    </ConfirmDialog>
}

export default PayUnsecureAgreementDialog
