import {Reducer} from "redux";
import {RegisterActionsTypes, RegisterState} from "./types";

export const initialState: RegisterState = {
    loading: false,
    notifications: {
        errors: [],
        alerts: [],
        infos: [],
        warnings: [],
        success: []
    },
    toasts: [],
    isRegistrationSuccessful: undefined
}

const reducer: Reducer<RegisterState> = (state = initialState, action) => {
    switch (action.type) {
        case RegisterActionsTypes.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                notifications: initialState.notifications,
            };

        case RegisterActionsTypes.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                isRegistrationSuccessful: true,
                notifications: initialState.notifications,
            };

        case RegisterActionsTypes.FETCH_ERROR:
            return {
                ...state,
                loading: false,
                notifications: {
                    ...state.notifications,
                    errors: action.payload
                },
                isRegistrationSuccessful: false,
            };

        default:
            return state;
    }
}

export {reducer as RegistrationReducer}
