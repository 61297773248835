import {
    useFormContext,
    UseFormReturn,
    FieldValues,
} from 'react-hook-form'

// Note: this will cause re-renders on form state change. Consider the more complex and performant approach documented here
// https://react-hook-form.com/advanced-usage#FormProviderPerformance
export const ConnectForm = <T extends FieldValues>({
                                                       children,
                                                   }: {
    children: (methods: UseFormReturn<T, any>) => JSX.Element
}) => {
    const formMethods = useFormContext<T>()
    return children ? children({...formMethods}) : null
}
