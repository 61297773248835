import {all, call, put, takeEvery} from 'redux-saga/effects';
import {IncomingPayableAgreementActionTypes} from "./types";
import {
    fetchError,
    fetchSuccess,
    fetchListSuccess,
    fetchRequest,
    sendPayableAgreementApprovalTFACodeSuccess, sendPayableAgreementApprovalTFACodeError
} from "./action";
import ServiceManager from "../../services/ServiceManager";
import {PayableAgreement} from "../../models/PayableAgreement";
import {AxiosResponse} from "axios";
import {PaginationMeta} from "../types";
import {handleError} from "../saga";
import {addToast} from "../action";
import Milestone from "../../models/Milestone";
import {Events, makeFactory} from "../events";
import {EntityTypes} from "../../models/types";

function* handleSendPayableAgreementTfaRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.twoFactorAuth,
            ServiceManager.dataApi.twoFactorAuth.update
        ], `payable_agreement/send`, {});

        if (response.status !== 200) {
            yield put(sendPayableAgreementApprovalTFACodeError(response.statusText))
        } else {
            yield put(sendPayableAgreementApprovalTFACodeSuccess())
        }
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handleApprovePaymentRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
                ServiceManager.dataApi.incomingPayableAgreementMilestone,
                ServiceManager.dataApi.incomingPayableAgreementMilestone.update
            ], action.payload.milestoneId + '/approve', {tfaVerificationCode: action.payload.tfaVerificationCode}
        );

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.PAYOUT_REQUEST.toString(), Events.APPROVED, action.payload.milestoneId));

        yield put(addToast("Payment request has been approved. The money would now be released.", "info"));
        yield handleFetch({payload: action.payload.payableAgreementId});

    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
        window.scrollTo(0, 0);
    }
}

function* handleRejectPaymentRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
                ServiceManager.dataApi.incomingPayableAgreementMilestone,
                ServiceManager.dataApi.incomingPayableAgreementMilestone.update
            ], action.payload.milestoneId + '/reject', {
                reason: action.payload.reason
            }
        );

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.PAYOUT_REQUEST.toString(), Events.REJECTED, action.payload.milestoneId));

        yield put(addToast("Milestone payment request denied successfully.", "error"));
        yield handleFetch({payload: action.payload.payableAgreementId});
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}


function* handleApproveDepositRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
                ServiceManager.dataApi.incomingPayableAgreementMilestone,
                ServiceManager.dataApi.incomingPayableAgreementMilestone.update
            ], action.payload + '/approve_milestone_deposit', {}
        );

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.DEPOSIT_REQUEST.toString(), Events.APPROVED, action.payload));

        yield put(addToast("Deposit request approved. Your bank account would be debited and money will he held safely with paysecure.", "success"));
        yield handleFetch({payload: response.data.payable_agreement_id});
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handleRejectDepositRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
                ServiceManager.dataApi.incomingPayableAgreementMilestone,
                ServiceManager.dataApi.incomingPayableAgreementMilestone.update
            ], action.payload.milestoneId + '/reject_milestone_deposit', {
                reason: action.payload.reason
            }
        );

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.DEPOSIT_REQUEST.toString(), Events.REJECTED, action.payload));

        yield put(addToast("Deposit request denied successfully.", "error"));
        yield handleFetch({payload: response.data.payable_agreement_id});
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handleAcceptPayableAgreement(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.incomingPayableAgreement,
            ServiceManager.dataApi.incomingPayableAgreement.update,
        ], action.payload.id + '/approve', {
            receiverBusinessEntityId: action.payload.receiverBusinessEntityId
        });

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.PAYABLE_AGREEMENT.toString(), Events.APPROVED, action.payload.id));

        const payableAgreement: PayableAgreement = response.data.data as PayableAgreement;
        yield put(addToast("Agreement Accepted. You will be notified once a payment is requested.", "success"));
        yield put(fetchSuccess(payableAgreement));

    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handleRejectPayableAgreement(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.incomingPayableAgreement,
            ServiceManager.dataApi.incomingPayableAgreement.update,
        ], action.payload.id + '/reject', {reason: action.payload.reason});

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.PAYABLE_AGREEMENT.toString(), Events.REJECTED, action.payload.id));

        const payableAgreement: PayableAgreement = response.data.data as PayableAgreement;
        yield put(fetchSuccess(payableAgreement));
        yield put(addToast("Agreement rejected! Sad to see it didn't work out.", "error"));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleFetch(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.incomingPayableAgreement,
            ServiceManager.dataApi.incomingPayableAgreement.getById
        ], action.payload);
        const payableAgreement: PayableAgreement = response.data.data as PayableAgreement;
        yield put(fetchSuccess(payableAgreement));

    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleListRequest(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.incomingPayableAgreement,
                ServiceManager.dataApi.incomingPayableAgreement.findAll
            ],
            action.payload.pageSize || 20,
            action.payload.pageNumber || 1,
            {}
        );

        const listOfPayableAgreements: PayableAgreement[] = response.data.data as PayableAgreement[];
        const paginationMeta: PaginationMeta = response.data.meta as PaginationMeta;
        yield put(fetchListSuccess(listOfPayableAgreements, paginationMeta))
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

/**
 * @param action
 */
function* handleUpdate(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.incomingPayableAgreement,
                ServiceManager.dataApi.incomingPayableAgreement.update
            ],
            action.payload.id,
            action.payload.data
        )

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.PAYABLE_AGREEMENT.toString(), Events.UPDATED, action.payload.id));

        const payableAgreement: PayableAgreement = response.data.data as PayableAgreement;
        yield put(fetchSuccess(payableAgreement));
        yield put(addToast("Agreement updated successfully!", "success"));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

function* handleCancelMilestoneRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.incomingPayableAgreementMilestone,
            ServiceManager.dataApi.incomingPayableAgreementMilestone.update
        ], action.payload.id + '/cancel', {
            reason: action.payload.reason
        });

        const milestone: Milestone = response.data.data as any;
        if (milestone.payable_agreement_id) {
            // Tracking events.
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.MILESTONES.toString(), Events.REQUEST_CANCELLATION, action.payload.id));

            yield put(fetchRequest(milestone.payable_agreement_id.toString()));
            yield put(addToast("Cancellation request sent successfully!", "success"))
            window.location.reload();
        } else {
            yield put(fetchError("Oops! failed to cancel the milestone. Please try again."));
        }
    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleApproveCancelMilestoneRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.incomingPayableAgreementMilestone,
            ServiceManager.dataApi.incomingPayableAgreementMilestone.update
        ], action.payload.id + '/approve_cancellation', {});

        const milestone: Milestone = response.data.data as any;
        if (milestone.payable_agreement_id) {
            // Tracking events.
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.MILESTONES.toString(), Events.CANCELLED, action.payload.id));

            yield put(addToast("Cancellation request approved successfully!", "success"))
            yield put(fetchRequest(milestone.payable_agreement_id.toString()));
            window.location.reload();
        } else {
            yield put(fetchError("Oops! failed to cancel the milestone. Please try again."));
        }
    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleRejectCancelMilestoneRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.incomingPayableAgreementMilestone,
            ServiceManager.dataApi.incomingPayableAgreementMilestone.update
        ], action.payload.id + '/reject_cancellation', {});

        const milestone: Milestone = response.data.data as any;
        if (milestone.payable_agreement_id) {
            // Tracking events.
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.MILESTONES.toString(), Events.REJECTED, action.payload.id));
            yield put(addToast("Cancellation request has been rejected successfully!", "success"))
            yield put(fetchRequest((milestone.payable_agreement_id.toString())));
            window.location.reload();
        } else {
            yield put(fetchError("Oops! failed to cancel the milestone. Please try again."));
        }
    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handlePayUnsecurePayableAgreement(action: any) {
    try {
        const response: AxiosResponse = yield call([
                ServiceManager.dataApi.incomingPayableAgreement,
                ServiceManager.dataApi.incomingPayableAgreement.update
            ], action.payload.payableAgreementId + '/pay_now', {
                bankAccountId: action.payload.bankAccountId
            }
        );

        const payableAgreement: PayableAgreement = response.data.data as PayableAgreement;
        yield put(fetchSuccess(payableAgreement));

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.PAY_NOW_REQUEST.toString(), Events.CREATED, payableAgreement.id));

        yield put(addToast("Payable agreement has been paid in full.", "success"));
        yield handleFetch({payload: payableAgreement.id});
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}


function* watchFetchRequest() {
    yield takeEvery(IncomingPayableAgreementActionTypes.ACCEPT_PAYABLE_AGREEMENT, handleAcceptPayableAgreement);
    yield takeEvery(IncomingPayableAgreementActionTypes.REJECT_PAYABLE_AGREEMENT, handleRejectPayableAgreement);

    yield takeEvery(IncomingPayableAgreementActionTypes.APPROVE_PAYMENT_REQUEST, handleApprovePaymentRequest)
    yield takeEvery(IncomingPayableAgreementActionTypes.REJECT_PAYMENT_REQUEST, handleRejectPaymentRequest)

    yield takeEvery(IncomingPayableAgreementActionTypes.APPROVE_DEPOSIT_REQUEST, handleApproveDepositRequest)
    yield takeEvery(IncomingPayableAgreementActionTypes.REJECT_DEPOSIT_REQUEST, handleRejectDepositRequest)

    yield takeEvery(IncomingPayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_REQUEST, handleCancelMilestoneRequest)
    yield takeEvery(IncomingPayableAgreementActionTypes.APPROVE_CANCEL_MILESTONE_FETCH_REQUEST, handleApproveCancelMilestoneRequest)
    yield takeEvery(IncomingPayableAgreementActionTypes.REJECT_CANCEL_MILESTONE_FETCH_REQUEST, handleRejectCancelMilestoneRequest)

    yield takeEvery(IncomingPayableAgreementActionTypes.SEND_TFA_CODE_REQUEST, handleSendPayableAgreementTfaRequest);

    yield takeEvery(IncomingPayableAgreementActionTypes.FETCH_LIST_REQUEST, handleListRequest)
    yield takeEvery(IncomingPayableAgreementActionTypes.FETCH_REQUEST, handleFetch)
    yield takeEvery(IncomingPayableAgreementActionTypes.UPDATE_REQUEST, handleUpdate)
    yield takeEvery(IncomingPayableAgreementActionTypes.PAY_UNSECURE_AGREEMENT_REQUEST, handlePayUnsecurePayableAgreement);
}

function* incomingPayableAgreementSaga() {
    yield all([
        watchFetchRequest()
    ]);
}

export default incomingPayableAgreementSaga;
