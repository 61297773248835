import React from "react";
import {NavItemStateProps} from "../types";
import NavItem from "./NavItem";
import {LANG} from "../../labels"

const items: NavItemStateProps[] = [
    // {
    //     label: 'How to guide',
    //     route: "/agreements/new?product_tour_id=351960",
    //     svgIcon: "comments"
    // },
    {
        label: LANG.DashboardPage.DISPLAY_NAME,
        route: "/dashboard",
        svgIcon: "dashboard"
    },
    {
        label: LANG.AgreementListPage.DISPLAY_NAME,
        route: "/agreements?filter=sent",
        svgIcon: "smart-contracts",
    },
    // {
    //     label: 'Transactions',
    //     route: "/transactions",
    //     svgIcon: "transactions",
    // },
    {
        label: LANG.BankAccountsPage.DISPLAY_NAME,
        route: "/bank_accounts",
        svgIcon: "bank",
    },
    {
        label: LANG.RecipientListPage.DISPLAY_NAME,
        route: "/recipients",
        svgIcon: "contacts"
    },
    {
        label: LANG.ProfilePage.DISPLAY_NAME,
        route: "/profile",
        svgIcon: "user",
    },
    {
        label: 'FAQ',
        route: "https://paysecure.com.au/faq",
        isExternal: true,
        svgIcon: "question"
    },
    // {
    //     label: LANG.BusinessPageList.DISPLAY_NAME,
    //     route: "/businesses",
    //     svgIcon: "shop",
    // },
    // {
    //     label: LANG.ThirdPartyIntegrationPage.DISPLAY_NAME,
    //     route: "/third_party_integration",
    //     svgIcon: "truck",
    // },
    {
        label: "Logout",
        route: "/logout",
        svgIcon: "lock"
    }
]

const Navigation: React.FunctionComponent<any> = () => {
    return <ul className="nav-main">
        {items.map((item, key) => <NavItem key={key} label={item.label} isExternal={item?.isExternal}
                                           svgIcon={item.svgIcon} icon={item.icon} subItems={item?.subItems}
                                           route={item.route}/>)}
    </ul>
}

export default Navigation;
