import BaseState from "../state";

export enum RegisterActionsTypes {
    FETCH_REQUEST = "@register/FETCH_REQUEST",
    FETCH_SUCCESS = "@register/FETCH_SUCCESS",
    FETCH_ERROR = "@register/FETCH_ERROR",
}

export interface RegisterState extends BaseState {
    readonly loading: boolean;
    readonly isRegistrationSuccessful: undefined | boolean
}
