import React from "react";

interface PageTitleProps {
    title: string | JSX.Element | JSX.Element[];
    subTitle?: string | JSX.Element | JSX.Element[] | undefined | null;
    children?: React.Component | JSX.Element | JSX.Element[] | string | undefined | null;
    toolTip?: string;
    noRecordsLabel?: string;

}

const PageTitle: React.FunctionComponent<PageTitleProps> = ({
                                                                title, subTitle, children, toolTip,
                                                            }: PageTitleProps) => {

    return <div className="content content-full pb-0">
        <div className="row">
            <div className="col-xs-12 col-sm-6">
                <h2 className="h2 font-w700 mb-2">{title}</h2>
                <h3 className="h3 font-w500 text-muted mb-0">{subTitle}</h3>
            </div>
            <div className="col-xs-12 col-sm-6 page-title-actions">{children}</div>
        </div>
    </div>
}

export default PageTitle;
