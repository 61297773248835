import React, {useEffect} from "react";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {ApplicationState} from "../../store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {PayableAgreement} from "../../models/PayableAgreement";
import {
    acceptPayableAgreementRequest,
    fetchRequest,
    rejectPayableAgreementRequest
} from "../../store/incomingPayableAgreement/action";
import MainLayout from "../../components/Layouts/MainLayout";
import NotificationsContainer from "../../components/Notifications/Containers";
import {UserCard} from "../../components/Cards";
import Block from "../../components/Blocks/Block";
import StatusPill from "../../components/PayableAgreement/StatusPill";
import Enums from "../../enums";
import {WarningNotification} from "../../components/Notifications";
import Timestamp from "../../components/PayableAgreement/Timestamp";
import PayableAgreementResponseButton from "../../components/PayableAgreement/Buttons/PayableAgreementResponseButtons";
import Statuses from "../../enums";
import PayUnsecurePayableAgreement from "../../components/PayableAgreement/PayUnsecurePayableAgreement";
import ListOfMilestones from "../../components/Milestones/ListOfMilestones";
import Milestone from "../../models/Milestone";
import {MilestoneContractorActions} from "../../components/Milestones/MilestoneContractorActions";
import {MilestoneCustomerActions} from "../../components/Milestones/MilestoneCustomerActions";

interface OwnProps extends RouteComponentProps<{ id: string }> {
}

const mapStateToProps = ({incomingPayableAgreement, bankAccount, attachment}: ApplicationState) => ({
    loading: incomingPayableAgreement.loading || bankAccount.loading || attachment.loading,
    notifications: incomingPayableAgreement.notifications,
    data: incomingPayableAgreement.data,
    toasts: incomingPayableAgreement?.toasts,
});
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = OwnProps & PropsFromRedux;

const IncomingProductAgreementResponsePage = (props: Props) => {
    const dispatch = useDispatch(),
        {loading, notifications, data, toasts, match: {params}} = props;

    useEffect(() => {
        if (params.id) {
            dispatch(fetchRequest(params.id))
        }
    }, [])

    if (loading === false && params.id && data && data.id && data.product_service_provided !== 'product') {
        return <Redirect to={`/incoming_agreements/${data.id}/view`}/>
    }

    return <MainLayout pageTitle={`Received Agreement`} isLoading={loading} toasts={toasts}>
        <div className="content">
            <NotificationsContainer notifications={notifications}/>

            {data && (data?.status === Enums.PayableAgreement.IN_PROGRESS || data?.status == Enums.PayableAgreement.ACCEPTED) && data?.can_approve_deposit === false && data?.reason_cant_approve_deposit ?
                <div className="content">
                    <WarningNotification>
                        <div dangerouslySetInnerHTML={{__html: data?.reason_cant_approve_deposit}}></div>
                    </WarningNotification>
                </div> : null}

            <div className="row push">
                <div className="col">
                    {data && data?.id
                        && <Timestamp typeOfAgreement={'Received'} id={data.id} title={data.title}/>}
                </div>
            </div>


            <div className="row">
                <div className="col-md-9 col-xs-12">
                    <Block withHeaderBorder={true}>
                        <div className="row">
                            <div className="col-md-6 push">
                                <img src="https://via.placeholder.com/250x150" alt=""/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h2 className="agreement-title">{data?.title}</h2>
                                <h3>{data?.total_amount_display}</h3>

                                <div className="agreement_details">{data?.agreement_details}</div>
                                {data?.product_url && <a href={data?.product_url} target="_blank" rel="noreferrer">{data?.product_url}</a>}
                            </div>
                        </div>
                    </Block>

                    <div className="row">
                        <div className="col">
                            {data && data.milestones && data.milestones[0] &&
                                <Block>
                                    <MilestoneCustomerActions payableAgreement={data} milestone={data.milestones[0]} />
                                </Block>}
                        </div>
                    </div>

                    {/*<div className="row">*/}
                    {/*    <div className="col">*/}
                    {/*        <div className="milestone-row heading">*/}
                    {/*            <div className="step-number">#</div>*/}
                    {/*            <div className="title">Title</div>*/}
                    {/*            <div className="description">Description</div>*/}
                    {/*            <div className="due text-right">Due by</div>*/}
                    {/*            <div className="amount text-right">Amount</div>*/}
                    {/*        </div>*/}
                    {/*        {data && data?.status && <ListOfMilestones payableAgreement={data} hideFees={true}*/}
                    {/*                                                   milestones={data.milestones}*/}
                    {/*                                                   payableAgreementStatus={data?.status}*/}
                    {/*                                                   onAddingNewMilestone={(m: Milestone) => {*/}
                    {/*                                                   }} canAddMoreMilestones={false}>*/}
                    {/*        */}
                    {/*        </ListOfMilestones>}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="row">
                        <div className="col text-right agreement-actions">
                            {data?.status && data?.status === "sent" ?
                                <PayableAgreementResponseButton
                                    acceptButtonLabel={`Buy now`}
                                    amount={data?.total_amount}
                                    agreement={data}
                                    handleRejectingAgreement={(reason: string) => {
                                        if (data && data.id) {
                                            dispatch(rejectPayableAgreementRequest({
                                                id: data.id.toString(),
                                                reason
                                            }))
                                        }
                                    }}
                                    handleAcceptingAgreement={(receiverBusinessEntityId: number) => {
                                        if (data && data.id) {
                                            dispatch(acceptPayableAgreementRequest(data?.id.toString(), receiverBusinessEntityId))
                                        }
                                    }}/> : null}
                        </div>
                    </div>
                </div>

                <div className="col-md-3 rhc-container">
                    {data?.user && <>
                        <UserCard user={data?.user}/>
                    </>}
                </div>
            </div>
        </div>
    </MainLayout>
}

export default connector(withRouter(IncomingProductAgreementResponsePage));
