import React, {useState} from "react";
import RequestPaymentDialog from "../Dialogs/RequestPaymentDialog";
import {PrimaryButton, SuccessButton} from "../../Buttons";
import ReducedMilestoneOfferDialog from "../Dialogs/ReducedMilestoneOfferDialog";
import {useShowDialog} from "../../../hooks";

interface Props {
    handleRequestPayment: () => void
    canRequestPayment: boolean,
    amount: number
    milestoneId: number
    isReRequesting?: boolean
}

export const RequestPaymentButton: React.FunctionComponent<Props> = ({
                                                                         canRequestPayment,
                                                                         isReRequesting,
                                                                         handleRequestPayment,
                                                                         amount,
                                                                         milestoneId
                                                                     }) => {
    const [showReducedMilestoneOfferDialog, setShowReducedMilestoneOfferDialog] = useState<boolean>(false);
    const {showDialog, show, hide} = useShowDialog(false);

    return <>
        <RequestPaymentDialog amount={amount} showDialog={showDialog}
                              onConfirm={() => {
                                  handleRequestPayment()
                                  hide()
                              }}
                              onCancel={() => hide()}/>
        <SuccessButton disabled={!canRequestPayment} onClick={() => show()}
                       className={`btn-sm my-1`}>{isReRequesting === true ? `Re-request Payment` : `Request Payment`}</SuccessButton>

        {isReRequesting && <>
            <ReducedMilestoneOfferDialog milestoneId={milestoneId} amount={amount} showDialog={showReducedMilestoneOfferDialog}
                                         closeModal={() => setShowReducedMilestoneOfferDialog(false)}/>
            <PrimaryButton onClick={() => {
                setShowReducedMilestoneOfferDialog(true);
            }}>Negotiate amended milestone amount</PrimaryButton>
        </>}
    </>
}

export default RequestPaymentButton;
