import React from "react";
import MainLayout from "../components/Layouts/MainLayout";

export const App404Page = () => {
    return <MainLayout pageTitle={'404 page not found'} isLoading={false}>
        <div className="hero error-hero">
            <div className="hero-inner text-center">
                <div className="bg-body-extra-light">
                    <div className="content content-full overflow-hidden">
                        <div className="py-4">
                            <h1 className="display-1 fw-bolder text-city">
                                404
                            </h1>
                            <h2 className="h4 fw-normal text-muted mb-5">
                                We are sorry but the page you are looking for was not found..
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="content content-full text-muted fs-sm fw-medium">
                    <p className="mb-1">
                        Would you like to let us know about it?
                    </p>
                    <a className="link-fx" href="#">Report it</a> or <a className="link-fx"
                                                                        href="/dashboard">Go Back to Dashboard</a>
                </div>
            </div>
        </div>
    </MainLayout>
}

export default App404Page
