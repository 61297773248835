import React, {useEffect, useState} from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import useQuery from "../../components/utils/userQuery";
import {ToastProp} from "../../components/Notifications/Toasts";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {fetchInvoiceRequest, convertDocumentToPayableAgreement} from "../../store/thirdPartyConnection/action";
import {connect} from "react-redux";
import {ThirdPartyConnection} from "../../models/ThirdPartyConnection";
import {DocumentLineItem, ThirdPartyDocument} from "../../models/ThirdPartyDocument";
import PayableAgreementResponseButton from "../../components/PayableAgreement/Buttons/PayableAgreementResponseButtons";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";


/**
 * @interface PropsFromState
 */
interface PropsFromState {
    loading: boolean
    notifications: NotificationState;
    data?: ThirdPartyConnection;
    document?: ThirdPartyDocument;
    toasts?: ToastProp[]
    isLoggedIn?: boolean
}

/**
 * @interface PropsFromDispatch
 */
interface PropsFromDispatch {
    convertDocumentToPayableAgreement: (orgId: string, invoiceId: string, receiverBusinessEntityId?: number) => any;
    fetchInvoiceDetails: (orgId: string, invoiceId: string) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

/**
 *
 * @constructor
 */
export const ThirdPartyInvoicePage: React.FunctionComponent<AllProps> = ({
                                                                             data,
                                                                             document,
                                                                             loading,
                                                                             isLoggedIn,
                                                                             notifications,
                                                                             convertDocumentToPayableAgreement,
                                                                             fetchInvoiceDetails,
                                                                         }) => {
    const query = useQuery(),
        orgId = query.get('shortCode'),
        invoiceId = query.get('invoiceNo'),
        invoiceAmount = query.get('amount');

    useEffect(() => {
        if (invoiceId && orgId) {
            fetchInvoiceDetails(orgId.toString(), invoiceId.toString());
        }
    }, []);

    return (
        <MainLayout pageTitle={`Making a payment using paysecure.`} isLoading={loading}>
            <PageTitle subTitle={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`}
                       title={`Making a payment using paysecure.`}/>

            <div className="content">
                {isLoggedIn && notifications && <NotificationsContainer notifications={notifications} />}

                <div className="row">
                    <div className="col-12 push">
                        <PayableAgreementResponseButton hideRejectAgreement={true}
                            agreement={{
                            id: 1,
                            title: '',
                            action: '',
                            product_service_provided: '',
                            agreement_details: '',
                            status: '',
                            can_accept_agreement:  document?.can_accept_agreement,
                            reason_cant_accept_agreement:  document?.reason_cant_accept_agreement,
                        }} handleAcceptingAgreement={(receiverBusinessEntityId) => {
                            if (orgId && invoiceId) {
                                if (receiverBusinessEntityId) {
                                    convertDocumentToPayableAgreement(orgId.toString(), invoiceId.toString(), receiverBusinessEntityId)
                                } else {
                                    convertDocumentToPayableAgreement(orgId.toString(), invoiceId.toString())
                                }
                            }
                        }} handleRejectingAgreement={(reason) => {
                            console.log(reason);
                        }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12"><h2>{document?.documentType.toUpperCase()}</h2></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <table className={`table table-bordered table-hover`}>
                                            <tr>
                                                <td className="bg-gray-light"><strong>From</strong></td>
                                                <td>{document?.organisation?.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-gray-light"><strong>Invoice number</strong></td>
                                                <td>{document?.docNumber}</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-gray-light"><strong>Due date</strong></td>
                                                <td>{document?.dueDate}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="col-sm-6">
                                        <table className={`table table-bordered table-hover`}>
                                            <tr>
                                                <td className="bg-gray-light"><strong>Name</strong></td>
                                                <td>{document?.recipient?.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-gray-light"><strong>Email</strong></td>
                                                <td>{document?.recipient?.email}</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-gray-light"><strong>Mobile</strong></td>
                                                <td>{document?.recipient?.mobile}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <table className={`table table-bordered table-hover`}>
                                            <thead>
                                            <tr>
                                                <th>Item code</th>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th>Unit amount</th>
                                                {/*<th>Tax amount</th>*/}
                                                <th>amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {document?.lineItems?.map((lineItem: DocumentLineItem) => {
                                                return <tr>
                                                    <td>{lineItem.itemCode}</td>
                                                    <td>{lineItem.description}</td>
                                                    <td>{lineItem.quantity}</td>
                                                    <td>${lineItem.unitAmount}</td>
                                                    {/*<td>${lineItem.taxAmount}</td>*/}
                                                    <td>${lineItem.lineAmount}</td>
                                                </tr>
                                            })}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={3}></td>
                                                    <td><strong>Subtotal</strong></td>
                                                    <td>${document?.subTotal}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}></td>
                                                    <td><strong>Tax</strong></td>
                                                    <td>${document?.totalTax}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}></td>
                                                    <td><strong>Total</strong></td>
                                                    <td>${document?.total}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {!isLoggedIn && <div>

                        </div>}
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

const mapStateToProps = ({thirdPartyConnection, login}: ApplicationState) => ({
    data: thirdPartyConnection.data,
    document: thirdPartyConnection.document,
    loading: thirdPartyConnection.loading,
    notifications: thirdPartyConnection.notifications,
    toasts: thirdPartyConnection.toasts,
    isLoggedIn: login.isLoggedIn
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        convertDocumentToPayableAgreement: (orgId: string, invoiceId: string, receiverBusinessEntityId?: number) => {
            dispatch(convertDocumentToPayableAgreement(orgId, invoiceId, receiverBusinessEntityId))
        },
        fetchInvoiceDetails: (orgId: string, invoiceId: string) => {
            dispatch(fetchInvoiceRequest(orgId, invoiceId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyInvoicePage);
