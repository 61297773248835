export enum Events {
    FETCHED = "fetched",
    CREATED = "created",
    UPDATED = "updated",
    DELETED = "deleted",

    SENT = "sent",

    REQUEST_APPROVAL = "request_approval",
    APPROVED = "approved",

    REJECTED = "rejected",

    REQUEST_CANCELLATION = "request_cancellation",
    CANCELLED = "cancelled",

    USER_REGISTERED = 'user_registered'
}

/**
 * @interface CustomEvent
 */
export interface CustomEvent {
    entity: string; // This is what we will be using as category in GA.
    action: string;
    value?: number;

    //
    type: Events;
}

/**
 * @param baseEntity
 * @param event
 * @param valueId
 */
export function makeFactory(baseEntity: string, event: Events, valueId: number): CustomEvent {
    return {
        entity: baseEntity,
        action: event.toString(),
        value: valueId ?? 0,
        type: event
    };
}
