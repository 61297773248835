import React, {useRef, useState} from "react";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";


type CancelDepositRequestDialogProps = {
    showDialog: boolean
    onConfirm: (reason: string) => void
    onCancel: () => void
}

const CancelDepositRequestDialog: React.FC<CancelDepositRequestDialogProps> = ({
                                                                                   showDialog,
                                                                                   onConfirm,
                                                                                   onCancel
                                                                               }) => {

    const reasonTextAreaRef = useRef(null),
        [hasError, setHasError] = useState(false);

    return <ConfirmDialog
        showDialog={showDialog}
        cancelButtonText={`Close`}
        confirmButtonText={`Decline Deposit Request`}
        onCancel={onCancel} onConfirm={() => {
        // @ts-ignore
        if (reasonTextAreaRef && reasonTextAreaRef.current && reasonTextAreaRef.current.value) {
            // @ts-ignore
            onConfirm(reasonTextAreaRef.current.value)
        } else {
            setHasError(true);
        }
    }} title={``}>

        <div className="row">
            <div className="col">
                <div className="form-group">
                    <h3>Are you sure you want to decline deposit request?</h3>
                </div>
            </div>
        </div>
        <div className="row push">
            <div className="col">
                <div className="form-group">
                    <label htmlFor="example-select-multiple">Reason for cancellation <span className="text-danger">*</span> </label>
                    <textarea ref={reasonTextAreaRef} className={"form-control " + (hasError ? `is-invalid` : ``)}
                              onChange={(e) => {
                                  // @ts-ignore
                                  setHasError(!(reasonTextAreaRef && reasonTextAreaRef.current && reasonTextAreaRef.current.value && reasonTextAreaRef.current.value.length > 0))
                              }}
                              name="agreement_details" rows={5} placeholder="Please enter a reason for declining this request." />
                    {hasError && <div className="invalid-feedback">Please enter a reason for declining this request.</div>}
                </div>
            </div>
        </div>
    </ConfirmDialog>
}

export default CancelDepositRequestDialog;
