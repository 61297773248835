import {CRUD} from "./ResourceManagerFactory";

/**
 * @class ResourceUrls
 */
class ResourceUrls {
    /**
     *
     * @private
     */
    private _baseResourceUrl: string;

    /**
     *
     * @private
     */
    private resourceUrls: { [p: string]: any };

    /**
     * @param baseResourceUrl
     */
    constructor(baseResourceUrl: string) {
        this._baseResourceUrl = baseResourceUrl;
        this.resourceUrls = {
            [CRUD.BASE_RESOURCE_URI]: baseResourceUrl,
            [CRUD.RESOURCE_BY_ID]: function (id: string) {
                return baseResourceUrl + '/' + id;
            },
        };
    }

    public getCollectionUrl() {
        return this.resourceUrls[CRUD.BASE_RESOURCE_URI];
    }

    public getResourceByIdUrl(id: string) {
        return this.resourceUrls[CRUD.RESOURCE_BY_ID](id);
    }
}

export default ResourceUrls;
