import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {PrimaryButton} from '../../components/Buttons';
import ServiceManager from "../../services/ServiceManager";
import WebsiteLayout from "../../components/Layouts/WebsiteLayout";
import User from "../../models/User";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {resetPasswordRequest} from "../../store/login/action";
import {connect} from 'react-redux';
import {ToastProp} from "../../components/Notifications/Toasts";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";

interface PropsFromState {
    readonly loading: boolean;
    readonly user?: User;
    readonly notifications: NotificationState
    readonly toasts?: ToastProp[]
    readonly isLoggedIn: boolean;
}

interface PropsFromDispatch {
    resetPassword: (email: string, password: string, rePassword: string, token: string) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

interface ResetPasswordRouterParams {
    email: string;
    token: string;
}

/**
 * @param loading
 * @param errors
 * @param resetPassword
 * @param toasts
 * @param isLoggedIn
 *
 * @constructor
 */
const ResetPasswordPage: React.FC<AllProps> = ({
                                                   loading,
                                                   notifications,
                                                   resetPassword,
                                                   toasts,
                                                   isLoggedIn,
                                               }) => {
    const [showPassword, setShowPassword] = useState<boolean>(true)

    useEffect(() => {
        if (isLoggedIn) {
            history.push('/dashboard');
        }
    }, [isLoggedIn]);

    const {email, token} = useParams<ResetPasswordRouterParams>(),
        {handleSubmit, register, formState} = useForm(),
        history = useHistory(),
        onSubmit = (data: any, e: any) => {
            resetPassword(email, data.password, data.rePassword, token);
        }
    const canHideResetPasswordForm = useCallback(() => {
        return notifications.success.length === 0;
    }, [notifications])

    return <WebsiteLayout pageTitle={`Reset Password`} isLoading={loading} hideHeader={true} toasts={toasts}>
        <div className="reset-password">
            <h2>Reset Password</h2>
            <NotificationsContainer notifications={notifications}/>
            {!canHideResetPasswordForm() && <div className="py-3">
                <a href="/login">Click here</a> to login.
            </div>}
        </div>

        {canHideResetPasswordForm() && <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-3">
                <div className={"form-group"}>
                    <div className="text-danger">Password must contain at least 8 characters.
                    </div>
                    <input type={showPassword ? 'password' : 'text'}
                           className={"form-control form-control-lg form-control-alt" + ServiceManager.formUtils.renderErrorClass(formState.errors.password)}
                           id="password" {...register('password', {required: true})}
                           placeholder="Password"/>
                    {ServiceManager.formUtils.renderErrorBlock(formState.errors, 'password', 'Please enter a password')}
                </div>
                <div className={"form-group"}>
                    <input type={showPassword ? 'password' : 'text'}
                           className={"form-control form-control-lg form-control-alt" + ServiceManager.formUtils.renderErrorClass(formState.errors.rePassword)}
                           id="rePassword" {...register('rePassword', {required: true})}
                           placeholder="Re-enter Password"/>
                    {ServiceManager.formUtils.renderErrorBlock(formState.errors, 'rePassword', 'Please enter a confirmation password')}
                </div>

                <div className="form-group">
                    <a href="#"
                       onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Show' : 'Hide'} password</a>
                </div>

                <div className="form-group">
                    <label htmlFor="">Dont need to reset your password.</label>
                    <div>
                        <a href="/login">Login here</a>
                    </div>
                </div>

                <div className="form-group">
                    <PrimaryButton className={`btn-block`}>Reset Password</PrimaryButton>
                </div>
            </div>
        </form>}
    </WebsiteLayout>
}

const mapStateToProps = ({login}: ApplicationState) => ({
    loading: login.loading,
    notifications: login.notifications,
    isLoggedIn: login.isLoggedIn,
    toasts: login.toasts
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        resetPassword: (email: string, password: string, rePassword: string, token: string) => {
            dispatch(resetPasswordRequest(email, password, rePassword, token))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
