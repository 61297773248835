import {action} from "typesafe-actions";
import {ToastActionTypes} from "./types";

/**
 * @param message
 * @param severity
 */
export const addToast = (message: string, severity: "error" | "warning" | "info" | "success") => action(ToastActionTypes.ADD_TOAST, {
    message, severity
})

/**
 *
 */
export const resetToasts = () => action(ToastActionTypes.RESET_TOASTS);