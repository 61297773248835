const PENDING = "PENDING";
const DRAFT = "draft";

const OVERDUE = "overdue";
const REJECT_DEPOSIT = "reject_deposit";

const PAYMENT_REQUESTED = "payment_requested";
const APPROVE_DEPOSIT = "approve_deposit";
const PROCESSING_DEPOSIT = "processing_deposit";
const REQUEST_DEPOSIT = "request_deposit";

const APPROVED = "approved";
const MONEY_SECURED = "money_secured";
const MONEY_CLEARING = "money_clearing";
const PAYMENT_CLEARED = "payment_cleared";
const PAYMENT_APPROVED = "payment_approved";
const RELEASING_MONEY = "releasing_money";
const REQUESTING_MONEY = "requesting_money";
const FAILED_AT_PAYMENT_PROVIDER = "failed_at_payment_provider";
const CANCELLED = "cancelled";

export type UserType = 'contractor' | 'client';

const USER_TYPES = {
    CONTRACTOR: "contractor",
    CLIENT: "client",
};

export default {
    Milestones: {
        PENDING,
        DRAFT,
        OVERDUE,
        REJECT_DEPOSIT,

        PAYMENT_REQUESTED,
        APPROVE_DEPOSIT,
        PROCESSING_DEPOSIT,
        REQUEST_DEPOSIT,

        APPROVED,

        MONEY_SECURED,
        // This is when the money is cleared from the bank but not in the Bank account yet.
        MONEY_CLEARING,
        PAYMENT_CLEARED,
        PAYMENT_APPROVED,
        RELEASING_MONEY,
        CANCELLED,
        FAILED_AT_PAYMENT_PROVIDER,
        PAYMENT_REFUNDED: "payment_refunded",
        CANCELLATION_REQUESTED: "cancellation_requested",
        REQUESTING_MONEY_FAILED: "requesting_money_failed",
        PAYMENT_REJECTED: "payment_rejected",
    },

    UserTypes: USER_TYPES,

    PayableAgreement: {
        ACCEPTED: "accepted",
        PENDING: "draft",
        IN_PROGRESS: "in_progress",
        REQUEST_DEPOSIT: "request_deposit",
        REJECT_DEPOSIT,
        APPROVE_DEPOSIT: "approve_deposit",
        MONEY_SECURED: "money_secured",
        PAYMENT_REQUESTED: "payment_requested",
        PAYMENT_APPROVED: "payment_approved",
        PAYMENT_REJECTED: "payment_rejected",
        PROCESSING_DEPOSIT: "processing_deposit",
        PAYMENT_CLEARED: "payment_cleared",
        PAYMENT_REFUNDED: "payment_refunded",
        OVERDUE: "overdue",
        REQUESTING_MONEY,
        RELEASING_MONEY,
        FAILED_AT_PAYMENT_PROVIDER,
        SENT: 'sent',
        CLOSED: 'closed',
    },
};
