import React, {useEffect} from "react";
import Recipient from "../../models/Recipient";
import {useForm} from "react-hook-form";
import Button from "@material-ui/core/Button";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Styles from '../../styles'
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {createRequest, updateRequest} from "../../store/recipient/action";
import {connect} from "react-redux";
import Input, {TextInput} from "../Forms/Fields/Input";

interface PropsFromState {
    onCancel: () => void,
    recipient?: Recipient
    onSaved?: (recipient: Recipient) => void,
}

interface PropsFromDispatch {
    saveRecipient: (recipient: Recipient) => void
    updateRecipient: (id: number, recipient: Recipient) => void
}

type Props = PropsFromState & PropsFromDispatch

const useStyles = makeStyles((theme: Theme) => createStyles({
    successButton: Styles.Button.successButton,
    dangerButton: Styles.Button.dangerButton
}))

export const EditRecipientForm: React.FC<Props> = ({recipient, onCancel, onSaved, saveRecipient, updateRecipient}) => {
    const {register, handleSubmit, formState: {errors}} = useForm<Recipient>({
        defaultValues: recipient
    })

    const classes = useStyles();
    const onSubmit = (data: Recipient) => {
        if (recipient) {
            if (recipient.id && recipient.id > 0) {
                updateRecipient(recipient.id, data)
            } else {
                saveRecipient(data)
            }
        }

        if (onSaved) {
            // Work around to set the display_value field because we're not using redux to sync the state for this
            data.display_label = `${data.first_name} ${data.last_name}`
            onSaved(data)
        }
    }

    return <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput label={`First name`}
                   errors={errors} {...register('first_name', {required: 'Please enter a first name'})} />
        <TextInput label={`Last name`} errors={errors} {...register('last_name')} />
        <TextInput label={`Email`}
                   errors={errors}  {...register('email', {required: 'Please enter a valid email address'})} />
        <TextInput {...register('mobile')} label={`Mobile`} errors={errors}/>
        <div className="actions">
            <MuiDialogActions>
                <Button className={classes.successButton} data-testid={'save-milestone'} type={`submit`}
                        variant={`contained`}
                        color={`primary`}>Save</Button>
                <Button className={classes.dangerButton} variant={`contained`} color={`secondary`}
                        onClick={onCancel}>Cancel</Button>
            </MuiDialogActions>
        </div>
    </form>
}

const mapStateToProps = ({recipient}: ApplicationState) => ({});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    saveRecipient: (recipient: Recipient) => dispatch(createRequest(recipient, false)),
    updateRecipient: (id: number, recipient: Recipient) => dispatch(updateRequest(id, recipient))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditRecipientForm);
