import {all, call, put, takeEvery} from 'redux-saga/effects';
import {TransactionActionTypes} from "./types";
import {fetchError, fetchSuccess, fetchListSuccess} from "./action";
import ServiceManager from "../../services/ServiceManager";
import {Transaction} from "../../models/Transaction";
import {AxiosResponse} from "axios";
import {PaginationMeta} from "../types";
import {handleError} from "../saga";

/**
 * @param action
 */
function* handleFetch(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.transaction, ServiceManager.dataApi.transaction.getById
        ], action.payload);
        const recipient: Transaction = response.data.data as Transaction;
        yield put(fetchSuccess(recipient));

    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleListRequest(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.transaction,
                ServiceManager.dataApi.transaction.findAll
            ],
            action.payload.pageSize || 20,
            action.payload.pageNumber || 1,
            {}
        );

        const listOfPayableAgreements: Transaction[] = response.data.data as Transaction[];
        const paginationMeta: PaginationMeta = response.data.meta as PaginationMeta;
        yield put(fetchListSuccess(listOfPayableAgreements, paginationMeta))
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

function* watchFetchRequest() {
    yield takeEvery(TransactionActionTypes.FETCH_LIST_REQUEST, handleListRequest)
    yield takeEvery(TransactionActionTypes.FETCH_REQUEST, handleFetch)
}

function* transactionSaga() {
    yield all([
        watchFetchRequest()
    ]);
}

export default transactionSaga;
