import React, {useCallback, useState} from "react";
import {useDispatch} from "react-redux";
import InfoDialog from "../../../components/Dialogs/InfoDialog";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";
import {InfoNotification} from "../../../components/Notifications";
import {DangerButton} from "../../../components/Buttons";
import {deleteRequest} from "../../../store/bankAccount/action";

interface Props {
    canDelete: boolean
    id: number
}

export const DeleteBankAccountButton = ({canDelete, id}: Props) => {
    const dispatch = useDispatch();
    const [showCannotDeleteDialog, setShowCannotDeleteDialog] = useState<boolean>(false);
    const [confirmDeleteBankAccountDialog, setConfirmDeleteBankAccountDialog] = useState<boolean>(false);

    const handleOnDelete = useCallback(() => {
        if (canDelete) {
            setConfirmDeleteBankAccountDialog(true)
        } else {
            setShowCannotDeleteDialog(true)
        }
    }, [canDelete, setShowCannotDeleteDialog])

    return <>
        <DangerButton type={'button'} onClick={handleOnDelete}>Delete
            bank account</DangerButton>

        <ConfirmDialog title={'Delete bank account'} showDialog={confirmDeleteBankAccountDialog}
                       onCancel={() => setConfirmDeleteBankAccountDialog(false)}
                       onConfirm={() => {
                           setConfirmDeleteBankAccountDialog(false)
                           dispatch(deleteRequest({id: id.toString()}))
                       }}>
            Are you sure you want to proceed?
        </ConfirmDialog>
        <InfoDialog title={'Delete bank account'} okButtonText={'Close'} onOk={() => setShowCannotDeleteDialog(false)}
                    showDialog={showCannotDeleteDialog}>
            <InfoNotification title={''}>Failed to delete bank account, it's being used in one or more
                agreements.</InfoNotification>
        </InfoDialog>
    </>
}

export default DeleteBankAccountButton
