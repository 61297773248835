import React from "react";
import noop from "../../utils/noop";
import FormGroup, {defaultInputClasses} from "../FormGroup";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import classnames from "classnames";
import {hasErrors} from "../common";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    label?: string
    errors?: FieldErrors
    isRequired?: boolean
}

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    return <FormGroup label={props.label ?? ``} fieldName={props.name ?? ``} errors={props.errors} isRequired={props.isRequired ?? false}>
        <input {...props} ref={ref}
               className={classnames(defaultInputClasses, props.className, {
                   'is-invalid': hasErrors({
                       errors: props.errors ?? {},
                       fieldName: props.name ?? ''
                   })
               })}
               onChange={props.onChange ?? noop} onBlur={props.onBlur ?? noop}/>
    </FormGroup>
})

export const TextInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    return <Input ref={ref} type={'text'} {...props} />
})

export const NumberInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    return <Input ref={ref} type={'number'} {...props} />
})

export const EmailInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    return <Input ref={ref} type={'email'} {...props} />
})

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
    return <FormGroup label={props.label ?? ``} fieldName={props.name ?? ``} errors={props.errors}>
        <textarea ref={ref} {...props}
                  className={classnames(defaultInputClasses, props.className, {
                      'is-invalid': hasErrors({
                          errors: props.errors ?? {},
                          fieldName: props.name ?? ''
                      })
                  })}
                  onChange={props.onChange ?? noop} onBlur={props.onBlur ?? noop}/>
    </FormGroup>
})

export default Input;
