import React from "react";
import {useForm} from "react-hook-form";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";

type RejectPayableAgreementAlertProps = {
    action: string
    showAlert: boolean
    onConfirm: (reason: string) => void,
    onCancel: () => void,
}

const RejectPayableAgreementAlert: React.FC<RejectPayableAgreementAlertProps> = (
    {
        action, showAlert, onConfirm, onCancel
    }
) => {
    const {register, getValues} = useForm();
    const handleOnConfirm = () => {
        const reason = getValues('reason_for_rejection');
        if (reason.toString().trim().length > 0) {
            onConfirm(reason.toString().trim())
            return;
        }

        alert('Please enter a valid reason.');
    }

    return <ConfirmDialog showDialog={showAlert} onConfirm={handleOnConfirm}
                          title={`Cancel Payable Agreement`} onCancel={onCancel}>
        <>
            <div className="row">
                <div className="col">
                    <p>Yikes! That's okay, would you like add a reason for rejection.</p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="reason_for_rejection">Reason</label>
                        <textarea className="form-control"{...register('reason_for_rejection', {required: true})}
                                  id="reason_for_rejection" rows={5} name="reason_for_rejection"
                                  placeholder="Reason for rejection."/>
                    </div>
                </div>
            </div>
        </>
    </ConfirmDialog>
}

export default RejectPayableAgreementAlert;