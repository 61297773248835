import Milestone from "../../../models/Milestone";

export const canRequestDeposit = (milestone: Milestone): boolean => {
    switch (milestone.status) {
        case "pending":
        case "reject_deposit":
            return true;
        case "request_deposit":
        case "approve_deposit":
        case "processing_deposit":
        case "money_secured":
        case "money_clearing":
        case "payment_requested":
        case "payment_approved":
        case "payment_rejected":
        case "payment_cleared":
        case "cancellation_requested":
        case "payment_refunded":
        case "cancelled":
            return false
    }
}

export const canRequestPayment = (milestone: Milestone): boolean => {
    switch (milestone.status) {
        case "reject_deposit":
        case "pending":
        case "payment_requested":
        case "payment_approved":
        case "payment_cleared":
        case "request_deposit":
        case "approve_deposit":
        case "processing_deposit":
        case "cancellation_requested":
        case "payment_refunded":
        case "cancelled":
            return false;
        case "money_secured":
        case "money_clearing":
        case "payment_rejected":
            return true;
    }
}

export const canCancel = (milestone: Milestone): boolean => {
    switch (milestone.status) {
        case "reject_deposit":
        case "request_deposit":
        case "approve_deposit":
        case "processing_deposit":
        case "payment_requested":
        case "payment_approved":
        case "payment_rejected":
        case "pending":
        case "money_secured":
        case "money_clearing":
            return true;
        case "payment_cleared":
        case "cancellation_requested":
        case "payment_refunded":
        case "cancelled":
            return false
    }
}
