import React from "react";
import {RequestDepositMilestone} from "../../../models/Milestone";
import {canCancel} from "./utils";
import {PayableAgreementTypes} from "../../../labels";

interface Props {
    milestone: RequestDepositMilestone
    payableAgreementType: PayableAgreementTypes
}

export const RequestDepositMilestoneActions: React.FC<Props> = ({milestone}: Props) => {
    return canCancel(milestone) ? <></> : null
}

export default RequestDepositMilestoneActions
