import React, {useState} from "react";
import {AuthPageLayoutProps} from '../types'
import Helmet from 'react-helmet';
import LoadingModal from "../Dialogs/LoadingModal";
import classnames from "classnames";

const AuthPageLayout: React.FunctionComponent<AuthPageLayoutProps> = ({
    children, pageTitle, isLoading
}: AuthPageLayoutProps) => {
    return <div id="page-container"
                className={classnames('sidebar-o sidebar-dark enable-page-overlay side-scroll page-header-fixed main-content-narrow sidebar-mini', {'show-loader': isLoading})}>
        <Helmet>
            <title>paysecure - {pageTitle}</title>
        </Helmet>
        {children}
        <LoadingModal isLoading={isLoading} />
    </div>
}

export default AuthPageLayout
