import React, {useState} from "react";
import FormDialog from "../../Dialogs/FormDialog";
import {DangerButton, SuccessButton} from "../../Buttons";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {BankAccount} from "../../../models/BankAccount";
import styled from "styled-components";
import {TextInput} from "../../Forms/Fields/Input";
import {ConnectForm} from "../../Forms/ConnectedForm";
import {InfoNotification} from "../../Notifications";
import {utils} from "../../../services/Utils";
import {negotiateAmendedAmountRequest} from "../../../store/payableAgreement/action";
import {AmendMilestoneAmountRequest} from "../../../models/Milestone";

type Props = {
    showDialog: boolean
    closeModal: () => void
    milestoneId: number
    amount?: number
}
const Buttons = styled.div`
    display: flex;
    padding: 12px 0 0 0;
    justify-content: flex-end;
    gap: 12px;
`

export const ReducedMilestoneOfferDialog = (props: Props) => {
    const {showDialog, closeModal, amount, milestoneId} = props
    const dispatch = useDispatch()
    const methods = useForm<AmendMilestoneAmountRequest>({
        shouldUseNativeValidation: true,
        defaultValues: {
            milestoneId
        }
    })
    const {handleSubmit} = methods;
    const onSubmit = (data: AmendMilestoneAmountRequest) => {
        dispatch(negotiateAmendedAmountRequest({
            amendedMilestoneAmount: data.amendedMilestoneAmount,
            milestoneId: milestoneId
        }))
        closeModal()
        methods.reset()
    }

    return <FormDialog showDialog={showDialog} title={`Negotiate amended amount`}>
        <InfoNotification>
            <p>{`You're about to offer an amended amount for this milestone. This amount should be less than ${utils.formatMoney(amount ?? 0)}, which is the total amount for the milestone.`}</p>
        </InfoNotification>
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ConnectForm<BankAccount>>
                    {({register, formState: {errors}}) => {
                        return <div className="row push">
                            <div className="col">
                                <TextInput errors={errors} {...register('amendedMilestoneAmount', {
                                    required: true,
                                    max: amount ?? 0
                                })} label={`Reduced amount`} placeholder="Example: 100.00"/>
                            </div>
                        </div>
                    }}
                </ConnectForm>
                <div className="row push">
                    <div className="col">
                        <div className="form-group">
                            <Buttons>
                                <DangerButton onClick={() => closeModal()}>Cancel</DangerButton>
                                <SuccessButton type={`submit`}>Offer reduced amount milestone</SuccessButton>
                            </Buttons>
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider>
    </FormDialog>
}

export default ReducedMilestoneOfferDialog
