import React from "react";
import {SecondaryButton} from "../../Buttons";
import Milestone from "../../../models/Milestone";
import {useShowDialog} from "../../../hooks";
import EditMilestoneDialog from "../Dialogs/EditMilestoneDialog";
import {updateMilestoneRequest} from "../../../store/payableAgreement/action";
import {useDispatch} from "react-redux";

interface Props {
    milestone: Milestone
}

export const EditMilestoneButton = ({milestone}: Props) => {
    const dispatch = useDispatch()
    const {show, hide, showDialog} = useShowDialog(false)

    return <>
        <SecondaryButton onClick={() => show()}>Edit milestone</SecondaryButton>
        <EditMilestoneDialog showDialog={showDialog}
                             milestone={milestone}
                             onConfirm={(m: Milestone) => {
                                 dispatch(updateMilestoneRequest({id: m.id.toString(), data: m}))
                                 hide()
                             }}
                             onCancel={() => hide()}/>
    </>
}

export default EditMilestoneButton;
