import {PayableAgreement} from "../../models/PayableAgreement";
import {PaginationMeta} from "../types";
import BaseState from "../state";
import {Attachment} from "../../models/Attachment";

export enum PayableAgreementActionTypes {
    CREATE_REQUEST = "@payableAgreement/CREATE_REQUEST",
    CREATE_SUCCESS = "@payableAgreement/CREATE_SUCCESS",
    CREATE_ERROR = "@payableAgreement/CREATE_ERROR",

    FETCH_REQUEST = "@payableAgreement/FETCH_REQUEST",
    FETCH_SUCCESS = "@payableAgreement/FETCH_SUCCESS",
    FETCH_ERROR = "@payableAgreement/FETCH_ERROR",

    DOWNLOAD_RECEIPT_REQUEST = "@payableAgreement/DOWNLOAD_RECEIPT",
    DOWNLOAD_RECEIPT_SUCCESS = "@payableAgreement/DOWNLOAD_RECEIPT_SUCCESS",
    DOWNLOAD_RECEIPT_ERROR = "@payableAgreement/DOWNLOAD_RECEIPT_ERROR",

    DOWNLOAD_MILESTONE_RECEIPT_REQUEST = "@payableAgreement/DOWNLOAD_MILESTONE_RECEIPT_REQUEST",
    DOWNLOAD_MILESTONE_RECEIPT_SUCCESS = "@payableAgreement/DOWNLOAD_MILESTONE_RECEIPT_SUCCESS",
    DOWNLOAD_MILESTONE_RECEIPT_ERROR = "@payableAgreement/DOWNLOAD_MILESTONE_RECEIPT_ERROR",

    APPROVE_CANCEL_MILESTONE_FETCH_REQUEST = "@payableAgreement/APPROVE_REQUEST_TO_CANCEL_MILESTONE",
    REJECT_CANCEL_MILESTONE_FETCH_REQUEST = "@payableAgreement/REJECT_CANCEL_MILESTONE_FETCH_REQUEST",

    DELETE_MILESTONE_FETCH_REQUEST = "@payableAgreement/DELETE_MILESTONE_FETCH_REQUEST",
    DELETE_MILESTONE_FETCH_SUCCESS = "@payableAgreement/DELETE_MILESTONE_FETCH_SUCCESS",
    DELETE_MILESTONE_FETCH_ERROR = "@payableAgreement/DELETE_MILESTONE_FETCH_ERROR",

    CANCEL_MILESTONE_FETCH_REQUEST = "@payableAgreement/CANCEL_MILESTONE_FETCH_REQUEST",
    CANCEL_MILESTONE_FETCH_SUCCESS = "@payableAgreement/CANCEL_MILESTONE_FETCH_SUCCESS",
    CANCEL_MILESTONE_FETCH_ERROR = "@payableAgreement/CANCEL_MILESTONE_FETCH_ERROR",

    APPROVE_PAYMENT_REQUEST = "@payableAgreement/APPROVE_PAYMENT_REQUEST",
    REJECT_PAYMENT_REQUEST = "@payableAgreement/REJECT_PAYMENT_REQUEST",

    REQUEST_DEPOSIT_REQUEST = "@payableAgreement/REQUEST_DEPOSIT_REQUEST",
    REQUEST_PAYMENT_REQUEST = "@payableAgreement/REQUEST_PAYMENT_REQUEST",
    SEND_AGREEMENT_REQUEST = "@payableAgreement/SEND_AGREEMENT_REQUEST",
    UPDATE_REQUEST = "@payableAgreement/UPDATE_REQUEST",
    SAVE_REQUEST = "@payableAgreement/SAVE_REQUEST",

    FETCH_LIST_REQUEST = "@payableAgreement/FETCH_LIST_REQUEST",
    FETCH_LIST_SUCCESS = "@payableAgreement/FETCH_LIST_SUCCESS",

    CREATE_MILESTONE_REQUEST = '@payableAgreement/CREATE_MILESTONE_REQUEST',

    UPDATE_MILESTONE_REQUEST = '@payableAgreement/UPDATE_MILESTONE_REQUEST',
    UPDATE_MILESTONE_SUCCESS = '@payableAgreement/UPDATE_MILESTONE_SUCCESS',

    CREATE_MILESTONE_ERROR = '@payableAgreement/CREATE_MILESTONE_ERROR',

    NEGOTIATE_AMEND_MILESTONE_AMOUNT_REQUEST = '@payableAgreement/NEGOTIATE_AMEND_MILESTONE_AMOUNT_REQUEST',
    NEGOTIATE_AMEND_MILESTONE_AMOUNT_ERROR = '@payableAgreement/NEGOTIATE_AMEND_MILESTONE_AMOUNT_REQUEST',

    CANCEL_AGREEMENT_REQUEST = '@payableAgreement/CANCEL_AGREEMENT_REQUEST',
    CANCEL_AGREEMENT_ERROR = '@payableAgreement/CANCEL_AGREEMENT_ERROR',
}

export interface PayableAgreementState extends BaseState {
    readonly data?: PayableAgreement;
    readonly listItems?: PayableAgreement[];
    readonly paginationMeta?: PaginationMeta;
    readonly attachmentsToBeSaved?: Attachment[];
}
