import React from "react";

const Footer: React.FunctionComponent<any> = () => {
    const year = (new Date()).getFullYear()
    return <footer id="page-footer" className="bg-body-light">
        <div className="content py-3">
            <div className="row font-size-sm">
                <div className="col-sm-6 order-sm-2 py-1 text-center text-sm-right">
                    <a className="font-w600" href="https://www.paysecure.com.au/" target="_blank">paysecure Pty
                        Ltd</a> &copy; <span>{year}</span>
                </div>
                <div className="col-sm-6 order-sm-1 py-1 text-center text-sm-left">
                </div>
            </div>
        </div>
    </footer>
}

export default Footer
