import React, {ChangeEvent} from "react";
import {HtmlSelectElementProp} from "../../types";

/**
 * @param onChange
 * @param agreementType
 * @constructor
 */
const TypeOfAgreements: React.FunctionComponent<HtmlSelectElementProp> = ({onChange, value}) => {
    return <div className="row push">
        <div className="col">
            <select name="type" onChange={onChange} className={`form-control`} value={value}>
                <option value={`standard`}>Standard Agreement</option>
                <option value={`only_payment_request`}>Only payment request</option>
            </select>
            <p className="font-size-sm text-muted my-2">
                {value === 'standard' ?
                    <div>In a standard agreement there are multiple milestones and for now the contractor is
                        responsible for the fees. All milestone require request of deposit and release of
                        payments.
                    </div> : null}
                {value === 'only_payment_request' ?
                    <div>In Only payment request agreement no request of deposit is required, the* money will be
                        debited once the client accepts the agreement.
                    </div> : null}
            </p>
        </div>
    </div>
}

export default TypeOfAgreements;
