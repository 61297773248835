import React, {useState} from "react";
import {deleteRequest} from "../../../store/attachment/action";
import {useDispatch} from "react-redux";
import {useShowDialog} from "../../../hooks";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import {Attachment} from "../../../models/Attachment";

type Props = {
    attachment: Attachment
}

/**
 * @param props
 * @constructor
 */
export const DeleteAttachmentButton = (props: Props) => {
    const {showDialog, show, hide} = useShowDialog()
    const dispatch = useDispatch();

    return <>
        <a href="#" onClick={(e) => {
            e.preventDefault();
            show();
        }}><i className="svg-icon cross danger"/></a>

        <ConfirmDialog showDialog={showDialog} onCancel={hide} title={'Delete attachment'}
                       onConfirm={() => {
                           hide()
                           dispatch(deleteRequest({id: props.attachment.id.toString()}))
                       }}>
            <p>Are you sure want to delete {`${props.attachment.file_name}`}. This action is not reversible.</p>
        </ConfirmDialog>
    </>
}

export default DeleteAttachmentButton
