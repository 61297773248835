import {action} from "typesafe-actions";
import {RecipientActionTypes} from "./types";
import {Recipient} from "../../models/Recipient";
import {PaginationMeta} from "../types";

export const fetchListRequest = (payload: any) => action(RecipientActionTypes.FETCH_LIST_REQUEST, payload);
export const fetchListSuccess = (listOfPayableAgreements: Recipient[], paginationMeta: PaginationMeta) => action(RecipientActionTypes.FETCH_LIST_SUCCESS, {
    listOfPayableAgreements,
    paginationMeta
});

export const createRequest = (payload: Recipient, redirect: boolean = true) => action(RecipientActionTypes.CREATE_REQUEST, {data: payload, redirect});
export const fetchError = (message: string) => action(RecipientActionTypes.FETCH_ERROR, [message]);
export const fetchRequest = (id: string) => action(RecipientActionTypes.FETCH_REQUEST, id);
export const fetchSuccess = (data: Recipient) => action(RecipientActionTypes.FETCH_SUCCESS, data);
export const updateRequest = (id: number, payload: Recipient) => action(RecipientActionTypes.UPDATE_REQUEST, {
    id,
    data: payload
});
export const deleteRequest = (id: number) => action(RecipientActionTypes.DELETE_REQUEST, {id})
