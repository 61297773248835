import React, {useRef, useState} from "react";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";

type CancelMilestoneDialogProps = {
    showDialog: boolean
    onConfirm: (reason: string) => void
    onCancel: () => void
}

export const CancelMilestoneDialog: React.FC<CancelMilestoneDialogProps> = ({
                                                                         showDialog,
                                                                         onConfirm,
                                                                         onCancel
                                                                     }) => {

    const reasonTextAreaRef = useRef(null),
        [hasError, setHasError] = useState(false);

    return <ConfirmDialog showDialog={showDialog} confirmButtonText={`Request to cancel Milestone`} onCancel={onCancel}
                          onConfirm={() => {
                              // @ts-ignore
                              if (reasonTextAreaRef && reasonTextAreaRef.current && reasonTextAreaRef.current.value) {
                                  // @ts-ignore
                                  onConfirm(reasonTextAreaRef.current.value)
                              } else {
                                  setHasError(true);
                              }
                          }} title={`Are you sure you want to cancel this milestone?`}>
        <div className="row push">
            <div className="col">
                <div className="form-group">
                    <label htmlFor="example-select-multiple">Reason for cancellation</label>
                    <textarea ref={reasonTextAreaRef} className={"form-control " + (hasError ? `is-invalid` : ``)}
                              onChange={(e) => {
                                  // @ts-ignore
                                  setHasError(!(reasonTextAreaRef && reasonTextAreaRef.current && reasonTextAreaRef.current.value && reasonTextAreaRef.current.value.length > 0))
                              }}
                              name="agreement_details" rows={5} placeholder="Please enter a reason for cancellation." />
                    {hasError && <div className="invalid-feedback">Please enter a reason for cancellation.</div>}
                </div>
            </div>
        </div>
    </ConfirmDialog>
}

export default CancelMilestoneDialog;
