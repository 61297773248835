import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from "./configureStore";
import Config from './pyntax/lib/config/index'

import ServiceManager from "./services/ServiceManager";
import Utils from "./services/Utils";
import FormUtils from "./services/Forms";
import StorageService from "./pyntax/lib/services/StorageService";
import AuthManager from "./pyntax/lib/data/AuthManager";
import DataApi from "./services/DataApi";
import Analytics from "./services/analytics";

const initialState: any = {};
const store = configureStore(initialState);

ServiceManager.utils = new Utils();
ServiceManager.formUtils = new FormUtils();
ServiceManager.storageService = new StorageService();
ServiceManager.authManager = new AuthManager();
ServiceManager.dataApi = new DataApi();
ServiceManager.analytics = new Analytics();


if (Config.APP_ENV === 'prod') {
    Sentry.init({
        dsn: "https://95aca816539b46359e5681efdb86722c@o510324.ingest.sentry.io/5947525",
        integrations: [new BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });

    TagManager.initialize({
        gtmId: 'GTM-TRZKD98'
    });

    ReactGA.initialize(Config.GA_TRACKING_ID);
}

ReactDOM.render(
    <React.StrictMode>
        <App store={store}/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
