import React, {useCallback, useState} from "react";
import {InfoNotification} from "../../../components/Notifications";
import InfoDialog from "../../../components/Dialogs/InfoDialog";
import {DangerButton} from "../../../components/Buttons";
import {deleteRequest} from "../../../store/recipient/action";
import {useDispatch} from "react-redux";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";

interface Props {
    canDelete: boolean
    id: number
}

export const DeleteRecipientButton = ({canDelete, id}: Props) => {
    const dispatch = useDispatch();
    const [showCannotDeleteDialog, setShowCannotDeleteDialog] = useState<boolean>(false);
    const [confirmDeleteRecipientDialog, setConfirmDeleteRecipientDialog] = useState<boolean>(false);

    const handleOnDelete = useCallback(() => {
        if (canDelete) {
            setShowCannotDeleteDialog(true)
        } else {
            setConfirmDeleteRecipientDialog(true)
        }
    }, [canDelete, id, setShowCannotDeleteDialog])


    return <>
        <DangerButton type={'button'} onClick={handleOnDelete}>Delete
            contact</DangerButton>

        <ConfirmDialog title={'Delete contact'} showDialog={confirmDeleteRecipientDialog}
                       onCancel={() => setConfirmDeleteRecipientDialog(false)}
                       onConfirm={() => {
                           setShowCannotDeleteDialog(false)
                           dispatch(deleteRequest(id))
                       }}>
            Are you sure you want to proceed?
        </ConfirmDialog>
        <InfoDialog title={'Delete contact'} okButtonText={'Close'} onOk={() => setShowCannotDeleteDialog(false)}
                    showDialog={showCannotDeleteDialog}>
            <InfoNotification title={''}>Failed to delete contact, it's being used in one or more
                agreements.</InfoNotification>
        </InfoDialog>
    </>
}

export default DeleteRecipientButton
