import React from "react";
import {Pagination as MuiPagination} from "@material-ui/lab";
import {PaginationMeta} from "../../store/types";

export type PaginationProps = {
    paginationMeta?: PaginationMeta
    onChangePage?: (event, page) => void
}

const Pagination: React.FunctionComponent<PaginationProps> = ({
                                                                  paginationMeta,
                                                                  onChangePage
                                                        }) => {
    return <div className={'ps-pagination'}>
        {paginationMeta && <MuiPagination
            siblingCount={2}
            count={paginationMeta?.last_page || 10}
            page={paginationMeta?.current_page || 1}
            onChange={(event, page) => onChangePage && onChangePage(event, page) } />}
    </div>
}

export default Pagination