import React, {useEffect} from "react";
import {Link, useHistory} from 'react-router-dom'
import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import Block from "../../components/Blocks/Block";
import RecordListTable from "../../components/Table/RecordListTable";
import {BankAccount} from '../../models/BankAccount'
import {PaginationMeta} from "../../store/types";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {fetchListRequest} from "../../store/bankAccount/action";
import {connect} from "react-redux";

import {ToastProp} from "../../components/Notifications/Toasts";
import {LANG} from "../../labels"
import useQuery from "../../components/utils/userQuery";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";
import Recipient from "../../models/Recipient";
import StatusPill from "../../components/BankAccounts/StatusPill";
import ListPageAddLink from "../../components/Buttons/ListPageAddLink";

interface PropsFromState {
    loading: boolean;
    listItems?: BankAccount[];
    paginationMeta?: PaginationMeta;
    notifications: NotificationState;
    toasts?: ToastProp[];
}

interface PropsFromDispatch {
    fetchListOfBankAccounts: (pageNumber: number, pageSize?: number, filter?: string) => any;
}


type AllProps = PropsFromState & PropsFromDispatch;

export const BankAccountsPage: React.FunctionComponent<AllProps> = ({
                                                                        fetchListOfBankAccounts,
                                                                        listItems,
                                                                        loading,
                                                                        paginationMeta,
                                                                        notifications,
                                                                        toasts,
                                                                    }) => {
    const history = useHistory()
    const columns = [
        {
            headerTitle: "Nickname",
            accessor: "nickname"
        },
        {
            headerTitle: "Bank name",
            accessor: "identifier_label"
        },
        {
            headerTitle: "Status",
            accessor: (row: BankAccount) => {
                return <StatusPill status={row.status}/>
            }
        },
        {
            headerTitle: "Connection type",
            accessor: (row: BankAccount) => {
                if (row.connection_type === 'Zepto_IAV') {
                    return 'Online banking login'
                }

                return 'Manual BSB & Bank account'
            }
        },
        {
            headerTitle: "View / Edit",
            accessor: (row: Recipient) => <Link className={`btn btn-primary btn-sm`}
                                                to={`/bank_accounts/${row.id}/edit`}>View
                / Edit</Link>
        }
    ];

    const query = useQuery();
    const returnPayableAgreementId = query.get('returnPayableAgreementId'),
        agreementType = query.get('type');

    useEffect(() => {
        if (returnPayableAgreementId && parseInt(returnPayableAgreementId) > 0) {
            if (agreementType && agreementType === 'incoming') {
                history.push(`/incoming_agreements/${returnPayableAgreementId}/view`)
            } else {
                history.push(`/agreements/${returnPayableAgreementId}/edit`)
            }
        }

        fetchListOfBankAccounts(1);
    }, [agreementType, fetchListOfBankAccounts, history, returnPayableAgreementId]);


    return <MainLayout pageTitle={`Connected Bank Accounts`} isLoading={loading} toasts={toasts}>
        {/* Hero */}
        <PageTitle title={LANG.BankAccountsPage.DISPLAY_NAME}>
            <ListPageAddLink desktopBtnLabel={`Connect Bank Account`} to={`/bank_accounts/connect`}/>
        </PageTitle>

        <NotificationsContainer notifications={notifications}/>

        <div className="content">
            <Block noPadding={true}>
                <RecordListTable<BankAccount> loadMoreResults={(pageNumber: number) => {
                    fetchListOfBankAccounts(pageNumber)
                }} paginationMeta={paginationMeta} columns={columns} data={listItems}/>
            </Block>
        </div>
    </MainLayout>
}


const mapStateToProps = ({bankAccount}: ApplicationState) => ({
    loading: bankAccount.loading,
    notifications: bankAccount.notifications,
    listItems: bankAccount.listItems,
    paginationMeta: bankAccount.paginationMeta,
    toasts: bankAccount.toasts,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchListOfBankAccounts: (pageNumber: number, pageSize?: number, filter?: string) => {
            dispatch(fetchListRequest({pageNumber, pageSize, filter}));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountsPage);
