import React, {useEffect} from "react";
import {ApplicationState} from "../../store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";
import Recipient from "../../models/Recipient";
import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import {createRequest} from "../../store/recipient/action";
import NotificationsContainer from "../../components/Notifications/Containers";
import {SuccessButton} from "../../components/Buttons";
import RecipientFields from "./RecipientFields";
import Block from "../../components/Blocks/Block";
import ButtonGroup from "../../components/Buttons/ButtonGroup";

interface OwnProps {
}

const mapStateToProps = ({recipient}: ApplicationState) => ({
    loading: recipient.loading,
    notifications: recipient.notifications,
    toasts: recipient.toasts,
})

const connector = connect(mapStateToProps, {})
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = OwnProps & PropsFromRedux

const CreateRecipientPage = (props: Props) => {
    const dispatch = useDispatch();
    const {loading, notifications, toasts,} = props
    const methods = useForm<Recipient>({
        defaultValues:  {
            country: 'AU'
        }
    })
    const {handleSubmit} = methods;
    const onSubmit = (data: Recipient) => {
        dispatch(createRequest(data))
    }

    return <MainLayout pageTitle={`Create new recipient`} isLoading={loading} toasts={toasts}>
        <PageTitle title={`Create new recipient`}/>

        <div className="content">
            <NotificationsContainer notifications={notifications}/>

            <Block withHeaderBorder>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <RecipientFields isUpdating={false} />
                        <div className="row push">
                            <div className="col">
                                <div className="form-group">
                                    <ButtonGroup>
                                        <SuccessButton type={`submit`}>Save recipient</SuccessButton>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </Block>
        </div>
    </MainLayout>
}

export default connector(CreateRecipientPage)
