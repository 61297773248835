import React, {useEffect} from "react";
import Milestone from "../../../models/Milestone";
import MilestoneFormDialog from "./MilestoneFormDialog";
import FormDialog from "../../Dialogs/FormDialog";

type Props = {
    showDialog: boolean
    milestone: Milestone
    onConfirm: (m: Milestone) => void
    onCancel: () => void
}

const EditMilestoneDialog = ({
                                 milestone, showDialog, onConfirm, onCancel
                             }: Props) => {

    return <FormDialog showDialog={showDialog} maxWidth={'md'}>
        <MilestoneFormDialog
            action={'update'}
            nextMilestoneId={milestone.id + 1}
            milestone={milestone}
            onCancel={onCancel}
            onConfirm={onConfirm}/>
    </FormDialog>
}

export default EditMilestoneDialog
