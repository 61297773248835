import React from "react";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import Utils from "../../../services/Utils";

const utils = new Utils()

/**
 * @class ApproveMilestoneCancellationDialogProps
 */
type ApproveMilestoneCancellationDialogProps = {
    showDialog: boolean
    action: 'approve' | 'reject'
    onConfirm: () => void
    onCancel: () => void
}


const ApproveOrRejectMilestoneCancellationDialog: React.FC<ApproveMilestoneCancellationDialogProps> = ({
                                                                                                           showDialog,
                                                                                                           action,
                                                                                                           onConfirm,
                                                                                                           onCancel
                                                                                                       }) => {

    return <ConfirmDialog showDialog={showDialog}
                          confirmButtonText={`${utils.ucfirst(action)} cancellation of Milestone`}
                          onCancel={onCancel}
                          onConfirm={onConfirm} title={`${utils.ucfirst(action)} cancellation of Milestone`}>
        <p>Are you sure you want to {action} the cancellation of milestone?</p>
        <p>If there was any money debited from your account it will be refunded.</p>
    </ConfirmDialog>
}

export default ApproveOrRejectMilestoneCancellationDialog;
