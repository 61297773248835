import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import ServiceManager from "../../services/ServiceManager";
import WebsiteLayout from "../../components/Layouts/WebsiteLayout";
import {ErrorNotification, SuccessNotification} from "../../components/Notifications";
import {Link} from "react-router-dom";
import useQuery from "../../components/utils/userQuery";
import {PrimaryButton} from "../../components/Buttons";
import GoogleButton from "../../components/Auth/Buttons/GoogleButton";
import FacebookButton from "../../components/Auth/Buttons/FacebookButton";
import {EmailInput} from "../../components/Forms/Fields/Input";
import Password from "../../components/Forms/Fields/Password";
import {ApplicationState} from "../../store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {fetchRequest} from "../../store/register/action";
import {UserRegistrationRequest} from "../../models/User";
import NotificationsContainer from "../../components/Notifications/Containers";

const FIELDS_CAN_BE_MAPPED_FROM_URL = [
    // 'first_name',
    // 'last_name',
    'email',
    // 'mobile'
];
const mapStateToProps = ({registration}: ApplicationState) => ({
    ...registration
})
const connector = connect(mapStateToProps, {})
type PropsFromRedux = ConnectedProps<typeof connector>

const SignUpPage: React.FunctionComponent<PropsFromRedux> = ({
                                                                 loading,
                                                                 isRegistrationSuccessful,
                                                                 notifications
                                                             }: PropsFromRedux) => {
    const dispatch = useDispatch()
    const query = useQuery();
    const {
        register,
        formState: {errors},
        handleSubmit,
        watch,
        getValues,
        setValue
    } = useForm<UserRegistrationRequest & {
        terms: boolean
    }>({});

    const [canSignUp, setCanSignUp] = useState<boolean>(false);
    useEffect(() => {
        FIELDS_CAN_BE_MAPPED_FROM_URL.forEach((field) => {
            if (field && query.get(field)) {
                setValue(field, query.get(field))
            }
        });
    }, [query, setValue]);

    const watchingTermsField = watch('terms')
    useEffect(() => {
        setCanSignUp(getValues('terms'))
    }, [getValues, watchingTermsField])

    /**
     * @param data
     */
    const onSubmit = (data: UserRegistrationRequest) => {
        dispatch(fetchRequest(data))
        // setApiErrors([]);
        // setIsLoading(true);
        // ServiceManager.authManager.registerAccount({
        //     ...data,
        //     email: data.email.toLowerCase()
        // }).then((data: any) => {
        //     setIsRegistrationSuccessful(true);
        //     setIsLoading(false);
        //     reset()
        // }).catch((errors: any) => {
        //     setIsRegistrationSuccessful(false);
        //     setApiErrors(errors.response.data.errors);
        //     setIsLoading(false);
        // });
    };

    // const renderErrors = () => {
    //     if (apiErrors !== undefined && apiErrors !== null && Object.keys(apiErrors).length > 0) {
    //         return <ErrorNotification>
    //             <ul>
    //                 {Object.keys(apiErrors).map((apiError) => {
    //                     // @ts-ignore
    //                     if (apiError !== undefined && apiError !== null && apiErrors[apiError].length > 0) {
    //                         // @ts-ignore
    //                         return apiErrors[apiError].map((err) => <li>{err}</li>)
    //                     }
    //                 })}
    //             </ul>
    //         </ErrorNotification>
    //     }
    // }

    // const watchFields = watch(["terms", "directDebit"]);
    // useEffect(() => {
    //     const formTerms = getValues('terms');
    //     const formDirectDebit = getValues('directDebit');
    //
    //     setCheckedState(formTerms && formDirectDebit)
    // }, [watchFields]);

    return <WebsiteLayout pageTitle={`Sign Up`} isLoading={loading} hideHeader={true}>
        <div className="signup-container">
            <h2>Sign up</h2>
            <div className="subheading">Please enter your details to access your account.</div>

            <NotificationsContainer notifications={notifications}/>

            {isRegistrationSuccessful && <SuccessNotification>
                <div>Thanks for registering with paysecure. You will be sent an email to verify
                    your account soon.
                </div>
                <div><Link to={`/login`}>Click here to login.</Link>
                </div>
            </SuccessNotification>}

            {!isRegistrationSuccessful && <form onSubmit={handleSubmit(onSubmit)}>

                <div className="form-group google-login-container">
                    <GoogleButton type={'signUp'} isSSOEnabled={canSignUp}/>
                </div>
                <div className="form-group">
                    <FacebookButton/>
                </div>
                <div className="form-group text-center">
                    <label htmlFor="" className="o text-muted">OR</label>
                </div>

                <EmailInput label={`Email`}
                            errors={errors} {...register('email', {required: 'Please enter a valid email'})} />
                <Password label={'Password'} errors={errors}  {...register('password', {
                    required: 'Please enter a valid password minimum 6 characters long',
                    minLength: 6
                })} />
                <div className="form-group">
                    <div className="d-md-flex flex-column justify-content-md-between">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox"
                                   {...register('terms', {required: true})}
                                   className={"custom-control-input " + ServiceManager.formUtils.renderErrorClass(errors.terms)}
                                   id="terms" name="terms"/> <label className="custom-control-label font-w400"
                                                                    htmlFor="terms">I agree to the <a
                            className="font-size-sm font-w500" target={`_blank`}
                            href="http://assets.paysecure.com.au/terms/index.html"> Terms &amp; Conditions</a></label>
                        </div>
                        {/*Hiding this checkbox will enable if Split's wants us to again*/}
                        {/*<div className="custom-control custom-checkbox">*/}
                        {/*    <input type="checkbox"*/}
                        {/*           {...register('directDebit', {required: true})}*/}
                        {/*           className={"custom-control-input " + ServiceManager.formUtils.renderErrorClass(errors.directDebit)}*/}
                        {/*           id="directDebit" name="directDebit"/> <label*/}
                        {/*    className="custom-control-label font-w400"*/}
                        {/*    htmlFor="directDebit">I agree to the <a target={`_blank`} className="font-size-sm font-w500"*/}
                        {/*                                            href="https://www.splitpayments.com.au/ddr/">DDR</a> {`& `}*/}
                        {/*    <a target={`_blank`} className="font-size-sm font-w500"*/}
                        {/*       href="https://www.splitpayments.com.au/ddrsa/">DDRSA</a></label>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="form-group ">
                    <PrimaryButton type="submit" className="btn btn-block btn-sign-up">
                        <i className="fa fa-fw fa-plus mr-1"/> Sign Up
                    </PrimaryButton>
                </div>
                <div className="form-group already-have-an-account">
                    Already have an account. <Link to="/login">Log in now!</Link>
                </div>
            </form>}
            {/* END Sign Up Form */}
        </div>
    </WebsiteLayout>
}

export default connector(SignUpPage);
