import React, {useCallback, useEffect} from "react";
import {useHistory, Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {PrimaryButton} from '../../components/Buttons';
import {ErrorNotification} from "../../components/Notifications";
import ServiceManager from "../../services/ServiceManager";
import WebsiteLayout from "../../components/Layouts/WebsiteLayout";
import User from "../../models/User";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {forgotPasswordRequest} from "../../store/login/action";
import {connect} from 'react-redux';
import {ToastProp} from "../../components/Notifications/Toasts";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";

interface PropsFromState {
    readonly loading: boolean;
    readonly user?: User;
    readonly notifications: NotificationState;
    readonly toasts?: ToastProp[]
    readonly isLoggedIn: boolean;
}

interface PropsFromDispatch {
    forgotPassword: (username: string) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

const LoginPage: React.FC<AllProps> = ({
                                           loading,
                                           notifications,
                                           forgotPassword,
                                           toasts,
                                           isLoggedIn,
                                       }) => {

    const {formState, register, handleSubmit} = useForm(),
        history = useHistory(),
        onSubmit = (data: any, e: any) => {
            forgotPassword(data.username);
        }
    const canHideResetPasswordForm = useCallback(() => {
        return notifications.success.length === 0;
    }, [notifications])

    useEffect(() => {
        if (isLoggedIn) {
            history.push('/dashboard');
        }
    }, [isLoggedIn])

    return <WebsiteLayout pageTitle={`Login`} isLoading={loading} hideHeader={true} toasts={toasts}>
        <div className="forgot-password-container">
            <h2>Forgot password</h2>
            <div className="subheading">Enter your email below and we'll send a link to reset your password.</div>

            <NotificationsContainer notifications={notifications} />

            {canHideResetPasswordForm() && <form onSubmit={handleSubmit(onSubmit)}>
                <div className={"form-group"}>
                    <input type="text"
                           className={"form-control form-control-lg form-control-alt" + ServiceManager.formUtils.renderErrorClass(formState.errors.username)}
                           id="username" {...register('username', {required: true})}
                           placeholder="Your email"/>
                    {ServiceManager.formUtils.renderErrorBlock(formState.errors, 'username', 'Please enter a valid email')}
                </div>
                <div className="form-group ">
                    <PrimaryButton className={`btn-block btn-reset-password`}>Send link</PrimaryButton>
                </div>
                <div className="form-group go-back-container"><Link to={`/login`}>{`<`} Go back</Link></div>
            </form>}
        </div>
    </WebsiteLayout>
}

const mapStateToProps = ({login}: ApplicationState) => ({
    loading: login.loading,
    notifications: login.notifications,
    isLoggedIn: login.isLoggedIn,
    toasts: login.toasts
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        forgotPassword: (email: string) => {
            dispatch(forgotPasswordRequest(email))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
