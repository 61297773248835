import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import LoadingModal from "../Dialogs/LoadingModal";
import {ToastProp, Toasts} from "../Notifications/Toasts";

export interface WebsiteLayoutProps {
    children: any[] | any | string | null | undefined;
    pageTitle: string;
    subTitle?: string;
    isLoading: boolean;
    hideHeader?: boolean;
    toasts?: ToastProp[]
}

const WebsiteLayout: React.FunctionComponent<WebsiteLayoutProps> = ({
                                                                        isLoading,
                                                                        pageTitle,
                                                                        subTitle,
                                                                        hideHeader,
                                                                        children,
                                                                        toasts
                                                                    }) => {


    const [toastsToBeDisplayed, setToastsToBeDisplayed] = useState<ToastProp[]>([]);

    useEffect(() => {
        if (toasts && toasts?.length > 0) {
            setToastsToBeDisplayed(toasts);
        }
    }, [toasts])

    return <>
        <Helmet>
            <title>paysecure - {pageTitle}</title>
        </Helmet>
        <div id="page-container"
             className="sidebar-dark side-scroll page-header-fixed page-header-dark overflow-hidden">

            <div className="content content-full p-0">
                <div className="row ps-non-logged-in-layout">
                    <div className="col col-lg-6 content-col">
                        <div className="logo-container">
                            <img src="/logo-t.svg" alt="paysecure.com.au" className="logo"/>
                        </div>
                        {children}
                    </div>
                    <div className="col col-lg-6 d-none d-lg-block image-col"></div>
                </div>
            </div>
        </div>
        <LoadingModal isLoading={isLoading}/>
        <Toasts toasts={toastsToBeDisplayed}/>
    </>
}

export default WebsiteLayout;
