import React from "react";
import {HtmlSelectElementProp} from "../../types";

const FeesPaidBy: React.FunctionComponent<HtmlSelectElementProp> = ({onChange, value}) => {
    return <div className="row push">
        <div className="col-lg-4">
            <label htmlFor="sltTypesOfAgreement">Fees <span className="text-danger">*</span></label>
            <p className="font-size-sm text-muted">
                You can either absorb the fee in the total amount of pass on the fee to the customer. Doing that,
                would increase the total by 1%.
            </p>
        </div>
        <div className="col-lg-8">
            <label htmlFor="sltFeesPaidBy">Fees paid by</label>
            <select className="form-control" id="sltFeesPaidBy" name="sltFeesPaidBy"
                    onChange={onChange} value={value}>
                <option value={`CONTRACTOR`}>Contractor</option>
                <option value={`CUSTOMER`}>Customer</option>
                <option value={`SHARE`}>Split the fees (50%)</option>
            </select>
        </div>
    </div>
}

export default FeesPaidBy;
