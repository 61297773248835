import React, {useState} from 'react';
import CancelMilestoneDialog from "../Dialogs/CancelMilestoneDialog";
import {DangerButton} from "../../Buttons";

interface Props {
    handleCancelMilestone: (reason: string) => void
}

export const CancelMilestoneButton: React.FunctionComponent<Props> = ({
                                                                          handleCancelMilestone
                                                                      }) => {

    const [showCancelMilestoneDialog, setShowCancelMilestoneDialog] = useState<boolean>(false);
    return <>
        <CancelMilestoneDialog showDialog={showCancelMilestoneDialog}
                               onConfirm={handleCancelMilestone}
                               onCancel={() => setShowCancelMilestoneDialog(false)}/>
        <DangerButton onClick={() => setShowCancelMilestoneDialog(true)}
                      className={`btn-sm btn-danger my-1`}>Cancel milestone</DangerButton>
    </>
}

export default CancelMilestoneButton;
