import {action} from "typesafe-actions";
import {PayableAgreementActionTypes} from "./types";
import {PayableAgreement} from "../../models/PayableAgreement";
import {
    CreateRequest,
    FetchListRequestPayload,
    FetchRequest,
    FetchSuccess,
    ListOfWithPagination,
    UpdateRequest
} from "../types";
import {AmendMilestoneAmountRequest, BaseMilestone, Milestone} from "../../models/Milestone";


export const fetchListRequest = (payload: FetchListRequestPayload) => action<PayableAgreementActionTypes, FetchListRequestPayload>(PayableAgreementActionTypes.FETCH_LIST_REQUEST, payload);
export const fetchListSuccess = (listOfWithPagination: ListOfWithPagination<PayableAgreement>) => action<PayableAgreementActionTypes, ListOfWithPagination<PayableAgreement>>(PayableAgreementActionTypes.FETCH_LIST_SUCCESS, listOfWithPagination);

export const updateRequest = (payload: UpdateRequest<PayableAgreement>) => action(PayableAgreementActionTypes.UPDATE_REQUEST, payload);
export const createRequest = (payload: CreateRequest<PayableAgreement>) => action(PayableAgreementActionTypes.CREATE_REQUEST, payload);

export const updateMilestoneRequest = (payload: UpdateRequest<Milestone>) => action(PayableAgreementActionTypes.UPDATE_MILESTONE_REQUEST, payload)

export const fetchRequest = (payload: FetchRequest) => action(PayableAgreementActionTypes.FETCH_REQUEST, payload.id);
export const fetchSuccess = (payload: FetchSuccess<PayableAgreement>) => action(PayableAgreementActionTypes.FETCH_SUCCESS, payload);

export const fetchError = (message: string) => action(PayableAgreementActionTypes.FETCH_ERROR, [message]);

export const requestDepositRequest = (id: number) => action(PayableAgreementActionTypes.REQUEST_DEPOSIT_REQUEST, id);
export const requestPaymentRequest = (id: number) => action(PayableAgreementActionTypes.REQUEST_PAYMENT_REQUEST, id);
export const sendAgreementRequest = (id: string) => action(PayableAgreementActionTypes.SEND_AGREEMENT_REQUEST, id);
export const deleteMilestoneSuccess = () => action(PayableAgreementActionTypes.DELETE_MILESTONE_FETCH_SUCCESS);
export const deleteMilestoneRequest = (id: number, payableAgreementId: number) => action(PayableAgreementActionTypes.DELETE_MILESTONE_FETCH_REQUEST, {
    id,
    payableAgreementId
});
export const deleteMilestoneError = (message: string) => action(PayableAgreementActionTypes.DELETE_MILESTONE_FETCH_ERROR, [message]);
export const approveCancellationOfMilestoneRequest = (id: number) => action(PayableAgreementActionTypes.APPROVE_CANCEL_MILESTONE_FETCH_REQUEST, {id});
export const rejectCancellationOfMilestoneRequest = (id: number) => action(PayableAgreementActionTypes.REJECT_CANCEL_MILESTONE_FETCH_REQUEST, {id});
export const cancelMilestoneRequest = (id: number, payableAgreementId: number, reason: string) => action(PayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_REQUEST, {
    id,
    payableAgreementId,
    reason
});
export const cancelMilestoneError = (message: string) => action(PayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_ERROR, [message]);
export const cancelMilestoneSuccess = () => action(PayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_SUCCESS);
export const downloadReceiptRequest = (id: string) => action(PayableAgreementActionTypes.DOWNLOAD_RECEIPT_REQUEST, {id});

export const downloadMilestoneReceiptRequest = (id: string) => action(PayableAgreementActionTypes.DOWNLOAD_MILESTONE_RECEIPT_REQUEST, {id});
export const downloadMilestoneReceiptSuccess = () => action(PayableAgreementActionTypes.DOWNLOAD_MILESTONE_RECEIPT_SUCCESS);
export const downloadMilestoneReceiptError = (message: string) => action(PayableAgreementActionTypes.DOWNLOAD_MILESTONE_RECEIPT_ERROR, message);

export const createMilestone = (payload: CreateRequest<BaseMilestone>) => action(PayableAgreementActionTypes.CREATE_MILESTONE_REQUEST, payload)
export const negotiateAmendedAmountRequest = (payload: AmendMilestoneAmountRequest) => action(PayableAgreementActionTypes.NEGOTIATE_AMEND_MILESTONE_AMOUNT_REQUEST, payload)

export const cancelAgreementRequest = (id: number) => action(PayableAgreementActionTypes.CANCEL_AGREEMENT_REQUEST, {id})
