import React from "react";
import {PayableAgreement, PayableAgreementStatus} from "../../models/PayableAgreement";
import milestone from "../../models/Milestone";

export type TimelineItemStatus = 'pending' | 'in_progress' | 'completed'

interface TimelineItem {
    label: string
    status: TimelineItemStatus
}

interface Props {
    payableAgreement: PayableAgreement
}

const getIcon = (timelineItemStatus: TimelineItemStatus) => {
    switch (timelineItemStatus) {
        case "in_progress":
            return 'hourglass'
        case "pending":
            return 'minus';
        case "completed":
            return "tick";
    }
}

const sentStatus = (payableAgreement: PayableAgreement) => !!payableAgreement?.sent_on_display;
const acceptedStatus = (payableAgreement: PayableAgreement) => {
    const isSent = sentStatus(payableAgreement);
    if (isSent) {
        if (payableAgreement?.accepted_on_display) {
            return 'completed'
        }

        return 'in_progress';
    }

    return 'pending';
}
const inProgressStatus = (payableAgreement: PayableAgreement) => {
    if (acceptedStatus(payableAgreement) === 'completed') {
        if (payableAgreement?.status === 'closed') {
            return 'completed'
        }

        return 'in_progress';
    }

    return 'pending';
}
const isCompleted = (payableAgreement: PayableAgreement) => {
    if (inProgressStatus(payableAgreement) === 'completed') {
        return 'completed';
    }

    return 'pending';
}
/**
 *
 * @param label
 * @param payableAgreement
 */
export const getStatusLabel = (label: string, payableAgreement: PayableAgreement) => {
    if (label.toLowerCase() === 'draft' && sentStatus(payableAgreement)) {
        return 'sent';
    }

    return label;
}

export const PayableAgreementTimeline: React.FunctionComponent<Props> = ({
                                                                             payableAgreement
                                                                         }) => {
    const timelineItems: TimelineItem[] = [
        {
            label: "Draft",
            status: sentStatus(payableAgreement) ? 'completed' : 'in_progress',
        },
        {
            label: "Accepted",
            status: acceptedStatus(payableAgreement),
        },
        {
            label: "In progress",
            status: inProgressStatus(payableAgreement),
        },
        {
            label: "Closed",
            status: isCompleted(payableAgreement),
        }
    ]

    return <div className="payable-agreement-timeline-container">
        <div className="middle-line"/>
        <div className="payable-agreement-timeline">
            {timelineItems.map((timelineItem: TimelineItem) => (<div className="timeline-item">
                <div className={`icon-container ${timelineItem.status}`}>
                    <i className={`svg-icon mini ${getIcon(timelineItem.status)} ${timelineItem.status}`}/>
                </div>
                {getStatusLabel(timelineItem.label, payableAgreement)}
            </div>))}
        </div>
    </div>
}

export default PayableAgreementTimeline;
