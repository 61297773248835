import {Reducer} from "redux";
import {IncomingPayableAgreementActionTypes, IncomingPayableAgreementState} from "./types";
import {ToastActionTypes} from "../types";

export const initialState: IncomingPayableAgreementState = {
    data: undefined,
    loading: false,
    notifications: {
        errors: [],
        alerts: [],
        infos: [],
        warnings: [],
        success: []
    },

    listItems: [],
    paginationMeta: undefined,
    toasts: [],
}

const reducer: Reducer<IncomingPayableAgreementState> = (state = initialState, action) => {
    switch (action.type) {
        case IncomingPayableAgreementActionTypes.ACCEPT_PAYABLE_AGREEMENT:
        case IncomingPayableAgreementActionTypes.REJECT_PAYABLE_AGREEMENT:
        case IncomingPayableAgreementActionTypes.APPROVE_PAYMENT_REQUEST:
        case IncomingPayableAgreementActionTypes.REJECT_PAYMENT_REQUEST:
        case IncomingPayableAgreementActionTypes.APPROVE_DEPOSIT_REQUEST:
        case IncomingPayableAgreementActionTypes.REJECT_DEPOSIT_REQUEST:
        case IncomingPayableAgreementActionTypes.SAVE_REQUEST:
        case IncomingPayableAgreementActionTypes.UPDATE_REQUEST:
        case IncomingPayableAgreementActionTypes.FETCH_LIST_REQUEST:
        case IncomingPayableAgreementActionTypes.FETCH_REQUEST:
        case IncomingPayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_REQUEST:
        case IncomingPayableAgreementActionTypes.APPROVE_CANCEL_MILESTONE_FETCH_REQUEST:
        case IncomingPayableAgreementActionTypes.SEND_TFA_CODE_REQUEST:
        case IncomingPayableAgreementActionTypes.PAY_UNSECURE_AGREEMENT_REQUEST:
            return {
                ...state, loading: true, toasts: [], listItems: [], notifications: {
                    errors: [],
                    alerts: [],
                    infos: [],
                    warnings: [],
                    success: []
                }
            }

        case ToastActionTypes.RESET_TOASTS:
            return {...state, toasts: []}

        case ToastActionTypes.ADD_TOAST:
            const existingToasts = state.toasts ?? [];
            return {...state, toasts: [...existingToasts, action.payload]}

        case IncomingPayableAgreementActionTypes.FETCH_LIST_SUCCESS:
            return {
                ...state,
                listItems: action.payload.listOfPayableAgreements,
                loading: false,
                paginationMeta: action.payload.paginationMeta
            }

        case IncomingPayableAgreementActionTypes.SEND_TFA_CODE_SUCCESS:
            return {...state, loading: false}

        case IncomingPayableAgreementActionTypes.FETCH_SUCCESS:
        case IncomingPayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_SUCCESS:
            return {...state, data: action.payload, loading: false}

        case IncomingPayableAgreementActionTypes.FETCH_ERROR:
        case IncomingPayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_ERROR:
        case IncomingPayableAgreementActionTypes.SEND_TFA_CODE_ERROR:
            return {
                ...state, loading: false, notifications: {
                    ...state.notifications,
                    errors: action.payload
                }
            }

        default:
            return state;
    }
}
export {reducer as IncomingPayableAgreementReducer}
