import React, {useEffect, useRef, useState} from "react";

import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import {useForm, Controller} from "react-hook-form";

import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {
    fetchRequest,
    updateRequest
} from "../../store/profile/action";
import {connect} from "react-redux";
import {Profile} from "../../models/Profile";
import Block from "../../components/Blocks/Block";
import {BankAccount} from "../../models/BankAccount";
import {fetchListRequest} from "../../store/bankAccount/action";
import {ToastProp} from "../../components/Notifications/Toasts";
import {LANG} from "../../labels"
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";
import SaveButton from "../../components/Buttons/SaveButton";
import {InfoNotification} from "../../components/Notifications";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";
import {UserCard} from "../../components/Cards";
import ProfileCard from "../../components/Cards/ProfileCard";
import {TextInput} from "../../components/Forms/Fields/Input";

export type Inputs = {
    mobile?: string;

    // Driver Licence
    // driver_licence_number?: string;
    // driver_licence_state?: string;
    // drivers_licence_expiry?: string;

    // business_name?: string;
    // business_number?: string;
    // business_yearly_turnover?: number
    //
    // business_street_address?: string;
    // business_street_address_2?: string;
    // business_suburb?: string;
    // business_state?: string;
    // business_postcode?: string;
    // business_country?: string;

    street_address?: string;
    street_address_2?: string;
    suburb?: string;
    state?: string;
    postcode?: string;
    country?: string;
    date_of_birth?: string;
};

interface PropsFromState {
    loading: boolean;
    data?: Profile;
    notifications: NotificationState;
    listOfBankAccounts?: BankAccount[]
    toasts?: ToastProp[]
}

interface PropsFromDispatch {
    fetchProfile: () => any;
    updateProfile: (data: any) => any;
    fetchListOfBankAccounts: (pageNumber: number, pageSize?: number) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

export const ProfilePage: React.FunctionComponent<AllProps> = ({
                                                                   loading,
                                                                   data,
                                                                   notifications,
                                                                   fetchProfile,
                                                                   toasts,
                                                                   fetchListOfBankAccounts,
                                                                   updateProfile,
                                                                   listOfBankAccounts
                                                               }) => {
    const {register, handleSubmit, reset, control} = useForm<Profile>({
            defaultValues: data
        }),
        onSubmit = (data: Profile) => updateProfile(data)

    const [activeBankAccounts, setActiveBankAccounts] = useState<BankAccount[]>([])

    useEffect(() => {
        let activeBankAccounts: BankAccount[];

        if (listOfBankAccounts) {
            activeBankAccounts = listOfBankAccounts?.filter(bankAccount => bankAccount.status === 'connected')
            setActiveBankAccounts(activeBankAccounts)
        } else {
            setActiveBankAccounts([])
        }

    }, [listOfBankAccounts])

    // On first load, let's load the profileDetails,
    useEffect(() => {
        fetchProfile();
        fetchListOfBankAccounts(1, 50);
    }, []);

    useEffect(() => {
        if (data) {
            reset(data)
        }
    }, [data])

    return <MainLayout pageTitle={`Profile Page`} isLoading={loading} toasts={toasts}>
        {/* Hero */}
        <PageTitle title={LANG.ProfilePage.DISPLAY_NAME}/>
        {/* END Hero */}

        <NotificationsContainer notifications={notifications}/>

        <div className="content">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-lg-3">
                        <ProfileCard profile={data}/>
                    </div>
                    <div className="col-lg-9">
                        <Block>
                            <div className="row push">
                                <div className="col-lg-12">
                                    <TextInput label={'Name'} {...register('name')} />
                                </div>
                            </div>
                            <div className="row push">
                                <div className="col-lg-6">
                                    <div className="form-group mobile">
                                        <label htmlFor="mobile">Mobile Number</label>
                                        <input  {...register('mobile')} type="text" className="form-control" id="mobile"
                                                tabIndex={0} autoComplete={`off`}/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="date_of_birth">Date of birth</label>
                                        <div className="form-control">
                                            <Controller name="date_of_birth" control={control}
                                                        render={({field, fieldState}) => {
                                                            return (<DayPickerInput
                                                                placeholder={'dd/mm/yyyy'}
                                                                onDayChange={(args) => {
                                                                    if (args) {
                                                                        return field.onChange(MomentLocaleUtils.formatDate(args, 'DD/MM/YYYY'))
                                                                    }

                                                                    field.onChange("")
                                                                }}
                                                                format={` `}
                                                                formatDate={MomentLocaleUtils.formatDate}
                                                                parseDate={MomentLocaleUtils.parseDate}
                                                                {...field}
                                                            />)
                                                        }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Block>
                        <Block>
                            <div className="row push">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="one-profile-edit-name">Street Address</label>
                                        <input type="text" className="form-control" id="street_address"
                                               tabIndex={1} {...register('street_address')}
                                               autoComplete={`off`}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="one-profile-edit-name">Suburb</label>
                                        <input type="text" className="form-control" id="suburb"
                                               tabIndex={3} {...register('suburb')} autoComplete={`off`}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="one-profile-edit-name">Postcode</label>
                                        <input type="text" className="form-control" id="postcode"
                                               tabIndex={5} {...register('postcode')}
                                               autoComplete={`off`}/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    {/*<div className="form-group">*/}
                                    {/*    <label htmlFor="one-profile-edit-name">Street Address 2</label>*/}
                                    {/*    <input type="text" className="form-control"*/}
                                    {/*           id="street_address_2"*/}
                                    {/*           tabIndex={2} {...register('street_address_2')} autoComplete={`off`}/>*/}
                                    {/*</div>*/}
                                    <div className="form-group">
                                        <label htmlFor="one-profile-edit-name">State</label>
                                        <select className="form-control" id="state"
                                                tabIndex={4} {...register('state')}>
                                            <option>ACT</option>
                                            <option>NSW</option>
                                            <option>NT</option>
                                            <option>QLD</option>
                                            <option>SA</option>
                                            <option>TAS</option>
                                            <option>VIC</option>
                                            <option>WA</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="one-profile-edit-name">Country</label>
                                        <input type="text" className="form-control" id="country"
                                               name="country" readOnly={true}
                                               tabIndex={6} value="Australia" autoComplete={`off`}/>
                                    </div>
                                </div>
                            </div>
                        </Block>
                        <Block>
                            <div className="row push">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="default_bank_account_id">Default Bank Account</label>
                                        <select className="form-control" id="default_bank_account_id"
                                                disabled={activeBankAccounts?.length === 0}
                                                tabIndex={9} {...register('default_bank_account_id')}>
                                            {activeBankAccounts?.map((listOfBankAccount: BankAccount) => {
                                                return <option
                                                    value={listOfBankAccount.id}>{listOfBankAccount.nickname}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {activeBankAccounts && activeBankAccounts.length === 0 && <div className="row push">
                                <div className="col">
                                    <InfoNotification>
                                        <>You don't have any <b>active</b> bank accounts connected. <a
                                            href={`/bank_accounts/connect`}>Click here</a> to connect one now.
                                        </>
                                    </InfoNotification>
                                </div>
                            </div>}
                        </Block>

                        <div className="m-4">
                            <SaveButton>Save Profile</SaveButton>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </MainLayout>
}

const mapStateToProps = ({profile, bankAccount}: ApplicationState) => ({
    loading: profile.loading || bankAccount.loading,
    data: profile.data,
    toasts: profile.toasts,
    notifications: profile.notifications,
    listOfBankAccounts: bankAccount.listItems
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        updateProfile: (data: any) => {
            dispatch(updateRequest({data}))
        },
        fetchProfile: () => {
            dispatch(fetchRequest(""))
        },
        fetchListOfBankAccounts: (pageNumber: number, pageSize?: number) => {
            dispatch(fetchListRequest({pageNumber, pageSize}));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
