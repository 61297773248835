import React from "react";
import {SaveAttachmentDialog} from "../../Attachment";
import {useShowDialog} from "../../../hooks";

interface Props {
    entityId?: number
    entityType: 'payable_agreement_milestones' |  'payable_agreements'
}

/**
 * @param props
 * @constructor
 */
export const AddAttachmentButton: React.FunctionComponent<Props> = (props) => {
    const {showDialog, hide, show} = useShowDialog(false);

    return <>
        <SaveAttachmentDialog showAlert={showDialog}
                              entityType={props.entityType}
                              entityTypeId={props.entityId} onConfirm={hide} onCancel={hide}/>
        <button onClick={show} className={`btn btn-secondary`}>Add Attachment</button>
    </>
}

export default AddAttachmentButton;
