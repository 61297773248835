import React from "react";
import {useHistory, Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {PrimaryButton} from '../../components/Buttons';
import ServiceManager from "../../services/ServiceManager";
import WebsiteLayout from "../../components/Layouts/WebsiteLayout";
import User from "../../models/User";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {loginRequest} from "../../store/login/action";
import {connect} from 'react-redux';
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";
import GoogleButton from "../../components/Auth/Buttons/GoogleButton";
import FacebookButton from "../../components/Auth/Buttons/FacebookButton";

/**
 * @interface PropsFromState
 */
interface PropsFromState {
    readonly loading: boolean;
    readonly user?: User;
    readonly notifications: NotificationState;
    readonly isLoggedIn: boolean;
}

interface PropsFromDispatch {
    loginUser: (username: string, password: string) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

const LoginPage: React.FC<AllProps> = ({
                                           loading,
                                           notifications,
                                           loginUser
                                       }) => {

    const {register, handleSubmit, formState} = useForm(),
        history = useHistory(),
        onSubmit = (data: any, e: any) => {
            loginUser(data.username, data.password);
        };

    return <WebsiteLayout pageTitle={`Login`} isLoading={loading} hideHeader={true}>
        <div className="login-container">
            <h2>Sign In</h2>
            <div className="subheading">Please enter your details to access your account.</div>
            <NotificationsContainer notifications={notifications}/>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group google-login-container">
                    <GoogleButton type={'login'} isSSOEnabled={true} />
                </div>
                <div className="form-group">
                    <FacebookButton/>
                </div>
                <div className="form-group text-center">
                    <label htmlFor="" className="o text-muted">OR</label>
                </div>
                <div className={"form-group"}>
                    <div className="input-group">
                        <span className="input-group-text">
                            <i className="svg-icon email"></i>
                        </span>
                        <input type="text"
                               className={"form-control form-control-lg" + ServiceManager.formUtils.renderErrorClass(formState.errors.username)}
                               id="username" {...register('username', {required: true})}
                               placeholder="Your email"/>
                        {ServiceManager.formUtils.renderErrorBlock(formState.errors, 'username', 'Please enter username')}
                    </div>
                </div>
                <div className="form-group">
                    <div className="input-group">
                        <span className="input-group-text">
                            <i className="svg-icon lock"></i>
                        </span>
                        <input type="password"
                               className={"form-control form-control-lg" + ServiceManager.formUtils.renderErrorClass(formState.errors.password)}
                               id="password" {...register('password', {required: true})}
                               placeholder="Your password"/>
                        {ServiceManager.formUtils.renderErrorBlock(formState.errors, 'password', 'Please enter password')}
                    </div>
                </div>
                <div className="form-group forgot-password-container">
                    <Link to={`/forgot-password`}>Forgot Password?</Link>
                </div>
                <div className="form-group">
                    <PrimaryButton className="btn btn-block sign-in">SIGN IN</PrimaryButton>
                </div>
                <div className="form-group no-account">
                    Don't have an account? <Link className="font-size-sm" to="/register">Sign up now!</Link>
                </div>
            </form>
        </div>
    </WebsiteLayout>
}

const mapStateToProps = ({login}: ApplicationState) => ({
    loading: login.loading,
    notifications: login.notifications,
    isLoggedIn: login.isLoggedIn,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        loginUser: (username: string, password: string) => {
            dispatch(loginRequest(username, password))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
