import React from "react";
import Navigation from './Navigation'
import UserContext from "../Context/UserContext";
import HeaderUserMenu from './HeaderUserMenu';

/**
 * @constructor
 */
const Header: React.FunctionComponent<any> = () => {

    /**
     * @param toggleClass
     */
    const toggleSidebar = (toggleClass: string) => {
        const pageContainer = document.getElementById("page-container")

        if (pageContainer) {
            pageContainer.classList.toggle(toggleClass);
        }
    }
    // Todo: Sort this toggle out and remove it not needed.
    // const toggleMiniSidebar = () => toggleSidebar("sidebar-mini")
    const toggleMobileSideBar = () => toggleSidebar("sidebar-o-xs");

    /**
     * Handle the logo click on touch devices
     * @param e
     */
    const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        // If we're on a device thar doesn't support hover (eg phone or tablet)
        if (window.matchMedia( "(hover: none)" ).matches) {
            const sidebar = document.getElementById("sidebar")
            if (sidebar) {
                // Get the translate value which returns a string in the format:
                // matrix(1, 0, 0, 1, -180, 0)
                const transform = window
                    .getComputedStyle(sidebar)
                    .getPropertyValue('transform')

                // Split the string and get the 5th element which is the translateX value
                const parts = transform.split(',')
                const translateX = parseInt(parts[4]);

                // If translateX is less than 0 then the sidebar isn't expanded. Let it expand and prevent the click
                // on the logo link. If it is expanded then let the click go through
                if (translateX < 0) {
                    e.preventDefault();
                }
            }
        }
    }

    return <UserContext.Consumer>
        {(user: any) => (<>
            {/* Logo */}
            <a className="font-w600 text-dual logo-container" href={`/dashboard`} onClick={(e) => handleLogoClick(e)}>
                <img src="/logo-t.svg" alt="paysecure" className="logo"/>
            </a>
            <nav id="sidebar" aria-label="Main Navigation">
                {/* Side Header */}
                <div className="content-header bg-white-5">
                    {/* END Logo */}
                    <a className="d-lg-none btn btn-sm btn-alt-secondary ms-1"
                       id="btn-sidebar-close"
                       onClick={() => toggleMobileSideBar()}>
                        <i className="fa fa-fw fa-times" />
                    </a>
                </div>
                {/* END Side Header */}

                {/* Sidebar Scrolling */}
                <div className="js-sidebar-scroll">
                    {/* Side Navigation */}
                    <div className="content-side">
                        <Navigation/>
                    </div>
                    {/* END Side Navigation */}
                </div>
                {/* END Sidebar Scrolling */}
            </nav>
            <header id="page-header">
                {/* Header Content */}
                <div className="content-header">
                    {/* Left Section */}
                    <div className="d-flex align-items-center">
                        {/* Toggle Sidebar */}
                        {/* Layout API, functionality initialized in Template._uiApiLayout()*/}
                        <button type="button" id="btn-toggle-sidebar" onClick={() => {
                            toggleMobileSideBar()
                        }} className="btn btn-sm btn-dual mr-2 d-lg-none">
                            <i className="fa fa-fw fa-bars"/>
                        </button>
                        {/* END Toggle Sidebar */}

                        {/* Toggle Mini Sidebar */}
                        {/* Layout API, functionality initialized in Template._uiApiLayout()*/}
                        {/*Preserving this toggle may be for the future*/}
                        {/*<button type="button" */}
                        {/*        onClick={() => toggleMiniSidebar()} */}
                        {/*        className="btn btn-sm btn-dual no-border mr-2 d-none d-lg-inline-block" data-toggle="layout"*/}
                        {/*        data-action="sidebar_mini_toggle">*/}
                        {/*    <i className="fa fa-fw fa-ellipsis-v"/>*/}
                        {/*</button>*/}
                        {/* END Toggle Mini Sidebar */}
                    </div>
                    {/* END Left Section */}

                    {/* Right Section */}
                    <div className="d-flex align-items-center">
                        <HeaderUserMenu user={user}/>{` `}
                        {/* Toggle Side Overlay */}
                    </div>
                    {/* END Right Section */}
                </div>
                {/* END Header Content */}
            </header>
        </>)}
    </UserContext.Consumer>
}

export default Header
