import React, {useEffect, useState} from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import {Redirect, useParams} from "react-router-dom";
import {PayableAgreement} from "../../models/PayableAgreement";
import Milestone from "../../models/Milestone";
import PayableAgreementResponseButton from "../../components/PayableAgreement/Buttons/PayableAgreementResponseButtons";
import {WarningNotification} from "../../components/Notifications";
import {
    fetchRequest,
    acceptPayableAgreementRequest,
    rejectPayableAgreementRequest,
    approvePaymentRequest,
    approveDepositRequest,
    cancelMilestoneRequest,
    sendPayableAgreementApprovalTFACode
} from "../../store/incomingPayableAgreement/action";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {ToastProp} from "../../components/Notifications/Toasts";
import Enums from '../../enums'
import ListOfMilestones from "../../components/Milestones/ListOfMilestones";
import MilestoneCustomerActions from "../../components/Milestones/MilestoneCustomerActions";
import Block from "../../components/Blocks/Block";
import StatusPill from "../../components/PayableAgreement/StatusPill";
import {UserCard} from "../../components/Cards";
import PayUnsecurePayableAgreement from "../../components/PayableAgreement/PayUnsecurePayableAgreement";
import Statuses from '../../enums'
import NotificationsContainer from "../../components/Notifications/Containers";
import {NotificationState} from "../../store/state";
import Timestamp from "../../components/PayableAgreement/Timestamp";

// Defining Props
interface PropsFromState {
    loading: boolean;
    data?: PayableAgreement;
    notifications: NotificationState;
    toasts?: ToastProp[];
}

// Defining PROPS.
interface PropsFromDispatch {
    fetchIncomingAgreement: (id: string) => any;
    acceptPayableAgreement: (id: string, receiverBusinessEntityId: number) => any;
    rejectPayableAgreement: (id: string, reason: string) => any;
    approveMilestonePaymentRequest: (milestoneId: string, payableAgreementId: string | undefined, tfaVerificationCode: string) => any;
    approveReleaseOfDepositRequest: (milestoneId: string) => any;
    requestCancelMilestone: (milestoneId: string, payableAgreementId: string, reason: string) => void;
    sendPayableAgreementApprovalTFACode: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

interface RespondAgreementRouterParams {
    id: string;
}

const IncomingPayableAgreementResponsePage: React.FunctionComponent<AllProps> = ({
                                                                                     loading,
                                                                                     data,
                                                                                     notifications,
                                                                                     toasts,
                                                                                     fetchIncomingAgreement,
                                                                                     acceptPayableAgreement,
                                                                                     rejectPayableAgreement,
                                                                                 }) => {

    // Retrieve the ID from the route to fetch a payable agreement.
    const {id} = useParams<RespondAgreementRouterParams>();
    const [isSecured, setIsSecured] = useState<boolean>(true);
    useEffect(() => {
        fetchIncomingAgreement(id);
    }, [id]);
    useEffect(() => {
        if (data) {
            setIsSecured(data?.type !== 'unsecured_agreement')
        }
    }, [data])

    // if (loading === false && data && data.id && data.product_service_provided == 'product') {
    //     return <Redirect to={`/product/incoming_agreements/${data.id}/view`}/>
    // }

    return <MainLayout pageTitle={`Received Agreement`} isLoading={loading} toasts={toasts}>
        <>
            {data && (data?.status === Enums.PayableAgreement.IN_PROGRESS || data?.status == Enums.PayableAgreement.ACCEPTED) && data?.can_approve_deposit === false && data?.reason_cant_approve_deposit ?
                <div className="content">
                    <WarningNotification>
                        <div dangerouslySetInnerHTML={{__html: data?.reason_cant_approve_deposit}}></div>
                    </WarningNotification>
                </div> : null}

            <div className="content content-full">
                <NotificationsContainer notifications={notifications}/>

                <div className="row push">
                    <div className="col-md-3 rhc-container">
                        {data?.user && <>
                            <UserCard user={data?.user}/>
                        </>}
                        <Block>
                            <div className="form-group agreement-status">
                                <label className="label">Agreement Status</label>
                                <div className="agreement-status">
                                    <StatusPill status={data?.status} userType={`client`}/>
                                </div>
                            </div>

                            {data?.accepted_on_display && <div className="form-group agreement-status">
                                <label className="label">Accepted on</label>
                                <div className="agreement-status">
                                    {data?.accepted_on_display}
                                </div>
                            </div>}

                            {!data?.accepted_on_display && data?.sent_on_display &&
                                <div className="form-group agreement-status">
                                    <label className="label">Last received on</label>
                                    <div className="agreement-status">
                                        {data?.sent_on_display}
                                    </div>
                                </div>}

                            <table className={`table total-table`}>
                                <tr>
                                    <td className={`label`}>Total amount</td>
                                    <td className={`amount`}><em>{data?.total_amount_display}</em></td>
                                </tr>
                            </table>
                        </Block>
                    </div>
                    <div className="col-md-9 col-xs-12">
                        <div className="row">
                            <div className="col">
                                <Block withHeaderBorder={true}>
                                    <h2 className="agreement-title pb-3">{data?.title}</h2>
                                </Block>
                            </div>
                        </div>
                        <div className="milestone-row heading">
                            <div className="step-number">#</div>
                            <div className="title">Title</div>
                            <div className="description">Description</div>
                            <div className="due text-right">Due by</div>
                            <div className="amount text-right">Amount</div>
                        </div>
                        {data && data?.status && <ListOfMilestones payableAgreement={data} hideFees={true}
                                                                   milestones={data.milestones}
                                                                   payableAgreementStatus={data?.status}
                                                                   onAddingNewMilestone={(m: Milestone) => {
                                                                   }} canAddMoreMilestones={false}>
                            <MilestoneCustomerActions/>
                        </ListOfMilestones>}

                        <div className="row">
                            <div className="col text-right agreement-actions">
                                {data?.status && data?.status === "sent" ?
                                    <PayableAgreementResponseButton
                                        amount={data?.total_amount}
                                        agreement={data}
                                        handleRejectingAgreement={(reason: string) => {
                                            if (data && data.id) {
                                                rejectPayableAgreement(data.id.toString(), reason)
                                            }
                                        }}
                                        handleAcceptingAgreement={(receiverBusinessEntityId: number) => {
                                            if (data && data.id) {
                                                acceptPayableAgreement(data.id.toString(), receiverBusinessEntityId)
                                            }
                                        }}/> : null}

                                {!isSecured && data && data.id &&
                                (data.status === Statuses.PayableAgreement.ACCEPTED
                                    || data.status === Statuses.PayableAgreement.IN_PROGRESS
                                    || data.status === Statuses.PayableAgreement.RELEASING_MONEY
                                    || data.status == Statuses.PayableAgreement.REQUESTING_MONEY) &&
                                (data?.status !== Statuses.PayableAgreement.PENDING &&
                                    data?.status !== Statuses.PayableAgreement.CLOSED) ?
                                    <PayUnsecurePayableAgreement canMakePayment={data?.can_show_pay_now === true}
                                                                 payableAgreementId={data.id}/> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    </MainLayout>
}

const mapStateToProps = ({incomingPayableAgreement, bankAccount, attachment}: ApplicationState) => ({
    loading: incomingPayableAgreement.loading || bankAccount.loading || attachment.loading,
    notifications: incomingPayableAgreement.notifications,
    data: incomingPayableAgreement.data,
    toasts: incomingPayableAgreement?.toasts,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchIncomingAgreement: (id: string) => {
            dispatch(fetchRequest(id))
        },
        acceptPayableAgreement: (id: string, receiverBusinessEntityId: number) => {
            dispatch(acceptPayableAgreementRequest(id, receiverBusinessEntityId));
        },
        rejectPayableAgreement: (id: string, reason: string) => {
            dispatch(rejectPayableAgreementRequest({id, reason}));
        },
        approveMilestonePaymentRequest: (milestoneId: string, payableAgreementId: string | undefined, tfaVerificationCode: string) => {
            dispatch(approvePaymentRequest(milestoneId, payableAgreementId ?? "", tfaVerificationCode));
        },
        requestCancelMilestone: (milestoneId: string, payableAgreementId: string, reason: string) => {
            dispatch(cancelMilestoneRequest(milestoneId, payableAgreementId, reason));
        },
        approveReleaseOfDepositRequest: (milestoneId: string) => {
            dispatch(approveDepositRequest(milestoneId));
        },
        sendPayableAgreementApprovalTFACode: () => {
            dispatch(sendPayableAgreementApprovalTFACode())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomingPayableAgreementResponsePage);
