import {AxiosError} from "axios";
import {all, call, put, takeEvery} from "redux-saga/effects";
import {handleError} from "../saga";
import ServiceManager from "../../services/ServiceManager";
import {ApiResponse, SuccessOK} from "../../models/Api/response";
import {fetchError, fetchSuccess} from "./action";
import {RegisterActionsTypes} from "./types";
import {Events, makeFactory} from "../events";
import {EntityTypes} from "../../models/types";

function* handleFetch(action: any) {
    try {
        const response: ApiResponse<SuccessOK> = yield call([
            ServiceManager.authManager,
            ServiceManager.authManager.registerAccount
        ], action.payload)

        if (response.data.success === 'Ok') {
            yield put(fetchSuccess())

            // Tracking events.
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.USER.toString(), Events.USER_REGISTERED, 0));
        } else {
            yield put(fetchError([
                'Oops! Something went wrong.'
            ]))
        }
    } catch (err: any) {
        handleError(err)
        const axiosError: AxiosError = err
        if (axiosError && axiosError.isAxiosError) {
            const errorsReturned = axiosError.response?.data.errors ?? {}
            const errors: string[] = [];
            if (errorsReturned && Object.keys(errorsReturned).length > 0) {
                Object.keys(errorsReturned).forEach((fieldKey) => {
                    if (errorsReturned[fieldKey] && errorsReturned[fieldKey].length > 0) {
                        errors.push(...axiosError.response?.data.errors[fieldKey])
                    }
                })
            }

            yield put(fetchError(errors))
        } else {
            yield put(fetchError([
                'Oops! Something went wrong.'
            ]))
        }
    }
}

function* watchRequests() {
    yield takeEvery(RegisterActionsTypes.FETCH_REQUEST, handleFetch)
}

function* registrationSaga() {
    yield all([
        watchRequests()
    ])
}

export default registrationSaga;
