import {Reducer} from "redux";
import {BankAccountActionTypes, BankAccountState} from "./types";
import {ToastActionTypes} from "../types";

export const initialState: BankAccountState = {
    data: undefined,
    loading: false,
    notifications: {
        errors: [],
        alerts: [],
        infos: [],
        warnings: [],
        success: []
    },

    listItems: [],
    paginationMeta: undefined,
    toasts: [],

    connectionUrl: undefined
}

const reducer: Reducer<BankAccountState> = (state = initialState, action) => {
    switch (action.type) {
        case BankAccountActionTypes.SAVE_REQUEST:
        case BankAccountActionTypes.UPDATE_REQUEST:
        case BankAccountActionTypes.FETCH_LIST_REQUEST:
        case BankAccountActionTypes.FETCH_REQUEST:
        case BankAccountActionTypes.CREATE_REQUEST:
        case BankAccountActionTypes.CONNECT_REQUEST:
        case BankAccountActionTypes.VERIFY_BANK_ACCOUNT_REQUEST:
        case BankAccountActionTypes.DELETE_REQUEST:
            return {
                ...state, loading: true, toasts: [], notifications: {
                    errors: [],
                    alerts: [],
                    infos: [],
                    warnings: [],
                    success: []
                }
            }

        case BankAccountActionTypes.CONNECT_SUCCESS:
            return {...state, toasts: [], connectionUrl: action.payload.connectionUrl}

        case ToastActionTypes.RESET_TOASTS:
            return {...state, toasts: []}

        case ToastActionTypes.ADD_TOAST:
            const existingToasts = state.toasts ?? [];
            return {...state, toasts: [...existingToasts, action.payload]}

        case BankAccountActionTypes.FETCH_LIST_SUCCESS:
            return {
                ...state,
                listItems: action.payload.listOfBankAccounts,
                loading: false,
                paginationMeta: action.payload.paginationMeta
            }

        case BankAccountActionTypes.FETCH_SUCCESS:
            return {...state, data: action.payload.data, loading: false}

        case BankAccountActionTypes.FETCH_ERROR:
            return {
                ...state, loading: false, notifications: {
                    ...state.notifications,
                    errors: action.payload
                }
            }

        default:
            return state;
    }
}

export {reducer as BankAccountReducer}
