import User from "../../models/User";
import BaseState from "../state";

export enum ProfileActionTypes {
    FETCH_REQUEST = "@profile/FETCH_REQUEST",
    FETCH_SUCCESS = "@profile/FETCH_SUCCESS",
    FETCH_ERROR = "@profile/FETCH_ERROR",

    UPDATE_REQUEST = "@profile/UPDATE_REQUEST",
}

export interface ProfileState extends BaseState {
    readonly data?: User;
}