import React from "react";
import {useFormContext} from "react-hook-form";
import {BaseProps} from "./common";
import {TextInput} from "./Input";

export const AgreementTitle = React.forwardRef<typeof TextInput, BaseProps>((props, ref) => {
    const formContext = useFormContext();
    // return <div data-testid={'agreement_title'}
    //             className={classnames("contract-title form-group", {"is-invalid": methods.formState.errors && methods.formState.errors.title})}>
    //     <TextField variant={`filled`} {...methods.register('title', {
    //         required: true,
    //         minLength: 3,
    //         maxLength: 50
    //     })} placeholder={props.placeholder ?? 'E.g. Selling a golf club, car or watch.'}
    //                label={props.placeholder ?? props.label}/>
    //     {methods.formState.errors && methods.formState.errors.title &&
    //         <div className="text-danger">{methods.formState.errors.title.message}</div>}
    // </div>

    return <TextInput data-testid={'agreement_title'}
                      {...formContext.register('agreement_title', {
                          required: true
                      })}
                      placeholder={props.placeholder ?? props.label}
                      label={props.label} />
})

export default AgreementTitle;
