import {PaginationMeta} from "../types";
import BaseState from "../state";
import {ThirdPartyConnection} from "../../models/ThirdPartyConnection";
import {ThirdPartyDocument} from "../../models/ThirdPartyDocument";

export enum ThirdPartyConnectionActionTypes {
    FETCH_REQUEST = "@thirdPartyConnection/FETCH_REQUEST",
    FETCH_SUCCESS = "@thirdPartyConnection/FETCH_SUCCESS",
    FETCH_ERROR = "@thirdPartyConnection/FETCH_ERROR",

    FETCH_INVOICE_REQUEST = "@thirdPartyConnection/FETCH_INVOICE_REQUEST",
    FETCH_INVOICE_SUCCESS = "@thirdPartyConnection/FETCH_INVOICE_SUCCESS",

    CONVERT_DOCUMENT_TO_PAYABLE_AGREEMENT_REQUEST = "@thirdPartyConnection/CONVERT_DOCUMENT_TO_PAYABLE_AGREEMENT_REQUEST",
    CONVERT_DOCUMENT_TO_PAYABLE_AGREEMENT_SUCCESS = "@thirdPartyConnection/CONVERT_DOCUMENT_TO_PAYABLE_AGREEMENT_SUCCESS",

    UPDATE_REQUEST = "@thirdPartyConnection/UPDATE_REQUEST",
    CREATE_REQUEST = "@thirdPartyConnection/CREATE_REQUEST",

    FETCH_LIST_REQUEST = "@thirdPartyConnection/FETCH_LIST_REQUEST",
    FETCH_LIST_SUCCESS = "@thirdPartyConnection/FETCH_LIST_SUCCESS",

    FETCH_ACCOUNTS_LIST_REQUEST = "@thirdPartyConnection/FETCH_ACCOUNTS_LIST_REQUEST",
    FETCH_ACCOUNTS_LIST_SUCCESS = "@thirdPartyConnection/FETCH_ACCOUNTS_LIST_SUCCESS",
}

export interface ThirdPartyConnectionState extends BaseState {
    readonly data?: ThirdPartyConnection;
    readonly document?: ThirdPartyDocument;
    readonly listItems?: ThirdPartyConnection[];
    readonly accounts?: any[];
    readonly paginationMeta?: PaginationMeta;
}
