import React, {useEffect} from "react";
import {Link} from 'react-router-dom';
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";

import {LANG} from "../../labels"
import {PaginationMeta} from "../../store/types";
import {ApplicationState} from "../../store";

import UserContext from "../../components/Context/UserContext";
import {fetchListRequest} from "../../store/thirdPartyConnection/action";

import {ToastProp} from "../../components/Notifications/Toasts";
import MainLayout from "../../components/Layouts/MainLayout";
import Block from "../../components/Blocks/Block";
import RecordListTable from "../../components/Table/RecordListTable";
import PageTitle from "../../components/PageTitle";
import {WarningNotification} from "../../components/Notifications"

import {ThirdPartyConnection} from "../../models/ThirdPartyConnection";
import ListPageAddLink from "../../components/Buttons/ListPageAddLink";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";

interface PropsFromState {
    loading: boolean;
    notifications: NotificationState;
    listItems?: ThirdPartyConnection[];
    paginationMeta?: PaginationMeta;
    toasts?: ToastProp[];
}

interface PropsFromDispatch {
    fetchListOfThirdPartyIntegrations: (pageNumber?: number, pageSize?: number) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

const ThirdPartyIntegrationPage: React.FunctionComponent<AllProps> = (
    {
        loading,
        listItems,
        notifications,
        paginationMeta,
        fetchListOfThirdPartyIntegrations,
        toasts
    }
) => {

    useEffect(() => {
        fetchListOfThirdPartyIntegrations()
    }, []);

    const columns = [
        {
            headerTitle: 'ID',
            accessor: "id"
        },
        {
            headerTitle: 'Application name',
            accessor: "third_party_provider"
        },
        {
            headerTitle: `Organisation name`,
            accessor: "organisation_name"
        },
        {
            headerTitle: `Status`,
            accessor: (row: any) => {
                return (row.connection_active ? 'Active' : 'Inactive').toUpperCase();
            }
        },
        {
            headerTitle: `Connected on`,
            accessor: "connected_on"
        }, {
            headerTitle: 'View / Respond',
            accessor: (row: ThirdPartyConnection) => {
                return <Link className={`btn btn-primary btn-sm`} to={`/third_party_integration/${row.id}/view`}>
                    <i className="fa fa-eye fa-sm" /> View Settings</Link>
            }
        }
    ];
    const noRecordLabel = <WarningNotification
        title="No records found">{LANG.ThirdPartyIntegrationPage.DISPLAY_NAME_NOTE}</WarningNotification>

    return <UserContext.Consumer>
        {(user: any) => (<MainLayout pageTitle={`Received Agreements`} isLoading={loading} toasts={toasts}>
            {/* Hero */}
            <PageTitle title={LANG.ThirdPartyIntegrationPage.DISPLAY_NAME}
                       toolTip={LANG.ThirdPartyIntegrationPage.DISPLAY_NAME_TOOLTIP}>
                <ListPageAddLink desktopBtnLabel={`Connect application`}
                                 to={`/third_party_integration/connect`} />
            </PageTitle>
            {/* END Hero */}

            <NotificationsContainer notifications={notifications} />

            <div className="content">
                <Block noPadding={true}>
                    <RecordListTable<ThirdPartyConnection>
                        noRecordsLabel={noRecordLabel}
                        loadMoreResults={(pageNumber: number) => {
                            fetchListOfThirdPartyIntegrations(pageNumber)
                        }} rowClassColumn={`status`} paginationMeta={paginationMeta}
                        columns={columns} data={listItems}/>
                </Block>
            </div>
        </MainLayout>)}
    </UserContext.Consumer>
}


const mapStateToProps = ({thirdPartyConnection}: ApplicationState) => ({
    loading: thirdPartyConnection.loading,
    notifications: thirdPartyConnection.notifications,
    listItems: thirdPartyConnection.listItems,
    paginationMeta: thirdPartyConnection.paginationMeta,
    toasts: thirdPartyConnection.toasts,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchListOfThirdPartyIntegrations: (pageNumber?: number, pageSize?: number) => {
            dispatch(fetchListRequest({pageNumber, pageSize}));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyIntegrationPage);
