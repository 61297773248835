import React, {useEffect} from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import Block from "../../components/Blocks/Block";
import RecordListTable from "../../components/Table/RecordListTable";
import PageTitle from "../../components/PageTitle";
import {Link} from 'react-router-dom';
import {PayableAgreement} from "../../models/PayableAgreement";
import UserContext from "../../components/Context/UserContext";
import {PaginationMeta} from "../../store/types";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {fetchListRequest} from "../../store/incomingPayableAgreement/action";
import {connect} from "react-redux";
import {ToastProp} from "../../components/Notifications/Toasts";
import {LANG} from "../../labels"
import {NotificationContainer, WarningNotification} from "../../components/Notifications"
import {Breadcrumbs} from "../../components/Breadcrumbs";
import StatusPill from "../../components/PayableAgreement/StatusPill";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";

interface PropsFromState {
    loading: boolean;
    listItems?: PayableAgreement[];
    paginationMeta?: PaginationMeta;
    notifications: NotificationState;
    toasts?: ToastProp[];
}

interface PropsFromDispatch {
    fetchListOfIncomingPayableAgreement: (pageNumber?: number, pageSize?: number) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

const IncomingAgreementListPage: React.FunctionComponent<AllProps> = (
    {
        loading,
        listItems,
        notifications,
        paginationMeta,
        fetchListOfIncomingPayableAgreement,
        toasts
    }
) => {

    useEffect(() => {
        fetchListOfIncomingPayableAgreement()
    }, [])

    const columns = [
        {
            headerTitle: '',
            accessor: () => <div className="d-flex justify-content-center">
                <span className="fa fa-file-alt fa-2x color-brand-primary" />
            </div>
        }, {
            headerTitle: 'Sender Name',
            accessor: (row: PayableAgreement) => {
                if (row?.sender_business_entity_id && row?.sender_business_entity) {
                    return <div>
                        {row?.user?.name}<br/>
                        <b>{row?.sender_business_entity?.name}</b>
                    </div>
                } else if (row?.recipient?.first_name || row?.recipient?.last_name) {
                    return `${row?.recipient?.first_name} ${row?.recipient?.last_name}`
                }

                return '';
            }
        }, {
            headerTitle: "Sender Email",
            accessor: "sender_email"
        }, {
            headerTitle: 'Date',
            accessor: (row: PayableAgreement) => {
                let data = row && row?.sent_on_display ? `Received on ` + row.sent_on_display : 'Not Sent';

                if (row.status === 'accepted') {
                    data = row && row?.accepted_on_display ? `Accepted ` + row.accepted_on_display : 'Not Accepted';
                }

                return data;
            }
        }, {
            headerTitle: 'Description',
            accessor: (row: PayableAgreement) => {
                return row.user?.name + ` is ` + row.action + ` you a ` + row.product_service_provided
            }
        }, {
            headerTitle: "Status",
            accessor: (row: PayableAgreement) => {
                let displayableStatus = row.status;
                if (displayableStatus.toLowerCase() === 'sent') {
                    displayableStatus = 'received';
                }

                return <StatusPill status={displayableStatus} userType={`client`} />
            }
        }, {
            headerTitle: 'View / Respond',
            accessor: (row: PayableAgreement) => {
                return <Link className={`btn btn-primary btn-sm`} to={`/incoming_agreements/${row.id}/view`}> <i
                    className="fa fa-eye fa-sm"></i> View</Link>
            }
        }
    ];
    const noRecordLabel = <WarningNotification
        title="No records found">{LANG.IncomingAgreementListPage.DISPLAY_NAME_NOTE}</WarningNotification>

    return <UserContext.Consumer>
        {(user: any) => (<MainLayout pageTitle={`Received Agreements`} isLoading={loading} toasts={toasts}>
            {/* Hero */}
            <PageTitle title={LANG.IncomingAgreementListPage.DISPLAY_NAME}
                       toolTip={LANG.IncomingAgreementListPage.DISPLAY_NAME_TOOLTIP}
                       subTitle={<>
                           <Breadcrumbs links={[
                               {
                                   name: LANG.IncomingAgreementListPage.DISPLAY_NAME,
                                   href: "/agreements"
                               }
                           ]}/>
                       </>}>
                <Link to={`/agreements/new`} className="btn btn-alt-success"><i
                    className="fa fa-plus"/> Create Agreement</Link>
            </PageTitle>
            {/* END Hero */}

            <NotificationsContainer notifications={notifications} />

            <div className="content">
                <Block noPadding={true}>
                    <RecordListTable<PayableAgreement> noRecordsLabel={noRecordLabel}
                                                       loadMoreResults={(pageNumber: number) => {
                                                           fetchListOfIncomingPayableAgreement(pageNumber)
                                                       }} rowClassColumn={`status`} paginationMeta={paginationMeta}
                                                       columns={columns} data={listItems}/>
                </Block>
            </div>
        </MainLayout>)}
    </UserContext.Consumer>
}


const mapStateToProps = ({incomingPayableAgreement}: ApplicationState) => ({
    loading: incomingPayableAgreement.loading,
    notifications: incomingPayableAgreement.notifications,
    listItems: incomingPayableAgreement.listItems,
    paginationMeta: incomingPayableAgreement.paginationMeta,
    toasts: incomingPayableAgreement.toasts,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchListOfIncomingPayableAgreement: (pageNumber?: number, pageSize?: number) => {
            dispatch(fetchListRequest({pageNumber, pageSize}));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingAgreementListPage);
