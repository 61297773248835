class StorageService {
    private shortStorage;
    private longStorage;
    static KEYS = {
        oAuthToken: 'oAuthToken',
        User: 'User',
        UserId: 'UserId',
        RedirectUrlAfterLoggingIn: 'RedirectUrlAfterLoggingIn',
    }

    constructor() {
        this.longStorage = window.localStorage;
        this.shortStorage = window.sessionStorage;
    }

    public registerKeys(keys: object) {
        StorageService.KEYS = Object.assign({}, StorageService.KEYS, keys);
    }

    /**
     * @param storeForALongTime
     * @returns {Storage}
     */
    public getStorage(storeForALongTime: boolean) {
        return storeForALongTime ? this.longStorage : this.shortStorage;
    }

    public clear(storeForALongTime: boolean) {
        return this.getStorage(storeForALongTime).clear();
    }

    /**
     * This function resets the logged in state for the User.
     */
    public clearAll(): Promise<void> {
        this.removeItem(StorageService.KEYS.oAuthToken, true);
        this.removeItem(StorageService.KEYS.UserId, true);
        this.removeItem(StorageService.KEYS.User, false);

        return Promise.resolve();
    }

    /**
     * @param key
     * @param storeForALongTime
     */
    public removeItem(key: string, storeForALongTime: boolean) {
        return (this.getStorage(storeForALongTime)).removeItem(key);
    }

    /**
     * @param redirectUrlAfterLoggingIn
     */
    public setRedirectUrlAfterLoggingIn(redirectUrlAfterLoggingIn: any) {
        return (this.getStorage(true)).setItem(StorageService.KEYS.RedirectUrlAfterLoggingIn, redirectUrlAfterLoggingIn);
    }

    /**
     * @returns {any}
     */
    public getRedirectUrlAfterLoggingIn() {
        return (this.getStorage(true)).getItem(StorageService.KEYS.RedirectUrlAfterLoggingIn);
    }

    /**
     * @param key
     * @param value
     * @param storeForALongTime
     */
    public setItem(key: string, value: any, storeForALongTime: boolean) {
        return (this.getStorage(storeForALongTime)).setItem(key, JSON.stringify(value));
    }

    /**
     * @param key
     * @param storeForALongTime
     */
    public getItem(key: string, storeForALongTime: boolean) {
        // @ts-ignore
        return JSON.parse(this.getStorage(storeForALongTime).getItem(key))
    }

    /**
     * @param user
     */
    public setUser(user: any) {
        this.setItem(StorageService.KEYS.UserId, user.id, true);
        return this.setItem(StorageService.KEYS.User, user, false);
    }

    /**
     *
     * @returns {*}
     */
    public getUser() {
        return this.getItem(StorageService.KEYS.User, false);
    }

    /**
     *
     * @returns {*}
     */
    public getUserId() {
        return this.getItem(StorageService.KEYS.UserId, true);
    }

    /**
     *
     * @param token
     */
    public setOAuthToken(token: any) {
        return this.setItem(StorageService.KEYS.oAuthToken, token, true);
    }


    public getOAuthToken() {
        return this.getItem(StorageService.KEYS.oAuthToken, true);
    }

    public getAccessToken() {
        const oAuthToken = this.getItem(StorageService.KEYS.oAuthToken, true);

        return ((oAuthToken !== null && oAuthToken !== undefined)
            && (oAuthToken.access_token !== null && oAuthToken.access_token !== undefined)) ? oAuthToken.access_token : null;
    }
}

export default StorageService;
