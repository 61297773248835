import React, {useEffect, useState, Fragment} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {SmartSelectOptionType, AsyncAutocompleteProps} from "./types";
import {BaseEntityInterface} from "../../models/BaseModel";

/**
 * @constructor
 */
export default function AsyncAutocomplete<T extends BaseEntityInterface>({
                                                                             entityType,
                                                                             resourceManagerFactory,
                                                                             inputLabel,
                                                                             onChange,
                                                                             children,
                                                                         }: AsyncAutocompleteProps) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<SmartSelectOptionType[]>([]);
    const loading = open && options.length === 0;
    const [hasResults, setHasResults] = useState<boolean>(true);

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const response = await resourceManagerFactory.findAll(100, 1, {});
            const options = await response.data.data;

            if (active) {
                const optionItems = options.map((item: { id: any; display_label: any; }) => ({
                    id: item.id,
                    display_label: item.display_label ?? `No Name (ID: ${item.id})`,
                    entity: item
                }))
                if (optionItems && optionItems.length > 0) {
                    setOptions(optionItems as SmartSelectOptionType[]);
                } else {
                    setHasResults(false);
                }
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return hasResults ? <Autocomplete
        id={`${entityType}-async-autocomplete`} className={`async-autocomplete`}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(event, val) => {
            if (onChange) {
                onChange(event, val)
            }
        }}
        getOptionSelected={(option, value) => option.display_label === value.display_label}
        getOptionLabel={(option) => option.display_label}
        options={options} loading={loading} renderInput={(params) => (
        <TextField{...params} label={`Choose ${inputLabel}`} variant="outlined"
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <Fragment>
                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                        {params.InputProps.endAdornment}
                    </Fragment>
                ),
            }}
        />
    )}
    /> : (React.isValidElement(children) ? children : <p>
        <em>No {inputLabel} found</em>
    </p>);

}
