import React from "react";
import FormDialog from "../../Dialogs/FormDialog";
import EditRecipientForm from "../EditRecipientForm";
import Recipient from "../../../models/Recipient";

interface Props {
    showDialog: boolean
    recipient: Recipient
    onCancel: () => void
    onSaved?: (recipient: Recipient) => void
}

const EditRecipientDialog: React.FC<Props> = ({showDialog, onCancel, recipient, onSaved}) => {
    return <FormDialog showDialog={showDialog} title={`Recipient`}>
        <EditRecipientForm recipient={recipient} onCancel={onCancel} onSaved={onSaved}/>
    </FormDialog>
}

export default EditRecipientDialog
