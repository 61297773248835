import React from "react";
import {isDesktop} from "react-device-detect";
import {Link} from "react-router-dom";

interface Props {
    desktopBtnLabel: string
    to: string
}

const ListPageAddLink: React.FC<Props> = ({
                                                desktopBtnLabel, to
                                            }) => {
    if (isDesktop) {
        return <Link to={to} className="btn btn-alt-success">{desktopBtnLabel}</Link>
    }

    return <Link to={to} className={`btn btn-alt-success icon-btn btn-float`}>
        <i className="svg-icon add-icon"/>
    </Link>
}


export default ListPageAddLink
