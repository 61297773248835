import React from "react";
import {PaymentRejectedMilestone} from "../../../models/Milestone";
import {canCancel, canRequestPayment} from "./utils";
import RequestPaymentButton from "../Buttons/RequestPaymentButton";
import {cancelMilestoneRequest, requestPaymentRequest} from "../../../store/payableAgreement/action";
import CancelMilestoneButton from "../Buttons/CancelMilestoneButton";
import {useDispatch} from "react-redux";

interface Props {
    milestone: PaymentRejectedMilestone
    payableAgreementId: number
}

/**
 * @param milestone
 * @param payableAgreementId
 *
 * @constructor
 */
export const PaymentRejectedMilestoneActions: React.FC<Props> = ({milestone, payableAgreementId}: Props) => {
    const dispatch = useDispatch();

    return canRequestPayment(milestone) ? <>
        <RequestPaymentButton milestoneId={milestone.id} isReRequesting={true}
                              amount={Number(milestone?.total_amount)} handleRequestPayment={() => {
            dispatch(requestPaymentRequest(milestone.id))
        }} canRequestPayment={canRequestPayment(milestone)}/>
        {canCancel(milestone) && <CancelMilestoneButton handleCancelMilestone={(reason: string) => {
            dispatch(cancelMilestoneRequest(milestone.id, payableAgreementId, reason))
        }}/>}
    </> : null;
}

export default PaymentRejectedMilestoneActions;
