import React from "react";

type Props = {
    typeOfAgreement: 'Sent' | 'Received'
    id?: number
    title?: string
}

const Timestamp = (props: Props) => {
    const {id, title, typeOfAgreement} = props;
    return <span
        className="timestamp">{typeOfAgreement} agreement / ({id && id > 0 ? `ID: ${id}` : `New`}) - {title}</span>
}

export default Timestamp
