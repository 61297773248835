import React, {ChangeEvent} from "react";
import {HtmlSelectElementProp} from "../../types";
import classnames from "classnames";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";

interface InspectionPeriodProps extends HtmlSelectElementProp {
    children?: React.ReactNode;
    showCustomOption: boolean;
}

/**
 * @param onChange
 * @param value
 * @param showCustomOption
 * @param children
 *
 * @constructor
 */
const InspectionPeriod: React.FunctionComponent<InspectionPeriodProps> = ({onChange, value, showCustomOption, children}) => {
    return <div className="row push">
        <div className="col-lg-4">
            <label htmlFor="sltTypesOfAgreement">Inspection Period <span className="text-danger">*</span></label>
            <p className="font-size-sm text-muted">
                The Inspection Period is the specified length of time agreed upon by both parties in which the
                customer may examine the merchandise or services after receipt. The customer has the option to “Accept”
                or “Reject” the goods within this period.
            </p>
        </div>
        <div className="col-lg-8">
            <label htmlFor="sltInspectionPeriod">Inspection Period</label>
            <select className="form-control" id="sltFeesPaidBy" name="sltFeesPaidBy"
                    onChange={onChange} value={value}>
                <option value={`24H`}>24 Hours</option>
                <option value={`48H`}>48 Hours</option>
                <option value={`72H`}>72 Hours</option>
                {showCustomOption && <option value={`custom`}>Custom</option>}
            </select>

            {showCustomOption && value === 'custom' ? children: null}
        </div>
    </div>
}

export default InspectionPeriod
