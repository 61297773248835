import {combineReducers} from 'redux';
import {all} from "redux-saga/effects";
import {connectRouter} from 'connected-react-router'

// Payable Agreement.
import {PayableAgreementReducer} from "./payableAgreement/reducer";
import {PayableAgreementState} from "./payableAgreement/types";
import payableAgreementSaga from "./payableAgreement/sagas";

import {BankAccountReducer} from "./bankAccount/reducers";
import {BankAccountState} from "./bankAccount/types";
import bankAccountsSaga from "./bankAccount/sagas";

import incomingPayableAgreementSaga from "./incomingPayableAgreement/sagas";
import {IncomingPayableAgreementState} from "./incomingPayableAgreement/types";
import {IncomingPayableAgreementReducer} from "./incomingPayableAgreement/reducer";

import {LoginState} from './login/types';
import {LoginReducer} from "./login/reducers";
import loginSaga from "./login/sagas";

import {RecipientState} from './recipient/types';
import {RecipientReducer} from './recipient/reducer';
import recipientSaga from "./recipient/sagas";

import {ProfileState} from "./profile/types";
import {ProfileReducer} from "./profile/reducer";
import profileSaga from "./profile/sagas";

import {DashboardState} from "./dashboard/types";
import {DashboardReducer} from "./dashboard/reducer";
import dashboardSaga from "./dashboard/sagas";

import {BusinessEntityState} from "./businessEntity/types";
import {BusinessEntityReducer} from "./businessEntity/reducers";
import businessEntitiesSaga from "./businessEntity/sagas";

import {AttachmentState} from "./attachment/types";
import {AttachmentReducer} from "./attachment/reducers";
import attachmentSaga from "./attachment/sagas";

import {ThirdPartyConnectionState} from "./thirdPartyConnection/types";
import {ThirdPartyConnectionReducer} from "./thirdPartyConnection/reducers";
import thirdPartyInvoiceSaga from "./thirdPartyConnection/sagas";

import {TransactionState} from "./transaction/types";
import {TransactionReducer} from "./transaction/reducer";
import transactionSaga from "./transaction/sagas";

import {RegisterState} from "./register/types";
import {RegistrationReducer} from "./register/reducers";
import registrationSaga from "./register/sagas";

export interface ApplicationState {
    payableAgreement: PayableAgreementState,
    bankAccount: BankAccountState,
    incomingPayableAgreement: IncomingPayableAgreementState,
    login: LoginState,
    recipient: RecipientState,
    profile: ProfileState,
    dashboard: DashboardState,
    businessEntity: BusinessEntityState,
    attachment: AttachmentState,
    thirdPartyConnection: ThirdPartyConnectionState,
    transaction: TransactionState,
    registration: RegisterState
}

export const createRootReducer = (history: any) => {
    return combineReducers({
        router: connectRouter(history),
        payableAgreement: PayableAgreementReducer,
        bankAccount: BankAccountReducer,
        incomingPayableAgreement: IncomingPayableAgreementReducer,
        login: LoginReducer,
        recipient: RecipientReducer,
        profile: ProfileReducer,
        dashboard: DashboardReducer,
        businessEntity: BusinessEntityReducer,
        attachment: AttachmentReducer,
        thirdPartyConnection: ThirdPartyConnectionReducer,
        transaction: TransactionReducer,
        registration: RegistrationReducer
    })
}

export function* rootSaga() {
    yield all([
        loginSaga(),
        registrationSaga(),
        payableAgreementSaga(),
        bankAccountsSaga(),
        incomingPayableAgreementSaga(),
        recipientSaga(),
        profileSaga(),
        dashboardSaga(),
        businessEntitiesSaga(),
        attachmentSaga(),
        thirdPartyInvoiceSaga(),
        transactionSaga(),
    ]);
}
