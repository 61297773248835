import React, {useMemo, useState} from "react";
import {DangerButton, SuccessButton} from "../../Buttons";
import {PayableAgreement} from "../../../models/PayableAgreement";
import TermsAndConditionsAcceptingPayableAgreementAlert
    from "../Alerts/TermsAndConditionsAcceptingPayableAgreementAlert";
import RejectPayableAgreementAlert from "../Alerts/RejectPayableAgreementAlter";
import {WarningNotification} from "../../Notifications";
import {findFirstMilestone} from "../../utils";

export interface PayableAgreementResponseButtonsProps {
    agreement: PayableAgreement,
    handleAcceptingAgreement: (receiverBusinessEntityId: number) => void,
    handleRejectingAgreement: (reason: string) => void,
    hideRejectAgreement?: boolean;
    amount?: number
    acceptButtonLabel?: string
}

const PayableAgreementResponseButton: React.FunctionComponent<PayableAgreementResponseButtonsProps> = ({
                                                                                                           agreement,
                                                                                                           handleAcceptingAgreement,
                                                                                                           handleRejectingAgreement,
                                                                                                           hideRejectAgreement,
                                                                                                           amount,
                                                                                                           acceptButtonLabel
                                                                                                       }: PayableAgreementResponseButtonsProps) => {
    // const [showAcceptingModal, setShowAcceptingModal] = useState<boolean>(false);
    // const hideModal = () => {
    //     // setShowAcceptingModal(false)
    // };

    const [showRejectAlert, setShowRejectAlert] = useState<boolean>(false);
    const [showAcceptAlert, setShowAcceptAlert] = useState<boolean>(false);
    const [action, setAction] = useState<string>('Accepting');

    const firstMilestone = useMemo(() => findFirstMilestone(agreement.milestones ?? []), [agreement])

    return <>
        {!agreement?.can_accept_agreement ? <div className={`py-2 text-left`}>
            <WarningNotification
                title={`Psst`}>{!agreement?.can_accept_agreement && agreement?.reason_cant_accept_agreement
                ? <div
                    dangerouslySetInnerHTML={{__html: agreement?.reason_cant_accept_agreement || ""}}></div> : ``}</WarningNotification>
        </div> : null}

        <TermsAndConditionsAcceptingPayableAgreementAlert
            payableAgreementType={agreement.type}
            onCancel={() => {
                setShowAcceptAlert(false)
            }}
            type={agreement?.type}
            onConfirm={(receiverBusinessEntityId) => {
                handleAcceptingAgreement(receiverBusinessEntityId);
                setShowAcceptAlert(false)
            }}
            firstMilestoneAmount={firstMilestone?.amount}
            action={action} showAlert={showAcceptAlert}
            amount={amount}/>

        <RejectPayableAgreementAlert action={action}
                                     showAlert={showRejectAlert}
                                     onConfirm={(reason) => {
                                         handleRejectingAgreement(reason);
                                         setShowAcceptAlert(false);
                                     }}
                                     onCancel={() => setShowRejectAlert(false)}/>

        <SuccessButton className={`accept-agreement`}
                       disabled={agreement?.can_accept_agreement === false}
                       type={`button`} onClick={() => {
            setAction('Accept & Sign Agreement');
            setShowAcceptAlert(true);
        }}>{acceptButtonLabel ?? `Accept & Sign Agreement`}</SuccessButton>{` `}
        {hideRejectAgreement !== true ? <DangerButton type={`button`} onClick={() => {
            setAction('Reject');
            setShowRejectAlert(true);
        }}>Reject Agreement</DangerButton> : ``}

        {/*<AcceptingAgreementOnProviderModal isOpen={showAcceptingModal} hideModal={hideModal}*/}
        {/*                                   agreementProviderUrl={agreement?.payment_provider_link}/>*/}
    </>
}

export default PayableAgreementResponseButton;
