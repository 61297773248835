import {Reducer} from "redux";
import {TransactionActionTypes, TransactionState} from "./types";
import {ToastActionTypes} from "../types";

export const initialState: TransactionState = {
    data: undefined,
    loading: false,
    notifications: {
        errors: [],
        alerts: [],
        infos: [],
        warnings: [],
        success: []
    },
    listItems: [],
    paginationMeta: undefined,
    toasts: [],
}

const reducer: Reducer<TransactionState> = (state = initialState, action) => {
    switch (action.type) {
        case TransactionActionTypes.CREATE_REQUEST:
        case TransactionActionTypes.SEND_AGREEMENT_REQUEST:
        case TransactionActionTypes.UPDATE_REQUEST:
        case TransactionActionTypes.FETCH_LIST_REQUEST:
        case TransactionActionTypes.FETCH_REQUEST:
            return {...state, loading: true, toasts: [], errors: ""}

        case ToastActionTypes.RESET_TOASTS:
            return {...state, toasts: []}

        case ToastActionTypes.ADD_TOAST:
            const existingToasts = state.toasts ?? [];
            return {...state, toasts: [...existingToasts, action.payload]}

        case TransactionActionTypes.FETCH_LIST_SUCCESS:
            return {
                ...state,
                listItems: action.payload.listOfTransactions,
                loading: false,
                paginationMeta: action.payload.paginationMeta
            }

        case TransactionActionTypes.FETCH_SUCCESS:
            return {...state, data: action.payload, loading: false}

        case TransactionActionTypes.FETCH_ERROR:
            return {...state, loading: false, errors: action.payload}

        default:
            return state;
    }
}
export {reducer as TransactionReducer}
