import React from 'react'

import Statuses from '../../enums'
import Milestone from '../../models/Milestone'
import {PayableAgreement} from '../../models/PayableAgreement'
import {WarningNotification} from '../Notifications'
import {ThunkDispatch} from 'redux-thunk'
import {AnyAction} from 'redux'
import {
    downloadMilestoneReceiptRequest,
} from '../../store/payableAgreement/action'
import {connect} from 'react-redux'
import {StatusPill} from './StatusPill'
import AddAttachmentButton from '../Attachment/Buttons/AddAttachmentButton'
import {Link} from 'react-router-dom'
import {MilestoneErrors} from './MilestoneErrors'
import DraftMilestoneActions from './Actions/DraftMilestoneActions'
import RequestDepositMilestoneActions from './Actions/RequestDepositMilestoneActions'
import MoneySecuredMilestoneActions from './Actions/MoneySecuredMilestoneActions'
import PaymentRequestedMilestoneActions from './Actions/PaymentRequestedMilestoneActions'
import RejectDepositMilestoneActions from './Actions/RejectDepositMilestoneActions'
import PaymentRejectedMilestoneActions from './Actions/PaymentRejectedMilestoneActions'
import CancellationRequestedMilestoneActions from './Actions/CancellationRequestedMilestoneActions'
import ButtonGroup from "../Buttons/ButtonGroup";
import {ListOfAttachments} from "../Attachment";

interface PropsFromState {
    milestone?: Milestone;
    agreement?: PayableAgreement;
    setIsLoading?: (isLoading: boolean) => void;
    showAddAttachments?: boolean;
    onShowEdit?: () => void
    cancelShowEdit?: () => void
    onEditMilestone?: (milestone: Milestone) => void
}

interface PropsFromDispatch {

}

type AllProps = PropsFromState & PropsFromDispatch;

export const MilestoneContractorActions: React.FunctionComponent<AllProps> = ({
                                                                                  milestone,
                                                                                  agreement,
                                                                                  showAddAttachments,
                                                                              }: AllProps) => {

    const payableAgreementType = agreement?.type || 'standard'
    const isSecureAgreement = payableAgreementType !== 'unsecured_agreement'
    const payableAgreementStatus = agreement?.status.toLowerCase()

    const canApproveCancellationMilestone = milestone?.cancel_milestone_approved_on == null && (milestone?.cancel_milestone_requested_on && milestone?.cancellation_requested_by === 'buyer')
    const canShowRequestPaymentButton = isSecureAgreement && milestone && !canApproveCancellationMilestone
        && ((milestone.status === Statuses.Milestones.MONEY_SECURED || milestone.status === Statuses.Milestones.MONEY_CLEARING) ||
            (milestone.status.toLowerCase() === Statuses.Milestones.FAILED_AT_PAYMENT_PROVIDER && milestone?.money_secured_on)
            || milestone.status === Statuses.Milestones.PAYMENT_REJECTED)

    const renderMilestoneActions = () => {
        if (!milestone) {
            return null
        }

        switch (milestone.status) {
            case 'pending':
                return milestone?.payable_agreement_id && payableAgreementStatus && <DraftMilestoneActions
                    payableAgreementStatus={payableAgreementStatus} milestone={milestone}/>

            case 'request_deposit':
                return milestone?.payable_agreement_id && payableAgreementStatus &&
                    <RequestDepositMilestoneActions milestone={milestone}
                                                    payableAgreementType={payableAgreementType}/>

            case 'reject_deposit':
                return milestone?.payable_agreement_id && payableAgreementStatus &&
                    <RejectDepositMilestoneActions milestone={milestone}/>

            case 'payment_requested':
                return milestone?.payable_agreement_id && payableAgreementStatus &&
                    <PaymentRequestedMilestoneActions milestone={milestone}/>

            case 'money_clearing':
            case 'money_secured':
                return milestone?.payable_agreement_id && payableAgreementStatus &&
                    <MoneySecuredMilestoneActions milestone={milestone}
                                                  canUserRequestPayment={agreement?.can_request_payment === true}/>

            case 'payment_rejected':
                return milestone?.payable_agreement_id && payableAgreementStatus &&
                    <PaymentRejectedMilestoneActions milestone={milestone}
                                                     payableAgreementId={milestone.payable_agreement_id}/>

            case 'cancellation_requested':
                return milestone?.payable_agreement_id && milestone.cancellation_requested_by === 'buyer' && payableAgreementStatus &&
                    <CancellationRequestedMilestoneActions milestone={milestone}
                                                           payableAgreementType={payableAgreementType}/>
        }
    }

    return <div className="milestone-actions" data-status={milestone?.status}>
        <div className="status-and-actions">
            {milestone?.status && <div className={'status-container'}>
                <StatusPill status={milestone.status} userType={'contractor'}/>
                {milestone?.status === 'payment_cleared' && milestone?.amended_amount && milestone?.amended_amount > 0 ?
                    <span className={`status-info text-info text-muted`}>
                    <i className="fa fa-info-circle text-success"/> Remaining amount refunded
                </span> : null}
            </div>}
            <ButtonGroup>
                {renderMilestoneActions()}
                {showAddAttachments ? <AddAttachmentButton entityType={'payable_agreement_milestones'}
                                                           entityId={milestone?.id}/> : null}{` `}
            </ButtonGroup>
        </div>
        {milestone && milestone.attachments && <ListOfAttachments userType={'contractor'} attachments={milestone?.attachments}/>}

        {canShowRequestPaymentButton && agreement && !agreement.can_request_payment ?
            <div className="connect-a-bank-account-warning">
                <div className={`py-2`}><WarningNotification>
                    <>
                        Please connect a bank account so you can request for payment. <Link
                        to={`/bank_accounts/connect`}>Connect now</Link>
                    </>
                </WarningNotification></div>
            </div> : null}

        <MilestoneErrors milestone={milestone}/>
    </div>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        downloadMilestoneReceipt: (milestoneId: string) => {
            dispatch(downloadMilestoneReceiptRequest(milestoneId))
        },
    }
}

export default connect(() => ({}), mapDispatchToProps)(MilestoneContractorActions)
