import React from "react";
import {TextArea} from "./Input";
import {useFormContext} from "react-hook-form";
import {BaseProps} from "./common";

const Description = React.forwardRef<HTMLInputElement, BaseProps>((props, ref) => {
    const formContext = useFormContext()
    return <TextArea {...formContext.register('agreement_details')}
                      placeholder={props.placeholder ?? props.label} label={props.label}/>
})

export default Description;
