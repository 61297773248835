import React from "react";
import Config from "../../../pyntax/lib/config";
import {GoogleLogin} from "react-google-login";
import {ApplicationState} from "../../../store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {socialLoginOrRegisterRequest} from "../../../store/login/action";

const mapStateToProps = ({login}: ApplicationState) => ({
        loading: login.loading,
        isLoggedIn: login,
    }),
    connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & {
    type: 'login' | 'signUp'
    isSSOEnabled: boolean
}

const GoogleButton = (props: Props) => {
    const dispatch = useDispatch()
    const googleButtonLabel = props.type === 'login' ? 'Sign In' : 'Sign Up';

    return <GoogleLogin
        disabled={props.loading}
        clientId={Config.SOCIAL_GOOGLE_APP_CLIENT_ID}
        buttonText={`${googleButtonLabel} with Google`}
        onSuccess={(res) => dispatch(socialLoginOrRegisterRequest(res))}
        onFailure={(res) => console.error(res)}
        cookiePolicy={'single_host_origin'}
    />
}

export default connector(GoogleButton)
