import React from "react";
import {FieldErrors} from "react-hook-form";

/**
 * @param errors
 * @param fieldName
 */
export const hasErrors = ({
                              errors,
                              fieldName
                          }: { errors?: FieldErrors, fieldName?: string }) => errors && fieldName && errors[fieldName];

/**
 * @param props
 */
export const renderErrorIfAny = (props: { errors?: FieldErrors, fieldName: string, label?: string }) => {
    const {fieldName, errors, label} = props

    if (errors && errors[fieldName]) {
        if (errors[fieldName].message) {
            return <div className="invalid-feedback">{errors[fieldName].message}</div>
        }

        return <div className="invalid-feedback">Invalid {(label ?? fieldName).toLowerCase()}</div>
    }
}
