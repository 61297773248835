import React, {useEffect} from "react";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {ApplicationState} from "../../store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";
import {PayableAgreement} from "../../models/PayableAgreement";
import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import NotificationsContainer from "../../components/Notifications/Containers";
import Block from "../../components/Blocks/Block";
import {fetchRequest, updateRequest} from "../../store/payableAgreement/action";
import ProductAgreementForm from "../../components/PayableAgreement/ProductAgreementForm";
import {InfoNotification} from "../../components/Notifications";

interface OwnProps extends RouteComponentProps<{ id: string }> {
}

const mapStateToProps = ({payableAgreement}: ApplicationState) => ({
    loading: payableAgreement.loading,
    data: payableAgreement.data,
    toasts: payableAgreement.toasts,
    notifications: payableAgreement.notifications
})
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = OwnProps & PropsFromRedux

/**
 * @param props
 * @constructor
 */
const EditProductAgreementPage = (props: Props) => {
    const dispatch = useDispatch(),
        {loading, notifications, data, toasts, match: {params}} = props,
        methods = useForm<PayableAgreement>({
            defaultValues: data ?? {}
        });

    useEffect(() => {
        if (params.id) {
            dispatch(fetchRequest({id: params.id}))
        }
    }, [dispatch, params.id])

    useEffect(() => {
        methods.reset({...data})
    }, [data, methods]);

    if (loading === false && params.id && data && data.id && data.product_service_provided !== 'product') {
        return <MainLayout pageTitle={'Redirect to Product page'} isLoading={loading} errors={[]} toasts={[]}>
            <div className="content content-full">
                <InfoNotification>
                    <div>
                        Please click on the following link to view this Agreement. <a
                        href={`/agreements/${data.id}/edit`} className="link">Click here to view</a>
                    </div>
                </InfoNotification>
            </div>
        </MainLayout>
    }

    const onSubmit = (payableAgreement: PayableAgreement) => {
        if (data && data.id && payableAgreement) {
            dispatch(updateRequest({
                id: data.id.toString(),
                data: payableAgreement
            }))
        }
    }

    return <MainLayout pageTitle={'Edit product payable agreement'} isLoading={loading} toasts={toasts}>
        <div className="content">
            <NotificationsContainer notifications={notifications}/>
        </div>

        <FormProvider<PayableAgreement> {...methods}>
            <ProductAgreementForm onSubmit={onSubmit}/>
        </FormProvider>
    </MainLayout>
}

export default connector(withRouter(EditProductAgreementPage));
