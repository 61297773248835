import React, {useEffect, useRef} from "react";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {createRequest} from "../../store/businessEntity/action";
import {connect} from "react-redux";
import {useForm} from "react-hook-form";

import {ApplicationState} from "../../store";

import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";

import {BusinessEntity} from "../../models/BusinessEntity";
import Block from "../../components/Blocks/Block";
import {BankAccount} from "../../models/BankAccount";
import {fetchListRequest} from "../../store/bankAccount/action";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";

export type Inputs = {
    business_name?: string;
    business_number?: string;
    business_yearly_turnover?: number

    business_street_address?: string;
    business_street_address_2?: string;
    business_suburb?: string;
    business_state?: string;
    business_postcode?: string;
    business_country?: string;
};

// Defining Props
interface PropsFromState {
    loading: boolean;
    data?: BusinessEntity;
    notifications: NotificationState;
    listOfBankAccounts?: BankAccount[]
}

// Defining PROPS.
interface PropsFromDispatch {
    addNewBusiness: (businessEntity: BusinessEntity) => any;
    fetchListOfBankAccounts: (pageNumber: number, pageSize?: number, filter?: string) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

/**
 * @constructor
 */
const AddNewBusinessPage: React.FunctionComponent<AllProps> = ({
                                                                   loading,
                                                                   data,
                                                                   notifications,
                                                                   addNewBusiness,
                                                                   fetchListOfBankAccounts,
                                                                   listOfBankAccounts
                                                               }) => {

    useEffect(() => {
        fetchListOfBankAccounts(1, 50);
    }, []);

    const hiddenSaveBtn = useRef(null),
        {register, handleSubmit} = useForm<Inputs>(),
        onSubmit = (newBusiness: any) => {
            addNewBusiness(newBusiness);
        }

    return <MainLayout pageTitle={`Add New Business`} isLoading={loading} errors={[]}>
        {/* Hero */}
        <PageTitle title={`Add New Business`} subTitle={<>Let's add new business details.</>}>

        </PageTitle>
        {/* END Hero */}

        <NotificationsContainer notifications={notifications} />

        <div className="content">
            <div className="row">
                <div className="col">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <button type={`submit`} ref={hiddenSaveBtn} className={`hidden-save`}/>
                        <Block>
                            <div className="row push">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Business Name</label>
                                        <input type="text" className="form-control" id="name" placeholder="Business Name"
                                               tabIndex={1} {...register('name')} autoComplete={`off`}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Business number (ABN/ACN)</label>
                                        <input type="text" className="form-control" id="number"
                                               {...register('number')} placeholder="Business number"
                                               tabIndex={3} autoComplete={`off`}/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="yearly_turnover">Yearly turnover</label>
                                        <input type="text" className="form-control" id="yearly_turnover"
                                            {...register('yearly_turnover')} placeholder="Yearly turnover"
                                               tabIndex={2}autoComplete={`off`}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number_type">Business number type</label>
                                        <select className="form-control" id="number_type"
                                                tabIndex={4} {...register('number_type')}>
                                            <option>ABN</option>
                                            <option>ACN</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </Block>
                        <Block>
                            <div className="row push">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="one-profile-edit-name">Street Address</label>
                                        <input type="text" className="form-control" id="street_address"
                                               {...register('street_address')} placeholder="1 George St"
                                               tabIndex={5} autoComplete={`off`}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="one-profile-edit-name">Suburb</label>
                                        <input type="text" className="form-control" id="suburb"
                                               {...register('suburb')} placeholder="Surry Hills"
                                               tabIndex={6} autoComplete={`off`}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="one-profile-edit-name">Postcode</label>
                                        <input type="text" className="form-control" id="postcode"
                                               {...register('postcode')} placeholder="2010"
                                               tabIndex={7} autoComplete={`off`}/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="one-profile-edit-name">Street Address 2</label>
                                        <input type="text" className="form-control" id="street_address_2"
                                               name="street_address_2" placeholder="Street Address 2"
                                               tabIndex={8} autoComplete={`off`}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="one-profile-edit-name">State</label>
                                        <select className="form-control" id="state" tabIndex={9} {...register('state')} >
                                            <option>ACT</option>
                                            <option>NSW</option>
                                            <option>NT</option>
                                            <option>QLD</option>
                                            <option>SA</option>
                                            <option>TAS</option>
                                            <option>VIC</option>
                                            <option>WA</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="one-profile-edit-name">Country</label>
                                        <input type="text" className="form-control" id="country"
                                               name="country" placeholder="Enter your name.." readOnly={true}
                                               tabIndex={10} value="Australia" autoComplete={`off`}/>
                                    </div>
                                </div>
                            </div>
                        </Block>
                        <Block>
                            <div className="row push">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="default_bank_account_id">Default Bank Account</label>
                                        <select className="form-control" id="default_bank_account_id"
                                                {...register('default_bank_account_id')} tabIndex={9}>
                                            {listOfBankAccounts?.map((listOfBankAccount: BankAccount) => {
                                                return <option
                                                    value={listOfBankAccount.id}>{listOfBankAccount.nickname}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </Block>
                        <div className="m-4">
                            <button onClick={() => {
                                // @ts-ignore
                                return hiddenSaveBtn && hiddenSaveBtn.current && hiddenSaveBtn.current.click();
                            }} className="btn btn-alt-success"><i className="fa fa-save"/> Save Business
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </MainLayout>
}

const mapStateToProps = ({businessEntity, bankAccount}: ApplicationState) => ({
    loading: businessEntity.loading || bankAccount.loading,
    notifications: businessEntity.notifications,
    data: businessEntity.data,
    listOfBankAccounts: bankAccount.listItems
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        addNewBusiness: (businessEntity: BusinessEntity) => {
            dispatch(createRequest({data: businessEntity}))
        },
        fetchListOfBankAccounts: (pageNumber: number, pageSize?: number, filter?: string) => {
            dispatch(fetchListRequest({pageNumber, pageSize, filter}));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewBusinessPage);
