import {colors} from "@material-ui/core";

export default {
    successButton: {
        backgroundColor: colors.green[500],
        color: "#ffffff",
        '&:hover': {
            backgroundColor: colors.green[700],
        }
    },
    dangerButton: {
        backgroundColor: colors.red[500],
        color: "#ffffff",
        '&:hover': {
            backgroundColor: colors.red[700],
        }
    }
}