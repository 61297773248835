import React, {useState} from "react";
import RequestDepositDialog from "../Dialogs/RequestDepositDialog";

interface Props {
    handleRequestDepositRequest: () => void
    amount?: number
}

export const RequestDepositButton: React.FunctionComponent<Props> = ({
                                                                         handleRequestDepositRequest,
                                                                         amount
                                                                     }) => {
    const [showRequestDepositDialog, setShowRequestDepositDialog] = useState<boolean>(false);

    return <>
        <RequestDepositDialog amount={amount} showDialog={showRequestDepositDialog}
                              onConfirm={() => {
                                  handleRequestDepositRequest();
                                  setShowRequestDepositDialog(false);
                              }}
                              onCancel={() => setShowRequestDepositDialog(false)}/>
        <button onClick={() => setShowRequestDepositDialog(true)}
                className={`btn btn-primary`}>Request secure funds</button>
    </>
}

export default RequestDepositButton
