import React, {useEffect} from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import useQuery from "../../components/utils/userQuery";
import {InfoNotification} from "../../components/Notifications";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connectABankAccount} from "../../store/bankAccount/action";

export interface ConnectBankAccountPageProps {
    connectABankAccount: (returnPayableAgreementId?: string, payableAgreementType?: string) => void
    loading: boolean
    connectionUrl?: string
}

export const ConnectBankAccountPage: React.FunctionComponent<ConnectBankAccountPageProps> = ({
                                                                                                 connectionUrl,
                                                                                                 connectABankAccount,
                                                                                                 loading
                                                                                             }) => {
    const query = useQuery();
    const returnPayableAgreementId = query.get('return_payable_agreement_id'),
        agreementType = query.get('type');

    useEffect(() => {
        if (returnPayableAgreementId && agreementType) {
            connectABankAccount(returnPayableAgreementId, agreementType)
        } else {
            connectABankAccount()
        }
    }, [])

    useEffect(() => {
        if (connectionUrl && connectionUrl.length > 0) {
            window.location.assign(connectionUrl)
        }
    }, [connectionUrl])

    return <MainLayout pageTitle={`Connect New Bank Account`} isLoading={loading}>
        <div className="content">
            <InfoNotification>
                We don't store any bank account details. All the data is securely stored with the Payment Provider.
            </InfoNotification>
        </div>

        {/* Hero */}
        <PageTitle title={`Connecting a new bank account`}/>

        {/*<div className="content">*/}
        {/*    <Block title="Bank ">*/}
        {/*        <div className="row push">*/}
        {/*            <div className="col">*/}
        {/*                <SuccessButton disabled={true} onClick={handleNewConnection}> Connect Bank Account</SuccessButton>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className="row">*/}
        {/*            <div className="col">*/}
        {/*                {connectionIframeUrl && connectionIframeUrl.length > 0 ?*/}
        {/*                    <iframe width={"100%"} height={"600px"} src={connectionIframeUrl}/> : null}*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </Block>*/}
        {/*</div>*/}
    </MainLayout>
}


const mapStateToProps = ({bankAccount}: ApplicationState) => ({
    loading: bankAccount.loading,
    connectionUrl: bankAccount.connectionUrl
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        connectABankAccount: (payableAgreementId?: string, payableAgreementType?: string) => dispatch(connectABankAccount({
            payableAgreementId, payableAgreementType
        }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectBankAccountPage);

