export const DANGER = "danger";
export const WARNING = "warning";
export const PRIMARY = "primary";
export const SUCCESS = "success";

export default {
    DANGER,
    WARNING,
    SUCCESS,
    PRIMARY
}