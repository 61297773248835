import React from "react";
import User from "../../models/User";
import EntityCard from "../Card";

type UserCardParams = {
    user?: User
    children?: React.ReactNode
}

/**
 *
 * @param recipient
 * @constructor
 */
export const UserCard: React.FC<UserCardParams> = ({user}: UserCardParams) => {
    const displayLabel = user?.name ?? user?.email ?? ''

    return <div className="user-card">
        <EntityCard entityType={`user`} canEdit={false} displayLabel={displayLabel}>
            <h5>{displayLabel}</h5>
            {/*{user?.profile?.full_address && <div className="full-address-container">*/}
            {/*    <i className="svg-icon address highlighted"></i>{` `}{user?.profile?.full_address}*/}
            {/*</div>}*/}
            <div className="email-container">
                <i className="svg-icon email highlighted"></i>{` `}{user?.email}
            </div>
            {user?.profile?.mobile && <div className="phone-container">
                <i className="svg-icon phone highlighted"></i>{` `}{user?.profile?.mobile}
            </div>}
        </EntityCard>
    </div>
}

export default UserCard
