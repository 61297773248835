import React from "react";
import {TextInput} from "./Input";
import {useFormContext} from "react-hook-form";
import {BaseProps} from "./common";

const Url = React.forwardRef<HTMLInputElement, BaseProps>((props, ref) => {
    const formContext = useFormContext()
    return <TextInput {...formContext.register('product_url')}
                      placeholder={props.placeholder ?? props.label} label={props.label}/>
})

export default Url;
