import React from "react";
import {ErrorNotification, InfoNotification, WarningNotification, SuccessNotification} from "../index";
import {NotificationState} from "../../../store/state";

type Props = {
    notifications: NotificationState
}

/**
 * @param props
 * @constructor
 */
const NotificationsContainer = ({notifications}: Props) => {
    const {errors, success, warnings, infos} = notifications;

    return <div className="notifications-container">
        {errors && errors.map((error) => <ErrorNotification>{error}</ErrorNotification>)}
        {success && success.map((successStr) => <SuccessNotification>{successStr}</SuccessNotification>)}
        {infos && infos.map((info) => <InfoNotification>{info}</InfoNotification>)}
        {warnings && warnings.map((warning) => <WarningNotification>{warning}</WarningNotification>)}
    </div>
}


export default NotificationsContainer
