import Enums from '../enums'
import Severity from './severity_classes';

/**
 *
 * @param status
 * @param classPrefix
 */
export function renderSeverityLabel(status: string, classPrefix: string = "") {
    const cleansedStatus = status.toLowerCase().trim();

    return {
        [classPrefix + Severity.WARNING]: (
            cleansedStatus === "pending"
            || cleansedStatus === "draft"
        ),
        [classPrefix + Severity.DANGER]: (
            cleansedStatus === "overdue"
            || cleansedStatus === "reject_deposit"
            || cleansedStatus === "requesting_money_failed"
            || cleansedStatus === "payment_rejected"
            || cleansedStatus === Enums.Milestones.FAILED_AT_PAYMENT_PROVIDER
            || cleansedStatus === Enums.Milestones.CANCELLATION_REQUESTED
            || cleansedStatus === Enums.Milestones.CANCELLED
            || cleansedStatus === Enums.Milestones.PAYMENT_REFUNDED
        ),
        [classPrefix + Severity.PRIMARY]: (
            cleansedStatus === "approve_deposit"
            || cleansedStatus === "processing_deposit"
            || cleansedStatus === "request_deposit"
            || cleansedStatus === "payment_requested"
            || cleansedStatus === "payment_approved"
            || cleansedStatus === "releasing_money"
        ),
        [classPrefix + Severity.SUCCESS]: (
            cleansedStatus === "approved"
            || cleansedStatus === Enums.Milestones.MONEY_SECURED
            || cleansedStatus === Enums.Milestones.MONEY_CLEARING
            || cleansedStatus === Enums.Milestones.PAYMENT_CLEARED
        ),
    }
}

/**
 * @param status
 */
export function renderMilestoneBadge(status: string) {
    return renderSeverityLabel(status, "badge-");
}
