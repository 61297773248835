import React, {useEffect, useState} from "react";

import Header from "../Navigation/Header";
import Footer from "../Navigation/Footer";
import AuthPageLayout from "./AuthPageLayout";
import {ToastProp, Toasts} from "../Notifications/Toasts";
import ServiceManager from "../../services/ServiceManager";

export interface MainLayoutProps {
    pageTitle: string;
    children: React.ReactNode;
    isLoading: boolean;
    errors?: string[],
    toasts?: ToastProp[] | undefined
}

const MainLayout: React.FunctionComponent<MainLayoutProps> = (props: MainLayoutProps) => {
    const {
        children, pageTitle, isLoading, toasts
    } = props;

    const [toastsToBeDisplayed, setToastsToBeDisplayed] = useState<ToastProp[]>([]);

    useEffect(() => {
        if (toasts && toasts?.length > 0) {
            setToastsToBeDisplayed(toasts);
        }
    }, [toasts])

    return <AuthPageLayout pageTitle={pageTitle} isLoading={isLoading}>
        <Header/>
            <main id="main-container" className={ServiceManager.utils.convertTextToClass(pageTitle)}>{children}</main>
        <Footer/>
        <Toasts toasts={toastsToBeDisplayed}/>
    </AuthPageLayout>
}

export default MainLayout;
