import React, {useState} from "react";
import Milestone from "../../models/Milestone";
import MilestoneRowItem from "./MilestoneRowItem";
import MilestoneFormDialog from "./Dialogs/MilestoneFormDialog";
import {PayableAgreement} from "../../models/PayableAgreement";
import {nextMilestoneId} from "../utils";

interface ListOfMilestonesProp {
    payableAgreement?: PayableAgreement
    milestones?: Milestone[]
    canAddMoreMilestones?: boolean
    payableAgreementStatus: string
    onAddingNewMilestone: (milestone: Milestone) => void
    hideFees?: boolean
    children?: React.ReactChild
    showAddNewFormOnLoad?: boolean
}

export const ListOfMilestones: React.FC<ListOfMilestonesProp> = ({
                                                                     payableAgreement,
                                                                     canAddMoreMilestones,
                                                                     milestones,
                                                                     payableAgreementStatus,
                                                                     onAddingNewMilestone,
                                                                     hideFees,
                                                                     children,
                                                                     showAddNewFormOnLoad
                                                                 }) => {
    const [showAddNewForm, setShowAddNewForm] = useState<boolean>(showAddNewFormOnLoad === true);

    return <React.Fragment>
        {payableAgreement && milestones && milestones.map((row: Milestone) => <MilestoneRowItem
            hideFees={Boolean(hideFees)}
            key={`${row.id}-Milestone`}
            payableAgreement={payableAgreement}
            milestone={row}
            payableAgreementStatus={payableAgreementStatus}>{children}</MilestoneRowItem>)}

        {showAddNewForm && <MilestoneFormDialog action={'save'}
            nextMilestoneId={nextMilestoneId(payableAgreement?.milestones ?? [])}
            onConfirm={(m: Milestone) => {
                setShowAddNewForm(false);
                onAddingNewMilestone(m);
            }} onCancel={() => {
            setShowAddNewForm(false)
        }}/>}

        {canAddMoreMilestones && <div className={`add-new-milestone-prompt`}>
            <div className="new-milestone-actions">
                <span className="circular-btn" onClick={() => setShowAddNewForm(true)}>
                    <i className="fa fa-plus"/>
                </span>
            </div>
            <div className={`foot-note`}>
                Add new milestone
            </div>
        </div>}
    </React.Fragment>
}

export default ListOfMilestones;
