export interface PaginationMeta {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
}

export enum ToastActionTypes {
    ADD_TOAST = "@toast/ADD_TOAST",
    RESET_TOASTS = "@toast/RESET_TOASTS"
}

export interface FetchListRequestPayload {
    pageNumber: number
    pageSize?: number
    filter?: string
}
export interface ListOfWithPagination<T> {
    list: T[],
    paginationMeta: PaginationMeta
}
export interface UpdateRequest<T> {
    id: string;
    data: T
}
export type CreateRequest<T> = Omit<UpdateRequest<T>, 'id'>
export type FetchSuccess<T> = Omit<UpdateRequest<T>, 'id'>
export interface FetchRequest {
    id: string
}

export interface RedirectToPayableAgreementAfterSuccessRequest {
    payableAgreementId?: string
    payableAgreementType?: string
}
