import React, {useEffect} from "react";
import {useDispatch} from 'react-redux';
import {logoutRequest} from "../../store/login/action";
import LoadingModal from "../../components/Dialogs/LoadingModal";


export default function LogoutPage() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logoutRequest())
    }, [dispatch])

    return <>
        <h1>Logging out...</h1>
        <LoadingModal isLoading={true}/>
    </>
}
