import {BankAccount} from "../../models/BankAccount";
import {PaginationMeta} from "../types";
import BaseState from "../state";

export enum BankAccountActionTypes {
    FETCH_REQUEST = "@bankAccount/FETCH_REQUEST",
    FETCH_SUCCESS = "@bankAccount/FETCH_SUCCESS",
    FETCH_ERROR = "@bankAccount/FETCH_ERROR",

    CREATE_REQUEST = "@bankAccount/CREATE_REQUEST",
    DELETE_REQUEST = "@bankAccount/DELETE_REQUEST",

    CONNECT_REQUEST = "@bankAccount/CONNECT_REQUEST",
    CONNECT_SUCCESS = "@bankAccount/CONNECT_SUCCESS",

    UPDATE_REQUEST = "@bankAccount/UPDATE_REQUEST",
    SAVE_REQUEST = "@bankAccount/SAVE_REQUEST",

    FETCH_LIST_REQUEST = "@bankAccount/FETCH_LIST_REQUEST",
    FETCH_LIST_SUCCESS = "@bankAccount/FETCH_LIST_SUCCESS",

    VERIFY_BANK_ACCOUNT_REQUEST = "@bankAccount/VERIFY_BANK_ACCOUNT_REQUEST",
    VERIFY_BANK_ACCOUNT_SUCCESS = "@bankAccount/VERIFY_BANK_ACCOUNT_SUCCESS",
}

export interface BankAccountState extends BaseState {
    readonly data?: BankAccount;
    readonly listItems?: BankAccount[];
    readonly paginationMeta?: PaginationMeta;
    readonly connectionUrl?: string
}
