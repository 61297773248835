import React from "react";

type Breadcrumb = {
    href: string
    name: string
}

type AllProps = {
    links: Breadcrumb[]
}

export const Breadcrumbs: React.FunctionComponent<AllProps> = ({links}) => {
    return <ol className="breadcrumb breadcrumb-alt">
        <li className="breadcrumb-item">
            <a className="link-fx" href="/">Dashboard</a></li>
        {links.map((link: Breadcrumb) => <li className="breadcrumb-item">
            <a className="link-fx" href={link.href}>{link.name}</a></li>)}
    </ol>
}
