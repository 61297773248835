import React, {useEffect, useState} from "react";
import Recipient from "../../models/Recipient";
import EditRecipientDialog from "../Recipients/Dialogs/EditRecipientDialog";
import EntityCard from "../Card";

type RecipientCardParams = {
    recipient: Recipient
    onSaved?: (recipient: Recipient) => void;
    displayType?: 'compact';
}

export const RecipientCard: React.FC<RecipientCardParams> = ({recipient, onSaved, displayType}: RecipientCardParams) => {
    const displayLabel = recipient?.display_label ?? 'A';
    const [showDialog, setShowDialog] = useState<boolean>(false);

    return <div className={`recipient-card ${displayType === 'compact' ? 'compact' : ''}`}>
        <EntityCard entityType={`recipient`} displayLabel={displayLabel} canEdit={true}
                    onEditHandleClick={() => setShowDialog(true)}>
            <h5>{displayLabel}</h5>
            <div className="email-container">
                <i className="svg-icon email highlighted mini"></i>{` `}{recipient?.email}
            </div>
            {recipient?.mobile && <div className="phone-container">
                <i className="svg-icon phone highlighted mini"></i>{` `}{recipient?.mobile}
            </div>}
        </EntityCard>
        <EditRecipientDialog showDialog={showDialog} recipient={recipient} onCancel={() => {
            setShowDialog(false)
        }} onSaved={(recipient: Recipient) => {
            setShowDialog(false);
            if (onSaved) {
                onSaved(recipient)
            }
        }}/>
    </div>
}

export default RecipientCard
