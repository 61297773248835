import Enums from '../enums'
import Severity_classes from "./severity_classes";

/**
 *
 * @param status
 */
export function renderPayableAgreementBadge(status: string | undefined) {

    const Danger = "badge-danger " + Severity_classes.DANGER,
        Warning = "badge-warning " + Severity_classes.WARNING,
        Primary = "badge-primary " + Severity_classes.PRIMARY,
        Success = "badge-success " + Severity_classes.SUCCESS

    return {
        [Danger]: (
            status === "overdue"
            || status === "rejected"
            || status === "disputed"
            || status === "requesting_money_failed"
            || status === "releasing_money_failed"
            || status === Enums.Milestones.CANCELLATION_REQUESTED
            || status === Enums.Milestones.PAYMENT_REFUNDED
        ),
        [Warning]: (status === "pending" || status === "DRAFT" || status === "draft"),
        [Primary]: (
            status === "processing_agreement"
            || status === "processing_deposit"
            || status === "sent"
            || status === "received"
            || status === "sending"
            || status === "requesting_money"
            || status === "releasing_money"
        ),
        [Success]: (
            status === "accepted"
            || status === "closed"
            || status === "in_progress"
            || status === "money_released"
            || status === Enums.Milestones.MONEY_CLEARING
            || status === "completed"
        ),
    }
}
