import React, {useEffect} from "react";
import WebsiteLayout from "../../components/Layouts/WebsiteLayout";
import useQuery from "../../components/utils/userQuery";
import ServiceManager from "../../services/ServiceManager";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import BearerToken from "../../models/Auth/token";
import {fetchUser, setToken} from "../../store/login/action";

const TokenLoginPage: React.FC<{}> = ({}) => {
    const dispatch = useDispatch();
    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        const st = query.get('st') ?? '',
            exp = query.get('ex')

        const bearerToken: BearerToken = {
            access_token: st,
            expires_in: parseInt(exp ?? Date.now().toString()),
            token_type: 'Bearer',
            refresh_token: undefined
        }
        dispatch(setToken(bearerToken))
        dispatch(fetchUser())

        history.push('/dashboard');
    }, [])

    return <WebsiteLayout pageTitle={`Loading....`} isLoading={true} hideHeader={true}>
        <main id="main-container">
            <div className="hero-static d-flex align-items-center">
                <div className="w-100">
                    <div className="bg-white">
                        <div className="content content-full">
                            <div className="row">
                                <div className="col text-center">
                                    <h2 className="h2">Loading please wait...</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </WebsiteLayout>
}

export default TokenLoginPage
