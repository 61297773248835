import Axios, {AxiosInstance} from "axios";
import Config from '../config';
import ServiceManager from "../../../services/ServiceManager";

/**
 * @param baseUrl
 */
export function make(baseUrl: string) {
    return Axios.create({baseURL: baseUrl, timeout: 10000});
}

export function factory() {
    return make(Config.API_URL);
}

/**
 * @param instance
 * @returns {AxiosInstance}
 */
export function withAuth(instance: any): AxiosInstance {
    instance.defaults.headers.common['Authorization'] = "Bearer " + ServiceManager.storageService.getAccessToken();
    return instance;
}