import React from "react";
import {ConnectForm} from "../../components/Forms/ConnectedForm";
import Recipient from "../../models/Recipient";
import {TextInput} from "../../components/Forms/Fields/Input";

type Props = {
    isUpdating: boolean
}

const RecipientFields = (props: Props) => {
    return <ConnectForm<Recipient>>
        {({register, formState: {errors}, getValues}) => {
            return <div className={'row'}>
                <div className="col-md-12 col-xs-12">
                    <div className="row push">
                        <div className="col col-xs-12">
                            <TextInput errors={errors} {...register('first_name', {
                                required: true
                            })} label={`First name`} placeholder="First name"/>
                            <TextInput errors={errors} {...register('email', {
                                required: true,
                            })} readOnly={props.isUpdating} label={`Email`} placeholder="Email"/>
                        </div>
                        <div className="col col-xs-12">
                            <TextInput errors={errors} {...register('last_name', {
                                required: true
                            })} label={`Last name`} placeholder="Last name"/>
                            <TextInput errors={errors} {...register('mobile', {
                                required: true
                            })} label={`Mobile`} placeholder="Mobile"/>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-xs-12">
                    <div className="row push">
                        <div className="col-md-9 col-xs-12">
                            <TextInput errors={errors} {...register('street_address')}
                                       label={`Street address`} placeholder="Street address"/>
                            <div className="row">
                                <div className="col">
                                    <TextInput errors={errors} {...register('suburb')}
                                               label={`Suburb`} placeholder="Suburb"/>
                                </div>
                                <div className="col">
                                    <TextInput errors={errors} {...register('country')} readOnly
                                               label={`Country`} placeholder="Country"/></div>
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-12">
                            <TextInput errors={errors} {...register('state')}
                                       label={`State`} placeholder="State"/>
                            <TextInput errors={errors} {...register('postcode')} label={`Postcode`} placeholder="Postcode"/>
                        </div>
                    </div>
                </div>
            </div>
        }}
    </ConnectForm>
}

export default RecipientFields
