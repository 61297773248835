import React from "react";
import ServiceManager from "../../services/ServiceManager";
import {AlertLevel} from "../types";

let TypeClassHashmap = new Map<AlertLevel, string>();
TypeClassHashmap.set(AlertLevel.PRIMARY, 'btn-primary');
TypeClassHashmap.set(AlertLevel.DANGER, 'btn-danger');
TypeClassHashmap.set(AlertLevel.SECONDARY, 'btn-secondary');
TypeClassHashmap.set(AlertLevel.SUCCESS, 'btn-success');
TypeClassHashmap.set(AlertLevel.INFO, 'btn-info');
TypeClassHashmap.set(AlertLevel.DARK, 'btn-dark');
TypeClassHashmap.set(AlertLevel.LIGHT, 'btn-light');

export interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
    styleType: AlertLevel
    type?: "submit" | "button"
    children?: React.ReactNode
}

export const renderButtonClass = (alertLevel: AlertLevel) => TypeClassHashmap.get(alertLevel) ?? '';

/**
 * @param styleType
 * @param type
 * @param className
 * @param children
 * @param props
 *
 * @constructor
 */
const Button: React.FC<ButtonProps> = ({
                                           styleType,
                                           type,
                                           className,
                                           children,
                                           ...props
                                       }) => {
    const btnType = ServiceManager.utils.isEmpty(type) ? "submit" : type,
        btnClassName = ServiceManager.utils.isEmpty(className) ? "" : className;

    return <button type={btnType} {...props}
                   className={"btn " + (renderButtonClass(styleType)) + " " + btnClassName}>{children}</button>
}

/**
 * @param props
 *
 * @returns {*}
 * @constructor
 */
export function SuccessButton({...props}) {
    return <Button styleType={AlertLevel.SUCCESS} {...props}/>
}

/**
 * @param props
 *
 * @returns {*}
 * @constructor
 */
export function PrimaryButton({...props}) {
    return <Button styleType={AlertLevel.PRIMARY} {...props}/>
}

/**
 * @param props
 *
 * @returns {*}
 * @constructor
 */
export function SecondaryButton({...props}) {
    return <Button styleType={AlertLevel.SECONDARY} {...props}/>
}

/**
 * @param props
 *
 * @returns {*}
 * @constructor
 */
export function DangerButton({...props}) {
    return <Button styleType={AlertLevel.DANGER} {...props}/>
}

/**
 * @param props
 *
 * @returns {*}
 * @constructor
 */
export function TertiaryButton({...props}) {
    return <Button styleType={AlertLevel.INFO} {...props}/>
}

export default Button;
