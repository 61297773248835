import {all, call, put, takeEvery} from 'redux-saga/effects';
import {ThirdPartyConnectionActionTypes} from "./types";
import {fetchError, fetchSuccess, fetchListSuccess, fetchInvoiceSuccess, fetchAccountListSuccess} from "./action";
import ServiceManager from "../../services/ServiceManager";
import {AxiosResponse} from "axios";
import {handleError} from "../saga";
import {ThirdPartyConnection} from "../../models/ThirdPartyConnection";
import {PaginationMeta} from "../types";
import {ThirdPartyAccountingLedgerAccount, ThirdPartyDocument} from "../../models/ThirdPartyDocument";
import {PayableAgreement} from "../../models/PayableAgreement";
import {acceptPayableAgreementRequest} from "../incomingPayableAgreement/action";

/**
 * @param action
 */
function* handleFetch(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.thirdPartyConnections,
            ServiceManager.dataApi.thirdPartyConnections.getById
        ], action.payload);

        const thirdPartyConnection: ThirdPartyConnection = response.data.data as ThirdPartyConnection;
        yield put(fetchSuccess(thirdPartyConnection));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleListRequest(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.thirdPartyConnections,
                ServiceManager.dataApi.thirdPartyConnections.findAll
            ],
            action.payload.pageSize || 20,
            action.payload.pageNumber || 1,
            {}
        );

        const listOfBankAccounts: ThirdPartyConnection[] = response.data.data as ThirdPartyConnection[];
        const paginationMeta: PaginationMeta = response.data.meta as PaginationMeta;
        yield put(fetchListSuccess(listOfBankAccounts, paginationMeta))
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

function* handleUpdate(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.thirdPartyConnections,
                ServiceManager.dataApi.thirdPartyConnections.update,
            ],
            action.payload.id,
            action.payload.data
        );

        const thirdPartyConnection: ThirdPartyConnection = response.data.data as ThirdPartyConnection;
        yield put(fetchSuccess(thirdPartyConnection));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

function* handleCreate(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.thirdPartyConnections,
                ServiceManager.dataApi.thirdPartyConnections.create,
            ],
            {third_party_provider: action.payload}
        );

        const thirdPartyConnection: ThirdPartyConnection = response.data.data as ThirdPartyConnection;
        yield put(fetchSuccess(thirdPartyConnection));

        if (thirdPartyConnection.connection_url) {
            window.location.href = <string>thirdPartyConnection.connection_url;
        }
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

/**
 * @param action
 */
function* handleInvoiceFetch(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.thirdPartyConnections,
            ServiceManager.dataApi.thirdPartyConnections.update
        ], `${action.payload.orgId}/invoice/${action.payload.invoiceId}`);

        const thirdPartyDocument: ThirdPartyDocument = response.data as ThirdPartyDocument;
        yield put(fetchInvoiceSuccess(thirdPartyDocument));

    } catch (err: any) {
        if (err?.response?.data?.redirect) {
            ServiceManager.storageService.setRedirectUrlAfterLoggingIn(window.location.pathname + window.location.search);
            window.location.href = err.response.data.redirect;

            yield put(fetchError(err?.response?.data?.message));
        } else {
            handleError(err);
        }
    }
}

/**
 * @param action
 */
function* handleConvertInvoiceToPayableAgreement(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.thirdPartyConnections,
            ServiceManager.dataApi.thirdPartyConnections.update
        ], [
            `${action.payload.orgId}/invoice/${action.payload.invoiceId}/convert`
        ]);

        const payableAgreement: PayableAgreement = response.data.data as PayableAgreement;
        if (payableAgreement && payableAgreement.id) {
            window.location.href = '/incoming_agreements/' + payableAgreement.id+'/view';
            // if (action.receiverBusinessEntityId) {
            //     yield(put(acceptPayableAgreementRequest(payableAgreement.id.toString(), action.receiverBusinessEntityId)))
            // } else {
            //     yield(put(acceptPayableAgreementRequest(payableAgreement.id.toString(), 0)))
            // }
        }

    } catch (err: any) {
        // handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleFetchAccountsListRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.thirdPartyConnections,
            ServiceManager.dataApi.thirdPartyConnections.getById
        ], `${action.payload.orgId}/accounts`);

        const accounts: any = response.data.data as ThirdPartyAccountingLedgerAccount[];
        yield put(fetchAccountListSuccess(accounts));
    } catch (err: any) {
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* watchFetchRequest() {
    yield takeEvery(ThirdPartyConnectionActionTypes.FETCH_LIST_REQUEST, handleListRequest)
    yield takeEvery(ThirdPartyConnectionActionTypes.FETCH_REQUEST, handleFetch);
    yield takeEvery(ThirdPartyConnectionActionTypes.CREATE_REQUEST, handleCreate);
    yield takeEvery(ThirdPartyConnectionActionTypes.UPDATE_REQUEST, handleUpdate);

    yield takeEvery(ThirdPartyConnectionActionTypes.FETCH_INVOICE_REQUEST, handleInvoiceFetch);
    yield takeEvery(ThirdPartyConnectionActionTypes.CONVERT_DOCUMENT_TO_PAYABLE_AGREEMENT_REQUEST, handleConvertInvoiceToPayableAgreement);
    yield takeEvery(ThirdPartyConnectionActionTypes.FETCH_ACCOUNTS_LIST_REQUEST, handleFetchAccountsListRequest);
    // yield takeEvery(ThirdPartyConnectionActionTypes.UPDATE_REQUEST, handleConvertInvoiceToPayableAgreement);
}


function* thirdPartyInvoiceSaga() {
    yield all([
        watchFetchRequest()
    ]);
}

export default thirdPartyInvoiceSaga;
