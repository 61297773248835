import {action} from "typesafe-actions";
import {AttachmentActionTypes} from "./types";
import {FetchRequest, PaginationMeta} from "../types";
import {Attachment} from "../../models/Attachment";
import {UserType} from "../../enums";

export const fetchListRequest = (payload: any) => action(AttachmentActionTypes.FETCH_LIST_REQUEST, payload);
export const fetchListSuccess = (listOfBusinessEntities: Attachment[], paginationMeta: PaginationMeta) => action(AttachmentActionTypes.FETCH_LIST_SUCCESS, {
    listOfBusinessEntities,
    paginationMeta
});

export const updateRequest = (payload: any) => action(AttachmentActionTypes.UPDATE_REQUEST, payload);

export const downloadRequest = (attachmentId: string, fileName: string, userType: UserType) => action(AttachmentActionTypes.DOWNLOAD_REQUEST, {
    attachmentId,
    fileName,
    userType
});
export const downloadSuccess = () => action(AttachmentActionTypes.DOWNLOAD_SUCCESS);
export const downloadError = (message: string) => action(AttachmentActionTypes.DOWNLOAD_ERROR, [message]);

export const fetchRequest = (id: string) => action(AttachmentActionTypes.FETCH_REQUEST, id);
export const fetchSuccess = (data: Attachment) => action(AttachmentActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(AttachmentActionTypes.FETCH_ERROR, [message]);

export const createRequest = (payload: any) => action(AttachmentActionTypes.CREATE_REQUEST, payload);

export const deleteRequest = (payload: FetchRequest) => action(AttachmentActionTypes.DELETE_REQUEST, {id: payload.id})
