import React, {ChangeEvent, useEffect, useState} from "react";
import UserContext from "../../components/Context/UserContext";
import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import Block from "../../components/Blocks/Block";
import {useParams} from "react-router-dom";
import {ToastProp} from "../../components/Notifications/Toasts";
import {ApplicationState} from "../../store";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {fetchAccountListRequest, fetchRequest, updateRequest} from "../../store/thirdPartyConnection/action";
import {ThirdPartyAccountingLedgerAccount} from "../../models/ThirdPartyDocument";
import {ThirdPartyConnection} from "../../models/ThirdPartyConnection";
import TypeOfAgreements from "../../components/PayableAgreement/Fields/TypesOfAgreeements";
import FeesPaidBy from "../../components/PayableAgreement/Fields/FeesPaidBy";
import InspectionPeriod from "../../components/PayableAgreement/Fields/InspectionPeriod";
import ListOfBusinesses from "../../components/PayableAgreement/Fields/ListOfBusinesses";
import {useForm} from "react-hook-form";
import SaveButton from "../../components/Buttons/SaveButton";


// Defining Props
interface PropsFromState {
    data?: ThirdPartyConnection;
    errors?: string;
    loading: boolean;
    toasts?: ToastProp[];
    accounts?: any[];
}

interface PropsFromDispatch {
    fetchThirdPartyAccounts: (orgId: string) => void;
    fetchThirdPartyConnectionRequest: (id: string) => void;
    updateThirdPartyConnectionRequest: (id: string, data: ThirdPartyConnection) => void;
}

interface PayableAgreementSetting {
    type: string
    fees_paid_by: string
    release_inspection_by: string
    sender_business_entity_id: string
}

type AllProps = PropsFromState & PropsFromDispatch


type ThirdPartyConnectionViewPageParams = {
    id: string
}

const ThirdPartyViewPage: React.FunctionComponent<AllProps> = ({
                                                                   loading,
                                                                   accounts,
                                                                   data,
                                                                   fetchThirdPartyAccounts,
                                                                   fetchThirdPartyConnectionRequest,
                                                                   updateThirdPartyConnectionRequest
                                                               }) => {

    const {id} = useParams<ThirdPartyConnectionViewPageParams>();
    const {control} = useForm<any>();
    const [xeroAccount, setXeroAccount] = useState('');
    const [selectedXeroAccountDescription, setSelectedXeroAccountDescription] = useState('');
    const [payableAgreementSetting, setPayableAgreementSetting] = useState<PayableAgreementSetting>({
        type: '24H',
        release_inspection_by: '24H',
        fees_paid_by: 'CONTRACTOR',
        sender_business_entity_id: "",
    })

    useEffect(() => {
        fetchThirdPartyAccounts(id);
        fetchThirdPartyConnectionRequest(id);
    }, [id]);

    useEffect(() => {
        let selectedAccounts: ThirdPartyAccountingLedgerAccount[];
        if (accounts && accounts.length > 0) {
            selectedAccounts = accounts?.filter((account: ThirdPartyAccountingLedgerAccount) => account.accountID === xeroAccount);

            if (selectedAccounts.length > 0) {
                let selectedAccount: ThirdPartyAccountingLedgerAccount | undefined = selectedAccounts.pop();
                if (selectedAccount && selectedAccount.description) {
                    setSelectedXeroAccountDescription(selectedAccount?.description);
                }
            }
        }
    }, [xeroAccount])


    return <UserContext.Consumer>
        {(user: any) => (<MainLayout
            pageTitle={`Received Agreements`} isLoading={loading}>
            <PageTitle title={`Third Party Integration`} subTitle={data?.organisation_name}/>

            <div className="content">
                <div className="row">
                    <div className="col-md-12">
                        <Block>
                            <h3>Payable agreement settings</h3>
                            <div className="row push">
                                <div className="col-4">
                                    <label htmlFor="sltTypesOfAgreement">{data?.third_party_provider} Ledger
                                        Account <span
                                            className="text-danger">*</span></label>
                                    <p className="font-size-sm text-muted my-2">
                                        The payments made towards the Milestone will be attributed to this ledger
                                        account in the accounting system.
                                    </p>
                                </div>
                                <div className="col-8">
                                    <select name="type" onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                        setXeroAccount(e.target.value)
                                    }} className={`form-control`} value={xeroAccount}>
                                        {accounts?.map((ledgerAccount: ThirdPartyAccountingLedgerAccount) => <option
                                            value={ledgerAccount.accountID}>{ledgerAccount.name}</option>)}
                                    </select>
                                    {selectedXeroAccountDescription && <p className="font-size-sm text-muted my-2">
                                        <em>Description:</em> {selectedXeroAccountDescription}</p>}
                                </div>
                            </div>

                            <TypeOfAgreements
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => setPayableAgreementSetting({
                                    ...payableAgreementSetting,
                                    type: e.target.value
                                })} value={payableAgreementSetting.type}/>
                            <FeesPaidBy onChange={(e: ChangeEvent<HTMLSelectElement>) => setPayableAgreementSetting({
                                ...payableAgreementSetting,
                                fees_paid_by: e.target.value
                            })} value={payableAgreementSetting.fees_paid_by}/>
                            <InspectionPeriod showCustomOption={false}
                                              onChange={(e: ChangeEvent<HTMLSelectElement>) => setPayableAgreementSetting({
                                                  ...payableAgreementSetting,
                                                  release_inspection_by: e.target.value
                                              })} value={payableAgreementSetting.release_inspection_by}/>
                            <ListOfBusinesses onChange={((event, value) => {
                                setPayableAgreementSetting({
                                    ...payableAgreementSetting,
                                    sender_business_entity_id: value.id
                                })
                            })} control={control}/>

                            <div className="row push">
                                <div className="col">
                                    <SaveButton onClick={(e) => {
                                        e.preventDefault();
                                        if (data && data.id && data.third_party_provider) {
                                            updateThirdPartyConnectionRequest(id, {
                                                payable_agreement_settings: payableAgreementSetting,
                                                third_party_ledger_accounts_id: xeroAccount,
                                                id: data.id,
                                                third_party_provider: data.third_party_provider,
                                            })
                                        }
                                    }}/>
                                </div>
                            </div>
                        </Block>
                    </div>
                </div>
            </div>
        </MainLayout>)}
    </UserContext.Consumer>
}

const mapStateToProps = ({thirdPartyConnection}: ApplicationState) => ({
    data: thirdPartyConnection.data,
    loading: thirdPartyConnection.loading,
    notifications: thirdPartyConnection.notifications,
    accounts: thirdPartyConnection.accounts
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchThirdPartyAccounts: (orgId: string) => {
            dispatch(fetchAccountListRequest(orgId));
        },
        fetchThirdPartyConnectionRequest: (id: string) => {
            dispatch(fetchRequest(id));
        },
        updateThirdPartyConnectionRequest: (id: string, data: ThirdPartyConnection) => {
            dispatch(updateRequest({id, data}));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyViewPage);
