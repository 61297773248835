import {Reducer} from "redux";
import {DashboardActionTypes, DashboardState} from "./types";
import {ToastActionTypes} from "../types";

export const initialState: DashboardState = {
    data: undefined,
    loading: false,
    notifications: {
        errors: [],
        alerts: [],
        infos: [],
        warnings: [],
        success: []
    },
    toasts: []
}

const reducer: Reducer<DashboardState> = (state = initialState, action) => {
    switch (action.type) {
        case DashboardActionTypes.FETCH_REQUEST:
            return {
                ...state, loading: true, toasts: [], notifications: {
                    errors: [],
                    alerts: [],
                    infos: [],
                    warnings: [],
                    success: []
                }
            }

        case ToastActionTypes.RESET_TOASTS:
            return {...state, toasts: []}

        case ToastActionTypes.ADD_TOAST:
            const existingToasts = state.toasts ?? [];
            return {...state, toasts: [...existingToasts, action.payload]}

        case DashboardActionTypes.FETCH_SUCCESS:
            return {...state, data: action.payload, loading: false}

        case DashboardActionTypes.FETCH_ERROR:
            return {
                ...state, loading: false, notifications: {
                    ...state.notifications, errors: action.payload
                }
            }

        default:
            return state;
    }
}

export {reducer as DashboardReducer}
