import React from "react";
import Milestone from "../../models/Milestone";
import DeleteMilestoneButton from "./Buttons/DeleteMilestoneButton";
import ButtonGroup from "../Buttons/ButtonGroup";

interface Props {
    milestone?: Milestone
    onDelete: (milestoneId: number) => void;
    onMilestoneEdit: (milestone: Milestone) => void;
}

export const NewAgreementMilestoneActions: React.FC<Props> = ({milestone, onDelete, onMilestoneEdit}) => {
    return <div className="milestone-actions">
        <ButtonGroup>
            <DeleteMilestoneButton handleDeleteMilestone={() => {
                if (milestone && milestone.id !== undefined) {
                    onDelete(milestone.id)
                }
            }}/>
        </ButtonGroup>
    </div>
}

export default NewAgreementMilestoneActions
