import React from 'react';
import './scss/index.scss';
import 'react-day-picker/lib/style.css';

import {Provider} from "react-redux";
import {Store} from "redux";

import {CssBaseline} from "@material-ui/core";
import {SnackbarProvider} from "notistack";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core";

import {ApplicationState} from "./store";
import AppRouter from "./AppRouter";

const PaySecureTheme = createMuiTheme({
    typography: {
        "fontFamily": `"Poppins"`,
        "fontSize": 16,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});

/**
 * @interface MainProps
 */
interface MainProps {
    store: Store<ApplicationState>
}

const App: React.FunctionComponent<MainProps> = ({store}) => {
    return (
        <MuiThemeProvider theme={PaySecureTheme}>
            <Provider store={store}>
                <SnackbarProvider anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <CssBaseline/>
                    <AppRouter/>
                </SnackbarProvider>
            </Provider>
        </MuiThemeProvider>
    );
}

export default App;
