import React from "react";

interface ErrorPanelProps {
    errors?: string[]
}

const ErrorPanel: React.FunctionComponent<ErrorPanelProps> = ({errors}: ErrorPanelProps) => {
    return null
}

export const ReduxFormErrorPanel: React.FunctionComponent<any> = ({errors}) => {
    const errorKeys = Object.keys(errors);
    return errorKeys.length > 0 ? <div className="content">
        <div className="row">
            <div className="col">
                {errorKeys.map((errorKey: string) => <p
                    className={`alert alert-danger`}>{errors[errorKey].message}</p>)}
            </div>
        </div>
    </div> : null;
}

export default ErrorPanel;
