import React from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import {ThirdPartyConnection} from "../../models/ThirdPartyConnection";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {createRequest} from "../../store/thirdPartyConnection/action";
import {connect} from "react-redux";
import {NotificationState} from "../../store/state";

// Defining Props
interface PropsFromState {
    loading: boolean;
    notifications: NotificationState;
    data?: ThirdPartyConnection;
}

interface PropsFromDispatch {
    createThirdPartyConnection: (thirdParty: string) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const CreateThirdPartyConnection: React.FunctionComponent<AllProps> = ({
                                                                                  loading,
                                                                                  notifications,
                                                                                  data,
                                                                                  createThirdPartyConnection
                                                                              }) => {
    return <MainLayout pageTitle={`Connect third party application`} isLoading={loading} errors={[]}>
        <PageTitle
            title={`Connect third party application`}
            subTitle={`The following integrations are available on paysecure, click on the button to connect.`}/>

        <div className="content">
            <div className="row">
                <div className="col-3">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Xero: Accounting platform</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the
                                bulk of the card's content.</p>
                        </div>
                        <div className="card-footer">
                            <a href="#" className="card-link" onClick={() => {
                                createThirdPartyConnection("xero")
                            }}>
                                <img
                                    src="https://developer.xero.com/static/images/documentation/ConnectToXero2019/connect-blue.svg"
                                    alt="Connect XERO"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
};

const mapStateToProps = ({thirdPartyConnection}: ApplicationState) => ({
        loading: thirdPartyConnection.loading,
        notifications: thirdPartyConnection.notifications,
        data: thirdPartyConnection.data
    }),
    mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
        return {
            createThirdPartyConnection: (thirdPartyProvider: string) => {
                dispatch(createRequest(thirdPartyProvider));
            }
        }
    }

export default connect(mapStateToProps, mapDispatchToProps)(CreateThirdPartyConnection);
