import React, {useState} from "react";
import FormGroup, {defaultInputClasses} from "../FormGroup";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import classnames from "classnames";
import {hasErrors} from "../common";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    label: string
    errors?: FieldErrors
}

const Password = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const [passwordFieldType, setPasswordFieldType] = useState<'password' | 'text'>('password')
    return <FormGroup label={props.label} fieldName={props.name} errors={props.errors}>
        <div className={classnames('input-group', {
            'is-invalid': hasErrors({
                errors: props.errors,
                fieldName: props.name
            })
        })}>
            <input type={passwordFieldType}
                   className={classnames(defaultInputClasses, props.className, {'is-invalid': hasErrors(props)})}
                   ref={ref} {...props} />
            <span className="input-group-text" onClick={() => {
                if (passwordFieldType === 'password') {
                    setPasswordFieldType('text')
                } else {
                    setPasswordFieldType('password')
                }
            }}><i className={'fa fa-eye'}/></span>
        </div>
    </FormGroup>
})

export default Password
