import React, {useEffect, useState} from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import PageTitle from "../../components/PageTitle";
import {Link, Redirect} from 'react-router-dom';
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {fetchRequest} from '../../store/dashboard/action';
import {Dashboard, DashboardWidget} from "../../models/Dashboard";
import {ToastProp} from "../../components/Notifications/Toasts";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import DashboardWizards from "../../components/Dashboard/Wizards";
import RecordListTable from "../../components/Table/RecordListTable";
import {PaymentRequest} from '../../models/PaymentRequest';
import Block from "../../components/Blocks/Block";
import classNames from "classnames";
import {renderMilestoneBadge} from "../../classes";
import renderMilestoneStatusLabel from "../../labels";

interface PropsFromState {
    loading: boolean
    data?: Dashboard;
    errors?: string;
    toasts?: ToastProp[]
}

interface PropsFromDispatch {
    fetchDashboard: () => any
}

type AllProps = PropsFromState & PropsFromDispatch;

/**
 * @constructor
 */
const DashboardPage: React.FunctionComponent<AllProps> = ({
                                                              loading,
                                                              data,
                                                              errors,
                                                              toasts,
                                                              fetchDashboard
                                                          }) => {
    const [dashboard, setDashboard] = useState<Dashboard | undefined>(undefined);

    // return <Redirect to={'/agreements?filter=sent'} />

    useEffect(() => {
        fetchDashboard();
    }, [fetchDashboard]);

    useEffect(() => {
        setDashboard(data);
    }, [data]);

    return <MainLayout pageTitle={`Dashboard`} isLoading={loading} toasts={toasts}>
        {/* Hero */}
        <PageTitle title={`Dashboard`}>
            <Link to={`/agreements/new`} className="btn btn-alt-success">Create Agreement</Link>
        </PageTitle>
        {/* END Hero */}

        {/* Page Content */}
        <div className="content">
            <div className="row">
                <div className="col-lg-9 col-xs-12">
                    {data && data?.transactions ? <>
                        <h3 className={`h3`}>Latest Transactions</h3>
                        <Block>
                            <RecordListTable<PaymentRequest> columns={[
                                {
                                    headerTitle: '',
                                    accessor: (row: PaymentRequest) => ''
                                },
                                {
                                    headerTitle: 'Contact',
                                    accessor: (row: PaymentRequest) => {
                                        if (row && row?.recipient && row?.recipient.display_label) {
                                            return row.recipient.display_label;
                                        }

                                        return '';
                                    },
                                },
                                {
                                    headerTitle: 'Agreement',
                                    accessor: 'payable_agreement_title'
                                },
                                {
                                    headerTitle: 'Amount',
                                    accessor: 'amount_display'
                                },
                                {
                                    headerTitle: 'Status',
                                    accessor: (row: PaymentRequest) => {
                                        if (row && row.status) {
                                            return <span
                                                data-status={row.status}
                                                className={classNames("badge p-2", renderMilestoneBadge(row.status))}>{renderMilestoneStatusLabel(row.status, 'client')}</span>
                                        }

                                        return 'N/A'
                                    }
                                }
                            ]} data={data?.transactions} />
                        </Block>
                    </>: null}
                </div>
                <div className="col-lg-3 ads-container">
                    <a href="/agreements/new?product_tour_id=351960">
                        <img src="/assets/images/ad.svg"/>
                    </a>
                </div>
            </div>
            {/* Overview */}
            {/*<div className="row row-deck">*/}
            {/*    <DashboardWizards widgets={dashboard?.widgets}/>*/}
            {/*</div>*/}
            {/* END Overview */}
        </div>
        {/* END Page Content */}
    </MainLayout>
}

const mapStateToProps = ({dashboard}: ApplicationState) => ({
    loading: dashboard.loading,
    data: dashboard.data,
    toasts: dashboard.toasts,
    notifications: dashboard.notifications,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchDashboard: () => {
            dispatch(fetchRequest());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
