import {factory, withAuth} from "./RequestManager";
import ServiceManager from "../../../services/ServiceManager";
import {AxiosInstance, AxiosResponse} from "axios";
import {UserRegistrationRequest} from "../../../models/User";

class AuthManager {
    public static _requestManager: AxiosInstance;
    private static _requestManagerWithAuth: AxiosInstance;

    constructor() {
        AuthManager._requestManager = factory();
        AuthManager._requestManagerWithAuth = withAuth(AuthManager.requestManager);
    }

    static get requestManagerWithAuth(): AxiosInstance {
        return this._requestManagerWithAuth;
    }

    static get requestManager() {
        return this._requestManager;
    }

    /**
     * @param socialMediaProvider
     * @param socialResponsePayload
     */
    public socialLoginOrRegister(socialMediaProvider: string, socialResponsePayload: any) {
        return AuthManager.requestManager.post(
            `register_with/${socialMediaProvider}`,
            socialResponsePayload
        )
    }

    /**
     * @param email
     * @param password
     * @param rePassword
     * @param token
     */
    public resetPassword(email: string, password: string, rePassword: string, token: string) {
        return AuthManager.requestManager.post('reset_password/' + token, {
            email: email,
            password: password,
            password_confirmation: rePassword
        })
    }

    public forgotPassword(email: string) {
        return AuthManager.requestManager.post('forgot_password', {
            email: email
        })
    }

    public login(username: string, password: string, onSuccess: () => void) {
        return AuthManager.requestManagerWithAuth.post(
            'login',
            {
                username, password, device_name: "desktop"
            }
        )
    }

    public getUser() {
        return AuthManager.requestManagerWithAuth.get(
            'user'
        )
    }

    public async logout(): Promise<void> {
        return ServiceManager.storageService.clearAll();
    }

    public refreshUser() {
        return AuthManager.requestManagerWithAuth.get('/user').then((response: AxiosResponse) => {
            ServiceManager.storageService.setUser(response.data);
        }).catch((reason: any) => {
            console.error(reason);
        })
    }

    public registerAccount(data: UserRegistrationRequest) {
        return AuthManager.requestManager.post('register', data)
    }

    public isAuthenticated() {
        return (ServiceManager.storageService.getOAuthToken() !== null && ServiceManager.storageService.getOAuthToken() !== undefined);
    }
}

export default AuthManager;
