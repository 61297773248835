export enum EntityTypes {
    BANK_ACCOUNTS = "bank_accounts",
    PAYABLE_AGREEMENT = "payable_agreements",
    MILESTONES = "payable_agreement_milestones",

    DEPOSIT_REQUEST = "deposit_request",
    PAYOUT_REQUEST = "payout_request",
    PAY_NOW_REQUEST = "pay_now_request",

    ATTACHMENTS = "attachments",
    USER = "users",
    RECIPIENT = "recipients",
    BUSINESS = "business_entities",
    SSO_PROVIDER = "user_sso_providers"
}
