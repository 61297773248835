import React, {useContext, useEffect} from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import RecordListTable from "../../components/Table/RecordListTable";
import PageTitle from "../../components/PageTitle";
import {Link, useHistory} from 'react-router-dom';
import {PayableAgreement} from "../../models/PayableAgreement";
import {PaginationMeta} from "../../store/types";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {fetchListRequest} from "../../store/payableAgreement/action";
import {connect} from "react-redux";
import {ToastProp} from "../../components/Notifications/Toasts";
import {LANG} from "../../labels"
import {WarningNotification} from "../../components/Notifications";
import StatusPill from "../../components/PayableAgreement/StatusPill";
import useQuery from "../../components/utils/userQuery";
import ListPageAddLink from "../../components/Buttons/ListPageAddLink";
import Block from "../../components/Blocks/Block";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";
import ServiceManager from "../../services/ServiceManager";
import User from "../../models/User";
import UserContext from "../../components/Context/UserContext";

interface PropsFromState {
    loading: boolean;
    notifications: NotificationState
    listItems?: PayableAgreement[];
    paginationMeta?: PaginationMeta;
    toasts?: ToastProp[]
}

interface PropsFromDispatch {
    fetchListOfPayableAgreement: (pageNumber: number, pageSize?: number, filter?: string) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;
type  ValidFilters = 'sent' | 'received' | 'draft' | 'all'

export const AgreementListPage: React.FunctionComponent<AllProps> = ({
                                                                         loading,
                                                                         listItems,
                                                                         paginationMeta,
                                                                         notifications,
                                                                         fetchListOfPayableAgreement,
                                                                         toasts,
                                                                     }) => {
    const query = useQuery(), history = useHistory();
    const activeFilter = query.get('filter') as ValidFilters

    // The userId of the logged-in User.
    const user = useContext<User | undefined>(UserContext);

    useEffect(() => {
        fetchListOfPayableAgreement(1, 20, activeFilter);
    }, [activeFilter, fetchListOfPayableAgreement]);

    const renderRecipientDetails = (row: PayableAgreement) => {
        const recipientNameAndEmail = row?.recipient?.first_name + ` ` + row?.recipient?.last_name + ` (` + row?.recipient?.email + `)`

        if (row?.recipient_business_entity_id && row?.recipient_business_entity) {
            return <>{recipientNameAndEmail}<br/><b>{row?.recipient_business_entity?.name}</b></>
        }

        return recipientNameAndEmail
    }

    const renderAgreementUrl = (agreement: PayableAgreement): string => {
        if (agreement.user?.id === user?.id) {
            // if (agreement.product_service_provided === 'product') {
            //     return `/product/agreements/${agreement.id}/edit`
            // }

            return `/agreements/${agreement.id}/edit`
        }

        // if (agreement.product_service_provided === 'product') {
        //     return `/product/incoming_agreements/${agreement.id}/view`
        // }

        return `/incoming_agreements/${agreement.id}/view`
    }

    const columns = [
        {
            headerTitle: '',
            accessor: () => <div className="d-flex justify-content-center">
                <i className="svg-icon smart-contracts highlighted"></i>
            </div>
        },
        {
            headerTitle: () => activeFilter === 'received' ? 'Sender' : 'Recipient',
            colClassName: 'hidden-mobile',
            accessor: (row: PayableAgreement) => {
                if (activeFilter === 'received') {
                    const recipientNameAndEmail = row?.user?.name + ` ` + row?.user?.display_label + ` (` + row?.user?.email + `)`
                    return <>{recipientNameAndEmail}</>
                }

                const recipientNameAndEmail = renderRecipientDetails(row)

                return <div className="recipient">
                    {recipientNameAndEmail}
                </div>
            }
        }, {
            headerTitle: 'Title',
            accessor: (row: PayableAgreement) => {
                return <div className="title" onClick={() => {
                    window.location.href = renderAgreementUrl(row)
                }}>
                    {`ID: ${row?.id} - ${row?.title}`}
                    <div className="mobile-only recipient text-muted">To: {renderRecipientDetails(row)}</div>
                </div>
            },
        }, {
            headerTitle: "Status",
            accessor: (row: PayableAgreement) => {
                return <StatusPill status={row?.status}
                                   userType={activeFilter === 'received' ? 'client' : 'contractor'}/>
            }
        },
        {
            headerTitle: 'View / Edit',
            colClassName: 'hidden-mobile',
            accessor: (row: PayableAgreement) => {
                return <Link data-test-id={`open-agreement-${row.id}`} className={`btn btn-primary btn-sm`}
                             to={renderAgreementUrl(row)}>Open</Link>
            }
        },
    ];
    const noRecordLabel = <WarningNotification
        title="No records found">{LANG.AgreementListPage.DISPLAY_NAME_NOTE}</WarningNotification>

    return <MainLayout pageTitle={`Sent Agreements`} isLoading={loading} toasts={toasts}>
        {/* Hero */}
        <PageTitle title={LANG.AgreementListPage.DISPLAY_NAME} toolTip={LANG.AgreementListPage.DISPLAY_NAME_TOOLTIP}>
            <ListPageAddLink desktopBtnLabel={`Start a new agreement`} to={`/agreements/new`}/>
        </PageTitle>
        {/* END Hero */}

        <NotificationsContainer notifications={notifications}/>

        <div className="content content-full agreement-list-container">
            <Block noPadding={true}>
                <RecordListTable<PayableAgreement>
                    activeFilter={activeFilter}
                    filters={[
                        {label: 'All', value: 'all'},
                        {label: 'Sent', value: 'sent'},
                        {label: 'Received', value: 'received'},
                        {label: 'Draft', value: 'draft'},
                        {label: 'Archived', value: 'archived'},
                    ]}
                    noRecordsLabel={noRecordLabel} hideHeader={true}
                    loadMoreResults={(pageNumber: number, pageSize: number, filter?: string) => {
                        history.push(`/agreements?filter=${filter}`)
                    }}
                    rowClassColumn={`status`} paginationMeta={paginationMeta} columns={columns} data={listItems}/>
            </Block>
        </div>
    </MainLayout>
}

const mapStateToProps = ({payableAgreement, login}: ApplicationState) => ({
    loading: payableAgreement.loading,
    notifications: payableAgreement.notifications,
    listItems: payableAgreement.listItems,
    paginationMeta: payableAgreement.paginationMeta,
    toasts: payableAgreement.toasts,
    userId: login.user?.id ?? null
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchListOfPayableAgreement: (pageNumber: number, pageSize?: number, filter?: string) => {
            dispatch(fetchListRequest({pageNumber, pageSize, filter}));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementListPage);
