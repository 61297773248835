import {Dashboard} from "../../models/Dashboard";
import BaseState from "../state";

export enum DashboardActionTypes {
    FETCH_REQUEST = "@dashboard/FETCH_REQUEST",
    FETCH_SUCCESS = "@dashboard/FETCH_SUCCESS",
    FETCH_ERROR = "@dashboard/FETCH_ERROR",
}

export interface DashboardState extends BaseState {
    readonly data?: Dashboard;
}