import User from "../../models/User";
import BaseState from "../state";

export enum LoginActionsTypes {
    LOGOUT_REQUEST = "@login/LOGOUT_REQUEST",
    LOGOUT_SUCCESS = "@login/LOGOUT_SUCCESS",

    FETCH_USER_REQUEST = "@login/FETCH_USER_REQUEST",

    FETCH_REQUEST = "@login/FETCH_REQUEST",
    FETCH_SUCCESS = "@login/FETCH_SUCCESS",
    FETCH_ERROR = "@login/FETCH_ERROR",

    SET_TOKEN = '@login/SET_TOKEN',
    SET_USER = '@login/SET_USER',

    INIT_SOCIAL_REGISTER_OR_LOGIN_REQUEST = "@login/INIT_SOCIAL_REGISTER_OR_LOGIN_REQUEST",
    PROCESS_REDIRECT_URL_AFTER_LOGIN = "@login/PROCESS_REDIRECT_URL_AFTER_LOGIN",

    FORGOT_PASSWORD = "@login/FORGOT_PASSWORD",
    FORGOT_PASSWORD_SUCCESS = "@login/FORGOT_PASSWORD_SUCCESS",

    RESET_PASSWORD = "@login/RESET_PASSWORD",
    RESET_PASSWORD_SUCCESS = "@login/RESET_PASSWORD_SUCCESS",
}

export interface LoginState extends BaseState {
    readonly isLoggedIn: boolean;
    readonly user?: User;
    readonly socialResponsePayload?: any;
}
