import React from "react";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";


type DeleteMilestoneDialogProps = {
    showDialog: boolean
    onConfirm: () => void
    onCancel: () => void
}

const DeleteMilestoneDialog: React.FC<DeleteMilestoneDialogProps> = ({
                                                                        showDialog,
                                                                        onConfirm,
                                                                        onCancel
                                                                    }) => {
    return <ConfirmDialog
        showDialog={showDialog}
        confirmButtonText={`Delete`}
        onCancel={onCancel} onConfirm={onConfirm}
        title={`Delete Milestone`}>
        <p>Are you sure you want to remove this milestone?</p>
    </ConfirmDialog>
}

export default DeleteMilestoneDialog;
