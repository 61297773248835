import Milestone from "../../models/Milestone";

export function findMaxMilestoneId(milestones: Milestone[]) {
    if (milestones.length === 0) {
        return 0;
    }

    const milestoneIds = milestones.map((m) => m.id)

    return Math.max(...milestoneIds) ?? 0
}

export function nextMilestoneId(milestones: Milestone[]) {
    const maxMilestoneId = findMaxMilestoneId(milestones);

    return maxMilestoneId + 1
}

/**
 *
 * @param milestones
 */
export function findFirstMilestone(milestones: Milestone[]): Milestone | undefined {
    let firstMilestone: Milestone | undefined;
    milestones?.map((m) => {
        if (!firstMilestone) {
            firstMilestone = m
        } else if (m.step_number < firstMilestone.step_number) {
            firstMilestone = m
        }
    })

    return firstMilestone;
}
