import React from "react";
import EntityCard from "../Card";
import {Profile} from "../../models/Profile";

type Params = {
    profile?: Profile
    children?: React.ReactNode
}

/**
 *
 * @param recipient
 * @constructor
 */
export const ProfileCard: React.FC<Params> = ({profile}: Params) => {
    const displayLabel = profile?.name ?? profile?.email ?? ''

    return <div className="profile-card">
        <EntityCard hideLabel={true} entityType={`profile`} canEdit={false} displayLabel={displayLabel}>
            <h5>{displayLabel}</h5>
            <div className="email-container">
                <i className="svg-icon email highlighted"></i>{` `}{profile?.email}
            </div>
            {profile?.mobile && <div className="phone-container">
                <i className="svg-icon phone highlighted"></i>{` `}{profile?.mobile}
            </div>}
        </EntityCard>
    </div>
}

export default ProfileCard
