import Milestone from "../models/Milestone";
import {ChangeEvent} from "react";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {Control} from "react-hook-form";
import {OmitResetState, SetValueConfig, UnpackNestedValue} from "react-hook-form/dist/types/form";
import {DeepPartial} from "react-hook-form/dist/types/utils";
import {Ref} from "react-hook-form/dist/types/fields";
import {ValidationRules} from "react-hook-form/dist/types/validator";

export interface IKeyValueObject {
    [key: string]: string | number | null | undefined
}

export interface AgreementProps {
    downloadAttachment?: (id: string, fileName: string) => void;
    showActions?: boolean;
    setIsLoading: (isLoading: boolean) => void;
    approveCancellationOfMilestone?: (id: string) => void;
    rejectCancellationOfMilestone?: (id: string) => void;
    requestPaymentRequest?: (id: string) => void;
    requestDepositRequest?: (id: string) => void;
    downloadMilestoneReceipt?: (id: string) => void;
    requestCancelMilestone?: (id: string, payableAgreementId: string, reason: string) => void;
    requestDeleteMilestone?: (id: string, payableAgreementId: string) => void;
    showAddAttachments?: boolean
}

export interface AuthPageLayoutStateProps {
    children?: any;
    pageTitle: string;
    isLoading: boolean;
}

export interface NavItemStateProps {
    label: string;
    route: string;
    isExternal?: boolean
    icon?: string;
    svgIcon?: string;
    subItems?: NavItemStateProps[];
}

export type NavItemProps = NavItemStateProps

export enum AlertLevel {
    PRIMARY,
    SECONDARY,
    SUCCESS,
    INFO,
    DANGER,
    DARK,
    LIGHT
}

export type AuthPageLayoutProps = AuthPageLayoutStateProps


/**
 *
 */
export interface HtmlSelectElementProp {
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    value: string
}

export interface ReactHookFormProps {
    errors: FieldErrors
    control: Control

    setValue<TFieldName extends string>(name: TFieldName, value: any, options?: SetValueConfig): void

    reset: (values?: UnpackNestedValue<DeepPartial<any>>, omitResetState?: OmitResetState) => void;

    register(ref: (any & Ref) | null, rules?: ValidationRules): any;
}
