import React from "react";
import {Controller} from "react-hook-form";
import AsyncAutocomplete from "../Autocomplete/AsyncAutocomplete";
import ServiceManager from "../../services/ServiceManager";
import classnames from "classnames";
import {ReactHookFormProps} from "../types";
import Recipient from "../../models/Recipient";

interface AsyncRecipientsDropDownProps extends ReactHookFormProps {
    onSelected?: (recipient: Recipient) => void;
}

export const AsyncRecipientsDropDown: React.FC<AsyncRecipientsDropDownProps> = ({
                                                                                    errors,
                                                                                    setValue,
                                                                                    control,
                                                                                    reset,
                                                                                    register,
                                                                                    onSelected
                                                                                }) => {
    return <Controller
        name="recipient_id" control={control}
        defaultValue={false} render={(field) => {
        return <div
            className={classnames('form-control aysnc-autocomplete', {'is-invalid': errors.recipient_user_email})}>
            <AsyncAutocomplete
                entityType={`recipient`}
                onChange={((event, value) => {
                    if (value === null) {
                        reset()
                        return;
                    }

                    setValue('recipient_user_email', value.entity.email);
                    setValue('recipient_id', value.id);
                    if (onSelected) {
                        onSelected(value.entity)
                    }
                })}
                resourceManagerFactory={ServiceManager.dataApi.recipient}
                inputLabel={`contact`} />
            {errors.recipient_user_email &&
                <div className="invalid-feedback">{errors.recipient_user_email.message}</div>}
        </div>
    }}
    />
}

export default AsyncRecipientsDropDown;
