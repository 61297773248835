import React from "react";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import Utils from "../../../services/Utils";

type RequestPaymentDialogProps = {
    showDialog: boolean
    onConfirm: () => void
    onCancel: () => void,
    amount?: number
}

const RequestPaymentDialog: React.FC<RequestPaymentDialogProps> = ({
                                                                       showDialog,
                                                                       onConfirm,
                                                                       onCancel,
                                                                       amount
                                                                   }) => {

    const forAmount = !!amount ? <>of <b>{Utils.formatAmount(amount)}</b> </> : ``
    return <ConfirmDialog
        showDialog={showDialog}
        confirmButtonText={`Request payment`}
        onCancel={onCancel} onConfirm={onConfirm}
        title={`Requesting payment`}>
        <p>You're now requesting payment to be released {forAmount} for this milestone. <em>Make
            sure you have fulfilled your obligation for this milestone.</em></p>
    </ConfirmDialog>
}

export default RequestPaymentDialog;
