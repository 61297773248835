import React  from 'react'
import Block from './Blocks/Block'
import { Avatar, IconButton } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

type CardParams = {
    entityType: 'recipient' | 'user' | 'business' | 'profile'
    displayLabel: string
    children: React.ReactNode
    canEdit?: boolean
    onEditHandleClick?: (event: React.MouseEvent<HTMLElement>) => void
    hideLabel?: boolean
    imgUrl?: string
}

/**
 *
 * @param title
 * @constructor
 */
const EntityCard: React.FC<CardParams> = ({
    entityType,
    displayLabel,
    canEdit,
    onEditHandleClick,
    children,
    hideLabel,
}: CardParams) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event.currentTarget) {
            setAnchorEl(event.currentTarget)
        }
    }
    // const avatar = displayLabel?.substr(0, 1)

    return <Block>
        {canEdit && onEditHandleClick && <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="recipient-card-edit" anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={onEditHandleClick}>Edit</MenuItem>
            </Menu>
        </>}
        {(!hideLabel) && <h4>{entityType === 'user' ? 'Seller' : 'Customer'}</h4>}
        {/*<Avatar>{avatar && avatar.toUpperCase()}</Avatar>*/}
        {children}
    </Block>
}

export default EntityCard
