import {all, call, put, takeEvery} from 'redux-saga/effects';
import {addToast} from "../action";
import {handleError} from "../saga";
import {DashboardActionTypes} from "./types";
import {fetchError, fetchSuccess} from "./action";
import {AxiosResponse} from "axios";
import ServiceManager from "../../services/ServiceManager";
import {Dashboard} from "../../models/Dashboard";

function* handleFetch(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.dashboard,
            ServiceManager.dataApi.dashboard.getById,
        ], '');
        const dashboard: Dashboard = response.data as Dashboard;
        yield put(fetchSuccess(dashboard));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message))
    }
}

function* watchAllRequest() {
    yield takeEvery(DashboardActionTypes.FETCH_REQUEST, handleFetch)
}

function* dashboardSaga() {
    yield all([
        watchAllRequest()
    ]);
}

export default dashboardSaga;