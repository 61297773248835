import {Reducer} from "redux";
import {PayableAgreementActionTypes, PayableAgreementState} from "./types";
import {ToastActionTypes} from "../types";

export const initialState: PayableAgreementState = {
    data: undefined,
    loading: false,
    notifications: {
        errors: [],
        alerts: [],
        infos: [],
        warnings: [],
        success: []
    },

    listItems: [],
    paginationMeta: undefined,
    toasts: [],
}

const reducer: Reducer<PayableAgreementState> = (state = initialState, action) => {
    switch (action.type) {
        case PayableAgreementActionTypes.SAVE_REQUEST:
        case PayableAgreementActionTypes.REJECT_PAYMENT_REQUEST:
        case PayableAgreementActionTypes.APPROVE_PAYMENT_REQUEST:
        case PayableAgreementActionTypes.REQUEST_DEPOSIT_REQUEST:
        case PayableAgreementActionTypes.REQUEST_PAYMENT_REQUEST:
        case PayableAgreementActionTypes.SEND_AGREEMENT_REQUEST:
        case PayableAgreementActionTypes.UPDATE_REQUEST:
        case PayableAgreementActionTypes.FETCH_LIST_REQUEST:
        case PayableAgreementActionTypes.FETCH_REQUEST:
        case PayableAgreementActionTypes.CREATE_REQUEST:
        case PayableAgreementActionTypes.DELETE_MILESTONE_FETCH_REQUEST:
        case PayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_REQUEST:
        case PayableAgreementActionTypes.APPROVE_CANCEL_MILESTONE_FETCH_REQUEST:
        case PayableAgreementActionTypes.REJECT_CANCEL_MILESTONE_FETCH_REQUEST:
        case PayableAgreementActionTypes.DOWNLOAD_RECEIPT_REQUEST:
        case PayableAgreementActionTypes.DOWNLOAD_MILESTONE_RECEIPT_REQUEST:
        case PayableAgreementActionTypes.CREATE_MILESTONE_REQUEST:
        case PayableAgreementActionTypes.NEGOTIATE_AMEND_MILESTONE_AMOUNT_REQUEST:
        case PayableAgreementActionTypes.CANCEL_AGREEMENT_REQUEST:
        case PayableAgreementActionTypes.UPDATE_MILESTONE_REQUEST:
            return {
                ...state, loading: true, toasts: [], listItems: [], notifications: {
                    errors: [],
                    alerts: [],
                    infos: [],
                    warnings: [],
                    success: []
                }
            }

        case ToastActionTypes.RESET_TOASTS:
            return {...state, toasts: []}

        case ToastActionTypes.ADD_TOAST:
            const existingToasts = state.toasts ?? [];
            return {...state, toasts: [...existingToasts, action.payload]}

        case PayableAgreementActionTypes.FETCH_LIST_SUCCESS:
            return {
                ...state,
                listItems: action.payload.list,
                loading: false,
                paginationMeta: action.payload.paginationMeta
            }

        case PayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_SUCCESS:
        case PayableAgreementActionTypes.DELETE_MILESTONE_FETCH_SUCCESS:
        case PayableAgreementActionTypes.UPDATE_MILESTONE_SUCCESS:
        case PayableAgreementActionTypes.CREATE_SUCCESS:
        case PayableAgreementActionTypes.FETCH_SUCCESS:
            return {...state, data: action.payload.data, loading: false}

        case PayableAgreementActionTypes.DOWNLOAD_RECEIPT_SUCCESS:
        case PayableAgreementActionTypes.DOWNLOAD_MILESTONE_RECEIPT_SUCCESS:
            return {...state, loading: false}

        case PayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_ERROR:
        case PayableAgreementActionTypes.DELETE_MILESTONE_FETCH_ERROR:
        case PayableAgreementActionTypes.CREATE_ERROR:
        case PayableAgreementActionTypes.FETCH_ERROR:
        case PayableAgreementActionTypes.DOWNLOAD_RECEIPT_ERROR:
        case PayableAgreementActionTypes.DOWNLOAD_MILESTONE_RECEIPT_ERROR:
        case PayableAgreementActionTypes.NEGOTIATE_AMEND_MILESTONE_AMOUNT_ERROR:
        case PayableAgreementActionTypes.CANCEL_AGREEMENT_ERROR:
            return {
                ...state, loading: false, notifications: {
                    ...state.notifications,
                    errors: action.payload
                }
            }

        default:
            return state;
    }
}
export {reducer as PayableAgreementReducer}
