import {useState} from 'react';

/**
 * @param defaultValue
 */
export const useShowDialog = (defaultValue: boolean = false) => {
    const [showDialog, setShowDialog] = useState<boolean>(defaultValue);

    return {
        showDialog,
        show: () => setShowDialog(true),
        hide: () => setShowDialog(false)
    }
}
