import React from "react";
import Status, {UserType} from "../enums";

export const LANG = {
    DashboardPage: {
        DISPLAY_NAME: "Dashboard",
    },
    RecipientListPage: {
        DISPLAY_NAME: "Contacts",
        DISPLAY_NAME_TOOLTIP: "Contacts are 'Buyers & clients' that you buy from, sell to OR trade with"
    },
    AgreementListPage: {
        DISPLAY_NAME: "Agreements",
        DISPLAY_NAME_TOOLTIP: "All your agreements that have been sent out, both personal & business",
        DISPLAY_NAME_NOTE: `Agreements that you have sent both personal or business.`,
        [Status.UserTypes.CONTRACTOR]: {}
    },
    IncomingAgreementListPage: {
        DISPLAY_NAME: "Received Smart Agreements",
        DISPLAY_NAME_TOOLTIP: "All your received agreements both personal & business related",
        DISPLAY_NAME_NOTE: `Agreements that have been offered to you on a personal or business interaction & are pending for you to approve.`,
    },
    BankAccountsPage: {
        DISPLAY_NAME: "Bank Accounts",
    },
    BusinessPageList: {
        DISPLAY_NAME: "My Businesses",
    },
    TransactionPageList: {
        DISPLAY_NAME: "My Transactions",
    },
    ThirdPartyIntegrationPage: {
        DISPLAY_NAME: "Integrations",
        DISPLAY_NAME_TOOLTIP: ``,
        DISPLAY_NAME_NOTE: `All your integrated apps that you have connected to your account.`,
    },
    ProfilePage: {
        DISPLAY_NAME: "My Profile",
    },
}

export const Icons = {
    Milestones: {
        [Status.UserTypes.CONTRACTOR]: {
            [Status.Milestones.REQUEST_DEPOSIT]: "fa fa-sync",
            [Status.Milestones.MONEY_SECURED]: "fa fa-lock",
            [Status.Milestones.PAYMENT_APPROVED]: "fa fa-clock",
            [Status.Milestones.PAYMENT_REQUESTED]: "fa fa-clock",
            [Status.Milestones.PROCESSING_DEPOSIT]: "fa fa-clock",
            [Status.Milestones.RELEASING_MONEY]: "fa fa-clock",
        },
        [Status.UserTypes.CLIENT]: {
            [Status.Milestones.REQUEST_DEPOSIT]: "fa fa-sync",
            [Status.Milestones.MONEY_SECURED]: "fa fa-lock",
            [Status.Milestones.PAYMENT_APPROVED]: "fa fa-clock",
            [Status.Milestones.PAYMENT_REQUESTED]: "fa fa-clock",
            [Status.Milestones.RELEASING_MONEY]: "fa fa-clock",
            [Status.Milestones.PROCESSING_DEPOSIT]: "fa fa-clock",
        },
    }
}

export const Labels = {
    Milestones: {
        [Status.UserTypes.CONTRACTOR]: {
            [Status.Milestones.APPROVE_DEPOSIT]: "Payment approved",
            [Status.Milestones.CANCELLATION_REQUESTED]: "Pending cancellation",
            [Status.Milestones.REJECT_DEPOSIT]: "Deposit request rejected by Client",
            [Status.Milestones.MONEY_SECURED]: "Money secured with paysecure",
            [Status.Milestones.MONEY_CLEARING]: "Money secured with paysecure",
            [Status.Milestones.PAYMENT_APPROVED]: "Funds release pending",
            [Status.Milestones.PAYMENT_CLEARED]: "Money is in your account",
            [Status.Milestones.PAYMENT_CLEARED]: "Money released",
            [Status.Milestones.PAYMENT_REFUNDED]: "Cancelled & payment refunded",
            [Status.Milestones.PAYMENT_REQUESTED]: "Funds release pending",
            [Status.Milestones.PENDING]: "Ready",
            [Status.Milestones.PROCESSING_DEPOSIT]: "Securing funds",
            [Status.Milestones.RELEASING_MONEY]: "Funds release pending",
            [Status.Milestones.REQUEST_DEPOSIT]: "Payment requested",
            [Status.Milestones.REQUESTING_MONEY_FAILED]: "Failed to process at payment provider",
            "close": "complete",
            [Status.Milestones.PAYMENT_REJECTED]: "Request rejected by Customer",
        },
        [Status.UserTypes.CLIENT]: {
            [Status.Milestones.PENDING]: "Ready",
            [Status.Milestones.REQUEST_DEPOSIT]: "Payment requested",
            [Status.Milestones.APPROVE_DEPOSIT]: "Payment approved",
            [Status.Milestones.REJECT_DEPOSIT]: "Deposit request rejected by You",
            [Status.Milestones.PROCESSING_DEPOSIT]: "Securing funds",
            [Status.Milestones.MONEY_SECURED]: "Money secured with paysecure",
            [Status.Milestones.MONEY_CLEARING]: "Money secured with paysecure",
            [Status.Milestones.PAYMENT_REQUESTED]: "Funds release pending",
            [Status.Milestones.PAYMENT_APPROVED]: "Releasing money",
            [Status.Milestones.RELEASING_MONEY]: "Releasing money",
            [Status.Milestones.PAYMENT_CLEARED]: "Money released",
            [Status.Milestones.CANCELLATION_REQUESTED]: "Pending cancellation",
            [Status.Milestones.PAYMENT_REFUNDED]: "Cancelled & payment refunded",
            [Status.Milestones.REQUESTING_MONEY_FAILED]: "Failed to process at payment provider",
            "close": "complete",
            [Status.Milestones.PAYMENT_REJECTED]: "Request rejected by you",
        }
    },
    PayableAgreement: {
        [Status.UserTypes.CONTRACTOR]: {
            [Status.PayableAgreement.APPROVE_DEPOSIT]: "Deposit approved",
            [Status.PayableAgreement.REQUESTING_MONEY]: "Requesting money",
            [Status.PayableAgreement.RELEASING_MONEY]: "Releasing money",
            [Status.PayableAgreement.FAILED_AT_PAYMENT_PROVIDER]: "Failed at payment provider",
            [Status.PayableAgreement.IN_PROGRESS]: "Agreement in progress",
            [Status.PayableAgreement.MONEY_SECURED]: "Money secured",
            [Status.PayableAgreement.OVERDUE]: "Overdue",
            [Status.PayableAgreement.PAYMENT_APPROVED]: "Payment approved",
            [Status.PayableAgreement.PAYMENT_CLEARED]: "Payment cleared",
            [Status.PayableAgreement.PAYMENT_REJECTED]: "Payment rejected",
            [Status.PayableAgreement.PAYMENT_REQUESTED]: "Payment requested",
            [Status.PayableAgreement.PENDING]: "Draft",
            [Status.PayableAgreement.PROCESSING_DEPOSIT]: "Processing Deposit",
            [Status.PayableAgreement.REJECT_DEPOSIT]: "Deposit rejected",
            [Status.PayableAgreement.REQUEST_DEPOSIT]: "Deposit requested",
            [Status.PayableAgreement.SENT]: "Awaiting Acceptance",
            "close": "complete",
        },
        [Status.UserTypes.CLIENT]: {
            [Status.PayableAgreement.APPROVE_DEPOSIT]: "Deposit approved",
            [Status.PayableAgreement.REQUESTING_MONEY]: "Requesting money",
            [Status.PayableAgreement.RELEASING_MONEY]: "Releasing money",
            [Status.PayableAgreement.FAILED_AT_PAYMENT_PROVIDER]: "Failed at payment provider",
            [Status.PayableAgreement.IN_PROGRESS]: "Agreement in progress",
            [Status.PayableAgreement.MONEY_SECURED]: "Money secured",
            [Status.PayableAgreement.OVERDUE]: "Overdue",
            [Status.PayableAgreement.PAYMENT_APPROVED]: "Payment approved",
            [Status.PayableAgreement.PAYMENT_CLEARED]: "Payment cleared",
            [Status.PayableAgreement.PAYMENT_REJECTED]: "Payment rejected",
            [Status.PayableAgreement.PAYMENT_REQUESTED]: "Payment requested",
            [Status.PayableAgreement.PENDING]: "Draft",
            [Status.PayableAgreement.PROCESSING_DEPOSIT]: "Processing Deposit",
            [Status.PayableAgreement.REJECT_DEPOSIT]: "Deposit rejected",
            [Status.PayableAgreement.REQUEST_DEPOSIT]: "Deposit requested",
            [Status.PayableAgreement.SENT]: "Received",
            "close": "complete",
        },
    },
    PayableAgreementTypes: {
        standard: 'Secured Agreement',
        only_payment_request: 'Auto-debit milestones',
        unsecured_agreement: 'Unsecured Agreement',
    }
}

export type PayableAgreementTypes = 'standard' | 'only_payment_request' | 'unsecured_agreement';

export function renderAgreementTypeLabel(type?: PayableAgreementTypes) {
    return (type && Labels.PayableAgreementTypes[type]) || type;
}

// @ts-ignore
export default function renderMilestoneStatusLabel(status: string, userType?: UserType): string {
    // @ts-ignore
    return Labels.Milestones[userType][status] || status.replaceAll("_", " ")
}

/**
 * @param status
 * @param userType
 */
export function renderPayableAgreementStatusLabel(status: string, userType?: UserType) {
    if (userType) {
        return Labels.PayableAgreement[userType][status] || status;
    }

    return status;
}

export function renderStatusIcon(status: string, userType?: UserType): React.ReactNode {
    if (userType) {
        const statusIcon = Icons.Milestones[userType][status]
        return statusIcon ? <i className={statusIcon}/> : null
    }

    return null
}
