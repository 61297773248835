import React from "react";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";

import MainLayout from "../../components/Layouts/MainLayout";
import {PayableAgreement} from "../../models/PayableAgreement";
import {createRequest} from "../../store/payableAgreement/action";
import {ApplicationState} from "../../store";
import Statues from "../../enums";
import {NotificationState} from "../../store/state";
import NotificationsContainer from "../../components/Notifications/Containers";
import Milestone from "../../models/Milestone";
import Utils from "../../services/Utils";
import MomentLocaleUtils from "react-day-picker/moment";
import ProductAgreementForm from "../../components/PayableAgreement/ProductAgreementForm";
import CreationWizard from "../../components/PayableAgreement/CreationWizard";

// Defining Props
interface PropsFromState {
    loading: boolean;
    data?: PayableAgreement;
    notifications: NotificationState
}

// Defining PROPS.
interface PropsFromDispatch {
    createAgreement: (payableAgreement: PayableAgreement, sendNow: boolean) => any;
}

type AllProps = PropsFromState & PropsFromDispatch;

/**
 * @constructor
 */
const CreateProductAgreementPage: React.FunctionComponent<AllProps> = ({loading, createAgreement, notifications}) => {
    const methods = useForm<PayableAgreement>({
        defaultValues: {
            id: -1,
            status: Statues.PayableAgreement.PENDING,
            title: '',
            type: "standard",
            action: "selling",
            agreement_details: "",
            product_service_provided: "product",
            milestones: [],
            sendNow: false,
        }
    })

    const onSubmit = (data: PayableAgreement) => {
        // Let's create that fake milestone
        if (data && data.recipient && data.total_amount) {
            const amount = parseFloat(data.total_amount.toString()),
                milestone_fee = (amount * 0.01)
            let milestone: Milestone = {
                amount,
                milestone_fee,
                milestone_fee_display: Utils.formatAmount(milestone_fee),
                description: data.agreement_details,
                id: 0,
                payout_amount: 0,
                status: "pending",
                step_number: "1",
                title: data.title,
                total_amount: amount,
                due_by: (MomentLocaleUtils.formatDate(new Date(), 'DD/MM/YYYY'))
            }

            data.milestones = [milestone];
            data.due_by = MomentLocaleUtils.formatDate(new Date(), 'DD/MM/YYYY')
            data.product_service_provided = 'product'

            createAgreement(data, false);
        }
    }

    // @ts-ignore
    return <MainLayout pageTitle={`Create Agreement`} isLoading={loading} errors={[]}>
        <div className="content">
            <NotificationsContainer notifications={notifications}/>
        </div>

        <FormProvider {...methods}>
            <ProductAgreementForm onSubmit={onSubmit}/>
        </FormProvider>
    </MainLayout>
}

const mapStateToProps = ({payableAgreement, recipient}: ApplicationState) => ({
    loading: payableAgreement.loading || recipient.loading,
    notifications: payableAgreement.notifications,
    data: payableAgreement.data
})

/**
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        createAgreement: (payableAgreement: PayableAgreement, sendAgreement: boolean = false) => {
            if (!payableAgreement?.recipient) {
                payableAgreement.recipient = {
                    id: -1,
                    first_name: payableAgreement?.recipient_first_name,
                    last_name: payableAgreement?.recipient_last_name,
                    mobile: payableAgreement.recipient_user_mobile,
                    email: payableAgreement.recipient_user_email
                };
            }

            // Check if we want to send at the same time.
            payableAgreement.sendNow = sendAgreement;

            dispatch(createRequest({data: payableAgreement}));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductAgreementPage);
