import {Reducer} from "redux";
import {ThirdPartyConnectionState, ThirdPartyConnectionActionTypes} from "./types";
import {ToastActionTypes} from "../types";

export const initialState: ThirdPartyConnectionState = {
    data: undefined,
    loading: false,
    notifications: {
        errors: [],
        alerts: [],
        infos: [],
        warnings: []
    },

    listItems: [],
    paginationMeta: undefined,
    toasts: [],
    accounts: []
}

/**
 *
 * @param state
 * @param action
 */
const reducer: Reducer<ThirdPartyConnectionState> = (state = initialState, action) => {
    switch (action.type) {
        case ThirdPartyConnectionActionTypes.FETCH_LIST_REQUEST:
        case ThirdPartyConnectionActionTypes.FETCH_REQUEST:
        case ThirdPartyConnectionActionTypes.FETCH_INVOICE_REQUEST:
        case ThirdPartyConnectionActionTypes.CONVERT_DOCUMENT_TO_PAYABLE_AGREEMENT_REQUEST:
        case ThirdPartyConnectionActionTypes.FETCH_ACCOUNTS_LIST_REQUEST:
        case ThirdPartyConnectionActionTypes.CREATE_REQUEST:
            return {...state, loading: true, toasts: [], errors: ""}

        case ToastActionTypes.RESET_TOASTS:
            return {...state, toasts: []}

        case ThirdPartyConnectionActionTypes.FETCH_INVOICE_SUCCESS:
            return {...state, document: action.payload, loading: false, errors: ""}

        case ThirdPartyConnectionActionTypes.FETCH_LIST_SUCCESS:
            return {
                ...state,
                listItems: action.payload.listOfThirdPartyConnections,
                loading: false,
                paginationMeta: action.payload.paginationMeta
            }

        case ThirdPartyConnectionActionTypes.FETCH_ACCOUNTS_LIST_SUCCESS:
            return {...state, accounts: action.payload, loading: false}

        case ThirdPartyConnectionActionTypes.FETCH_SUCCESS:
            return {...state, data: action.payload, loading: false}

        case ThirdPartyConnectionActionTypes.FETCH_ERROR:
            return {...state, loading: false, errors: action.payload}

        default:
            return state;
    }
}
export {reducer as ThirdPartyConnectionReducer}
