import React from "react";

const UserContext: React.Context<any> = React.createContext({
    name: "Hello user!"
});

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

export default UserContext
