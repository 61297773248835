import React, {useState} from "react";

import Milestone from "../../models/Milestone";
import Block from "../Blocks/Block";
import {PayableAgreement} from "../../models/PayableAgreement";
import MilestoneFormDialog from "./Dialogs/MilestoneFormDialog";
import {nextMilestoneId} from "../utils";
import Utils from "../../services/Utils";
import classNames from "classnames";


interface MilestoneRowItemProps {
    children?: React.ReactChild
    payableAgreement?: PayableAgreement;
    milestone: Milestone;
    payableAgreementStatus: string;
    hideFees?: boolean
    onEditMilestone?: (milestone: Milestone) => void
}

export const MilestoneRowItem: React.FC<MilestoneRowItemProps> = ({
                                                                      payableAgreement,
                                                                      milestone,
                                                                      onEditMilestone,
                                                                      hideFees,
                                                                      payableAgreementStatus,
                                                                      children
                                                                  }) => {

    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    return <Block>
        {!showEditForm && <>
            <div className="milestone-row" data-milestoneId={milestone.id}>
                <div className="step-number">
                    <label htmlFor="">Step number</label>
                    <h4>{milestone?.step_number}</h4>
                </div>
                <div className="title">
                    <label htmlFor="">Title</label>
                    <h4>{milestone?.title}</h4>
                </div>
                <div className="description text-muted">
                    <label htmlFor="">Description</label>
                    {milestone?.description}
                </div>
                <div className="due text-muted">
                    <label htmlFor="">Due by</label>
                    {milestone?.due_by}
                </div>
                {hideFees !== true ?
                    <div className="fees text-muted">
                        {/* ToDo: This needs to be done properly, only hide when there is a discounted fee */}
                        {/* {milestone?.milestone_fee_display} */}
                        <label htmlFor="">Fees</label>
                        <div className="discounted-fee text-muted">{milestone?.discounted_milestone_fee_display}</div>
                    </div> : ``}
                <div
                    className={classNames('amount text-muted', {'amended-amount': milestone?.amended_amount && milestone?.amended_amount > 0})}>
                    <label htmlFor="">Amount</label>
                    <div className="milestone-amount">{milestone?.amount_display}</div>
                    <div className="amended-amount">
                        {milestone?.amended_amount && milestone?.amended_amount > 0 ? Utils.formatAmount(milestone?.amended_amount) : ''}
                    </div>
                </div>
            </div>
            {React.isValidElement(children) ? <div className="block-footer milestone-row-actions">
                {React.cloneElement(children, {
                    milestone,
                    payableAgreement,
                    payableAgreementStatus,
                    onShowEdit: () => setShowEditForm(true),
                    cancelShowEdit: () => setShowEditForm(false),
                    onEdit: (milestone: Milestone) => {
                        if (onEditMilestone) {
                            onEditMilestone(milestone);
                        }
                    }
                })}
            </div> : null}
        </>}
        {showEditForm && <MilestoneFormDialog action={'save'} nextMilestoneId={nextMilestoneId(payableAgreement?.milestones ?? [])}
                                              onConfirm={() => {
                                                  // ToDo: Somehow save this form.
                                              }} milestone={milestone} onCancel={() => setShowEditForm(false)}/>}
    </Block>
}

export default MilestoneRowItem
