import React, {useState} from "react";
import {DangerButton, SuccessButton} from "../../Buttons";
import ApproveOrRejectMilestoneCancellationDialog from "../Dialogs/ApproveOrRejectMilestoneCancellationDialog";
import {
    CancellationRequestedByContractorMilestone,
    CancellationRequestedByCustomerMilestone
} from "../../../models/Milestone";

export interface CancelMilestoneButtonsProps {
    milestone: CancellationRequestedByCustomerMilestone | CancellationRequestedByContractorMilestone
    onConfirm: (cancellation: CancellationAction) => void;
    onCancel: () => void;
}

export type CancellationAction = 'approve' | 'reject';
export const ApproveMilestoneCancellationButtons: React.FunctionComponent<CancelMilestoneButtonsProps> = ({
                                                                                                              onConfirm,
                                                                                                              onCancel,

                                                                                                          }: CancelMilestoneButtonsProps) => {

    const [dialogAction, setDialogAction] = useState<CancellationAction>('approve');
    const [showApproveDialog, setShowApproveDialog] = useState(false),
        handleOnConfirm = () => {
            setShowApproveDialog(false);
            onConfirm(dialogAction);
        },
        handleOnCancel = () => {
            setShowApproveDialog(false);
            onCancel()
        }

    return <>
        <ApproveOrRejectMilestoneCancellationDialog
            action={dialogAction}
            showDialog={showApproveDialog}
            onConfirm={handleOnConfirm}
            onCancel={handleOnCancel}/>
        <SuccessButton className={`btn-sm my-1`} onClick={() => {
            setDialogAction('approve');
            setShowApproveDialog(true);
        }}>Approve request for cancellation</SuccessButton>{` `}
        <DangerButton className={`btn-sm my-1`} onClick={() => {
            setDialogAction('reject');
            setShowApproveDialog(true);
        }}>Decline request for cancellation</DangerButton>{` `}
    </>
}

export default ApproveMilestoneCancellationButtons;
