import React from "react";
import {NavLink} from "react-router-dom";
import {NavItemProps} from "../types";
import classnames from 'classnames'

const NavItem: React.FunctionComponent<NavItemProps> = ({
                                                            label,
                                                            svgIcon,
                                                            icon,
                                                            route,
                                                            subItems,
                                                            isExternal
                                                        }) => {
    route = route || "";
    icon = ("nav-main-link-icon si si-" + icon) || "";

    const renderIcon = () => {
        if (svgIcon) {
            return <i className={`svg-icon ${svgIcon}`}/>
        } else if (icon && icon.length > 0) {
            return <i className={icon}/>
        }
    }

    const hasSubItems = subItems || false;

    return <li className={classnames("nav-main-item", {"open": hasSubItems})}>
        {isExternal === true ?
            <a target='_blank'
               className={classnames("nav-main-link", {'nav-main-link nav-main-link-submenu': hasSubItems})}
               href={route}>
                {renderIcon()}
                <span className="nav-main-link-name">{label}</span></a> :
            <NavLink activeClassName={`active`} data-toggle="submenu"
                     className={classnames("nav-main-link", {'nav-main-link nav-main-link-submenu': hasSubItems})}
                     to={route}>
                {renderIcon()}
                <span className="nav-main-link-name">{label}</span>
            </NavLink>}
        {subItems && <ul className="nav-main-submenu">
            {subItems.map((item, key) => <NavItem key={key} label={item.label} icon={item.icon} route={item.route}/>)}
        </ul>}
    </li>
}

export default NavItem
