import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Statuses from '../../enums'
import Milestone from "../../models/Milestone";
import {PayableAgreement} from "../../models/PayableAgreement";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {DangerButton, SuccessButton} from "../Buttons";
import AuthoriseDepositRequestDialog from "./Dialogs/AuthoriseDepositRequestDialog";
import CancelDepositRequestDialog from "./Dialogs/CancelDepositRequestDialog";
import ApprovePaymentRequestDialog from "./Dialogs/ApprovePaymentRequestDialog";
import RejectPaymentRequestDialog from "./Dialogs/RejectPaymentRequestDialog";
import ApproveMilestoneCancellationButtons from "./Buttons/ApproveMilestoneCancellationButtons";
import {WarningNotification} from "../Notifications";
import CancelMilestoneButton from "./Buttons/CancelMilestoneButton";

import {
    acceptPayableAgreementRequest,
    approveCancellationOfMilestoneRequest,
    approveDepositRequest,
    approvePaymentRequest,
    cancelMilestoneRequest,
    rejectDepositRequest,
    rejectPayableAgreementRequest,
    rejectPaymentRequest,
    sendPayableAgreementApprovalTFACode
} from "../../store/incomingPayableAgreement/action";
import {StatusPill} from "./StatusPill";
import {MilestoneErrors} from "./MilestoneErrors";
import CancellationRequestedMilestoneActions from "./Actions/CancellationRequestedMilestoneActions";
import ButtonGroup from "../Buttons/ButtonGroup";
import {ListOfAttachments} from "../Attachment";

export interface PropsFromState {
    milestone?: Milestone;
    payableAgreement?: PayableAgreement;
}

export interface PropsFromDispatch {
    approvePaymentRequest?: (milestoneId: string, payableAgreementId: string, tfaVerificationCode: string) => any;
    rejectPaymentRequest?: (milestoneId: string, payableAgreementId: string) => any;
    rejectMilestonePaymentRequest?: (milestoneId: string, payableAgreementId: string, reason: string) => any;
    approveReleaseOfDepositRequest?: (milestoneId: string, payableAgreementId: string) => any;
    rejectReleaseOfDepositRequest?: (milestoneId: number, reason: string) => any;
    approveCancellationOfMilestone?: (milestoneId: number) => any;
    requestCancelMilestone?: (milestoneId: string, payableAgreementId: string, reason: string) => any;
    sendPayableAgreementApprovalTFACode?: () => void;
}

type AppProps = PropsFromState & PropsFromDispatch;

export const MilestoneCustomerActions: React.FunctionComponent<AppProps> = ({
                                                                                payableAgreement,
                                                                                milestone,
                                                                                approvePaymentRequest,
                                                                                requestCancelMilestone,
                                                                                rejectPaymentRequest,
                                                                                approveReleaseOfDepositRequest,
                                                                                rejectReleaseOfDepositRequest,
                                                                                rejectMilestonePaymentRequest,
                                                                                sendPayableAgreementApprovalTFACode,
                                                                                approveCancellationOfMilestone
                                                                            }) => {

    const payableAgreementType = payableAgreement?.type || 'standard';
    const isSecureAgreement = payableAgreementType !== 'unsecured_agreement';
    const [showAuthoriseAlert, setShowAuthoriseAlert] = useState<boolean>(false);
    const [showCancelAlert, setShowCancelAlert] = useState<boolean>(false);
    const [showApprovePaymentAlert, setShowApprovePaymentAlert] = useState<boolean>(false);
    const [showRejectPaymentAlert, setShowRejectPaymentAlert] = useState<boolean>(false);

    const handleApprovePaymentReleaseRequest = (verificationCode: string) => {
            setShowApprovePaymentAlert(false);
            if (approvePaymentRequest && milestone && milestone.id && payableAgreement && payableAgreement?.id) {
                approvePaymentRequest(milestone.id.toString(), payableAgreement?.id.toString(), verificationCode)
            }
        },
        handleRejectPaymentReleaseRequest = (reason: string) => {
            setShowRejectPaymentAlert(false);
            if (rejectMilestonePaymentRequest && milestone && milestone.id && payableAgreement && payableAgreement?.id) {
                rejectMilestonePaymentRequest(milestone.id.toString(), payableAgreement?.id.toString(), reason)
            }
        }

    const handleApproveDepositRequest = () => {
            setShowAuthoriseAlert(false);
            if (approveReleaseOfDepositRequest && milestone && milestone.id && payableAgreement && payableAgreement?.id) {
                approveReleaseOfDepositRequest(milestone.id.toString(), payableAgreement?.id.toString());
            }
        },
        handleRejectDepositRequest = (reason: string) => {
            setShowCancelAlert(false);
            if (rejectReleaseOfDepositRequest && milestone && milestone.id && payableAgreement && payableAgreement?.id) {
                rejectReleaseOfDepositRequest(milestone.id, reason)
            }
        }
    const status = milestone && milestone.status;
    const canApproveDepositRequest = () => {
        if (isSecureAgreement) {
            return (status === Statuses.Milestones.REQUEST_DEPOSIT)
        }

        return (
            payableAgreement?.status === Statuses.PayableAgreement.ACCEPTED &&
            status !== Statuses.Milestones.DRAFT &&
            status !== Statuses.Milestones.APPROVE_DEPOSIT &&
            status !== Statuses.Milestones.PROCESSING_DEPOSIT &&
            status !== Statuses.Milestones.PAYMENT_APPROVED &&
            status !== Statuses.Milestones.PAYMENT_CLEARED
        )
    }
    const canRejectDepositRequest = () => {
        if (isSecureAgreement) {
            return status !== Statuses.Milestones.APPROVED && canApproveDepositRequest()
        }

        return false
    };

    const canApprovePaymentRelease = (status === Statuses.Milestones.PAYMENT_REQUESTED && isSecureAgreement),
        canRejectPaymentRelease = (status !== Statuses.Milestones.APPROVED && canApprovePaymentRelease)

    const canCancelMilestone = payableAgreement && isSecureAgreement && (payableAgreement?.status == Statuses.PayableAgreement.ACCEPTED || payableAgreement?.status === Statuses.PayableAgreement.IN_PROGRESS)
        && !milestone?.cancel_milestone_requested_on
        && !milestone?.cancel_milestone_approved_on
        && milestone?.status !== Statuses.Milestones.REQUEST_DEPOSIT
        && milestone?.status !== Statuses.Milestones.APPROVE_DEPOSIT
        && milestone?.status !== Statuses.Milestones.PROCESSING_DEPOSIT
        && milestone?.status !== Statuses.Milestones.PAYMENT_APPROVED
        && milestone?.status !== Statuses.Milestones.PAYMENT_CLEARED
        && milestone?.status !== Statuses.Milestones.RELEASING_MONEY;

    const handleCancelMilestone = (reason: string) => {
        if (requestCancelMilestone && milestone && milestone.id && payableAgreement && payableAgreement.id) {
            requestCancelMilestone(milestone.id.toString(), payableAgreement.id.toString(), reason);
        }
    }

    const renderMilestoneActions = () => {
        switch (milestone?.status) {
            case "cancellation_requested":
                return milestone?.payable_agreement_id && milestone.cancellation_requested_by === 'seller' &&
                    <CancellationRequestedMilestoneActions milestone={milestone}
                                                           payableAgreementType={payableAgreementType}/>
        }
    }

    const milestoneAmount = milestone?.amended_amount ?? milestone?.amount

    return <div className={`milestone-actions`} data-status={milestone?.status}>
        <div className="status-and-actions">
            {milestone?.status && <div className={'status-container'}>
                <StatusPill status={milestone?.status} userType={`client`}/>
                {milestone?.status === 'payment_cleared' && milestone?.amended_amount && milestone?.amended_amount > 0 ?
                    <span className={`status-info text-info text-muted`}>
                    <i className="fa fa-info-circle text-success"/> Remaining amount refunded
                </span> : null}
            </div>}
            <ButtonGroup>
                {renderMilestoneActions()}
                {canApproveDepositRequest() && payableAgreement && !payableAgreement.can_approve_deposit ?
                    <div className={`py-2`}>
                        <WarningNotification>
                            <>
                                Please connect a bank account so you can approve request for deposit. <a
                                href={`/bank_accounts/connect?return_payable_agreement_id=${payableAgreement?.id}`}>Click
                                here to connect a bank account.</a>
                            </>
                        </WarningNotification>
                    </div> : null}

                {canApproveDepositRequest() ? <>
                    <AuthoriseDepositRequestDialog
                        isAgreementSecured={isSecureAgreement}
                        amount={milestone?.total_amount}
                        onConfirm={handleApproveDepositRequest}
                        title={isSecureAgreement ? `Authorise release of funds` : 'Pay milestone'}
                        onCancel={() => {
                            setShowAuthoriseAlert(false)
                        }} showDialog={showAuthoriseAlert}/>
                    <SuccessButton className={`btn-sm my-1 mr-1`}
                                   disabled={payableAgreement && !payableAgreement.can_approve_deposit}
                                   onClick={() => {
                                       setShowAuthoriseAlert(true)
                                   }}>{isSecureAgreement ? `Authorise release of funds` : 'Pay milestone'}</SuccessButton>
                </> : ``}{` `}
                {canRejectDepositRequest() ?
                    <>
                        <CancelDepositRequestDialog
                            onConfirm={handleRejectDepositRequest}
                            onCancel={() => {
                                setShowCancelAlert(false)
                            }} showDialog={showCancelAlert}/>
                        <DangerButton className={`btn-sm my-1 mr-1 link`} type={`button`}
                                      disabled={payableAgreement && !payableAgreement.can_approve_deposit}
                                      onClick={() => {
                                          setShowCancelAlert(true)
                                      }}>Decline release of funds</DangerButton>
                    </> : ``}{` `}
                {canApprovePaymentRelease ?
                    <>
                        <ApprovePaymentRequestDialog
                            amount={milestoneAmount}
                            showDialog={showApprovePaymentAlert}
                            onConfirm={handleApprovePaymentReleaseRequest}
                            onCancel={() => setShowApprovePaymentAlert(false)}/>
                        <SuccessButton className={`btn-sm my-1 mr-1`} onClick={() => {
                            // ToDo: Disabling TFA
                            // if (sendPayableAgreementApprovalTFACode) {
                            //     sendPayableAgreementApprovalTFACode();
                            // }
                            setShowApprovePaymentAlert(true);
                        }}>Approve Payment Release</SuccessButton>
                    </> : ``}{` `}

                {canRejectPaymentRelease ?
                    <>
                        <RejectPaymentRequestDialog showDialog={showRejectPaymentAlert}
                                                    onConfirm={handleRejectPaymentReleaseRequest}
                                                    onCancel={() => setShowRejectPaymentAlert(false)}/>
                        <DangerButton onClick={() => setShowRejectPaymentAlert(true)} className="btn-sm my-1 mr-1"
                                      type="button">Reject Payment Release</DangerButton>
                    </> : ``}{` `}

                {canCancelMilestone ? <CancelMilestoneButton handleCancelMilestone={handleCancelMilestone}/> : null}
            </ButtonGroup>
        </div>
        {milestone && milestone.attachments &&
            <ListOfAttachments canDelete={false} userType={'client'} attachments={milestone?.attachments}/>}
        <MilestoneErrors milestone={milestone}/>
    </div>
}

const mapStateToProps = ({payableAgreement}: ApplicationState) => ({});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        acceptPayableAgreement: (id: string, receiverBusinessEntityId: number) => {
            dispatch(acceptPayableAgreementRequest(id, receiverBusinessEntityId));
        },
        rejectPayableAgreement: (id: string, reason: string) => {
            dispatch(rejectPayableAgreementRequest({id, reason}));
        },
        approvePaymentRequest: (milestoneId: string, payableAgreementId: string | undefined, tfaVerificationCode: string) => {
            dispatch(approvePaymentRequest(milestoneId, payableAgreementId ?? "", tfaVerificationCode));
        },
        rejectMilestonePaymentRequest: (milestoneId: string, payableAgreementId: string, reason: string) => {
            dispatch(rejectPaymentRequest(milestoneId, payableAgreementId ?? "", reason));
        },
        requestCancelMilestone: (milestoneId: string, payableAgreementId: string, reason: string) => {
            dispatch(cancelMilestoneRequest(milestoneId, payableAgreementId, reason));
        },
        approveReleaseOfDepositRequest: (milestoneId: string) => {
            dispatch(approveDepositRequest(milestoneId));
        },
        rejectReleaseOfDepositRequest: (milestoneId: number, reason: string) => {
            dispatch(rejectDepositRequest(milestoneId, reason));
        },
        approveCancellationOfMilestone: (milestoneId: number) => {
            dispatch(approveCancellationOfMilestoneRequest(milestoneId));
        },
        sendPayableAgreementApprovalTFACode: () => {
            dispatch(sendPayableAgreementApprovalTFACode())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MilestoneCustomerActions);
