import React, {useEffect, useState} from "react";
import ServiceManager from "../../services/ServiceManager";
import {useHistory} from "react-router-dom";
import {UserProvider} from "../Context/UserContext";
import {AxiosError, AxiosResponse} from "axios";
import {useHandleRequestError} from "../../pyntax/lib/data/ResourceManagerFactory";
import TagManager from "react-gtm-module";
import User from "../../models/User";
import {ApiResponse} from "../../models/Api/response";
/**
 * @param children
 * @constructor
 */
const ProtectedRoute: React.FunctionComponent<any> = ({children}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false),
        [user, setUser] = useState<User|undefined>(undefined),
        history = useHistory(),
        handleRequestError = useHandleRequestError((e: AxiosError) => {});

    const setTagManagerDataLayer = function(user: any) {
        const nameToken = user && user.name ? user.name.split(" ") : [];
        const lastName = nameToken[nameToken.length - 1]
        const firstName = nameToken.splice(0, nameToken.length - 1).join(" ");

        TagManager.dataLayer({
            dataLayer: {
                // "business_name": "", // ToDo: Enable when we have this value.
                "created_at":  user.created_at,
                "first_name":  user.first_name || firstName,
                "last_name":  user.last_name || lastName,
                "user_email":  user.email,
                "user_id":  user.id
            }
        })

        // Passing the User.id and user data.
        ServiceManager.analytics.identifyUser(user.id, user);
    }

    useEffect(() => {
        const access_token = ServiceManager.storageService.getAccessToken();
        if (access_token !== null && access_token !== undefined) {
            setIsLoggedIn(true);
            const userFromStorage = ServiceManager.storageService.getUser();
            if (!!userFromStorage) {
                setUser(userFromStorage);
                setTagManagerDataLayer(userFromStorage);
            } else {
                ServiceManager.dataApi.user.getById("1").then((res: AxiosResponse<ApiResponse<User>>) => {
                    setUser(res.data.data);
                    setTagManagerDataLayer(res.data.data)
                }).catch(handleRequestError);
            }
        } else {
            setIsLoggedIn(false);
            history.push('/login');
        }

        ServiceManager.analytics.trackPage(window.location.pathname + window.location.search);
    }, []);

    return isLoggedIn ? <UserProvider value={user}>{children}</UserProvider> : null;
}

export default ProtectedRoute;
