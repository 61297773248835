class FeeCalculator {
    private readonly defaultFeePercentage: number = 1;

    /**
     *
     * @param amount
     * @param feePercentage
     */
    calculateFee(amount: number, feePercentage: number = 0): number {
        if (feePercentage === 0) {
            feePercentage = this.defaultFeePercentage;
        }

        return amount * (feePercentage / 100);
    }

    /**
     *
     * @param amount
     * @param feePercentage
     */
    deductFee(amount: number, feePercentage: number = 0): number {
        const feeAmount = this.calculateFee(amount, feePercentage);

        return amount - feeAmount;
    }
}

export default (new FeeCalculator())
