import React from "react";

/**
 * @deprecated
 *
 * Please use src/components/Forms/common.tsx
 */
class FormUtils {

    /**
     * @deprecated
     *
     * @param error
     * @param errorClass
     */
    renderErrorClass(error: string | any | undefined, errorClass?: string) {
        errorClass = errorClass || " is-invalid ";
        if (error) {
            return errorClass;
        }

        return "";
    }

    /**
     * @deprecated
     *
     * @param errors
     * @param fieldName
     * @param errorMessage
     */
    renderErrorBlock(errors: any, fieldName: string, errorMessage: string) {
        errorMessage = errorMessage || "Invalid value provided"
        if (errors && errors[fieldName]) {
            return <div className="invalid-feedback">{errorMessage}</div>
        }

        return null;
    }
}

export default FormUtils
