import React, {useEffect, useState} from 'react';
import {SuccessButton} from "../Buttons";
import PayUnsecureAgreementDialog from "./Alerts/PayUnsecureAgreementDialog";
import {ApplicationState} from "../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {BankAccount} from "../../models/BankAccount";
import {fetchListRequest} from "../../store/bankAccount/action";
import {payPayableAgreementNow} from "../../store/incomingPayableAgreement/action";

interface PropsFromState {
    payableAgreementId: number
    canMakePayment: boolean
    listOfBankAccounts?: BankAccount[]
}

interface PropsFromDispatch {
    fetchListOfBankAccounts: (pageNumber: number, pageSize?: number) => any;
    payNow: (payableAgreementId: number, bankAccountId: number) => void
}

type AllProps = PropsFromState & PropsFromDispatch

const PayUnsecurePayableAgreementButton: React.FC<AllProps> = ({
                                                                   payableAgreementId,
                                                                   canMakePayment,
                                                                   fetchListOfBankAccounts,
                                                                   listOfBankAccounts,
                                                                   payNow
                                                               }) => {


    useEffect(() => {
        fetchListOfBankAccounts(1, 100)
    }, [])

    const [showPayUnsecureDialog, setShowPayUnsecureDialog] = useState<boolean>(false);
    return <>
        <PayUnsecureAgreementDialog showDialog={showPayUnsecureDialog}
                                    listOfBankAccounts={listOfBankAccounts}
                                    onCancel={() => {
                                        setShowPayUnsecureDialog(false)
                                    }}
                                    onConfirm={(bankAccountId: number) => {
                                        payNow(payableAgreementId, bankAccountId)
                                        setShowPayUnsecureDialog(false)
                                    }}/>

        {canMakePayment && <SuccessButton
            type={`button`} onClick={() => {
            if (canMakePayment) {
                setShowPayUnsecureDialog(true)
            }
        }}>Pay Now with paysecure</SuccessButton>}
        {!canMakePayment && <SuccessButton disabled={`disabled`}>Pay Now with paysecure</SuccessButton>}
    </>
}
const mapStateToProps = ({incomingPayableAgreement, bankAccount}: ApplicationState) => ({
    loading: incomingPayableAgreement?.loading || bankAccount.loading,
    listOfBankAccounts: bankAccount.listItems
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchListOfBankAccounts: (pageNumber: number, pageSize?: number) => {
            dispatch(fetchListRequest({pageNumber, pageSize}));
        },
        payNow: (payableAgreementId: number, bankAccountId: number) => {
            dispatch(payPayableAgreementNow(payableAgreementId, bankAccountId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayUnsecurePayableAgreementButton)
