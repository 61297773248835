import {action} from "typesafe-actions";
import {IncomingPayableAgreementActionTypes} from "./types";
import {PayableAgreement} from "../../models/PayableAgreement";
import {CreateRequest, PaginationMeta} from "../types";
import {ProductAgreement} from "../../models/ProductAgreement";

export const fetchListRequest = (payload: any) => action(IncomingPayableAgreementActionTypes.FETCH_LIST_REQUEST, payload);
export const fetchListSuccess = (listOfPayableAgreements: PayableAgreement[], paginationMeta: PaginationMeta) => action(IncomingPayableAgreementActionTypes.FETCH_LIST_SUCCESS, {
    listOfPayableAgreements,
    paginationMeta
});

export const updateRequest = (payload: any) => action(IncomingPayableAgreementActionTypes.UPDATE_REQUEST, payload);
export const fetchRequest = (id: string) => action(IncomingPayableAgreementActionTypes.FETCH_REQUEST, id);
export const fetchSuccess = (data: PayableAgreement) => action(IncomingPayableAgreementActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(IncomingPayableAgreementActionTypes.FETCH_ERROR, [message]);

export const cancelMilestoneRequest = (id: string, payableAgreementId: string, reason: string) => action(IncomingPayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_REQUEST, {id, payableAgreementId, reason});
export const cancelMilestoneError = (message: string) => action(IncomingPayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_ERROR, message);
export const cancelMilestoneSuccess = () => action(IncomingPayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_SUCCESS);

export const sendPayableAgreementApprovalTFACode = () => action(IncomingPayableAgreementActionTypes.SEND_TFA_CODE_REQUEST);
export const sendPayableAgreementApprovalTFACodeSuccess = () => action(IncomingPayableAgreementActionTypes.SEND_TFA_CODE_SUCCESS);
export const sendPayableAgreementApprovalTFACodeError = (message: string) => action(IncomingPayableAgreementActionTypes.SEND_TFA_CODE_ERROR);

export const approvePaymentRequest = (milestoneId: string, payableAgreementId: string, tfaVerificationCode: string) => action(IncomingPayableAgreementActionTypes.APPROVE_PAYMENT_REQUEST, {milestoneId, payableAgreementId, tfaVerificationCode});
export const rejectPaymentRequest = (milestoneId: string, payableAgreementId: string, reason: string) => action(IncomingPayableAgreementActionTypes.REJECT_PAYMENT_REQUEST, {
    milestoneId, payableAgreementId, reason});

export const acceptPayableAgreementRequest = (id: string, receiverBusinessEntityId: number) => action(IncomingPayableAgreementActionTypes.ACCEPT_PAYABLE_AGREEMENT, {id, receiverBusinessEntityId});
export const rejectPayableAgreementRequest = (payload: any) => action(IncomingPayableAgreementActionTypes.REJECT_PAYABLE_AGREEMENT, payload);

export const approveDepositRequest = (id: string) => action(IncomingPayableAgreementActionTypes.APPROVE_DEPOSIT_REQUEST, id);
export const rejectDepositRequest = (milestoneId: number, reason: string) => action(IncomingPayableAgreementActionTypes.REJECT_DEPOSIT_REQUEST, {
    milestoneId,
    reason
});

export const approveCancellationOfMilestoneRequest = (id: number) => action(IncomingPayableAgreementActionTypes.APPROVE_CANCEL_MILESTONE_FETCH_REQUEST, {id});
export const rejectCancellationOfMilestoneRequest = (id: number) => action(IncomingPayableAgreementActionTypes.REJECT_CANCEL_MILESTONE_FETCH_REQUEST, {id});
export const payPayableAgreementNow = (payableAgreementId: number, bankAccountId: number) => action(IncomingPayableAgreementActionTypes.PAY_UNSECURE_AGREEMENT_REQUEST, {
    payableAgreementId,
    bankAccountId
})
