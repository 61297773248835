import {AxiosResponse} from "axios";
import {all, call, put, takeEvery} from "redux-saga/effects";
import ServiceManager from "../../services/ServiceManager";
import {fetchSuccess, fetchError} from "./action";
import {handleError} from "../saga";
import {ProfileActionTypes} from "./types";
import {Profile} from "../../models/Profile";
import {addToast} from "../action";

/**
 * @param action
 */
function* handleFetch(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.profile, ServiceManager.dataApi.profile.getById
        ], action.payload);

        const profile: Profile = response.data.data as Profile;
        yield put(fetchSuccess(profile));

    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleUpdate(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.profile,
                'update'
            ],
            1,
            action.payload.data
        )

        const profile: Profile = response.data.data as Profile;
        yield put(fetchSuccess(profile));
        yield put(addToast("Profile updated successfully.", "success"));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

function* watchFetchRequest() {
    yield takeEvery(ProfileActionTypes.FETCH_REQUEST, handleFetch)
    yield takeEvery(ProfileActionTypes.UPDATE_REQUEST, handleUpdate)
}

function* profileSaga() {
    yield all([
        watchFetchRequest()
    ]);
}

export default profileSaga;