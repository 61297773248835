import React from "react";
import DashboardPage from "../../pages/Dashboard/DashboardPage";
import IncomingPayableAgreementResponsePage from "../../pages/PayableAgreement/IncomingPayableAgreementResponsePage";
import RecipientListPage from "../../pages/Contacts/RecipientListPage";

import CreateProductAgreementPage from "../../pages/PayableAgreement/CreateProductAgreementPage";
import IncomingAgreementListPage from "../../pages/PayableAgreement/IncomingAgreementListPage";
import AgreementListPage from "../../pages/PayableAgreement/AgreementListPage";
import ProfilePage from "../../pages/User/ProfilePage";
import ConnectBankAccountPage from "../../pages/BankAccount/ConnectBankAccountPage";
import BankAccountsPage from "../../pages/BankAccount/BankAccountsPage";
import AddNewBusiness from "../../pages/Business/AddNewBusiness";
import EditBusiness from "../../pages/Business/EditBusiness";
import BusinessListPage from "../../pages/Business/BusinessListPage";
import LogoutPage from "../../pages/Auth/LogoutPage";
import LoginPage from "../../pages/Auth/LoginPage";
import ForgotPasswordPage from "../../pages/Auth/ForgotPasswordPage";
import ResetPasswordPage from "../../pages/Auth/ResetPasswordPage";
import SignUpPage from "../../pages/Auth/SignUpPage";
import EditAgreementPage from "../../pages/PayableAgreement/EditAgreementPage";
import ThirdPartyInvoicePage from "../../pages/ThirdPartyIntegration/ThirdPartyInvoicePage";
import ThirdPartyIntegrationsListPage from '../../pages/ThirdPartyIntegration/ThirdPartyIntegrationsListPage';
import CreateThirdPartyConnection from "../../pages/ThirdPartyIntegration/CreateThirdPartyConnection";
import ThirdPartyViewPage from "../../pages/ThirdPartyIntegration/ThirdPartyViewPage";
import TokenLoginPage from "../../pages/Auth/TokenLogin";
import TransactionListPage from "../../pages/Transaction/TransactionListPage";
import EditRecipientPage from "../../pages/Contacts/EditRecipientPage";
import EditBankAccountPage from "../../pages/BankAccount/EditBankAccountPage";
import CreateRecipientPage from "../../pages/Contacts/CreateRecipientPage";
import EditProductAgreementPage from "../../pages/PayableAgreement/EditProductAgreementPage";
import IncomingProductAgreementResponsePage from "../../pages/PayableAgreement/IncomingProductAgreementResponsePage";
import CreateWizard from "../../pages/PayableAgreement/CreateWizard";

// import {default as CreateAgreementPage} from "../../pages/PayableAgreement/CreatePage";
import CreateAgreementPage from "../../pages/PayableAgreement/CreateAgreementPage";

export * from "./ProtectedRoute";

export type AppRoute = {
    path: string;
    component: React.ReactNode;
    isProtected: boolean;
}

export const AppRoutes: AppRoute[] = [
    {
        path: '/dashboard',
        component: <DashboardPage/>,
        isProtected: true
    },
    {
        path: '/incoming_agreements/:id/view',
        component: <IncomingPayableAgreementResponsePage/>,
        isProtected: true
    },
    {
        path: '/product/incoming_agreements/:id/view',
        component: <IncomingProductAgreementResponsePage/>,
        isProtected: true
    },
    {
        path: '/recipients/:id/edit',

        component: <EditRecipientPage/>,
        isProtected: true
    },
    {
        path: '/recipients/new',
        component: <CreateRecipientPage/>,
        isProtected: true
    },
    {
        path: '/recipients',
        component: <RecipientListPage/>,
        isProtected: true
    },
    {
        path: '/wizards/agreements',
        component: <CreateWizard />,
        isProtected: true
    },
    {
        path: '/agreements/:id/edit',
        component: <EditAgreementPage/>,
        isProtected: true
    },
    {
        path: '/agreements/new',
        component: <CreateAgreementPage/>,
        isProtected: true
    },
    {
        path: '/product/agreements/new',
        component: <CreateProductAgreementPage/>,
        isProtected: true
    },
    {
        path: '/product/agreements/:id/edit',
        component: <EditProductAgreementPage/>,
        isProtected: true
    },
    {
        path: '/agreements/incoming',
        component: <IncomingAgreementListPage/>,
        isProtected: true
    },
    {
        path: '/agreements',
        component: <AgreementListPage/>,
        isProtected: true
    },
    {
        path: '/profile',
        component: <ProfilePage/>,
        isProtected: true
    },
    {
        path: '/bank_accounts/:id/edit',
        component: <EditBankAccountPage/>,
        isProtected: true
    },
    {
        path: '/bank_accounts/connect',
        component: <ConnectBankAccountPage/>,
        isProtected: true
    },
    {
        path: '/bank_accounts',
        component: <BankAccountsPage/>,
        isProtected: true
    },
    {
        path: '/businesses/new',
        component: <AddNewBusiness/>,
        isProtected: true
    },
    {
        path: '/businesses/:id',
        component: <EditBusiness/>,
        isProtected: true
    },
    {
        path: '/businesses',
        component: <BusinessListPage/>,
        isProtected: true
    },
    {
        path: '/transactions',
        component: <TransactionListPage/>,
        isProtected: true
    },
    {
        path: '/logout',
        component: <LogoutPage/>,
        isProtected: true
    },
    {
        path: '/login',
        component: <LoginPage/>,
        isProtected: false
    },
    {
        path: '/forgot-password',
        component: <ForgotPasswordPage/>,
        isProtected: false
    },
    {
        path: '/update-password/:token/:email',
        component: <ResetPasswordPage/>,
        isProtected: false
    },
    {
        path: '/register',
        component: <SignUpPage/>,
        isProtected: false
    },
    {
        path: '/pay-invoice-now',
        component: <ThirdPartyInvoicePage/>,
        isProtected: false
    },
    {
        path: '/third_party_integration/:id/view',
        component: <ThirdPartyViewPage/>,
        isProtected: true,
    },
    {
        path: '/third_party_integration/connect',
        component: <CreateThirdPartyConnection/>,
        isProtected: true,
    },
    {
        path: '/third_party_integration',
        component: <ThirdPartyIntegrationsListPage/>,
        isProtected: true,
    },
    {
        path: '/tk_login',
        component: <TokenLoginPage />,
        isProtected: false
    }
]
