import React, {useEffect, useState} from "react";
import Milestone from "../../../models/Milestone";
import classnames from "classnames";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";
import {useForm, Controller} from "react-hook-form";
import {usePayableAgreement} from "../../Context/PayableAgreementContext";
import Block from "../../Blocks/Block";
import Utils from "../../../services/Utils";
import ButtonGroup from "../../Buttons/ButtonGroup";
import {DangerButton, PrimaryButton, SecondaryButton} from "../../Buttons";

export interface MilestoneFormDialogProps {
    onConfirm: (newMilestone: Milestone) => void
    onCancel: () => void
    milestone?: Milestone
    action: 'save' | 'update'
    nextMilestoneId: number
}


/**
 * @param showDialog
 * @param onConfirm
 * @param onCancel
 *
 * @constructor
 */
export const MilestoneFormDialog: React.FC<MilestoneFormDialogProps> = ({
                                                                            onConfirm,
                                                                            onCancel,
                                                                            milestone,
                                                                            nextMilestoneId,
                                                                            action
                                                                        }: MilestoneFormDialogProps) => {
    const defaultInputClasses = 'form-control';
    const {register, handleSubmit, reset, formState: {errors}, setError, control} = useForm<Milestone>({
        defaultValues: {
            status: 'pending',
            ...milestone
        }
    });
    const [dueBy, setDueBy] = useState<string>();

    useEffect(() => {
        if (milestone?.due_by) {
            setDueBy(milestone.due_by)
        }
    }, [milestone])

    // @deprecated --
    // ToDo: The following is not true anymore, we need to remove this.
    // const {currentPayableAgreement} = usePayableAgreement();

    // ToDo: Make sure we load this via the Contract's in the future
    const MILESTONE_FEE_PERC = 0.01;

    const onFormSubmit = (data: Milestone) => {
        // Check if the data.amount is not null and not a negative integer
        if (data.amount !== null && data.amount > 0) {
            const amount = parseFloat(data.amount.toString());

            data.amount_display = Utils.formatAmount(amount);
            data.milestone_fee = (amount * MILESTONE_FEE_PERC)
            data.milestone_fee_display = Utils.formatAmount(data.milestone_fee);

            data.milestone_fee = (MILESTONE_FEE_PERC * data.amount);
            // if (currentPayableAgreement?.pass_the_fee_to_customer) {
            //     data.total_amount = data.amount + data.milestone_fee;
            // } else {
            data.total_amount = data.amount;
            data.amount = data.total_amount - data.milestone_fee;
            // }

            if (action === 'save') {
                data.id = nextMilestoneId
            }

            // Saving the data.
            onConfirm(data);

            // Reset the form.
            reset();
        } else {
            setError('amount', {
                message: 'Please enter a valid amount greater than $0.00'
            });
        }
    }

    return <div className={`add-new-milestone-container`}>
        <Block>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="row py-2">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="title">Milestone title <span className="text-danger">*</span></label>
                            <input type={`text`} data-testid={'milestone_title'}
                                   className={classnames(defaultInputClasses, {'is-invalid': errors.title})}
                                   id="title" {...register('title', {required: true})} />
                            {errors.title && <div className="invalid-feedback">Please enter milestone title</div>}
                        </div>
                    </div>
                </div>
                <div className="row py-2">
                    <div className="col-md-8 col-xs-12">
                        <div className="form-group text-area-fh">
                            <label htmlFor="description">Short description</label>
                            <textarea data-testid={'milestone_description'}
                                      className={classnames(defaultInputClasses, {'is-invalid': errors.description})}
                                      id="description" rows={4} {...register('description')} />
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <div className="form-group">
                            <label htmlFor="amount">Amount <span className="text-danger">*</span></label>
                            <input type="text" data-testid={'milestone_amount'}
                                   className={classnames(defaultInputClasses, {'is-invalid': errors.amount})}
                                   id="amount" {...register('amount', {
                                required: true,
                                pattern: /\d{0,2}(\.\d{1,2})?/
                            })} name="amount"/>
                            {errors.amount && <div className="invalid-feedback">Please enter an amount</div>}
                        </div>
                        {/*<div className="form-group">*/}
                        {/*    <div className="input-group">*/}
                        {/*        <span className="input-group-text"><i className="fa fa-dollar-sign"/></span>*/}
                        {/*        <input type="number" disabled={true} className={classnames(defaultInputClasses, {'is-invalid': errors.amount})} value={milestoneFee} />*/}
                        {/*        {errors.amount && <div className="invalid-feedback">Invalid amount</div>}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="form-group">
                            <label htmlFor="due_by">Due date <span className="text-danger">*</span></label>
                            <div className={classnames(defaultInputClasses + ` due_by`, {'is-invalid': errors.due_by})}>
                                <Controller name={`due_by`}
                                            control={control} rules={{required: true}}
                                            data-testid={'milestone_due_by'}
                                            render={(props) => <DayPickerInput
                                                value={dueBy}
                                                onDayChange={(args: Date) => {
                                                    props.field.onChange(MomentLocaleUtils.formatDate(args, 'DD/MM/YYYY'))
                                                    // setDueBy(MomentLocaleUtils.formatDate(args, 'DD/MM/YYYY'))
                                                }}
                                                format={`DD/MM/YYYY`}
                                                formatDate={MomentLocaleUtils.formatDate}
                                                parseDate={MomentLocaleUtils.parseDate}
                                                placeholder={``}/>}/>
                            </div>
                            {errors.due_by &&
                                <div className="invalid-feedback">Invalid due date</div>}
                        </div>
                    </div>
                </div>
                <div className="row block-footer">
                    <div className="col footer-content">
                        <ButtonGroup>
                            {/*<SecondaryButton type={`button`}><i className="fa fa-copy fa-2x"/></SecondaryButton>*/}
                            <SecondaryButton data-testid={'save-milestone'}
                                             type={'submit'}>{action === 'save' ? 'Add' : 'Update'} Milestone</SecondaryButton>
                            <DangerButton type={'button'} onClick={() => {
                                reset();
                                onCancel();
                            }}>Cancel</DangerButton>
                        </ButtonGroup>
                    </div>
                </div>
            </form>
        </Block>
    </div>
    // </ConfirmDialog>
}

export default MilestoneFormDialog
