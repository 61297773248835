/**
 *
 * @param variableName
 * @param defaultValue
 *
 * @returns {*}
 */
function getVarFromEnv(variableName: string, defaultValue: any = null) {
    return process.env['REACT_APP_' + variableName] || defaultValue;
}

// Let's figure out what the ENV is.
const APP_ENV = getVarFromEnv('APP_ENV')

export default {
    APPLICATION_NAME: getVarFromEnv('APPLICATION_NAME'),
    BASE_URL: getVarFromEnv('BASE_URL'),
    API_URL: getVarFromEnv('BASE_API_URI'),
    UNAUTHENTICATED_REDIRECT_ROUTE: getVarFromEnv('UNAUTHENTICATED_REDIRECT_ROUTE'),
    SOCIAL_GOOGLE_APP_CLIENT_ID: getVarFromEnv('SOCIAL_GOOGLE_APP_CLIENT_ID'),
    GA_TRACKING_ID: getVarFromEnv('GA_TRACKING_ID'),
    APP_ENV: APP_ENV === undefined ? 'prod' : APP_ENV
}
