import {Reducer} from "redux";
import {LoginActionsTypes, LoginState} from "./types";
import {ToastActionTypes} from "../types";

export const initialState: LoginState = {
    user: undefined,
    loading: false,
    notifications: {
        errors: [],
        alerts: [],
        infos: [],
        warnings: [],
        success: []
    },
    isLoggedIn: false,
    toasts: [],
}

const reducer: Reducer<LoginState> = (state = initialState, action) => {
    switch (action.type) {
        case LoginActionsTypes.FORGOT_PASSWORD:
        case LoginActionsTypes.RESET_PASSWORD:
        case LoginActionsTypes.FETCH_REQUEST:
        case LoginActionsTypes.FETCH_USER_REQUEST:
        case LoginActionsTypes.LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
                notifications: {
                    errors: [],
                    alerts: [],
                    infos: [],
                    warnings: [],
                    success: []
                },
                toasts: []
            };

        case ToastActionTypes.RESET_TOASTS:
            return {...state, toasts: []}

        case ToastActionTypes.ADD_TOAST:
            const existingToasts = state.toasts ?? [];
            return {...state, toasts: [...existingToasts, action.payload]}

        case LoginActionsTypes.LOGOUT_SUCCESS:
            return initialState;

        case LoginActionsTypes.FETCH_SUCCESS:
            return {
                ...state,
                loading: true,
                user: action.payload,
                isLoggedIn: true
            };

        case LoginActionsTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: {
                    ...state.notifications,
                    success: [
                        "Your password has been updated. You can now login with the new password."
                    ]
                }
            }

        case LoginActionsTypes.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: {
                    ...state.notifications,
                    success: [
                        "We sent a recovery link to you. Click on the link in the email to reset your password."
                    ]
                }
            };

        case LoginActionsTypes.FETCH_ERROR:
            return {
                ...state, loading: false, user: undefined, isLoggedIn: false,
                notifications: {
                    ...state.notifications,
                    errors: action.payload
                }
            };

        case LoginActionsTypes.INIT_SOCIAL_REGISTER_OR_LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                socialResponsePayload: action.payload
            };

        default:
            return state;
    }
}

export {reducer as LoginReducer}
