import React, {useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import MainLayout from "../../components/Layouts/MainLayout";
import Block from "../../components/Blocks/Block";
import {SuccessButton} from "../../components/Buttons";
import Url from "../../components/Forms/Fields/Url";
import {useForm, FormProvider} from "react-hook-form";
import Description from "../../components/Forms/Fields/Description";
import TotalAmount from "../../components/Forms/Fields/TotalAmount";
import AgreementTitle from "../../components/Forms/Fields/AgreementTitle";
import {ProductAgreement} from "../../models/ProductAgreement";

interface Props extends RouteComponentProps<{ type: string }> {
}

const TOTAL_STEPS = 4

const CreateWizard = (props: Props) => {
    const [step, setStep] = useState<number>(1),
        methods = useForm<ProductAgreement>(),
        onSubmit = (data: ProductAgreement) => {
            if (step === TOTAL_STEPS) {
                // Submit the actual form.
                alert('Submit my form please.');
            } else {
                setStep(step + 1);
            }
        }

    return <MainLayout pageTitle={'Create an Agreement'} isLoading={false}>
        <FormProvider<ProductAgreement> {...methods}>
            <form className="content" onSubmit={methods.handleSubmit(onSubmit)}>
                <Block withHeaderBorder>

                    <div className="row push">
                        <div className="col">
                            <h2 className="heading border-bottom">Start agreement</h2>
                        </div>
                    </div>

                    <div className="row push ">
                        <div className="col-md-4">
                            <h3>Step {step}</h3>
                            {step === 1 && <>
                                <p className="fs-sm text-muted">
                                    If you are selling a product e.g. Golf club, a watch or an iPhone
                                    choose <em>Product</em>.
                                </p>
                                <p className="fs-sm text-muted">
                                    If you are providing any kind of service .e.g. cleaning services, plumbing or
                                    renovating
                                    a kitchen choose <em>Service</em>
                                </p>
                            </>}
                            {step === 2 && <>
                                <p className="fs-sm text-muted">
                                    If you have a product listed on Gumtree, Facebook marketplace etc. paste the URL
                                    here.
                                </p>
                            </>}
                        </div>
                        <div className="col-md-8">
                            {step === 1 && <div className="block-card-actions">
                                <a href="#" className="card-action" onClick={(e) => {
                                    e.preventDefault();
                                    setStep(2);
                                }}>
                                    <i className="svg-icon shop svg-2x"/>
                                    <h4>Product</h4>
                                </a>
                                <a href="/agreements/new" className="card-action">
                                    <i className="svg-icon transactions svg-2x"/>
                                    <h4>Service</h4>
                                </a>
                            </div>}

                            {step >= 2 && <div className="row push">
                                <div className="col">
                                    <Url
                                        placeholder={'Copy and paster URL from Facebook Market place or Gumtree etc.'}
                                        label={'Url'}/>
                                </div>
                            </div>}

                            {step >= 3 && <>
                                <div className="row push">
                                    <div className="col">
                                        <Description placeholder={'Product description'} label={'Product description'}/>
                                    </div>
                                </div>
                                <div className="row push">
                                    <div className="col">
                                        <TotalAmount label={'Price'}/>
                                    </div>
                                </div>
                            </>}

                            {step >= 4 && <>
                                <AgreementTitle label={'Agreement title'} placeholder={'Agreement title'}/>
                            </>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col push">
                            <div className="btn-group float-right">
                                {<SuccessButton type={'button'} disabled={step === 1}
                                                onClick={() => setStep(step - 1)}>{`<`} Prev</SuccessButton>}
                                <SuccessButton type={'submit'}>Next {`>`}</SuccessButton>
                            </div>
                        </div>
                    </div>
                </Block>
            </form>
        </FormProvider>
    </MainLayout>
}

export default withRouter(CreateWizard)
