import {all, call, put, takeEvery} from 'redux-saga/effects';
import {BankAccountActionTypes} from "./types";
import {fetchError, fetchSuccess, fetchListSuccess, connectABankAccountSuccess} from "./action";
import ServiceManager from "../../services/ServiceManager";
import {BankAccount} from "../../models/BankAccount";
import {AxiosResponse} from "axios";
import {PaginationMeta} from "../types";
import {handleError} from "../saga";
import {push} from "connected-react-router";
import {addToast} from "../action";
import {Events, makeFactory} from "../events";
import {EntityTypes} from "../../models/types";
import {ApiResponse} from "../../models/Api/response";

/**
 * @param action
 */
function* handleFetch(action: any) {
    try {
        const response: AxiosResponse<ApiResponse<BankAccount>> = yield call([
            ServiceManager.dataApi.bank_accounts,
            ServiceManager.dataApi.bank_accounts.getById
        ], action.payload);

        const bankAccount: BankAccount = response.data.data
        yield put(fetchSuccess({data: bankAccount}));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleDelete(action: any) {
    try {
        yield call([
                ServiceManager.dataApi.bank_accounts,
                ServiceManager.dataApi.bank_accounts.delete
            ],
            action.payload.id,
            {}
        );

        yield put(addToast("Bank account deleted successfully.", "success"));
        yield put(push(`/bank_accounts`));

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.BANK_ACCOUNTS.toString(), Events.DELETED, action.payload.id));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleListRequest(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.bank_accounts,
                ServiceManager.dataApi.bank_accounts.findAll
            ],
            action.payload.pageSize || 20,
            action.payload.pageNumber || 1,
            {}
        );

        const listOfBankAccounts: BankAccount[] = response.data.data as BankAccount[];
        const paginationMeta: PaginationMeta = response.data.meta as PaginationMeta;
        yield put(fetchListSuccess({
            list: listOfBankAccounts, paginationMeta
        }))
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

/**
 * @param action
 */
function* handleConnectionRequestFetch(action: any) {
    const {payableAgreementId, payableAgreementType} = action.payload;

    let connectionUrl = 'connect'
    if (payableAgreementId && payableAgreementType) {
        connectionUrl = `connect?returnPayableAgreementId=${payableAgreementId}&type=${payableAgreementType}`
    }
    try {
        const response: AxiosResponse<ApiResponse<BankAccount>> = yield call([
                ServiceManager.dataApi.bank_accounts,
                ServiceManager.dataApi.bank_accounts.update
            ], connectionUrl, {
                connection_type: 'Zepto_IAV'
            }
        );

        const bankAccount: BankAccount = response.data.data

        //ToDo: Hide this in the backend.
        if (bankAccount.payment_provider_agreement_url) {
            yield put(connectABankAccountSuccess(bankAccount.payment_provider_agreement_url))
        } else {
            yield put(fetchError("Oops! Something went wrong. Please try again later."));
        }
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleConnectAManualRequestFetch(action: any) {
    const {returnPayableAgreementId, agreementType} = action.payload

    let connectionUrl = 'connect';
    if (returnPayableAgreementId && agreementType) {
        connectionUrl = `connect?returnPayableAgreementId=${returnPayableAgreementId}&type=${agreementType}`
    }

    try {
        const response: AxiosResponse<ApiResponse<BankAccount>> = yield call([
                ServiceManager.dataApi.bank_accounts,
                ServiceManager.dataApi.bank_accounts.update
            ], connectionUrl, action.payload.data
        );

        const bankAccount: BankAccount = response.data.data;
        yield put(fetchSuccess({data: bankAccount}));
        yield put(push(`/bank_accounts/${bankAccount.id}/edit`));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleVerifyBankAccountRequestFetch(action: any) {
    const {id, confirmationCode} = action.payload

    try {
        const response: AxiosResponse<ApiResponse<BankAccount>> = yield call([
                ServiceManager.dataApi.bank_accounts,
                ServiceManager.dataApi.bank_accounts.update
            ], id + '/verify', {
                verification_code: confirmationCode
            }
        );
        const bankAccount: BankAccount = response.data.data;

        yield put(fetchSuccess({data: bankAccount}));
        yield put(push(`/bank_accounts/${bankAccount.id}/edit`));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* watchFetchRequest() {
    yield takeEvery(BankAccountActionTypes.FETCH_LIST_REQUEST, handleListRequest)
    yield takeEvery(BankAccountActionTypes.FETCH_REQUEST, handleFetch);
    yield takeEvery(BankAccountActionTypes.CONNECT_REQUEST, handleConnectionRequestFetch);
    yield takeEvery(BankAccountActionTypes.CREATE_REQUEST, handleConnectAManualRequestFetch);
    yield takeEvery(BankAccountActionTypes.VERIFY_BANK_ACCOUNT_REQUEST, handleVerifyBankAccountRequestFetch);
    yield takeEvery(BankAccountActionTypes.DELETE_REQUEST, handleDelete);
}

function* bankAccountsSaga() {
    yield all([
        watchFetchRequest()
    ]);
}

export default bankAccountsSaga;
