import {Recipient} from "../../models/Recipient";
import {PaginationMeta} from "../types";
import BaseState from "../state";

export enum RecipientActionTypes {
    FETCH_REQUEST = "@recipient/FETCH_REQUEST",
    FETCH_SUCCESS = "@recipient/FETCH_SUCCESS",
    FETCH_ERROR = "@recipient/FETCH_ERROR",

    SEND_AGREEMENT_REQUEST = "@recipient/SEND_AGREEMENT_REQUEST",
    UPDATE_REQUEST = "@recipient/UPDATE_REQUEST",
    CREATE_REQUEST = "@recipient/CREATE_REQUEST",
    DELETE_REQUEST = "@recipient/DELETE_REQUEST",

    FETCH_LIST_REQUEST = "@recipient/FETCH_LIST_REQUEST",
    FETCH_LIST_SUCCESS = "@recipient/FETCH_LIST_SUCCESS",
}

export interface RecipientState extends BaseState {
    readonly data?: Recipient;
    readonly listItems?: Recipient[];
    readonly paginationMeta?: PaginationMeta;
}
