import {all, call, put, takeEvery} from 'redux-saga/effects';
import {PayableAgreementActionTypes} from "./types";
import {
    downloadMilestoneReceiptError,
    downloadMilestoneReceiptSuccess,
    fetchError,
    fetchListSuccess,
    fetchRequest,
    fetchSuccess
} from "./action";
import {createRequest} from '../attachment/action';
import ServiceManager from "../../services/ServiceManager";
import {PayableAgreement} from "../../models/PayableAgreement";
import {AxiosResponse} from "axios";
import {PaginationMeta} from "../types";
import {handleError} from "../saga";
import {addToast} from "../action";
import Milestone from "../../models/Milestone";
import {push} from "connected-react-router";
import {Attachment} from "../../models/Attachment";
import {Events, makeFactory} from "../events";
import {EntityTypes} from "../../models/types";
import {ApiResponse} from "../../models/Api/response";

function* handleDownloadMilestoneReceipt(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.milestones,
                ServiceManager.dataApi.milestones.downloadFile
            ],
            action.payload.id + '/pdf', 'milestone_receipt.pdf'
        );
        yield put(downloadMilestoneReceiptSuccess())
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
        yield put(downloadMilestoneReceiptError(err?.response?.data?.message));
    }
}

function* handleDownloadReceipt(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.payableAgreement,
                ServiceManager.dataApi.payableAgreement.downloadFile
            ],
            action.payload.id + '/pdf', 'payable_agreement.pdf'
        );

    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handleMakeDepositRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
                ServiceManager.dataApi.milestones,
                ServiceManager.dataApi.milestones.update
            ], action.payload + '/request_deposit', {}
        );

        const milestone: Milestone = response.data as Milestone;
        if (milestone.payable_agreement_id) {
            // Tracking events.
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.DEPOSIT_REQUEST.toString(), Events.CREATED, milestone.payable_agreement_id));
            yield put(addToast("Request to secure funds sent successfully.", "success"));
            yield put(fetchRequest({id: milestone.payable_agreement_id.toString()}));
        }

    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handleMakePaymentRequest(action: any) {
    try {
        const response: AxiosResponse<ApiResponse<Milestone>> = yield call([
                ServiceManager.dataApi.milestones,
                ServiceManager.dataApi.milestones.update
            ], action.payload + '/request_payment', {}
        );

        const milestone: Milestone = response.data.data
        if (response.data && milestone.payable_agreement_id) {
            // Tracking events.
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.PAYOUT_REQUEST.toString(), Events.CREATED, milestone.payable_agreement_id));
            yield put(addToast("Payment requested successfully.", "success"));
            yield put(fetchRequest({id: milestone.payable_agreement_id.toString()}));
        }
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handleSendAgreementRequest(action: any) {
    try {
        const response: AxiosResponse<ApiResponse<PayableAgreement>> = yield call([
                ServiceManager.dataApi.payableAgreement,
                ServiceManager.dataApi.payableAgreement.update
            ], action.payload + '/send', {}
        );
        const payableAgreement: PayableAgreement = response.data.data

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.PAYABLE_AGREEMENT.toString(), Events.SENT, payableAgreement.id));

        yield put(addToast("Agreement sent successfully!", "success"));
        yield put(fetchRequest({id: payableAgreement.id.toString()}));

        yield put(push(`/agreements?filter=sent`))
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handleUpdateMilestoneRequest(action: any) {
    try {
        const response: AxiosResponse<ApiResponse<Milestone>> = yield call([
            ServiceManager.dataApi.milestones,
            ServiceManager.dataApi.milestones.update
        ], action.payload.id, action.payload.data);

        const milestone: Milestone = response.data.data
        if (milestone.payable_agreement_id) {
            // Tracking events.
            // @ts-ignore
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.MILESTONES.toString(), Events.UPDATED, milestone.payable_agreement_id));

            yield put(addToast("Milestone updated successfully!", "success"));
            yield put(fetchRequest({id: milestone.payable_agreement_id.toString()}));
        } else {
            yield put(fetchError("Oops! failed to update the milestone."));
        }
    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handleDeleteMilestoneRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.milestones,
            ServiceManager.dataApi.milestones.delete
        ], action.payload.id, {});

        const milestone: Milestone = response.data.data as any;

        if (milestone.payable_agreement_id) {
            // Tracking events.
            // @ts-ignore
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.MILESTONES.toString(), Events.DELETED, milestone.payable_agreement_id));

            yield put(addToast("Milestone deleted successfully!", "success"));
            yield put(fetchRequest({id: milestone.payable_agreement_id.toString()}));
        } else {
            yield put(fetchError("Oops! failed to delete the milestone."));
        }
    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handleNegotiateAmendMilestoneAmountRequest(action: any) {
    try {
        const response: AxiosResponse<ApiResponse<Milestone>> = yield call([
            ServiceManager.dataApi.milestones,
            ServiceManager.dataApi.milestones.update
        ], `${action.payload.milestoneId}/amend_milestone_amount`, {
            amend_milestone_amount: action.payload.amendedMilestoneAmount
        });

        const milestone: Milestone = response.data.data as Milestone;
        if (milestone.payable_agreement_id) {
            // Tracking events.
            // @ts-ignore
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.MILESTONES.toString(), Events.UPDATED, milestone.payable_agreement_id));

            yield put(addToast("Milestone amount amended successfully!", "success"));
            yield put(fetchRequest({id: milestone.payable_agreement_id.toString()}));
        } else {
            yield put(fetchError("Oops! failed to amend the milestone amount."));
        }
    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleApproveCancelMilestoneFetchRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.milestones,
            ServiceManager.dataApi.milestones.update
        ], action.payload.id + '/approve_cancellation', {});

        const milestone: Milestone = response.data.data as Milestone;
        if (milestone.payable_agreement_id) {
            // Tracking events.
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.MILESTONES.toString(), Events.CANCELLED, milestone.id));

            yield put(addToast("Cancellation request successfully approved!", "success"))
            yield put(fetchRequest({id: milestone.payable_agreement_id.toString()}));
        } else {
            yield put(fetchError("Oops! failed to cancel the milestone. Please try again."));
        }
    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* handleCancelAgreementRequest(action: any) {
    try {
        const response: ApiResponse<PayableAgreement> = yield call([
            ServiceManager.dataApi.payableAgreement,
            ServiceManager.dataApi.payableAgreement.update
        ], action.payload.id + '/cancel', {});

        const payableAgreement: PayableAgreement = response.data
        if (payableAgreement) {
            yield put(addToast("Agreement cancelled successfully", "success"))
            yield put(fetchRequest({id: payableAgreement.id.toString()}));
        } else {
            yield put(fetchError('Oops! Failed to cancel agreement. Please try again'));
        }
    } catch (err: any) {
        handleError(err)
        yield put(fetchError(err?.response?.data?.message))
    }
}

/**
 * @param action
 */
function* handleCancelMilestoneRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.milestones,
            ServiceManager.dataApi.milestones.update
        ], action.payload.id + '/cancel', {
            reason: action.payload.reason
        });

        const milestone: Milestone = response.data.data as any;
        if (milestone.payable_agreement_id) {
            // Tracking events.
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.MILESTONES.toString(), Events.REQUEST_CANCELLATION, milestone.id));
            yield put(addToast("Cancellation request successfully approved!", "success"))
            yield put(fetchRequest({id: milestone.payable_agreement_id.toString()}));
        } else {
            yield put(fetchError("Oops! failed to cancel the milestone. Please try again."));
        }
    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 *
 * @param action
 */
function* handleRejectCancelMilestoneRequest(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.milestones,
            ServiceManager.dataApi.milestones.update
        ], action.payload.id + '/reject_cancellation', {});

        const milestone: Milestone = response.data.data as any;
        if (milestone.payable_agreement_id) {
            // Tracking events.
            ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.MILESTONES.toString(), Events.REJECTED, milestone.id));
            yield put(addToast("Cancellation request has been rejected successfully!", "success"))
            yield put(fetchRequest(({id: milestone.payable_agreement_id.toString()})));
        } else {
            yield put(fetchError("Oops! failed to cancel the milestone. Please try again."));
        }
    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleFetch(action: any) {
    try {
        const response: AxiosResponse<ApiResponse<PayableAgreement>> = yield call([
            ServiceManager.dataApi.payableAgreement,
            ServiceManager.dataApi.payableAgreement.getById
        ], action.payload);

        const payableAgreement: PayableAgreement = response.data.data;
        yield put(fetchSuccess({data: payableAgreement}));
    } catch (err: any) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleListRequest(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.payableAgreement,
                ServiceManager.dataApi.payableAgreement.findAll
            ],
            action.payload.pageSize || 20,
            action.payload.pageNumber || 1,
            {
                filter: action.payload.filter ?? 'sent'
            }
        );

        const listOfPayableAgreements: PayableAgreement[] = response.data.data as PayableAgreement[];
        const paginationMeta: PaginationMeta = response.data.meta as PaginationMeta;
        yield put(fetchListSuccess({
            list: listOfPayableAgreements, paginationMeta
        }))
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleCreate(action: any) {
    try {
        // We want to clean all the milestones and remove id the as they were only used for FE things.
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.payableAgreement,
                ServiceManager.dataApi.payableAgreement.create
            ],
            Object.assign({}, action.payload.data, {id: null})
        )

        const payableAgreement: PayableAgreement = response.data.data as PayableAgreement;
        yield put(fetchSuccess({data: payableAgreement}));

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.PAYABLE_AGREEMENT.toString(), Events.CREATED, payableAgreement.id));

        const attachmentsToBeSaved = action.payload.data.attachments ?? [];
        if (attachmentsToBeSaved && attachmentsToBeSaved.length > 0) {
            yield addingAttachments(attachmentsToBeSaved, payableAgreement.id);
        }

        yield put(push(`/agreements/${payableAgreement.id}/edit`));
    } catch (err) {
        handleError(err);

        yield put(fetchError(err?.response?.data?.message));
        // yield put(fetchError(err.message))
    }
}

/**
 * @param action
 */
function* handleCreateMilestone(action: any) {
    try {
        // We want to clean all the milestones and remove id the as they were only used for FE things.
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.milestones,
                ServiceManager.dataApi.milestones.create
            ],
            Object.assign({}, action.payload.data, {id: null})
        )

        const milestone: Milestone = response.data.data as Milestone;

        yield put(addToast("Milestone added successfully!", "success"))
        if (milestone && milestone.payable_agreement_id) {
            yield put(fetchRequest(({id: milestone?.payable_agreement_id.toString()})));
        }

        // Tracking events.
        ServiceManager.analytics.trackEvent(makeFactory(EntityTypes.MILESTONES.toString(), Events.CREATED, milestone.id));
    } catch (err) {
        handleError(err);

        yield put(fetchError(err?.response?.data?.message));
        yield put(fetchError(err.message))
    }
}

function* addingAttachments(attachmentsToBeSaved: Attachment[], payableAgreementId: number) {
    if (attachmentsToBeSaved && attachmentsToBeSaved.length > 0) {

        for (let i = 0; i < attachmentsToBeSaved.length; i++) {
            let attachmentToBeSaved: Attachment = attachmentsToBeSaved[i];

            // We need to upload the file now,
            if (attachmentsToBeSaved && (attachmentToBeSaved.id == null || attachmentToBeSaved.id == undefined)) {
                // @ts-ignore
                yield put(createRequest({
                    "file": attachmentToBeSaved.file,
                    "parent_entity_type_id": payableAgreementId,
                    "parent_entity_type": `payable_agreements`,
                }));
                yield put(addToast(`Attaching file ${attachmentToBeSaved.file?.name}`, "info"))
                yield put(fetchRequest({id: payableAgreementId.toString()}));
            }
        }

        yield put(addToast("File/s attached successfully.", "success"));
    }
}

/**
 * @param action
 */
function* handleUpdate(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.payableAgreement,
                'update'
            ],
            action.payload.id,
            action.payload.data
        )

        const payableAgreement: PayableAgreement = response.data.data as PayableAgreement;
        yield put(addToast("Agreement updated successfully.", "success"));
        yield put(fetchSuccess({data: payableAgreement}));
        yield put(fetchRequest({id: action.payload.id.toString()}));

        // const attachmentsToBeSaved = action.payload.data.attachments ?? [];
        // if (attachmentsToBeSaved && attachmentsToBeSaved.length > 0) {
        //     yield addingAttachments(attachmentsToBeSaved, payableAgreement.id);
        // }

        // ToDo: Not the best way.
        // Move pages so we can reload.
        // yield put(push(`/agreements/${payableAgreement.id}/edit`));
        // yield () => window.location.reload();
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* watchFetchRequest() {
    yield takeEvery(PayableAgreementActionTypes.SEND_AGREEMENT_REQUEST, handleSendAgreementRequest);
    yield takeEvery(PayableAgreementActionTypes.REQUEST_DEPOSIT_REQUEST, handleMakeDepositRequest);
    yield takeEvery(PayableAgreementActionTypes.REQUEST_PAYMENT_REQUEST, handleMakePaymentRequest);

    yield takeEvery(PayableAgreementActionTypes.DELETE_MILESTONE_FETCH_REQUEST, handleDeleteMilestoneRequest)

    yield takeEvery(PayableAgreementActionTypes.UPDATE_MILESTONE_REQUEST, handleUpdateMilestoneRequest)

    yield takeEvery(PayableAgreementActionTypes.CANCEL_MILESTONE_FETCH_REQUEST, handleCancelMilestoneRequest)
    yield takeEvery(PayableAgreementActionTypes.APPROVE_CANCEL_MILESTONE_FETCH_REQUEST, handleApproveCancelMilestoneFetchRequest)
    yield takeEvery(PayableAgreementActionTypes.REJECT_CANCEL_MILESTONE_FETCH_REQUEST, handleRejectCancelMilestoneRequest)

    yield takeEvery(PayableAgreementActionTypes.FETCH_LIST_REQUEST, handleListRequest)
    yield takeEvery(PayableAgreementActionTypes.FETCH_REQUEST, handleFetch)
    yield takeEvery(PayableAgreementActionTypes.CREATE_REQUEST, handleCreate)
    yield takeEvery(PayableAgreementActionTypes.UPDATE_REQUEST, handleUpdate)

    yield takeEvery(PayableAgreementActionTypes.CREATE_MILESTONE_REQUEST, handleCreateMilestone)

    // yield takeEvery(PayableAgreementActionTypes.DOWNLOAD_RECEIPT_REQUEST, handleDownloadReceipt);
    yield takeEvery(PayableAgreementActionTypes.DOWNLOAD_MILESTONE_RECEIPT_REQUEST, handleDownloadMilestoneReceipt);
    yield takeEvery(PayableAgreementActionTypes.NEGOTIATE_AMEND_MILESTONE_AMOUNT_REQUEST, handleNegotiateAmendMilestoneAmountRequest);
    yield takeEvery(PayableAgreementActionTypes.CANCEL_AGREEMENT_REQUEST, handleCancelAgreementRequest);
}

function* payableAgreementSaga() {
    yield all([
        watchFetchRequest()
    ]);
}

export default payableAgreementSaga;
