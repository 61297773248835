import React, {useEffect, useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {isMobile, isTablet} from 'react-device-detect';
import Styles from '../../styles'

type ConfirmDialogProps = {
    showDialog: boolean;
    children: React.ReactNode;
    title?: string;
    maxWidth?: 'sm' | 'md' | 'lg' | 'xl'
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    titleContainer: Styles.Dialog.titleContainer,
    title: Styles.Dialog.title,
    dangerButton: Styles.Button.dangerButton
}))


const FormDialog: React.FC<ConfirmDialogProps> = ({
                                                      children,
                                                      showDialog,
                                                      title,
                                                      maxWidth = 'sm'
                                                  }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);

    useEffect(() => {
        setFullScreen(isTablet || isMobile);
    }, [isMobile, isTablet])

    const classes = useStyles();
    return <Dialog open={showDialog} fullWidth={true} maxWidth={maxWidth} fullScreen={fullScreen}>
        <MuiDialogContent className={`dialog-content-container`}>
            <Typography className={classes.title} variant="h6">{title}</Typography>
            {children}
        </MuiDialogContent>
    </Dialog>
}

export default FormDialog;
