import React from "react";
import Enums from "../../enums";

import Milestone from "../../models/Milestone";
import {PaymentRequest} from "../../models/PaymentRequest";
import {ErrorNotification} from "../Notifications";
import moment from "moment";


interface Props {
    milestone?: Milestone
}

export const MilestoneErrors = ({milestone}: Props) => {
    if (!milestone) {
        return null
    }

    const renderErrors = () => {
        switch (milestone?.status) {
            case Enums.Milestones.REQUESTING_MONEY_FAILED:
                return milestone?.payment_requests && milestone?.payment_requests?.length > 0 &&
                    <dl>
                        {/*@ts-ignore*/}
                        {milestone?.payment_requests?.map((value: PaymentRequest, index: number) => {
                            return <dd>
                                <ErrorNotification title={"Reason"}>
                                    <>{value?.payment_processing_failed_reason}</>
                                </ErrorNotification>
                            </dd>
                        })}
                    </dl>
            case 'reject_deposit':
                return <dl>
                    <dd>
                        <ErrorNotification
                            title={`Request for funds was rejected on ${moment(milestone.deposit_request_rejected_on).format('DD/MM/YYYY')}`}>
                            <>The request for deposit was rejected by the client
                                because: {milestone.deposit_request_rejected_reason}</>
                        </ErrorNotification>
                    </dd>
                </dl>
            case 'payment_rejected':
                return milestone?.payment_requests && milestone?.payment_requests?.length > 0 &&
                    <dl>
                        {/*@ts-ignore*/}
                        {milestone?.payment_requests?.map((value: PaymentRequest, index: number) => {
                            return <dd>
                                <ErrorNotification title={"Reason"}>
                                    <>{value?.reason_of_rejection}</>
                                </ErrorNotification>
                            </dd>
                        })}
                    </dl>
            case 'cancelled':
            case 'cancellation_requested':
                return <dl>
                    <dd><ErrorNotification
                        title={`Request for cancellation was carried out on ${moment(milestone.cancel_milestone_requested_on).format('DD/MM/YYYY')}`}>
                        <>
                            <div className={`push`}>Reason for cancellation
                                by <b>{milestone.cancellation_requested_by === 'seller' ? 'contractor' : 'customer'}</b> - {milestone.cancel_milestone_reason}
                            </div>

                            {milestone?.cancel_milestone_requested_on_display && <>
                                <div className="push">
                                    <b>{milestone.cancellation_requested_by === 'seller' ? 'customer' : 'contractor'}</b> has
                                    disagreed to cancel this milestone, therefore this now becomes a pending dispute. We
                                    encourage both parties to start an open discussion and negotiation to resolve the
                                    issue.
                                </div>
                                <div className="push">
                                    If this still cannot be resolved we recommend a formal dispute resolution process
                                    with our assistance. For further information please call 1300 272 599 or
                                    disputes@paysecure.com.au.
                                </div>
                                <div className="push"><a href="#">Click here</a> for more info</div>
                            </>}
                        </>
                    </ErrorNotification></dd>
                </dl>

            case 'payment_refunded':
                return <dl>
                    <dd>
                        <ErrorNotification
                            title={`Request for cancellation was carried out on ${moment(milestone.cancel_milestone_requested_on).format('DD/MM/YYYY')}`}>
                            <>
                                <div className={`push`}>Reason for cancellation
                                    by <b>{milestone.cancellation_requested_by === 'seller' ? 'contractor' : 'customer'}</b> - {milestone.cancel_milestone_reason}
                                </div>
                                Refund reference
                                id: {((milestone?.payment_requests?.filter((pr) => pr.status === 'payment_refunded'))?.map(pr => `PR${pr.id}`))?.join(',')}
                            </>
                        </ErrorNotification>
                    </dd>
                </dl>
        }
    }

    return <div className="milestone-errors">
        {renderErrors()}
    </div>
}
