import React from "react";
import {ApplicationState} from "../../../store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import FormDialog from "../../Dialogs/FormDialog";
import {FormProvider, useForm} from "react-hook-form";
import {SuccessButton} from "../../Buttons";
import {ConnectForm} from "../../Forms/ConnectedForm";
import {TextInput} from "../../Forms/Fields/Input";
import {BankAccountConfirmation} from "../../../models/BankAccount";
import {verifyManualBankAccountRequest} from "../../../store/bankAccount/action";

interface OwnProps { //extends RouteComponentProps<{ id: string }> {
    showDialog: boolean,
    closeModal: () => void
    id: number
}

const mapStateToProps = ({bankAccount}: ApplicationState, props: OwnProps) => ({
    loading: bankAccount.loading,
    data: bankAccount.data,
})

const connector = connect(mapStateToProps, {})
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = OwnProps & PropsFromRedux

const VerifyBankAccountModal = (props: Props) => {
    const {showDialog, closeModal, id} = props
    const dispatch = useDispatch()
    const methods = useForm<BankAccountConfirmation>({
        shouldUseNativeValidation: true,
        defaultValues: {}
    })
    const {handleSubmit} = methods;
    const onSubmit = (data: BankAccountConfirmation) => {
        dispatch(verifyManualBankAccountRequest({
            ...data,
            id: id
        }))
        closeModal()
    }

    return <FormDialog showDialog={showDialog} title={`Link your bank account`}>
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ConnectForm<BankAccountConfirmation>>
                    {({register, formState: {errors}}) => <TextInput
                        errors={errors} {...register('confirmationCode', {
                        required: true
                    })} label={`Amount deposited in your account`} placeholder="Example: 0.84"/>}
                </ConnectForm>

                <div className="row push">
                    <div className="col">
                        <div className="form-group">
                            <SuccessButton type={`submit`}>Verify account</SuccessButton>
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider>
    </FormDialog>
}

export default connector(VerifyBankAccountModal)
