type NumberFormat = Intl.NumberFormat;

class Utils {
    private static _numberFormatter: NumberFormat;

    static formatAmount(amount: number) {
        Utils._numberFormatter = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0,
            //maximumFractionDigits: 0,
        });

        return Utils._numberFormatter.format(amount);
    }

    /**
     * @param element
     */
    isEmpty(element: any) {
        return element === undefined || element === null || element === "";
    }

    /**
     * @param displayObj
     * @param fieldName
     * @param defaultValue
     */
    displayProp(displayObj: any, fieldName: string, defaultValue?: string) {
        defaultValue = this.isEmpty(defaultValue) ? "" : defaultValue;
        if (!this.isEmpty(displayObj) && !this.isEmpty(displayObj[fieldName])) {
            return displayObj[fieldName];
        }

        return defaultValue;
    }

    formatMoney(amount: number) {
        return Utils.formatAmount(amount); /* $2,500.00 */
    }

    ucfirst(strValue: string) {
        return strValue.charAt(0).toUpperCase() + strValue.slice(1)
    }

    convertTextToClass(titleText: string) {
        return titleText.replaceAll(" ", "-").toLowerCase();
    }
}

export const utils = (new Utils())

export default Utils
