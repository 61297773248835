import React, {useRef, useState} from "react";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import Utils from "../../../services/Utils";

type ApprovePaymentRequestDialogProps = {
    showDialog: boolean
    onConfirm: (tfaVerificationCode: string) => void
    onCancel: () => void
    amount?: number
}

const ApprovePaymentRequestDialog: React.FC<ApprovePaymentRequestDialogProps> = ({
                                                                                     showDialog,
                                                                                     onConfirm,
                                                                                     onCancel,
                                                                                     amount
                                                                                 }) => {
    // const [showErrors, setShowErrors] = useState(false);
    // const verificationCodeRef = useRef(null);
    const handleOnConfirm = () => {
        // // @ts-ignore
        // if (verificationCodeRef && verificationCodeRef.current && verificationCodeRef.current.value) {
        //     setShowErrors(false);
        //     // @ts-ignore
            onConfirm("");
        // } else {
        //     setShowErrors(true);
        // }
    }
    let amountClause = null
    if (amount) {
        amountClause = <>{` `} of the <b>{Utils.formatAmount(amount)}</b></>
    }

    return <ConfirmDialog
        showDialog={showDialog}
        confirmButtonText={`Release payment`}
        onCancel={onCancel} onConfirm={handleOnConfirm} title={`Approve payment release`}>
        <p>Are you sure you're ready to release the payment{amountClause}? <b>Make sure you verify all the work has been completed for this
            milestone.</b></p>
        {/*{showErrors && <p className={`alert alert-danger`}>Please enter a valid verification code.</p>}*/}
        {/*<div className="row mt-2">*/}
        {/*    <div className="form-group">*/}
        {/*        <label className="col control-label">*/}
        {/*            A verification code has been sent to your phone, please enter the code below:*/}
        {/*        </label>*/}
        {/*        <div className="col">*/}
        {/*            <input ref={verificationCodeRef} type="text" className="form-control"*/}
        {/*                   placeholder="Verification code"/>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </ConfirmDialog>
}

export default ApprovePaymentRequestDialog;
