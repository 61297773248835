import {all, call, put, takeEvery} from 'redux-saga/effects';
import {AttachmentActionTypes} from "./types";
import {fetchError, fetchSuccess, fetchListSuccess, downloadSuccess} from "./action";
import ServiceManager from "../../services/ServiceManager";
import {Attachment} from "../../models/Attachment";
import {AxiosResponse} from "axios";
import {PaginationMeta} from "../types";
import {handleError} from "../saga";
import {addToast} from "../action";
import {ApiResponse, SuccessOK} from "../../models/Api/response";

/**
 * @param action
 */
function* handleFetch(action: any) {
    try {
        const response: AxiosResponse = yield call([
            ServiceManager.dataApi.attachment,
            ServiceManager.dataApi.attachment.getById
        ], action.payload);
        const bankAccount: Attachment = response.data.data as Attachment;
        yield put(fetchSuccess(bankAccount));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleListRequest(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.attachment,
                ServiceManager.dataApi.attachment.findAll
            ],
            action.payload.pageSize || 20,
            action.payload.pageNumber || 1,
            {}
        );

        const listOfBankAccounts: Attachment[] = response.data.data as Attachment[];
        const paginationMeta: PaginationMeta = response.data.meta as PaginationMeta;
        yield put(fetchListSuccess(listOfBankAccounts, paginationMeta))
    } catch (err) {
        handleError(err);
        yield put(fetchError(err.message))
    }
}

export function* handleCreate(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.attachment,
                ServiceManager.dataApi.attachment.uploadFile
            ],
            action.payload
        )

        const attachment: Attachment = response.data.data as Attachment;
        yield put(fetchSuccess(attachment));
        yield put(addToast("Attachment updated successfully.", "success"));

        // Todo: Let's do this properly, am not sure why the reload API is not working as expected.
        window.location.reload();
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
        yield put(fetchError(err.message))
    }
}

export function* handleDelete(action: any) {
    try {
        const response: ApiResponse<SuccessOK> = yield call(
            [
                ServiceManager.dataApi.attachment,
                ServiceManager.dataApi.attachment.delete
            ],
            action.payload.id
        )

        if (response.data.success === 'Ok') {
            yield put(addToast("Attachment deleted successfully.", "success"));
            // Todo: Let's do this properly, am not sure why the reload API is not working as expected.
            window.location.reload();
        } else {
            handleError(new Error('Something went wrong'));
        }
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
        yield put(fetchError(err.message))
    }
}

function* handleDownload(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.attachment,
                ServiceManager.dataApi.attachment.downloadFile
            ],
            `${action.payload.attachmentId}/download`, action.payload.fileName, {
                userType: action.payload.userType
            }
        )
        yield put(downloadSuccess());
        yield put(addToast("Attachment updated successfully.", "success"));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

/**
 * @param action
 */
function* handleUpdate(action: any) {
    try {
        const response: AxiosResponse = yield call(
            [
                ServiceManager.dataApi.attachment,
                'update'
            ],
            action.payload.id,
            action.payload.data
        )

        const attachment: Attachment = response.data.data as Attachment;
        yield put(fetchSuccess(attachment));
        yield put(addToast("Attachment updated successfully.", "success"));
    } catch (err) {
        handleError(err);
        yield put(fetchError(err?.response?.data?.message));
    }
}

function* watchFetchRequest() {
    yield takeEvery(AttachmentActionTypes.FETCH_LIST_REQUEST, handleListRequest)
    yield takeEvery(AttachmentActionTypes.FETCH_REQUEST, handleFetch);
    yield takeEvery(AttachmentActionTypes.CREATE_REQUEST, handleCreate);
    yield takeEvery(AttachmentActionTypes.DELETE_REQUEST, handleDelete);
    yield takeEvery(AttachmentActionTypes.UPDATE_REQUEST, handleUpdate);
    yield takeEvery(AttachmentActionTypes.DOWNLOAD_REQUEST, handleDownload);
}

function* attachmentSaga() {
    yield all([
        watchFetchRequest()
    ]);
}

export default attachmentSaga;
