import {action} from "typesafe-actions";
import {ThirdPartyConnectionActionTypes} from "./types";
import {ThirdPartyConnection} from "../../models/ThirdPartyConnection";
import {PaginationMeta} from "../types";
import {ThirdPartyAccountingLedgerAccount, ThirdPartyDocument} from "../../models/ThirdPartyDocument";

export const createRequest = (thirdPartyProvider: string) => action(ThirdPartyConnectionActionTypes.CREATE_REQUEST, thirdPartyProvider);
export const updateRequest = (payload: any) => action(ThirdPartyConnectionActionTypes.UPDATE_REQUEST, payload);

export const fetchListRequest = (payload: any) => action(ThirdPartyConnectionActionTypes.FETCH_LIST_REQUEST, payload);
export const fetchListSuccess = (listOfThirdPartyConnections: ThirdPartyConnection[], paginationMeta: PaginationMeta) => action(ThirdPartyConnectionActionTypes.FETCH_LIST_SUCCESS, {
    listOfThirdPartyConnections,
    paginationMeta
});
export const fetchRequest = (id: string) => action(ThirdPartyConnectionActionTypes.FETCH_REQUEST, id);
export const fetchSuccess = (data: ThirdPartyConnection) => action(ThirdPartyConnectionActionTypes.FETCH_SUCCESS, data);

export const fetchInvoiceRequest = (orgId: string, invoiceId: string) => action(ThirdPartyConnectionActionTypes.FETCH_INVOICE_REQUEST, {
    orgId,
    invoiceId
});
export const fetchInvoiceSuccess = (thirdPartyDocument: ThirdPartyDocument) => action(ThirdPartyConnectionActionTypes.FETCH_INVOICE_SUCCESS, thirdPartyDocument);

export const fetchError = (message: string) => action(ThirdPartyConnectionActionTypes.FETCH_ERROR, [message]);

export const convertDocumentToPayableAgreement = (orgId: string, invoiceId: string, receiverBusinessEntityId?: number) => action(ThirdPartyConnectionActionTypes.CONVERT_DOCUMENT_TO_PAYABLE_AGREEMENT_REQUEST, {
    orgId,
    invoiceId,
    receiverBusinessEntityId
});

export const fetchAccountListRequest = (orgId: string) => action(ThirdPartyConnectionActionTypes.FETCH_ACCOUNTS_LIST_REQUEST, {orgId});
export const fetchAccountListSuccess = (accounts: ThirdPartyAccountingLedgerAccount[]) => action(ThirdPartyConnectionActionTypes.FETCH_ACCOUNTS_LIST_SUCCESS, accounts);

