import React from "react";
import {PaymentRequestedMilestone} from "../../../models/Milestone";
import {canCancel} from "./utils";
import CancelMilestoneButton from "../Buttons/CancelMilestoneButton";
import {cancelMilestoneRequest} from "../../../store/payableAgreement/action";
import {useDispatch} from "react-redux";

interface Props {
    milestone: PaymentRequestedMilestone
}

export const PaymentRequestedMilestoneActions: React.FC<Props> = ({milestone}: Props) => {
    const dispatch = useDispatch();
    const payableAgreementId = milestone.payable_agreement_id

    if (!payableAgreementId) {
        return null
    }

    return <>
        {canCancel(milestone) && <CancelMilestoneButton handleCancelMilestone={(reason: string) => {
            dispatch(cancelMilestoneRequest(milestone.id, payableAgreementId, reason))
        }}/>}
    </>
}

export default PaymentRequestedMilestoneActions
