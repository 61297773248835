import {action} from "typesafe-actions";
import {BusinessEntityActionTypes} from "./types";
import {
    CreateRequest,
    FetchListRequestPayload,
    FetchRequest, FetchSuccess,
    ListOfWithPagination,
    UpdateRequest
} from "../types";
import {BusinessEntity} from "../../models/BusinessEntity";

export const fetchListRequest = (payload: FetchListRequestPayload) => action(BusinessEntityActionTypes.FETCH_LIST_REQUEST, payload);
export const fetchListSuccess = (payload: ListOfWithPagination<BusinessEntity>) => action(BusinessEntityActionTypes.FETCH_LIST_SUCCESS, {
    listOfBusinessEntities: payload.list,
    paginationMeta: payload.paginationMeta
});

export const updateRequest = (payload: UpdateRequest<BusinessEntity>) => action(BusinessEntityActionTypes.UPDATE_REQUEST, payload);
export const fetchRequest = (payload: FetchRequest) => action(BusinessEntityActionTypes.FETCH_REQUEST, payload.id);
export const fetchSuccess = (payload: FetchSuccess<BusinessEntity>) => action(BusinessEntityActionTypes.FETCH_SUCCESS, payload);
export const fetchError = (message: string) => action(BusinessEntityActionTypes.FETCH_ERROR, [message]);
export const createRequest = (payload: CreateRequest<BusinessEntity>) => action(BusinessEntityActionTypes.CREATE_REQUEST, payload);
