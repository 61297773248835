import {Attachment} from "../../models/Attachment";
import {PaginationMeta} from "../types";
import BaseState from "../state";

export enum AttachmentActionTypes {
    CREATE_REQUEST = "@attachment/CREATE_REQUEST",
    DELETE_REQUEST = "@attachment/DELETE_REQUEST",
    CREATE_SUCCESS = "@attachment/CREATE_SUCCESS",
    CREATE_ERROR = "@attachment/CREATE_ERROR",

    FETCH_REQUEST = "@attachment/FETCH_REQUEST",
    FETCH_SUCCESS = "@attachment/FETCH_SUCCESS",
    FETCH_ERROR = "@attachment/FETCH_ERROR",

    UPDATE_REQUEST = "@attachment/UPDATE_REQUEST",
    SAVE_REQUEST = "@attachment/SAVE_REQUEST",

    FETCH_LIST_REQUEST = "@attachment/FETCH_LIST_REQUEST",
    FETCH_LIST_SUCCESS = "@attachment/FETCH_LIST_SUCCESS",

    DOWNLOAD_REQUEST = "@attachment/DOWNLOAD_REQUEST",
    DOWNLOAD_SUCCESS = "@attachment/DOWNLOAD_SUCCESS",
    DOWNLOAD_ERROR = "@attachment/DOWNLOAD_ERROR",
}

export interface AttachmentState extends BaseState {
    readonly data?: Attachment;
    readonly listItems?: Attachment[];
    readonly paginationMeta?: PaginationMeta;
}
